import { Element } from "domhandler";

export type NullableEditorElement = Element | undefined | null;

export enum HtmlElement {
  H1 = "h1",
  H2 = "h2",
  H3 = "h3",
  H4 = "h4",
  H5 = "h5",
  H6 = "h6",
  Ol = "ol",
  Ul = "ul",
  Li = "li",
  Img = "img",
  Blockquote = "blockquote",
  Hr = "hr",
  Figure = "figure",
  Table = "table",
  Tr = "tr",
  Td = "td",
  Th = "th",
  A = "a",
  P = "p",
}

export const HeadingElements = [
  HtmlElement.H1,
  HtmlElement.H2,
  HtmlElement.H3,
  HtmlElement.H4,
  HtmlElement.H5,
  HtmlElement.H6,
];

export const ListElements = [HtmlElement.Ol, HtmlElement.Ul, HtmlElement.Li];

export const TableElements = [HtmlElement.Table, HtmlElement.Tr, HtmlElement.Td, HtmlElement.Th];
