import { Box, useTheme } from "@mui/material";
import React, { useContext } from "react";
import { Media } from "../../../api/muu-api/graphql/generated";
import { WIDGET_ROW_IMG_SIZE } from "../../../theme/sizings.theme";
import { LayoutContext } from "../../layout/layout.context";

interface IWidgetRowMediaProps {
  media?: Media | null;
  defaultIcon: React.ReactNode;
}

export const WidgetRowMedia: React.FC<IWidgetRowMediaProps> = (props) => {
  const { media, defaultIcon } = props;
  const theme = useTheme();
  const { breakpoint } = useContext(LayoutContext);

  return (
    <Box>
      {media && (
        <img
          height={WIDGET_ROW_IMG_SIZE[breakpoint]}
          src={media.mediaUrl}
          alt={media.mediaAlt ?? ""}
          style={{
            objectFit: "cover",
            objectPosition: "center",
            width: WIDGET_ROW_IMG_SIZE[breakpoint],
            borderRadius: theme.spacing(2.5),
          }}
        />
      )}
      {!media && (
        <Box
          sx={{
            width: WIDGET_ROW_IMG_SIZE[breakpoint],
            height: WIDGET_ROW_IMG_SIZE[breakpoint],
            backgroundColor: "accent1.25",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            borderRadius: 2.5,
          }}
        >
          {defaultIcon}
        </Box>
      )}
    </Box>
  );
};
