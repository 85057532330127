import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import ReactDOM from "react-dom/client";
import { I18nextProvider } from "react-i18next";
import { GraphQLProvider } from "./api/context/graphql.context";
import { App } from "./app/app";
import { AccessTokenContextProvider } from "./app/context/access-token.context";
import { TrackingProvider } from "./app/context/tracking/tracking.context";
import { UserContextProvider } from "./app/context/user.context";
import { LayoutContextProvider } from "./components/layout/layout.context";
import { NavMenuContextProvider } from "./components/layout/nav/context/nav-menu.context";
import { ThemeContextProvider } from "./components/layout/theme.context";
import { SnackbarProvider } from "./components/snackbar/snackbar.context";
import { i18n } from "./translations/i18n";
import { BrowserRouter } from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
  <I18nextProvider i18n={i18n}>
    <SnackbarProvider>
      <AccessTokenContextProvider>
        <GraphQLProvider>
          <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale={i18n.language}>
            <UserContextProvider>
              <NavMenuContextProvider>
                <TrackingProvider>
                  <LayoutContextProvider>
                    <ThemeContextProvider>
                      <BrowserRouter>
                        <App />
                      </BrowserRouter>
                    </ThemeContextProvider>
                  </LayoutContextProvider>
                </TrackingProvider>
              </NavMenuContextProvider>
            </UserContextProvider>
          </LocalizationProvider>
        </GraphQLProvider>
      </AccessTokenContextProvider>
    </SnackbarProvider>
  </I18nextProvider>,
);
