import { Widget } from "../../../../api/muu-api/graphql/generated";

export interface IWidgetOptions {
  height: number;
  width: number;
  placeHolderX: number;
  placeHolderY: number;
  currentDropIndex: number;
  sourceIndex: number;
}

export interface ICurrentlyDraggingWidget {
  id: string;
  initialX: number;
  initialY: number;
}

export interface ICategoryFilterInfo {
  id: string;
  isLocked: boolean;
}

export interface IExtendedWidget extends Omit<Widget, "categoryIds"> {
  categoryFilter: ICategoryFilterInfo[];
}

export const EDIT_DASHBOARD_PATH = "?edit";
export const UNCREATED_FIXED_WIDGET_ID = "UNCREATED_FIXED_WIDGET_ID";
