import { WidgetType } from "../../../../api/muu-api/graphql/generated";
import { NotificationStateFilterContextProvider } from "../../../../components/notification/context/notification-state-filter.context";
import { Breakpoint } from "../../../../theme/sizings.theme";
import { WidgetFavoriteFilterContextProvider } from "../context/widget-favorite-filter.context";
import { WidgetAddLinkFavoriteAction } from "../menu-components/widget-add-link-favorite-action.component";
import { WidgetAddThinkTankPostAction } from "../menu-components/widget-add-think-tank-post-action.component";
import { WidgetFavoriteFilter } from "../menu-components/widget-favorite-filter.component";
import { WidgetLunchMenusInfo } from "../menu-components/widget-lunch-info.component";
import { WidgetNotificationStateFilterAction } from "../menu-components/widget-notification-state-filter-action.component";
import { IExtendedWidget } from "../types/widget.type";

enum WidgetActionItem {
  FavoriteFilter = "FavoriteFilter",
  AddLinkFavorite = "AddLinkFavorite",
  NotificationStateFilter = "NotificationStateFilter",
  AddThinkTankPost = "AddThinkTankPost",
  LunchMenusInfo = "LunchMenusInfo",
}

export interface IWidgetActionItemBaseProps {
  widget: IExtendedWidget;
  color: string;
  isSmall?: boolean;
}

export interface IWidgetActionItemDictionaryEntry {
  id: number;
  Component: React.FC<IWidgetActionItemBaseProps>;
  ContextProvider?: React.FC;
  showOnBreakpoint: Breakpoint[];
}

const favoriteFilterWidgetActionEntry: IWidgetActionItemDictionaryEntry = {
  id: 1,
  Component: WidgetFavoriteFilter,
  ContextProvider: WidgetFavoriteFilterContextProvider,
  showOnBreakpoint: [Breakpoint.DESKTOP, Breakpoint.DESKTOP_XL],
};

const addLinkFavoriteWidgetActionEntry: IWidgetActionItemDictionaryEntry = {
  id: 2,
  Component: WidgetAddLinkFavoriteAction,
  showOnBreakpoint: Object.values(Breakpoint),
};

const notificationStateFilterWidgetActionEntry: IWidgetActionItemDictionaryEntry = {
  id: 3,
  Component: WidgetNotificationStateFilterAction,
  ContextProvider: NotificationStateFilterContextProvider,
  showOnBreakpoint: Object.values(Breakpoint),
};

const lunchMenusWidgetActionEntry: IWidgetActionItemDictionaryEntry = {
  id: 4,
  Component: WidgetLunchMenusInfo,
  showOnBreakpoint: [Breakpoint.DESKTOP, Breakpoint.DESKTOP_XL],
};

const addThinkTankPostWidgetActionEntry: IWidgetActionItemDictionaryEntry = {
  id: 5,
  Component: WidgetAddThinkTankPostAction,
  showOnBreakpoint: Object.values(Breakpoint),
};

export const WidgetActionItemDictionary = {
  [WidgetActionItem.FavoriteFilter]: favoriteFilterWidgetActionEntry,
  [WidgetActionItem.AddLinkFavorite]: addLinkFavoriteWidgetActionEntry,
  [WidgetActionItem.NotificationStateFilter]: notificationStateFilterWidgetActionEntry,
  [WidgetActionItem.AddThinkTankPost]: addThinkTankPostWidgetActionEntry,
  [WidgetActionItem.LunchMenusInfo]: lunchMenusWidgetActionEntry,
};

export const WidgetTypeActionDictionary = {
  [WidgetType.BUS_BAHN_BIM]: [],
  [WidgetType.INFOCENTER]: [],
  [WidgetType.CAROUSEL]: [],
  [WidgetType.NOTIFICATIONS]: [WidgetActionItem.NotificationStateFilter],
  [WidgetType.EVENTS]: [WidgetActionItem.FavoriteFilter],
  [WidgetType.FAVORITES]: [WidgetActionItem.AddLinkFavorite],
  [WidgetType.LUNCH]: [WidgetActionItem.LunchMenusInfo],
  [WidgetType.THINK_TANK]: [WidgetActionItem.AddThinkTankPost],
  [WidgetType.WORK_SPACES]: [],
};
