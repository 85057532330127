import { Grid, SxProps, Theme, Typography } from "@mui/material";
import React, { FC, useContext } from "react";
import { ISvgIconBaseProps } from "../../assets/icons/icons.component";
import { CUSTOM_DIALOG_TITLE_HEIGHT, DIALOG_ICON_MARGIN_FACTOR } from "../../theme/sizings.theme";
import { LayoutContext } from "../layout/layout.context";
import { ThemeContext } from "../layout/theme.context";

interface ICustomDialogTitleProps {
  Icon?: React.FC<ISvgIconBaseProps>;
  iconProps?: ISvgIconBaseProps;
  title: string;
  sx?: SxProps<Theme> | undefined;
}

export const CustomDialogTitle: FC<ICustomDialogTitleProps> = (props) => {
  const { Icon, iconProps, title, sx } = props;
  const { breakpoint, breakpointInfo } = useContext(LayoutContext);
  const { isContrastThemeActive } = useContext(ThemeContext);

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      sx={{
        backgroundColor: "widgetHeader.main",
        color: "widgetHeader.contrastText",
        height: CUSTOM_DIALOG_TITLE_HEIGHT[breakpoint],
        p: 4,
        ...sx,
      }}
    >
      {Icon && (
        <Grid
          item
          sx={{
            pr: DIALOG_ICON_MARGIN_FACTOR[breakpoint],
          }}
          xs="auto"
          container
          direction="column"
          justifyContent="center"
        >
          <Icon
            {...iconProps}
            sx={{ ...iconProps?.sx, ...(isContrastThemeActive && { color: "primaryDark.contrastText" }) }}
          />
        </Grid>
      )}
      <Grid item xs>
        <Typography variant={breakpointInfo.isMobile ? "h6" : "h5"}>{title}</Typography>
      </Grid>
    </Grid>
  );
};
