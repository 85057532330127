import React, { useContext, useMemo } from "react";
import { Grid, Typography } from "@mui/material";
import { CustomChip } from "../../chip/custom-chip.component";
import { BreadcrumbsContext } from "../nav/context/breadcrumbs.context";
import { PageHeaderBreadcrumbsDesktop } from "./page-header-breadcrumbs-desktop.component";
import { PageHeaderBreadcrumbsMobile } from "./page-header-breadcrumbs-mobile.component";
import { LayoutContext } from "../layout.context";
import { PageHeaderContext } from "./page-header.context";
import { SimpleTopicPage } from "../../../api/muu-api/graphql/generated";

interface IPageHeaderProps {}

export const PageHeader: React.FC<IPageHeaderProps> = () => {
  const { currentMenuItem } = useContext(BreadcrumbsContext);
  const { breakpointInfo } = useContext(LayoutContext);
  const { shouldRenderChips, heading, breadcrumbsToRender, lastClickableBreadcrumb, getMenuItemSx, onClickMenuItem } =
    useContext(PageHeaderContext);

  const mb = useMemo(() => {
    if (shouldRenderChips) return 0;
    // this is needed because some pages like the dashboard or the profile do not render a heading or chips and in that case we do not need margin
    if (!heading) return 0;

    return 6;
  }, [heading, shouldRenderChips]);

  return (
    <Grid id="page-header" container sx={{ mb }}>
      {(breadcrumbsToRender.length > 0 ||
        (heading && (currentMenuItem?.page as SimpleTopicPage)?.__typename === "SimpleTopicPage")) &&
        !breakpointInfo.isMobile && <PageHeaderBreadcrumbsDesktop />}

      {lastClickableBreadcrumb && breakpointInfo.isMobile && <PageHeaderBreadcrumbsMobile />}

      {shouldRenderChips && (
        <Grid
          container
          item
          xs={12}
          spacing={4}
          flexWrap={breakpointInfo.isMobile ? "nowrap" : "wrap"}
          sx={{
            pb: 6,
            overflowX: breakpointInfo.isMobile ? "scroll" : "none",
            scrollbarWidth: 0,
            "-ms-overflow-style": "none",
            "::-webkit-scrollbar": {
              display: "none",
            },
          }}
        >
          {currentMenuItem?.menuItems.map((menuItem, index) => (
            <Grid item key={`${currentMenuItem.id}-${index}`}>
              <CustomChip
                variant="body2"
                chipVariant="header"
                label={menuItem.title}
                sx={getMenuItemSx(menuItem)}
                onClick={() => onClickMenuItem(menuItem)}
              />
            </Grid>
          ))}
        </Grid>
      )}

      {(currentMenuItem?.page as SimpleTopicPage)?.__typename !== "SimpleTopicPage" && (
        <Grid item xs={12} sx={{ mb: shouldRenderChips ? 4 : 0 }}>
          {heading && (
            <Typography variant="h4" color="primaryDark.main">
              {heading}
            </Typography>
          )}
        </Grid>
      )}
    </Grid>
  );
};
