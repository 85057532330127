import React from "react";
import { Media } from "../../api/muu-api/graphql/generated";
import { IThinkTankPostFormFile } from "../../components/think-tank/think-tank-post-form.component";
import { useThinkTankAttachment } from "./hooks/use-think-tank-attachment.hook";
import { TopicLink } from "../topic/content-components/topic-link.content-component";
import { Grid } from "@mui/material";
import { CustomIconButton } from "../../components/icon/custom-icon-button.component";
import { TrashIcon } from "../../assets/icons/icons.component";

interface IThinkTankDocumentAttachmentProps {
  attachment: Media | IThinkTankPostFormFile;
  editMode?: boolean;
  onDelete?: () => void;
}

export const ThinkTankDocumentAttachment: React.FC<IThinkTankDocumentAttachmentProps> = (props) => {
  const { attachment, editMode, onDelete } = props;

  const { url, name } = useThinkTankAttachment(attachment);

  return (
    <Grid container position="relative">
      <Grid item>
        <TopicLink link={{ __typename: "TopicLinkContent", link: url, title: name }} />
      </Grid>
      <Grid item xs />
      <Grid item>
        {editMode && (
          <CustomIconButton
            size="medium"
            variant="contained"
            onClick={(event) => {
              event.stopPropagation();
              onDelete && onDelete();
            }}
          >
            <TrashIcon size="xsmall" />
          </CustomIconButton>
        )}
      </Grid>
    </Grid>
  );
};
