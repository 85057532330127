import { Divider, Grid, Stack, Typography, Box } from "@mui/material";
import React, { useCallback, useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { NotificationState, SearchNotification, WidgetType } from "../../../api/muu-api/graphql/generated";
import { CopyIcon } from "../../../assets/icons/icons.component";
import { DETAIL_PATH } from "../../../router/routes.const";
import { encodeIdBase64 } from "../../../utils/base64.util";
import { WIDGET_CONTENT_ROW_HEIGHT, WIDGET_TOTAL_ROW_HEIGHT } from "../../../theme/sizings.theme";
import { getAbsoluteRoutingPath } from "../../../utils/get-absolute-routing-path.util";
import { LayoutContext } from "../../layout/layout.context";
import { NavMenuContext } from "../../layout/nav/context/nav-menu.context";
import { ThemeContext } from "../../layout/theme.context";
import { getNotificationStatusIcon } from "../../notification/helpers/notification-icon.helper";
import { notificationStatusToColor } from "../../notification/helpers/notification-status-to-color.helper";
import { SnackbarSeverity, useSnackbar } from "../../snackbar/snackbar.context";
import { CustomIconButton } from "../../icon/custom-icon-button.component";

interface INotificationWidgetRowProps {
  notification: SearchNotification;
}

export const NotificationWidgetRow: React.FC<INotificationWidgetRowProps> = (props) => {
  const { notification } = props;
  const { pageOfInterestDictionary } = useContext(NavMenuContext);
  const { showSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const { breakpoint } = useContext(LayoutContext);
  const { isContrastThemeActive } = useContext(ThemeContext);

  const isRead = useMemo(
    () =>
      notification.notificationState === NotificationState.READ ||
      notification.notificationState === NotificationState.ACCEPTED,
    [notification],
  );

  const navigationPath = useMemo(() => {
    return `${getAbsoluteRoutingPath(pageOfInterestDictionary.NOTIFICATIONS.path)}/${DETAIL_PATH}/?id=${encodeIdBase64(
      notification.id,
      { type: null },
    )}`;
  }, [pageOfInterestDictionary, notification]);

  const copyLink = useCallback(() => {
    navigator.clipboard.writeText(window.location.host + navigationPath);
    showSnackbar(t(`widgets.${WidgetType.NOTIFICATIONS}.copy_link_success`), SnackbarSeverity.Success);
  }, [showSnackbar, t, navigationPath]);

  const statusIcon = useMemo(() => getNotificationStatusIcon(notification.notificationState), [notification]);

  const indicatorColor = useMemo(() => {
    switch (notification.notificationState) {
      case NotificationState.ACCEPTED:
        return "clear";
      case NotificationState.UNACCEPTED:
        return notificationStatusToColor(notification.notificationState);
      default:
        return "clear";
    }
  }, [notification]);

  return (
    <>
      <Stack direction={"row"}>
        <Box sx={{ backgroundColor: indicatorColor, width: 6 }} />
        <Grid
          id={`notification-row-${notification.id}`}
          container
          alignItems="center"
          justifyContent="flex-start"
          sx={{ p: 2, pl: 2.5, pr: 4, flexWrap: "nowrap", height: WIDGET_TOTAL_ROW_HEIGHT[breakpoint] }}
        >
          <Grid item container xs direction="column" sx={{ pr: 4 }}>
            <Link
              id={`notification-row-link-${notification.id}`}
              to={navigationPath}
              style={{ textDecoration: "none", color: "inherit", font: "inherit" }}
            >
              <Grid
                item
                container
                sx={{
                  height: WIDGET_CONTENT_ROW_HEIGHT[breakpoint],
                }}
              >
                <Typography
                  variant="body2"
                  sx={{
                    fontWeight: "bold",
                    overflow: "hidden",
                    display: "-webkit-box",
                    WebkitLineClamp: 1,
                    WebkitBoxOrient: "vertical",
                    opacity: isRead && !isContrastThemeActive ? 0.5 : 1,
                    wordBreak: "break-word",
                    lineHeight: "20px", // needed to avoid strange effect of lineclamp with long text - +2px for lineheight of body2
                  }}
                >
                  {notification.title ?? ""}
                </Typography>
              </Grid>
              {notification.teaser && (
                <Grid item container alignItems="center" sx={{ height: WIDGET_CONTENT_ROW_HEIGHT[breakpoint] }}>
                  <Typography
                    variant="body2"
                    sx={{
                      color: "grey.500",
                      overflow: "hidden",
                      display: "-webkit-box",
                      WebkitLineClamp: 1,
                      WebkitBoxOrient: "vertical",
                      opacity: isRead && !isContrastThemeActive ? 0.5 : 1,
                      wordBreak: "break-word",
                    }}
                  >
                    {notification.teaser}
                  </Typography>
                </Grid>
              )}
            </Link>
          </Grid>
          <Grid item sx={{ pr: 2 }} xs="auto" container direction="column" justifyContent="center">
            {statusIcon}
          </Grid>
          <Grid item xs="auto" container direction="column" justifyContent="center">
            <CustomIconButton
              variant="contained"
              aria-label="Copy Link"
              sx={{ boxShadow: "none", p: 1, color: "grey.800" }}
              onClick={(event) => {
                event.stopPropagation();
                event.preventDefault();
                copyLink();
              }}
              title={t("dashboard.tooltip.widget.deeplink")}
            >
              <CopyIcon />
            </CustomIconButton>
          </Grid>
        </Grid>
      </Stack>
      <Divider sx={{ color: "gray.100" }} />
    </>
  );
};
