import React, { useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { decodeIdBase64 } from "../../utils/base64.util";
import { ThinkTankProvider } from "./context/think-tank.context";
import { ThinkTankPageContent } from "./think-tank.page-content";

interface IThinkTankPageProps {}

export const ThinkTankPage: React.FC<IThinkTankPageProps> = (props) => {
  const [searchParams] = useSearchParams();

  const id = useMemo(() => {
    const decoded = decodeIdBase64(searchParams.get("id") || "");
    return decoded?.id || null;
  }, [searchParams]);

  return (
    <ThinkTankProvider id={id}>
      <ThinkTankPageContent />
    </ThinkTankProvider>
  );
};
