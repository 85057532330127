import { createTheme, PaletteOptions, ThemeOptions } from "@mui/material/styles";
import { deepmerge } from "@mui/utils";
import { LightTheme } from "./light.theme";

const ContrastThemeOptions: ThemeOptions = {
  palette: {
    widgetHeader: {
      main: LightTheme.palette.primary[500],
      contrastText: LightTheme.palette.primaryDark.contrastText,
    },
    importantNews: {
      main: LightTheme.palette.primaryDark[500],
    },
    grey: {
      500: "#666666",
      searchBorder: "#888888",
    },
    primary: {
      25: "#F5FBF5",
    },
    menuBar: {
      main: LightTheme.palette.primaryDark[500],
      contrastText: "#FFFFFF",
    },
  } as unknown as PaletteOptions,
  components: {
    MuiMenuItem: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            ":hover": {
              backgroundColor: LightTheme.palette.primaryDark[500],
            },
            color: "#FFFFFF",
            backgroundColor: LightTheme.palette.primaryDark[500],
          },
          ":hover": {
            color: "#FFFFFF",
            backgroundColor: LightTheme.palette.primaryDark[500],
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          ":disabled": {
            color: LightTheme.palette.grey[500],
          },
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: LightTheme.palette.grey[600],
          textDecorationColor: LightTheme.palette.grey[600],
        },
      },
      defaultProps: {
        underline: "always",
      },
    },
  },
};

export const ContrastTheme = createTheme(deepmerge(LightTheme, ContrastThemeOptions));
