import {
  Language,
  NotificationState,
  Role,
  SearchFilterName,
  ThinkTankType,
  WidgetType,
} from "../api/muu-api/graphql/generated";
import { NONE_CATEGORY } from "../app/helpers/const.helper";
import { ProfileOptions } from "../components/layout/profile/profile.component";

export const TRANSLATIONS_DE = {
  dashboard: {
    welcome_text: "Med Uni MUniverse Applikationsversion {{version}}",
    edit_dashboard: "Dashboard bearbeiten",
    add_widget: "Widget hinzufügen",
    add_widget_dialog: {
      title: "Widget hinzufügen",
      description: "Wählen Sie ein Widget aus, um es dem Dashboard hinzuzufügen.",
      select_placeholder: "Auswahl Widget",
      cancel: "Abbrechen",
      add: "Hinzufügen",
    },
    save_widgets: "Speichern",
    read_more: "Mehr lesen",
    tooltip: {
      language_selection: "Sprache auswählen",
      theme: "Kontrastmodus aktivieren/deaktivieren",
      bell: "Benachrichtigungen",
      profile: "Profil",
      search: {
        filter: "Kategoriefilter",
        glass: "Suche starten",
        clear: "Suchbegriff löschen",
      },
      sidebar: "Navigation anzeigen/verbergen",
      widget: {
        settings: "Widget-Einstellungen",
        favorites: {
          add: "Favorit hinzufügen",
        },
        think_tank: {
          add: "Beitrag erstellen",
        },
        deeplink: "Link kopieren",
        events: {
          attendence_fee: "Kostenpflichtig",
          attendence_registration: "Anmeldepflichtig",
        },
        notifications: {
          filter: "Lesestatus auswählen",
        },
      },
      favorite: {
        add: "Favorit hinzufügen",
        remove: "Favorit entfernen",
      },
    },
  },
  error: {
    back_to_dashboard: "Zurück zum MUniverse",
    401: {
      title: "Unauthorized",
      description:
        "Mit diesem Account können sie sich nicht im MUniverse anmelden. Bitte verwenden Sie Ihren personalisierten Mitarbeitenden (o_) oder Studierenden (os) Account.",
    },
    403: {
      title: "Policy Error",
      description:
        "Dieser Inhalt ist nur für Personen verfügbar, die in MUniverse angemeldet und für den Inhalt freigeschalten sind.",
    },
  },
  public_transport: {
    language: "de_AT",
  },
  searchbar: {
    placeholder: "Suche nach ...",
    label: "Suche",
  },
  language_select: {
    short: {
      [Language.DE]: "DE",
      [Language.EN]: "EN",
    },
    [Language.DE]: "Deutsch",
    [Language.EN]: "English",
    update_error: "Die Spracheinstellung konnte nicht gespeichert werden.",
  },
  profile: {
    [ProfileOptions.EDIT_DASHBOARD]: "Dashboard bearbeiten",
    [ProfileOptions.PROFILE_CARD]: "Meine Daten",
    [ProfileOptions.SETTINGS]: "Einstellungen",
    save_dashboard: "Dashboard speichern",
  },
  widgets: {
    [WidgetType.BUS_BAHN_BIM]: {
      title: "BusBahnBim",
    },
    [WidgetType.INFOCENTER]: {
      title: "Infocenter",
      no_data: "Keine Beiträge vorhanden.",
      copy_link_success: "URL erfolgreich kopiert.",
    },
    [WidgetType.EVENTS]: {
      title: "Veranstaltungen",
      no_data: "Keine Veranstaltungen vorhanden.",
    },
    [WidgetType.FAVORITES]: {
      title: "Favoriten",
      show_all: "Alle ansehen",
      no_data: "Keine Favoriten vorhanden.",
    },
    [WidgetType.NOTIFICATIONS]: {
      title: "Aussendungen",
      no_data: "Keine Aussendungen vorhanden.",
      copy_link_success: "URL erfolgreich kopiert.",
    },
    [WidgetType.THINK_TANK]: {
      title: "Pinnwand",
      no_data: "Keine Beiträge vorhanden.",
      copy_link_success: "URL erfolgreich kopiert.",
      no_category: "Keine Kategorien verfügbar",
    },
    [WidgetType.LUNCH]: {
      title: "Mittagsmenüs",
      weekly_special: "Wochenspecial",
      no_data: "Keine Mittagsmenüs vorhanden.",
      vegetarian: "vegetarisch",
      vegan: "vegan",
    },
    [WidgetType.WORK_SPACES]: {
      title: "Meine Arbeitsbereiche",
    },
    filter: {
      favorites: "Favoriten anzeigen",
      favorites_short: "Favoriten",
      selected_restaurants: "Ausgewählte Restaurants",
      no_restaurants_selected: "Keine Restaurants ausgewählt.",
    },
    menu_items: {
      color: "Widget einfärben",
      category_filter: "Auswahl anzeigen",
      add_link_favorite: "Favorit hinzufügen",
      edit_link_favorite: "Favorit bearbeiten",
      add_think_tank_post: "Pinnwand Beitrag erstellen",
      restaurant_filter: "Restaurantfilter",
    },
    general: {
      show_all: "Alle ansehen",
    },
  },
  search: {
    search_results: "Suchergebnisse",
    no_results: "Keine Suchergebnisse gefunden.",
    search_string_too_short: "Mindestens 2 Zeichen eingeben.",
    filter: {
      [SearchFilterName.ALL]: "Alle",
      [SearchFilterName.PERSONS]: "Personen",
      [SearchFilterName.ORGANIZATIONAL_UNITS]: "Organisationseinheiten",
      [SearchFilterName.INFOCENTER]: "Infocenter",
      [SearchFilterName.EVENTS]: "Veranstaltungen",
      [SearchFilterName.NOTIFICATIONS]: "Aussendungen",
      [SearchFilterName.TOPIC_PAGES]: "Themen",
      [SearchFilterName.LEGAL_DOCUMENTS]: "Rechtsregister",
      [SearchFilterName.THINK_TANK]: "Pinnwand",
    },
    filter_label: "Suchfilter",
  },
  person_detail: {
    profile: "Profil",
    organization_function: "Funktion",
    place_of_work: "{{address}}, Raum {{room}}",
  },
  settings: {
    title: "Einstellungen",
  },
  infocenter: {
    title: "Infocenter",
    related_links: "Weiterführende Links",
    research_fields: {
      start_date: "Start",
      link: "Info",
      mode: "Modus zur Einreichung",
      deadline: "Deadline zur Einreichung",
      funders: "Geldgeber",
      endowment: "Dotierung",
    },
  },
  events: {
    title: "Veranstaltungen",
    attendence_registration: "Anmeldepflichtig",
    attendence_fee: "Kostenpflichtig",
    organizer: "Veranstalter",
    location: "Ort",
    contact_person: "Kontaktperson",
    dfp_points: "DFP Punkte",
    website: "Website",
    registration: "Anmeldung",
    registration_deadline_info: "möglich bis ",
    attachment_default_name: "Programm",
    ical_export: "Zum Kalender hinzufügen",
  },
  filter: {
    daterange: {
      from: "Von",
      to: "Bis",
      filter_label: "Datum einschränken",
    },
    category: {
      filter_label: "Auswahl anzeigen",
    },
  },
  general: {
    reset_filter: "Auswahl zurücksetzen",
    apply: "Übernehmen",
    save: "Speichern",
    save_changes: "Änderungen speichern",
    cancel: "Abbrechen",
    back_to_overview: "Zurück zur Übersicht",
    back_to_dashboard: "Zurück zum Dashboard",
    copy_link_success: "URL erfolgreich kopiert.",
    add: "Hinzufügen",
    show_more: "mehr anzeigen",
    show_less: "weniger anzeigen",
    confirm: "Bestätigen",
    last_edit_at: "zuletzt bearbeitet am {{date}} Uhr",
    time: " Uhr",
    save_success: "Erfolgreich gespeichert.",
    save_error: "Beim Speichern ist ein Fehler aufgetreten.",
  },
  favorites: {
    save_favorites: "Favorit(en) speichern",
    save_favorite: "Favorit speichern",
    custom_link: "persönlicher Link",
    quick_link: "Quicklink",
    no_data: "Keine Daten vorhanden.",
    title: "Titel",
    link: "Link",
  },
  think_tank: {
    page_title: "Pinnwand",
    request: "Suche",
    offer: "Biete",
    title: "Titel*",
    description: "Beschreibung*",
    role: "Sichtbar für",
    publish: "Veröffentlichen",
    type: {
      title: "Rubrik*",
      [ThinkTankType.OFFER]: "Biete",
      [ThinkTankType.REQUEST]: "Suche",
    },
    category: "Kategorie",
    [NONE_CATEGORY]: "Keine",
    delete_post: "Beitrag löschen",
    edit_post: "Beitrag bearbeiten",
    dialog: {
      delete_title: "Beitrag löschen",
      confirm: "Wollen Sie den Beitrag wirklich löschen?",
    },
    update_error: "Beim Aktualisieren des Pinnwand Beitrags ist ein Fehler aufgetreten.",
    too_many_attachments: "Es können maximal 3 Anhänge hinzugefügt werden.",
  },
  work_spaces: {
    webex_room: "Zu deinem persönlichen Webex Raum",
  },
  widget_color: {
    default: "Standard",
  },
  notifications: {
    status: {
      ALL: "Alle",
      [NotificationState.ACCEPTED]: "Bestätigt",
      [NotificationState.UNACCEPTED]: "Nicht bestätigt",
      [NotificationState.READ]: "Gelesen",
      [NotificationState.UNREAD]: "Nicht gelesen",
    },
    read_notification: "Lesebestätigung",
    read_confirmation:
      "Bitte bestätigen Sie hier, den Inhalt der Aussendung gelesen und zur Kenntnis genommen zu haben.",
  },
  legal_register: {
    legal_basis: "Rechtsgrundlage",
    publication_date: "Bekanntmachung MiBL",
    responsibility: "Verantwortlichkeit",
    comment: "Anmerkung",
    modification_date: "Änderung",
  },
  ck_editor: {
    init_error: "Beim Initialisieren des Editors ist ein Fehler aufgetreten.",
    colors: {
      dark_green: "Dunkelgrün",
      light_green: "Hellgrün",
      orange: "Orange",
      petrol: "Petrol",
      sunflower_yellow: "Sonnengelb",
    },
  },
  roles: {
    [Role.ALL]: "Alle",
    [Role.EMPLOYEE]: "Mitarbeiter*innen",
    [Role.STUDENT]: "Studierende",
  },
  no_content: {
    infocenter: "Dieser Infocenterbeitrag existiert leider nicht mehr.",
    infocenter_error:
      "Beim Laden dieses Infocenterbeitrags ist ein Fehler aufgetreten.\nBitte versuchen Sie es später noch einmal.",
    infocenter_overview: "Es konnten keine Infocenterbeiträge gefunden werden.",
    infocenter_overview_error:
      "Beim Laden der Infocenterbeiträge ist ein Fehler aufgetreten.\nBitte versuchen Sie es später noch einmal.",
    notification_overview: "Es konnten keine Aussendungen gefunden werden.",
    notification_overview_error:
      "Beim Laden der Aussendungen ist ein Fehler aufgetreten.\nBitte versuchen Sie es später noch einmal.",
    event: "Diese Veranstaltung existiert leider nicht mehr.",
    event_error:
      "Beim Laden dieser Veranstaltung ist ein Fehler aufgetreten.\nBitte versuchen Sie es später noch einmal.",
    event_overview: "Es konnten keine Veranstaltungen gefunden werden.",
    event_overview_error:
      "Beim Laden der Veranstaltungen ist ein Fehler aufgetreten.\nBitte versuchen Sie es später noch einmal.",
    person: "Diese Person konnte leider nicht gefunden werden.",
    person_error: "Beim Laden dieser Person ist ein Fehler aufgetreten.\nBitte versuchen Sie es später noch einmal.",
    document: "Dieses Dokument existiert leider nicht mehr.",
    topic: "Diese Seite existiert leider nicht mehr.",
    topic_page_detail_error:
      "Beim Laden dieses Inhaltes ist ein Fehler aufgetreten.\nBitte versuchen Sie es später noch einmal.",
    notification: "Diese Aussendung existiert leider nicht mehr.",
    notification_error: "Dieser Inhalt ist nicht für Sie freigegeben.",
    think_tank: "Dieser Beitrag existiert leider nicht mehr.",
    legal_register_overview: "Es konnten keine Rechtsdokumente gefunden werden.",
    legal_register_overview_error:
      "Beim Laden des Rechtsregisters ist ein Fehler aufgetreten.\nBitte versuchen Sie es später noch einmal.",
    settings_error:
      "Beim Laden der Einstellungen ist ein Fehler aufgetreten.\nBitte versuchen Sie es später noch einmal.",
  },
  widget_section: {
    update_error: "Beim Aktualisieren der Widgets ist ein Fehler aufgetreten.",
  },
  notification_settings: {
    title: "Benachrichtigungen",
    notifications: "Benachrichtigungen für Aussendungen",
    notifications_with_read_receipt:
      "Aussendungen mit einer verpflichtenden Lesebestätigung scheinen immer als Benachrichtigung auf.",
    notifications_without_read_receipt: "Aussendungen aktivieren/deaktivieren für:",
  },
  bell_data_drawer: {
    title: "Benachrichtigungen",
    no_notifications: "Es gibt keine neuen Benachrichtigungen.",
  },
};
