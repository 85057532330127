import { Grid, Link, Typography, useTheme } from "@mui/material";
import React, { useContext } from "react";
import { ThemeContext } from "../../components/layout/theme.context";

interface IEventClickLinkProps {
  startAdornment: React.ReactNode;
  onClick: () => void;
  title: string;
}

export const EventClickLink: React.FC<IEventClickLinkProps> = (props) => {
  const { startAdornment, onClick, title } = props;
  const { isContrastThemeActive } = useContext(ThemeContext);
  const theme = useTheme();

  return (
    <Grid item container xs="auto" alignItems="center">
      <Grid item sx={{ display: "flex" }}>
        {startAdornment}
      </Grid>
      <Grid item xs sx={{ pl: 1 }}>
        <Link
          underline={isContrastThemeActive ? "always" : "none"}
          sx={{
            ...(isContrastThemeActive && {
              color: theme.palette.grey[600],
              textDecorationColor: theme.palette.grey[600],
            }),
          }}
          onClick={onClick}
        >
          <Typography variant="body1">{title}</Typography>
        </Link>
      </Grid>
    </Grid>
  );
};
