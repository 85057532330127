import { NotificationState } from "../../../api/muu-api/graphql/generated";
import { NotificationStateWithAll } from "../context/notification-state-filter.context";

export const notificationStatusToColor = (status: NotificationStateWithAll): string => {
  switch (status) {
    case "ALL":
      return "primary.500";
    case NotificationState.ACCEPTED:
      return "primary.550";
    case NotificationState.UNACCEPTED:
      return "secondary.500";
    case NotificationState.READ:
      return "grey.300";
    case NotificationState.UNREAD:
      return "accent1.main";
    default:
      return "grey.300";
  }
};
