import { SimplePage } from "../../../../api/muu-api/graphql/generated";
import { PageOfInterestDictionaryType } from "../../../../router/routes.const";
import { IMenuItemWithPortalRoute } from "../context/nav-menu.context";

export const checkIfMenuItemNavigates = (menuItem: IMenuItemWithPortalRoute) =>
  (menuItem.page as SimplePage)?.component ||
  menuItem.page?.__typename === "SimpleTopicPage" ||
  menuItem.page?.__typename === "SimpleExtPage";

export const checkIfMenuItemNavigatesWithCategory = (
  menuItem: IMenuItemWithPortalRoute,
  pageOfInterestDictionary: PageOfInterestDictionaryType,
) => {
  const fullPath = menuItem.url.split("?");
  if (menuItem.menuItems && menuItem.menuItems.length > 0) {
    return false;
  }
  if (menuItem.isExternalLink) {
    return false;
  }
  if (fullPath.length > 1) {
    const parentPath = fullPath[0];
    switch (parentPath) {
      case pageOfInterestDictionary.INFOCENTER?.path:
        return true;
      case pageOfInterestDictionary.EVENTS?.path:
        return true;
      case pageOfInterestDictionary.NOTIFICATIONS?.path:
        return true;
    }
  }
  return false;
};
