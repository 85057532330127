import React, { createContext, PropsWithChildren, useCallback, useEffect, useState } from "react";
import { SESSION_STORAGE_KEY } from "../../models/storage-keys";
import { config } from "../../utils/config.util";
import jwtDecode, { JwtPayload } from "jwt-decode";

interface IAccessTokenContext {
  accessToken?: string;
  setAccessToken: (accessToken?: string) => void;
}

export const AccessTokenContext = createContext<IAccessTokenContext>({} as IAccessTokenContext);

interface IAccessTokenContextProviderProps extends PropsWithChildren {}

export const AccessTokenContextProvider: React.FC<IAccessTokenContextProviderProps> = (props) => {
  const [accessToken, setAccessToken] = useState<string | undefined>(
    sessionStorage.getItem(SESSION_STORAGE_KEY.MUU_TOKEN) || undefined,
  );

  const onChangeAccessToken = useCallback((newAccessToken?: string) => {
    handleAccessToken(newAccessToken);
    setAccessToken(newAccessToken);
  }, []);

  useEffect(() => {
    if (accessToken) {
      return;
    }
    // Ignore existing token if user is on error page
    if (window.location.pathname === "/401") {
      return;
    }
    if (window.location.pathname === "/403") {
      return;
    }
    const queryParams = new URLSearchParams(window.location.search);
    const urlParamToken = queryParams.has("token") ? queryParams.get("token") : null;

    if (urlParamToken) {
      onChangeAccessToken(urlParamToken);
    } else {
      sessionStorage.setItem(SESSION_STORAGE_KEY.INITIAL_ROUTE, window.location.pathname);
      sessionStorage.setItem(SESSION_STORAGE_KEY.INITIAL_SEARCH_PARAMS, window.location.search);
      sessionStorage.setItem(SESSION_STORAGE_KEY.INITIAL_FRAGMENT, window.location.hash);
      window.location.href = config.REACT_APP_AUTH_URL;
    }
  }, [accessToken, onChangeAccessToken]);

  return (
    <AccessTokenContext.Provider
      value={{
        accessToken,
        setAccessToken,
      }}
    >
      {props.children}
    </AccessTokenContext.Provider>
  );
};

const handleAccessToken = (accessToken?: string) => {
  if (!accessToken) {
    sessionStorage.removeItem(SESSION_STORAGE_KEY.MUU_TOKEN);
    return;
  }
  const payload = jwtDecode(accessToken) as JwtPayload;
  if (!payload.sub) {
    localStorage.removeItem(SESSION_STORAGE_KEY.MUU_TOKEN);
    throw new Error("Invalid Token");
  }
  sessionStorage.setItem(SESSION_STORAGE_KEY.MUU_TOKEN, accessToken);
};
