import { MenuItem, MenuItemProps } from "@mui/material";
import { FC, useState } from "react";

interface ICustomMenuItemProps extends Omit<MenuItemProps, "children"> {
  children: (isHovering: boolean) => React.ReactNode;
  component?: string;
  href?: string;
  target?: string;
  title?: string;
}

export const CustomMenuItem: FC<ICustomMenuItemProps> = (props: ICustomMenuItemProps) => {
  const { children, ...rest } = props;
  const [isHovering, setHovering] = useState<boolean>(false);
  return (
    <MenuItem onMouseOver={() => setHovering(true)} onMouseLeave={() => setHovering(false)} {...rest}>
      {children(isHovering)}
    </MenuItem>
  );
};
