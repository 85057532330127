import { useCallback, useContext, useEffect, useState } from "react";
import { LayoutContext } from "../components/layout/layout.context";
import { DEFAULT_CARD_MIN_WIDTH, NAV_WIDTH, OVERVIEW_SPACING_FACTOR, SMALL_NAV_WIDTH } from "../theme/sizings.theme";
import { useTheme } from "@mui/material";

export const useColumnLayout = () => {
  const { breakpoint, breakpointInfo, sidebarOpen } = useContext(LayoutContext);
  const [columns, setColumns] = useState<number>(breakpointInfo.isMobile ? 6 : 12);
  const theme = useTheme();

  const handleResize = useCallback(() => {
    let navSize = sidebarOpen ? NAV_WIDTH[breakpoint] : SMALL_NAV_WIDTH[breakpoint];
    const padding = parseFloat(theme.spacing(OVERVIEW_SPACING_FACTOR[breakpoint] * 2));
    const cardWidth = DEFAULT_CARD_MIN_WIDTH[breakpoint];
    if (window.innerWidth - navSize < cardWidth * 2 + padding * 2) {
      setColumns(6);
    } else {
      setColumns(12);
    }
  }, [breakpoint, sidebarOpen, theme]);

  useEffect(() => {
    if (breakpointInfo.isMobile) {
      setColumns(6);
      return;
    }

    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, [handleResize, breakpointInfo]);

  return { columns };
};
