import React from "react";
import { IExtendedWidget } from "../../../pages/dashboard/widget-section/types/widget.type";
import { ThinkTankContentContextProvider } from "../../think-tank/context/think-tank-content.context";
import { ThinkTankContent } from "./think-tank.content";

interface IThinkTankWidgetContentProps {
  widget: IExtendedWidget;
}

export const ThinkTankWidgetContent: React.FC<IThinkTankWidgetContentProps> = (props) => {
  const { widget } = props;

  return (
    <ThinkTankContentContextProvider widget={widget}>
      <ThinkTankContent widget={widget} />
    </ThinkTankContentContextProvider>
  );
};
