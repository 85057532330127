import { Favorite, FavoriteType } from "../../../api/muu-api/graphql/generated";
import { parseRelativeLink } from "../../../app/helpers/relative-link.helper";
import { PageOfInterestDictionaryType, StaticPageDictionary, TopicPageDictionary } from "../../../router/routes.const";
import { encodeIdBase64 } from "../../../utils/base64.util";
import { config } from "../../../utils/config.util";
import { getAbsoluteRoutingPath } from "../../../utils/get-absolute-routing-path.util";
import { favoriteSourceToInfocenterSource } from "../../infocenter/helper/infocenter-mapping.helper";
import { getInfocenterDetailUrl } from "../../infocenter/helper/infocenter.helper";
import { getNotificationDetailUrl } from "../../notification/helpers/notification.helper";
import { favoriteSourceToEventSource } from "../../widgets/events/helper/event-mapping.helper";
import { getEventDetailUrl } from "../../widgets/events/helper/event.helper";

export const getFavoriteRedirectUrl = (
  favorite: Favorite,
  pageOfInterestDictionary: PageOfInterestDictionaryType,
): string | undefined => {
  switch (favorite.type) {
    case FavoriteType.CUSTOM_LINK:
    case FavoriteType.QUICK_LINK:
      return favorite.link ? parseRelativeLink(favorite.link).link : undefined;
    case FavoriteType.EVENT:
      return getEventDetailUrl(
        favorite.entryId,
        favoriteSourceToEventSource(favorite.source),
        pageOfInterestDictionary,
      );
    case FavoriteType.INFOCENTER:
      return getInfocenterDetailUrl(
        favorite.entryId,
        favoriteSourceToInfocenterSource(favorite.source),
        pageOfInterestDictionary,
      );
    case FavoriteType.NOTIFICATION:
      return getNotificationDetailUrl(favorite.entryId, pageOfInterestDictionary);
    case FavoriteType.TOPIC:
      const topicPageDictionaryEntry = TopicPageDictionary[favorite.entryId];
      if (!topicPageDictionaryEntry) {
        return `/page/${favorite.entryId}`;
      }
      return `/${topicPageDictionaryEntry.route}`;
    case FavoriteType.LEGAL_REGISTER:
      return `${config.REACT_APP_RELATIVE_LINK_URL}${favorite.link}` ?? undefined;
    case FavoriteType.THINK_TANK:
      return `${getAbsoluteRoutingPath(StaticPageDictionary.THINK_TANK_DETAIL.path)}?id=${encodeIdBase64(
        favorite.entryId,
        { type: null },
      )}`;
  }
};
