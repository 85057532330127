import React, { Fragment } from "react";
import { Box, Divider, Skeleton, useTheme } from "@mui/material";

interface IWorkSpacesSkeletonProps {}

export const WorkSpacesSkeleton: React.FC<IWorkSpacesSkeletonProps> = (props) => {
  const theme = useTheme();

  return (
    <>
      {[1, 2, 3].map((index) => (
        <Fragment key={`work-spaces-loading-${index}`}>
          <Box sx={{ p: 4 }}>
            <Skeleton variant="text" width={150} sx={{ fontSize: theme.typography.subtitle2 }} />
            <Skeleton variant="text" width={370} sx={{ fontSize: theme.typography.body2 }} />
            <Skeleton variant="text" width={240} sx={{ fontSize: theme.typography.body2 }} />
          </Box>
          <Divider />
        </Fragment>
      ))}
    </>
  );
};
