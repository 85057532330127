import React, { PropsWithChildren, useMemo } from "react";
import { IWidgetActionItemDictionaryEntry } from "../../pages/dashboard/widget-section/dictionaries/widget-action.dictionary";
import { nestComponentArray } from "../../utils/nest-component-array.util";

interface IWidgetContextWrapperProps extends PropsWithChildren {
  actions: IWidgetActionItemDictionaryEntry[];
}

export const WidgetContextWrapper: React.FC<IWidgetContextWrapperProps> = (props) => {
  const { actions, children } = props;

  // this wraps the entire widget.component in all necessary context providers
  // each widget action defines a context provider, so we can access the state of the action (which resides in the header) in the widget.content
  // to define which action has which provider, edit widget-action.dictionary
  const Wrappers = useMemo(() => {
    const providers = actions.filter((action) => !!action.ContextProvider).map((action) => action.ContextProvider!);
    return nestComponentArray(providers);
  }, [actions]);

  return <Wrappers>{children}</Wrappers>;
};
