import { LinkProps, useTheme } from "@mui/material";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { LayoutContext } from "../layout/layout.context";
import { APP_BAR, EDITOR_CONTENT_LARGE_SPACING } from "../../theme/sizings.theme";
import { TopicDescriptionContext } from "../../pages/topic/content-components/topic-description.context";

// this component should only be used by editor content link mapper
export const Anchor: React.FC<LinkProps> = (props) => {
  const location = useLocation();
  const [shouldScrollToAnchor, setShouldScrollToAnchor] = useState<boolean>(false);
  const { setOpen } = useContext(TopicDescriptionContext);
  const ref = useRef<HTMLDivElement>(null);
  const { href } = props;
  const { breakpoint } = useContext(LayoutContext);
  const theme = useTheme();

  const scrollMarginTop = useMemo(
    () =>
      APP_BAR[breakpoint] +
      EDITOR_CONTENT_LARGE_SPACING[breakpoint] +
      parseFloat(theme.typography.h1.fontSize?.toString() || "0"),
    [breakpoint, theme.typography.h1.fontSize],
  );

  useEffect(() => {
    if (!ref.current || location.hash !== href) {
      return;
    }

    setOpen(true);
    setShouldScrollToAnchor(true);
  }, [location.hash, href, setOpen]);

  useEffect(() => {
    if (!shouldScrollToAnchor || !ref.current) {
      return;
    }
    ref.current.scrollIntoView({
      block: "start",
      behavior: "smooth",
    });
    setShouldScrollToAnchor(false);
  }, [shouldScrollToAnchor]);

  return (
    <div
      ref={ref}
      id={href}
      style={{
        scrollMarginTop,
      }}
    />
  );
};
