import { Divider, Grid, Typography } from "@mui/material";
import React from "react";
import { TopicTeamMemberContent, usePersonDetailsQuery } from "../../../api/muu-api/graphql/generated";
import { TopicTeamMemberLoading } from "./topic-team-member-loading.component";
import { useNavigate } from "react-router-dom";
import { getAbsoluteRoutingPath } from "../../../utils/get-absolute-routing-path.util";
import { StaticPageDictionary } from "../../../router/routes.const";
import { PhoneOrMailTypography } from "../../../components/typography/phone-or-mail-typography.component";

interface ITopicTeamMemberContentProps {
  content: TopicTeamMemberContent;
  isLastItem?: boolean;
}

export const TopicTeamMember: React.FC<ITopicTeamMemberContentProps> = (props) => {
  const { content, isLastItem } = props;
  const navigate = useNavigate();

  const { data, loading } = usePersonDetailsQuery({
    skip: !content.medUniId,
    variables: { id: content.medUniId!! },
  });

  if (loading) {
    return <TopicTeamMemberLoading />;
  }

  if (!loading && !data?.person) {
    return null;
  }

  return (
    <Grid item container rowSpacing={2}>
      <Grid item container columnSpacing={4} direction="row">
        <Grid item xs={12} md={4}>
          <Typography
            variant="body1"
            fontWeight={600}
            onClick={() => {
              navigate({
                pathname: getAbsoluteRoutingPath(StaticPageDictionary.PERSON_DETAIL.path),
                search: `id=${data?.person?.id}`,
              });
            }}
            sx={{ "&:hover": { cursor: "pointer", textDecoration: "underline", color: "inherit" } }}
          >{`${data?.person?.lastName}, ${data?.person?.firstName}`}</Typography>
        </Grid>
        <Grid item xs={12} md={4} container direction="column">
          {data?.person?.phone && (
            <Grid item>
              <PhoneOrMailTypography variant="body1" type="phone" value={data?.person?.phone} />
            </Grid>
          )}
          {data?.person?.phoneExternal && (
            <Grid item>
              <PhoneOrMailTypography variant="body1" type="phone" value={data?.person?.phoneExternal} />
            </Grid>
          )}
          {data?.person?.mobile && (
            <Grid item>
              <PhoneOrMailTypography variant="body1" type="phone" value={data?.person?.mobile} />
            </Grid>
          )}
          {data?.person?.email && (
            <Grid item>
              <PhoneOrMailTypography type="mail" value={data.person.email} variant="body1" />
            </Grid>
          )}
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography variant="body1" color={"grey.500"} sx={{ whiteSpace: "pre-line" }}>
            {content?.description || ""}
          </Typography>
        </Grid>
      </Grid>
      {!isLastItem && (
        <Grid item xs={12}>
          <Divider />
        </Grid>
      )}
    </Grid>
  );
};
