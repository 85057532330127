import { ChevronRight } from "@mui/icons-material";
import { Box, Grid, Typography, useTheme } from "@mui/material";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { LayoutContext } from "../layout/layout.context";
import { WIDGET_CONTENT_BOTTOM_PADDING } from "../../theme/sizings.theme";

interface IWidgetContentShowAllProps {
  onShowAllClick: () => void;
}

export const WidgetContentShowAll: React.FC<IWidgetContentShowAllProps> = (props) => {
  const { onShowAllClick } = props;
  const { t } = useTranslation();
  const theme = useTheme();
  const { breakpoint } = useContext(LayoutContext);

  return (
    <Box>
      <Grid
        container
        direction="row"
        justifyContent="flex-end"
        alignItems={"center"}
        sx={{
          width: "100%",
          textAlign: "end",
          pb: WIDGET_CONTENT_BOTTOM_PADDING[breakpoint],
          pt: 3,
          pr: 4,
          "&:hover": { cursor: "pointer" },
        }}
      >
        <Grid item onClick={onShowAllClick} sx={{ pr: 2 }}>
          <Typography variant="body2" sx={{ fontWeight: "700" }}>
            {t(`widgets.general.show_all`)}
          </Typography>
        </Grid>
        <Grid item onClick={onShowAllClick} sx={{ height: theme.icon["medium"], width: theme.icon["medium"] }}>
          <ChevronRight sx={{ color: "grey.800" }} />
        </Grid>
      </Grid>
    </Box>
  );
};
