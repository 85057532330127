import { Media } from "../../api/muu-api/graphql/generated";

const SVG_MIME_TYPE = "image/svg+xml";
const SVG_FILE_ENDING = ".svg";

export const isValidIcon = (media: Media | null | undefined) => {
  if (!media || !media.mediaUrl) {
    return false;
  }

  return media.mimeType === SVG_MIME_TYPE && media.mediaUrl.endsWith(SVG_FILE_ENDING);
};
