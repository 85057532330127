import { DateTime } from "luxon";

export const formatShortDate = (dateString: string) => DateTime.fromISO(dateString).toFormat("dd.MM.yyyy");

export const formatShortJSDate = (date: Date) => DateTime.fromJSDate(date).toFormat("yyyy-MM-dd");

export const formatShortDateTime = (dateString: string, locale: string) =>
  DateTime.fromISO(dateString).toLocaleString(DateTime.DATETIME_SHORT, { locale }).replace(":", ".");

export const formatMonthDate = (dateString: string) => DateTime.fromISO(dateString).toFormat("dd.MM");

export const formatShortTime = (dateString: string, locale: string) =>
  DateTime.fromISO(dateString).toLocaleString(DateTime.TIME_SIMPLE, { locale }).replace(":", ".");

export const format24ShortTime = (dateString: string, locale: string) =>
  DateTime.fromISO(dateString).toLocaleString(DateTime.TIME_24_SIMPLE, { locale });

export const formatApiShortDate = (dateString: string) => DateTime.fromISO(dateString).toFormat("yyyy-MM-dd");

export const formatDateTimeToShort = (dateTime: DateTime) => dateTime.toFormat("yyyy-MM-dd");

export const formatMillisToDayDate = (millis: number, locale: string) =>
  DateTime.fromMillis(millis).setLocale(locale).toFormat("ccc, dd.MM.yyyy");

export const formatDateTimeToLocaleMonthYear = (dateTime: DateTime, locale: string) =>
  dateTime.toFormat("MMMM yyyy", { locale });

export const formatMillisToShortDate = (millis: number) => DateTime.fromMillis(millis).toFormat("dd.MM.yyyy");

export const formatMillisToLocaleDateWithTime = (millis: number, locale: string) =>
  DateTime.fromMillis(millis)
    .toLocaleString(DateTime.DATETIME_MED, {
      locale,
    })
    .replace(":", ".");

export const formatMillisToLocaleDate = (millis: number, locale: string) =>
  DateTime.fromMillis(millis).toLocaleString(DateTime.DATE_FULL, {
    locale,
  });

export const formatJSDateToLocaleShort = (date: Date, locale: string) =>
  DateTime.fromJSDate(date).toLocaleString(DateTime.DATE_SHORT, {
    locale,
  });

export const formatJSDatetoLocaleDateHuge = (date: Date, locale: string) =>
  DateTime.fromJSDate(date).toLocaleString(DateTime.DATE_HUGE, {
    locale,
  });

export const dateISOFromMillis = (millis: number) => DateTime.fromMillis(millis).toISODate();

export const dateISOFromJsDate = (date: Date) => DateTime.fromJSDate(date).toISODate();
