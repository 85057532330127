import { Typography } from "@mui/material";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { QuickLink } from "../../../api/muu-api/graphql/generated";
import { CreateFavoriteQuickLinkRow } from "./add-favorite-quick-link-row.component";
import { AddFavoriteQuickLinkSkeleton } from "./add-favorite-quick-link.skeleton";

interface IAddFavoriteQuickLinkTabProps {
  quickLinks: QuickLink[];
  setQuickLinks: (quickLinks: QuickLink[]) => void;
  availableQuickLinks: QuickLink[];
  areAvailableQuickLinksLoading: boolean;
}

export const AddFavoriteQuickLinkTab: React.FC<IAddFavoriteQuickLinkTabProps> = (props) => {
  const { quickLinks, setQuickLinks, availableQuickLinks, areAvailableQuickLinksLoading } = props;
  const { t } = useTranslation();

  const isFavorite = useCallback(
    (quickLink: QuickLink) => quickLinks.find((link) => link.id === quickLink.id) !== undefined,
    [quickLinks],
  );

  const handleOnClick = useCallback(
    (quickLink: QuickLink) => {
      const isAdded = quickLinks.find((link) => link.id === quickLink.id);
      if (isAdded) {
        setQuickLinks(quickLinks.filter((link) => link.id !== isAdded.id));
      } else {
        setQuickLinks([...quickLinks, quickLink]);
      }
    },
    [quickLinks, setQuickLinks],
  );

  return (
    <>
      {areAvailableQuickLinksLoading && <AddFavoriteQuickLinkSkeleton />}
      {!areAvailableQuickLinksLoading && availableQuickLinks.length === 0 && (
        <Typography>{t("favorites.no_data")}</Typography>
      )}
      {!areAvailableQuickLinksLoading &&
        availableQuickLinks.map((link) => (
          <CreateFavoriteQuickLinkRow
            quickLink={link}
            onClick={() => handleOnClick(link)}
            isFavorite={isFavorite(link)}
          />
        ))}
    </>
  );
};
