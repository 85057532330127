import { Box, Typography, useTheme } from "@mui/material";
import { FC, useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { WidgetColor, WidgetType } from "../../../../api/muu-api/graphql/generated";
import { VeganIcon, VeggieIcon } from "../../../../assets/icons/icons.component";
import { IWidgetActionItemBaseProps } from "../dictionaries/widget-action.dictionary";
import { useWidgetColorToThemeColor } from "../hooks/use-widget-color-to-theme-color.hook";
import { ThemeContext } from "../../../../components/layout/theme.context";

interface IWidgetLunchMenusInfoProp extends Pick<IWidgetActionItemBaseProps, "widget" | "isSmall"> {
  isInContent?: boolean;
}

export const WidgetLunchMenusInfo: FC<IWidgetLunchMenusInfoProp> = (props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { widget, isSmall, isInContent } = props;
  const widgetColorPaletteOption = useWidgetColorToThemeColor(widget.color, false);
  const { isContrastThemeActive } = useContext(ThemeContext);

  const iconColor = useMemo(() => {
    if (isInContent && widget.color === WidgetColor.DEFAULT && isContrastThemeActive) {
      return `${widgetColorPaletteOption}.main`;
    }
    if (isInContent && widget.color === WidgetColor.DEFAULT) {
      return `${widgetColorPaletteOption}.contrastText`;
    }
    if (isInContent) {
      return `${widgetColorPaletteOption}.main`;
    }
    return `${widgetColorPaletteOption}.contrastText`;
  }, [widgetColorPaletteOption, isInContent, isContrastThemeActive]);

  const typographyColor = useMemo(() => {
    if (isInContent) {
      return "grey.500";
    }
    if (!isContrastThemeActive && widget.color === WidgetColor.DEFAULT) {
      return "grey.500";
    }
    return "white";
  }, [widget, isContrastThemeActive, isInContent]);

  if (isSmall) return null;

  return (
    <Box sx={{ display: "flex", columnGap: 2, pr: 2 }}>
      <Box sx={{ display: "flex", columnGap: 2 }}>
        <VeggieIcon
          sx={{
            color: iconColor,
            width: theme.icon.small,
            height: theme.icon.small,
          }}
        />
        <Typography
          variant={"body2"}
          sx={{
            color: typographyColor,
          }}
        >
          {t(`widgets.${WidgetType.LUNCH}.vegetarian`)}
        </Typography>
      </Box>
      <Box sx={{ display: "flex", columnGap: 2 }}>
        <VeganIcon
          sx={{
            color: iconColor,
            width: theme.icon.small,
            height: theme.icon.small,
          }}
        />
        <Typography
          variant={"body2"}
          sx={{
            color: typographyColor,
          }}
        >
          {t(`widgets.${WidgetType.LUNCH}.vegan`)}
        </Typography>
      </Box>
    </Box>
  );
};
