import { Box, Card, useTheme } from "@mui/material";
import React, { useCallback, useMemo, useState } from "react";
import { InfocenterCarouselArticle } from "../../../api/muu-api/graphql/generated";
import { InfocenterCarouselSlide } from "../../infocenter/slide/infocenter-carousel-slide.component";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import { InfocenterCarouselSlideInfo } from "../../infocenter/slide/infocenter-carousel-slide-info.component";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

interface ICarouselProps {
  slides: InfocenterCarouselArticle[];
}

export const Carousel: React.FC<ICarouselProps> = (props) => {
  const { slides } = props;
  const [index, setIndex] = useState<number>(0);

  const theme = useTheme();

  const setSlideIndicatorSlide = (indicatorIndex: number) => {
    setIndex(indicatorIndex);
  };

  const nextIndex = useCallback(() => {
    const newIndex = index < slides.length - 1 ? index + 1 : 0;
    setIndex(newIndex);
  }, [index, slides]);

  const containerRef = React.useRef<HTMLDivElement>(null);

  const currentArticle = useMemo(() => {
    return slides[index];
  }, [slides, index]);

  return (
    <Box
      id="carousel-box"
      sx={{
        width: "100%",
        position: "relative",
      }}
    >
      <Box
        id="carousel-slide-container"
        sx={{
          display: "block",
          position: "relative",
          top: 0,
          borderRadius: 4,
          overflow: "hidden",
        }}
        ref={containerRef}
      >
        <Card
          sx={{
            width: "100%",
            height: "100%",
            borderRadius: 4,
            elevation: 0,
            boxShadow: "0px 16px 32px -4px rgba(145, 158, 171, 0.16)",
          }}
        >
          <AutoPlaySwipeableViews
            role="presentation"
            axis={theme.direction === "rtl" ? "x-reverse" : "x"}
            index={index}
            onChangeIndex={nextIndex}
            interval={15000}
            enableMouseEvents
          >
            {slides.map((step, tempIndex) => {
              return <InfocenterCarouselSlide key={step.id} carouselArticle={step} />;
            })}
          </AutoPlaySwipeableViews>
          <InfocenterCarouselSlideInfo
            carouselArticle={currentArticle}
            index={index}
            setSlideIndicatorSlide={setSlideIndicatorSlide}
            slideIndices={slides.map((slide, index) => index)}
          />
        </Card>
      </Box>
    </Box>
  );
};
