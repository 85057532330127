import React from "react";
import { Language, PageComponentAll } from "../api/muu-api/graphql/generated";
import { DashboardPage } from "../pages/dashboard/dashboard.page";
import { EventsDetailPage } from "../pages/events-detail/events-detail.page";
import { EventsPage } from "../pages/events/events.page";
import { InfocenterDetailPage } from "../pages/infocenter-detail/infocenter-detail.page";
import { InfocenterPage } from "../pages/infocenter/infocenter.page";
import { LegalRegisterPage } from "../pages/legal-register/legal-register.page";
import { NotificationsPage } from "../pages/notifications/notifications.page";
import { PersonDetailPage } from "../pages/person-detail/person-detail.page";
import { SearchPage } from "../pages/search/search.page";
import { SettingsPage } from "../pages/settings/settings.page";
import { ThinkTankPage } from "../pages/think-tank/think-tank.page";
import { StaticTopicPagePage } from "../pages/static-topic-page/static-topic-page.page";
import { NotificationDetailPage } from "../pages/notification-detail/notification-detail.page";
import { AliasPage } from "../pages/alias/alias.page";

export const DETAIL_PATH = "detail";

export enum StaticPage {
  DASHBOARD = "DASHBOARD",
  SEARCH = "SEARCH",
  PERSON_DETAIL = "PERSON_DETAIL",
  THINK_TANK_DETAIL = "THINK_TANK_DETAIL",
  SETTINGS = "SETTINGS",
  TOPIC_PAGE = "TOPIC_PAGE",
  NOTIFICATION_DETAIL = "NOTIFICATION_DETAIL",
  INFOCENTER_DETAIL = "INFOCENTER_DETAIL",
  ALIAS = "ALIAS",
}

export enum PageOfInterest {
  INFOCENTER = "INFOCENTER",
  EVENTS = "EVENTS",
  LEGAL_REGISTER = "LEGAL_REGISTER",
  NOTIFICATIONS = "NOTIFICATIONS",
}

export interface IPageComponentDictionaryEntry {
  component: React.FC;
  pageOfInterestType?: PageOfInterest;
  subPages: {
    path: string;
    component: React.FC;
  }[];
}

// title and basePath are used purely for tracking purposes
export interface IStaticPageDictionaryEntry extends IPageComponentDictionaryEntry {
  path: string;
  basePath: string;
  title: string;
}

export interface IPageOfInterestDictionaryEntry extends IPageComponentDictionaryEntry {
  path: string;
}

export type StaticPageDictionaryType = { [key in StaticPage]: IStaticPageDictionaryEntry };
export type StaticPageDictionaryByPathType = { [key: string]: IStaticPageDictionaryEntry };
export type TopicPageDictionaryType = { [id: string]: { route: string; language: Language } };
export type ExtPageDictionaryType = { [id: string]: string };
export type PageOfInterestDictionaryType = { [key in PageOfInterest]: IPageOfInterestDictionaryEntry };
export type PageComponentDictionaryType = { [key in PageComponentAll["__typename"]]: IPageComponentDictionaryEntry };

export const StaticPageDictionary: StaticPageDictionaryType = {
  [StaticPage.DASHBOARD]: {
    component: DashboardPage,
    path: "dashboard",
    basePath: "dashboard",
    title: "Dashboard",
    subPages: [],
  },
  [StaticPage.SEARCH]: { component: SearchPage, path: "search", basePath: "search", title: "Search", subPages: [] },
  [StaticPage.PERSON_DETAIL]: {
    component: PersonDetailPage,
    path: "person-detail",
    basePath: "person-detail",
    title: "Person Detail",
    subPages: [],
  },
  [StaticPage.THINK_TANK_DETAIL]: {
    component: ThinkTankPage,
    path: "think-tank-detail",
    basePath: "think-tank-detail",
    title: "Think Tank Detail",
    subPages: [],
  },
  [StaticPage.SETTINGS]: {
    component: SettingsPage,
    path: "settings",
    basePath: "settings",
    title: "Settings",
    subPages: [],
  },
  [StaticPage.TOPIC_PAGE]: {
    component: StaticTopicPagePage,
    path: "page/:id",
    basePath: "page",
    title: "Static Topic Page / Organizational Unit",
    subPages: [],
  },
  [StaticPage.NOTIFICATION_DETAIL]: {
    component: NotificationDetailPage,
    path: "notification-detail",
    basePath: "notification-detail",
    title: "Notification Detail",
    subPages: [],
  },
  [StaticPage.INFOCENTER_DETAIL]: {
    component: InfocenterDetailPage,
    path: "infocenter-detail",
    basePath: "infocenter-detail",
    title: "Infocenter Detail",
    subPages: [],
  },
  [StaticPage.ALIAS]: {
    component: AliasPage,
    path: ":alias",
    basePath: "",
    title: "Alias",
    subPages: [],
  },
};

export const StaticPageDictionaryByPath: StaticPageDictionaryByPathType = Object.values(StaticPageDictionary).reduce(
  (acc, curr) => ({ ...acc, [curr.basePath]: curr }),
  {},
);

export const PageComponentDictionary: PageComponentDictionaryType = {
  PageEventsComponent: {
    component: EventsPage,
    pageOfInterestType: PageOfInterest.EVENTS,
    subPages: [{ component: EventsDetailPage, path: DETAIL_PATH }],
  },
  PageInfocenterComponent: {
    component: InfocenterPage,
    pageOfInterestType: PageOfInterest.INFOCENTER,
    subPages: [{ component: InfocenterDetailPage, path: DETAIL_PATH }],
  },
  PageLegalRegisterComponent: {
    component: LegalRegisterPage,
    pageOfInterestType: PageOfInterest.LEGAL_REGISTER,
    subPages: [],
  },
  PageNotificationsComponent: {
    component: NotificationsPage,
    pageOfInterestType: PageOfInterest.NOTIFICATIONS,
    subPages: [{ component: NotificationDetailPage, path: DETAIL_PATH }],
  },
};

export const TopicPageDictionary: TopicPageDictionaryType = {};
export const ExtPageDictionary: ExtPageDictionaryType = {};
