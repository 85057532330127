import React from "react";
import { TopicAccordionContent } from "../../../api/muu-api/graphql/generated";
import { CustomAccordion } from "../../../components/accordion/custom-accordion.component";
import { EditorContent } from "../../../components/editor/editor-content.component";
import { TopicTeamMembers } from "./topic-team-members.content-component";
import { Box, Typography } from "@mui/material";

interface ITopicAccordionProps {
  content: TopicAccordionContent;
}

export const TopicAccordion: React.FC<ITopicAccordionProps> = (props) => {
  const { content } = props;
  return (
    <Box>
      <Typography variant="h6" sx={{ color: "primaryDark.main", mb: 2 }}>
        {content.title}
      </Typography>
      {content.content.map((innerContent, index) => (
        <CustomAccordion
          id={`accordion-${innerContent.title}-${index}`}
          key={`accordion-${innerContent.title}-${index}`}
          title={innerContent.title || undefined}
          isFirstInAccordionList={index === 0}
        >
          {innerContent.description && (
            <Box sx={{ width: "100%", mb: 10 }}>
              <EditorContent editorContentHtmlString={innerContent.description} />
            </Box>
          )}
          {innerContent.teamMembers && innerContent.teamMembers.length > 0 && (
            <Box sx={{ width: "100%", mb: 10 }}>
              <TopicTeamMembers teamMembers={innerContent.teamMembers} />
            </Box>
          )}
        </CustomAccordion>
      ))}
    </Box>
  );
};
