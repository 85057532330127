import { Grid, Typography } from "@mui/material";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { NoContentFound } from "../../components/error/no-content-found.component";
import { BellDataSettings } from "../../components/notification-settings/bell-data-settings.component";
import { useSettingsQuery } from "../../api/muu-api/graphql/generated";

interface ISettingsPageProps {}

export const SettingsPage: React.FC<ISettingsPageProps> = (props) => {
  const { t } = useTranslation();
  const { data: settingsResult, error, loading } = useSettingsQuery();

  const settings = useMemo(() => settingsResult?.settings, [settingsResult]);

  return (
    <Grid container direction="column">
      <Grid item>
        <Typography variant="h4" color="primaryDark.main" sx={{ mb: 6 }}>
          {t("settings.title")}
        </Typography>
      </Grid>
      <Grid item xs>
        {!loading && <BellDataSettings initialValues={settings?.bellDataSettings ?? null} />}
        {error && <NoContentFound variant="settingsError" />}
      </Grid>
    </Grid>
  );
};
