import { ApolloClient } from "@apollo/client";
import { SearchThinkTank, SearchThinkTankDocument, ThinkTankType } from "../../../api/muu-api/graphql/generated";
import { THINK_TANK_LIMIT } from "../hooks/use-think-tank-widget-posts-with-fetch-more.hook";

export const THINK_TANK_SEARCH_ENTRY_EMPTY_ID = "THINK_TANK_EMPTY_ID";

export type MutateThinkTankPostsFunc = (currentPosts: SearchThinkTank[]) => SearchThinkTank[];

export const getEmptySearchThinkTankPost = (
  title: string,
  description: string,
  type: ThinkTankType,
  category: string,
): SearchThinkTank => {
  return {
    __typename: "SearchThinkTank",
    id: THINK_TANK_SEARCH_ENTRY_EMPTY_ID,
    title,
    description,
    thinkTankCategory: category,
    thinkTankType: type,
    publishedAt: new Date().toDateString(),
    author: "",
  };
};

export const writeToSearchThinkTankCache = (
  client: ApolloClient<object>,
  type: ThinkTankType,
  mutateThinkTankPostsFunc: MutateThinkTankPostsFunc,
) => {
  const queryVariables = {
    limit: THINK_TANK_LIMIT,
    offset: 0,
    categories: [],
    dateRange: {
      from: null,
      to: null,
    },
    thinkTankType: type,
  };
  const result = client.readQuery({
    query: SearchThinkTankDocument,
    variables: queryVariables,
  });
  if (result == null) return;
  const { results: thinkTankPosts, count } = result.searchThinkTank;
  const mutatedThinkTankPosts = mutateThinkTankPostsFunc(Object.assign([], thinkTankPosts ?? []));
  client.writeQuery({
    query: SearchThinkTankDocument,
    variables: queryVariables,
    data: { searchThinkTank: { results: mutatedThinkTankPosts, count: count + 1 } },
  });
};
