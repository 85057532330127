import { Element, Text, domToReact } from "html-react-parser";
import { parseRelativeLink } from "../../../app/helpers/relative-link.helper";
import { CustomLink } from "../../link/custom-link.component";
import { cssStringToCssProps } from "../helpers/editor-content.helper";
import { Anchor } from "../../link/anchor.component";

const getNodeText = (node: Element, styles: string): { text: Text | undefined; styles: string } => {
  for (const child of node.children) {
    if ((child as Element)?.attribs?.style) {
      styles = `${styles}${(child as Element)?.attribs?.style}`;
    }
    if (child.type === "text") {
      return { text: child as Text, styles };
    }

    if ((child as Element).children) {
      return getNodeText(child as Element, styles);
    }
  }
  return { text: undefined, styles };
};

export const mapToLink = (node: Element) => {
  // Add strapi url for uploads (files saved in media library)
  const { link, isAnchor } = parseRelativeLink(node.attribs.href);
  const { styles } = getNodeText(node, "");
  const style = cssStringToCssProps(styles);

  if (isAnchor) {
    return <Anchor href={link} />;
  }

  // Use domToReact for children to support nested elements (e.g. Assoz.-Prof.<sub>in</sub>)
  return (
    <CustomLink href={link} style={style}>
      {domToReact(node.children)}
    </CustomLink>
  );
};
