import { Button, Dialog, DialogContent, DialogProps, Grid, Typography } from "@mui/material";
import { FC, useCallback, useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Role, Widget, WidgetType, useUserDetailsQuery } from "../../../api/muu-api/graphql/generated";
import { PlusIcon } from "../../../assets/icons/icons.component";
import { CustomDialogTitle } from "../../../components/dialog/custom-dialog-title.component";
import { CustomSelect, ISelectOption } from "../../../components/select/custom-select.component";
import { DashboardWidgetsContext } from "./dashboard-widgets.context";
import { IExtendedWidget } from "./types/widget.type";
import { CustomDialogActions } from "../../../components/dialog/custom-dialog-actions.component";

interface IAddWidgetDialogProps extends DialogProps {
  onClose: () => void;
  widgets: Widget[] | IExtendedWidget[];
}

export const AddWidgetDialog: FC<IAddWidgetDialogProps> = (props) => {
  const { t } = useTranslation();
  const { open, onClose, widgets } = props;
  const { addWidget } = useContext(DashboardWidgetsContext);
  const { data: userDetails } = useUserDetailsQuery();

  const [selectedOption, setSelectedOption] = useState<ISelectOption | undefined>();

  const userWidgetTypes = useMemo(() => widgets.map((widget) => widget.type), [widgets]);
  const options: ISelectOption[] = useMemo(() => {
    const widgets = [
      {
        id: WidgetType.BUS_BAHN_BIM,
        value: t(`widgets.${WidgetType.BUS_BAHN_BIM}.title`),
        disabled: userWidgetTypes.includes(WidgetType.BUS_BAHN_BIM),
      },
      {
        id: WidgetType.INFOCENTER,
        value: t(`widgets.${WidgetType.INFOCENTER}.title`),
        disabled: userWidgetTypes.includes(WidgetType.INFOCENTER),
      },
      {
        id: WidgetType.EVENTS,
        value: t(`widgets.${WidgetType.EVENTS}.title`),
        disabled: userWidgetTypes.includes(WidgetType.EVENTS),
      },
      {
        id: WidgetType.FAVORITES,
        value: t(`widgets.${WidgetType.FAVORITES}.title`),
        disabled: userWidgetTypes.includes(WidgetType.FAVORITES),
      },
      {
        id: WidgetType.NOTIFICATIONS,
        value: t(`widgets.${WidgetType.NOTIFICATIONS}.title`),
        disabled: userWidgetTypes.includes(WidgetType.NOTIFICATIONS),
      },
      {
        id: WidgetType.THINK_TANK,
        value: t(`widgets.${WidgetType.THINK_TANK}.title`),
        disabled: userWidgetTypes.includes(WidgetType.THINK_TANK),
      },
      {
        id: WidgetType.LUNCH,
        value: t(`widgets.${WidgetType.LUNCH}.title`),
        disabled: userWidgetTypes.includes(WidgetType.LUNCH),
      },
    ];
    if (userDetails?.userDetails.role === Role.EMPLOYEE) {
      widgets.push({
        id: WidgetType.WORK_SPACES,
        value: t(`widgets.${WidgetType.WORK_SPACES}.title`),
        disabled: userWidgetTypes.includes(WidgetType.WORK_SPACES),
      });
    }
    return widgets;
  }, [t, userWidgetTypes, userDetails]);

  const allWidgetsDisabled = useMemo(() => options.every((option) => option.disabled), [options]);

  const handleClose = useCallback(() => {
    onClose();
    setSelectedOption(undefined);
  }, [onClose]);

  const onAddClicked = useCallback(() => {
    if (!selectedOption) {
      return;
    }

    addWidget(selectedOption.id as WidgetType);
    handleClose();
  }, [addWidget, selectedOption, handleClose]);

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <CustomDialogTitle title={t("dashboard.add_widget_dialog.title")} Icon={PlusIcon} />
      <DialogContent>
        <Grid container direction="column" spacing={6}>
          <Grid item>
            <Typography variant="body1">{t("dashboard.add_widget_dialog.description")}</Typography>
          </Grid>
          <Grid item>
            <CustomSelect
              label={t("dashboard.add_widget_dialog.select_placeholder")}
              labelId={"select-widget-label"}
              options={options}
              value={selectedOption?.id || ""}
              onChange={(event) => setSelectedOption(options.find((option) => option.id === event.target.value))}
              disabled={allWidgetsDisabled}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <CustomDialogActions>
        <Button variant="outlined" onClick={handleClose}>
          {t("dashboard.add_widget_dialog.cancel")}
        </Button>
        <Button variant="contained" disabled={!selectedOption} onClick={onAddClicked}>
          {t("dashboard.add_widget_dialog.add")}
        </Button>
      </CustomDialogActions>
    </Dialog>
  );
};
