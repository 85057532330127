import { Box, Typography } from "@mui/material";
import React, { useMemo } from "react";
import { TopicContactBoxContent, usePersonDetailsQuery } from "../../../api/muu-api/graphql/generated";
import { EditorContent } from "../../../components/editor/editor-content.component";
import {
  IPersonDetailCardImageInformation,
  PersonDetailCardImageInformation,
} from "../../../components/person-detail/person-detail-card-image-information.component";
import { PersonDetailCardImageInformationSkeleton } from "../../../components/person-detail/person-detail-card-image-information.skeleton";

interface ITopicContactBoxContentProps {
  content: TopicContactBoxContent;
}

export const TopicContactBox: React.FC<ITopicContactBoxContentProps> = (props) => {
  const { content } = props;

  const { data, loading } = usePersonDetailsQuery({
    skip: !content.medUniIdNullable,
    variables: { id: content.medUniIdNullable! },
  });

  const showImage = useMemo(() => {
    return (content.medUniIdNullable && content.showPicture) || (!content.medUniIdNullable && content.picture?.mediaUrl)
      ? true
      : false;
  }, [content]);

  const { person, textarea }: { person?: IPersonDetailCardImageInformation; textarea?: string } = useMemo(() => {
    if (content.medUniIdNullable && !data?.person) {
      return { person: undefined };
    }

    if (content.medUniIdNullable && data?.person) {
      return {
        person: {
          firstName: data.person.firstName,
          lastName: data.person.lastName,
          email: data.person.email || undefined,
          consultation: data.person.consultation || undefined,
          mobile: data.person.mobile || undefined,
          phone: data.person.phone || undefined,
          phoneExternal: data.person.phoneExternal || undefined,
          personExtra: data.person.personExtra || { organizations: [], nonOrganizations: [], placeOfWork: undefined },
          suffixedAcademicTitle: data.person.title || undefined,
          avatar: data.person.avatar || undefined,
          appendix: data.person.appendix || undefined,
          prefixTitle: data.person.prefixTitle || undefined,
          suffixTitle: data.person.suffixTitle || undefined,
        },
        textarea: content.textarea || undefined,
      };
    }

    return {
      person: {
        firstName: content.firstName || undefined,
        lastName: content.lastName || undefined,
        email: content.email || undefined,
        consultation: content.consultation || undefined,
        mobile: content.mobile || undefined,
        phone: content.phone || undefined,
        phoneExternal: content.phoneExternal || undefined,
        organizationRaw: content.organization || undefined,
        organizationRawId: content.organizationId || undefined,
        suffixedAcademicTitle: content.academicTitle || undefined,
        avatar: content.picture?.mediaUrl || undefined,
      },

      textarea: content.textarea || undefined,
    };
  }, [content, data]);

  if (!loading && !person && !textarea) {
    return null;
  }

  return (
    <Box>
      <Typography variant="h6" sx={{ color: "primaryDark.main", mb: 2 }}>
        {content.title}
      </Typography>

      {loading && <PersonDetailCardImageInformationSkeleton />}
      {person && !loading && <PersonDetailCardImageInformation person={person} showImage={showImage} />}

      {textarea && (
        <Box sx={{ pt: 2 }}>
          <EditorContent editorContentHtmlString={textarea} />
        </Box>
      )}
    </Box>
  );
};
