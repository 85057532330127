import { Divider, Grid, Skeleton, useTheme } from "@mui/material";
import { FunctionComponent, useCallback, useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { Category, ThinkTankCategory } from "../../api/muu-api/graphql/generated";
import { NONE_CATEGORY } from "../../app/helpers/const.helper";
import { CustomChip } from "../chip/custom-chip.component";
import { CATEGORIES_SEARCH_PARAM } from "../filter/hooks/use-url-selected-categories.hook";
import { CustomMediaIcon } from "../icon/custom-media-icon.component";
import { NavMenuContext } from "../layout/nav/context/nav-menu.context";
import { getAbsoluteRoutingPath } from "../../utils/get-absolute-routing-path.util";
import { DIVIDER_PADDING } from "../../theme/sizings.theme";
import { LayoutContext } from "../layout/layout.context";

interface ICategoryChipsProps {
  additionalCategories?: Category[];
  mainCategory?: Category | ThinkTankCategory;
  loading: boolean;
  type: "events" | "infocenter" | "thinkTank";
}

export const CategoryChips: FunctionComponent<ICategoryChipsProps> = (props) => {
  const { additionalCategories, mainCategory, loading, type } = props;

  const navigate = useNavigate();
  const { pageOfInterestDictionary } = useContext(NavMenuContext);
  const { breakpoint, breakpointInfo } = useContext(LayoutContext);
  const theme = useTheme();
  const chipHeight = useMemo(() => theme.icon.medium + parseFloat(theme.spacing(4)), [theme]);

  const onClickCategory = useCallback(
    (category: Category | ThinkTankCategory) => {
      switch (type) {
        case "thinkTank": {
          break;
        }
        case "events": {
          navigate(
            getAbsoluteRoutingPath(`${pageOfInterestDictionary.EVENTS.path}?${CATEGORIES_SEARCH_PARAM}=${category.id}`),
          );
          break;
        }
        case "infocenter": {
          navigate(
            getAbsoluteRoutingPath(
              `${pageOfInterestDictionary.INFOCENTER.path}?${CATEGORIES_SEARCH_PARAM}=${category.id}`,
            ),
          );
          break;
        }
      }
    },

    [navigate, pageOfInterestDictionary, type],
  );

  const filteredAdditionalCategories = useMemo(
    () =>
      (additionalCategories || [])
        .filter((category) => category.id !== mainCategory?.id)
        .filter((category) => category.searchFilter !== NONE_CATEGORY),
    [additionalCategories, mainCategory],
  );

  const correctMainCategory = useMemo(() => {
    return mainCategory?.searchFilter !== NONE_CATEGORY ? mainCategory : null;
  }, [mainCategory]);

  if (additionalCategories?.length === 0 && !correctMainCategory) {
    return null;
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sx={{ mb: DIVIDER_PADDING[breakpoint] - 2, mt: DIVIDER_PADDING[breakpoint] }}>
        <Divider />
      </Grid>
      {loading && (
        <>
          <Grid item>
            <Skeleton variant="rectangular" width={100} sx={{ borderRadius: 90, height: chipHeight }} />
          </Grid>
          <Grid item>
            <Skeleton variant="rectangular" width={140} sx={{ borderRadius: 90, height: chipHeight }} />
          </Grid>
          <Grid item>
            <Skeleton variant="rectangular" width={120} sx={{ borderRadius: 90, height: chipHeight }} />
          </Grid>
          <Grid item>
            <Skeleton variant="rectangular" width={160} sx={{ borderRadius: 90, height: chipHeight }} />
          </Grid>
        </>
      )}

      {!loading && correctMainCategory && correctMainCategory.searchFilter !== NONE_CATEGORY && (
        <Grid item>
          <CustomChip
            variant={breakpointInfo.isMobile ? "body2" : "body1"}
            onClick={() => onClickCategory(correctMainCategory)}
            icon={correctMainCategory.icon ? <CustomMediaIcon strapiMedia={correctMainCategory.icon} /> : undefined}
            label={correctMainCategory.title}
          />
        </Grid>
      )}
      {!loading &&
        filteredAdditionalCategories
          .filter((category) => category.searchFilter !== NONE_CATEGORY)
          .map((category) => (
            <Grid item key={category.id}>
              <CustomChip
                variant={breakpointInfo.isMobile ? "body2" : "body1"}
                onClick={() => onClickCategory(category)}
                icon={category.icon ? <CustomMediaIcon strapiMedia={category.icon} /> : undefined}
                label={category.title}
              />
            </Grid>
          ))}
    </Grid>
  );
};
