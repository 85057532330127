import React from "react";
import { AppBar, Grid, Toolbar, useTheme } from "@mui/material";
import { APP_BAR } from "../../../theme/sizings.theme";
import { MobileNavHandle } from "../mobile-nav/mobile-nav-handle.component";
import { LanguageSelect } from "../language-select/language-select.component";
import { BellDataIndicator } from "../bell-data/bell-data-indicator.component";
import { Profile } from "../profile/profile.component";
import { Searchbar } from "../searchbar/searchbar.component";
import { ThemeSelect } from "../theme-select/theme-select.component";

export const MobileHeader: React.FC = () => {
  const theme = useTheme();
  return (
    <AppBar sx={{ boxShadow: "none", backgroundColor: "background.default" }}>
      <Toolbar sx={{ height: APP_BAR.MOBILE, backgroundColor: "background.default", padding: theme.spacing(0, 4) }}>
        <Grid container alignItems="center">
          <Grid item>
            <MobileNavHandle />
          </Grid>
          <Grid item>
            <Searchbar />
          </Grid>
          <Grid item xs />
          <Grid item>
            <LanguageSelect />
          </Grid>
          <Grid item>
            <ThemeSelect />
          </Grid>
          <Grid item>
            <BellDataIndicator />
          </Grid>
          <Grid item>
            <Profile />
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};
