import { RestaurantMenu } from "../../../../api/muu-api/graphql/generated";
import { dateISOFromJsDate, dateISOFromMillis } from "../../../../utils/date.util";

export const filterNewRestaurantsByDate = (restaurants: RestaurantMenu[], date: Date) => {
  const newRestaurants: RestaurantMenu[] = [];
  restaurants.forEach((restaurant) => {
    const meals = restaurant.meals?.filter((meal) => dateISOFromMillis(meal.validAt) === dateISOFromJsDate(date));
    const specials = restaurant.specials?.filter((special) => {
      return (
        dateISOFromMillis(special.validFrom) <= dateISOFromJsDate(date) &&
        dateISOFromMillis(special.validTo) >= dateISOFromJsDate(date)
      );
    });

    // Don't display restaurants without any meals or specials
    if (meals?.length === 0 && specials?.length === 0) {
      return;
    }

    newRestaurants.push({
      id: restaurant.id,
      name: restaurant.name,
      url: restaurant.url,
      meals: meals,
      specials: specials,
    } as RestaurantMenu);
  });

  return newRestaurants;
};
