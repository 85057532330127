import { Divider, Grid, Link, Skeleton, Typography, useTheme } from "@mui/material";
import { FunctionComponent, useContext, useMemo } from "react";
import { SearchLegalDocument } from "../../api/muu-api/graphql/generated";
import { FilterDocumentsIcon } from "../../assets/icons/icons.component";
import { SEARCH_RESULT_ICON_HORIZONTAL_MARGIN_FACTOR, SEARCH_RESULT_SKELETON_WIDTH } from "../../theme/sizings.theme";
import { config } from "../../utils/config.util";
import { LayoutContext } from "../layout/layout.context";
import { LinkState, useCheckLink } from "../../hooks/use-check-link.hook";
import { parseRelativeLink } from "../../app/helpers/relative-link.helper";

interface ILegalDocumentsSearchResultProps {
  legalDocument?: SearchLegalDocument;
  loading: boolean;
  isLastItem: boolean;
}

export const LegalDocumentsSearchResult: FunctionComponent<ILegalDocumentsSearchResultProps> = (props) => {
  const { legalDocument, loading } = props;
  const theme = useTheme();
  const { breakpoint } = useContext(LayoutContext);
  const href = `${config.REACT_APP_RELATIVE_LINK_URL}${legalDocument?.media?.mediaUrl}`;
  const { linkStatus } = useCheckLink(href);

  const parsedHref = useMemo(() => {
    if (linkStatus === null || linkStatus !== LinkState.VALID) {
      return "#";
    }
    return href && linkStatus === LinkState.VALID ? parseRelativeLink(href).link : href;
  }, [href, linkStatus]);

  return (
    <Grid container direction="column">
      <Link href={parsedHref} target="_blank" style={{ textDecoration: "none", color: "inherit", font: "inherit" }}>
        <Grid container direction="row" alignItems="center" sx={{ p: 4 }}>
          <Grid
            item
            sx={{
              color: "accent1.main",
              ml: SEARCH_RESULT_ICON_HORIZONTAL_MARGIN_FACTOR[breakpoint],
              mr: SEARCH_RESULT_ICON_HORIZONTAL_MARGIN_FACTOR[breakpoint],
              pr: 4,
              display: "flex",
            }}
          >
            <FilterDocumentsIcon fontSize="medium" />
          </Grid>
          <Grid item xs container direction="column">
            <Grid item>
              {!!legalDocument && legalDocument.title && (
                <Typography
                  variant="subtitle2"
                  sx={{ color: "text.primary", textAlign: "start", wordBreak: "break-word" }}
                >
                  {legalDocument.title}
                </Typography>
              )}
              {!legalDocument && loading && (
                <Skeleton
                  variant="text"
                  width={SEARCH_RESULT_SKELETON_WIDTH[breakpoint]}
                  sx={{ fontSize: theme.typography.body1 }}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
      </Link>
      <Grid item>
        <Divider sx={{ backgroundColor: "grey.100" }} />
      </Grid>
    </Grid>
  );
};
