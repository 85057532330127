import { Avatar, Divider, Grid, Link, Skeleton, Typography, useTheme } from "@mui/material";
import React, { useCallback, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { SearchPerson } from "../../api/muu-api/graphql/generated";
import { StaticPageDictionary } from "../../router/routes.const";
import { getAbsoluteRoutingPath } from "../../utils/get-absolute-routing-path.util";
import parse from "html-react-parser";
import { PhoneIcon } from "../../assets/icons/icons.component";
import { useTracking } from "../../app/context/tracking/tracking.context";
import { LayoutContext } from "../layout/layout.context";
import { SEARCH_RESULT_AVATAR_SIZE } from "../../theme/sizings.theme";

interface IPersonsSearchResultProps {
  person?: SearchPerson;
  loading: boolean;
  isLastItem: boolean;
}

export const PersonsSearchResult: React.FC<IPersonsSearchResultProps> = (props) => {
  const { person, isLastItem, loading } = props;
  const navigate = useNavigate();
  const theme = useTheme();
  const { trackEvent } = useTracking();
  const { breakpointInfo, breakpoint } = useContext(LayoutContext);

  const onClick = useCallback(() => {
    if (!person) {
      return;
    }
    trackEvent({
      event: "search_result_view",
      action: "redirect",
      category: "page_visit",
    });
    navigate({
      pathname: getAbsoluteRoutingPath(StaticPageDictionary.PERSON_DETAIL.path),
      search: `id=${person.id}`,
    });
  }, [navigate, person, trackEvent]);

  return (
    <Grid container direction="column">
      <Grid item>
        <Link
          component="button"
          onClick={onClick}
          style={{ textDecoration: "none", color: "inherit", font: "inherit" }}
        >
          <Grid
            container
            sx={{ p: 4 }}
            alignItems={breakpointInfo.isMobile ? "flex-start" : "center"}
            flexWrap="nowrap"
          >
            <Grid item sx={{ pr: 4 }}>
              {!!person && (
                <Avatar
                  alt={`${person?.firstName} ${person?.lastName}`}
                  src={person?.avatar ? person.avatar : ""}
                  sx={{
                    borderRadius: 3,
                    width: SEARCH_RESULT_AVATAR_SIZE[breakpoint],
                    height: SEARCH_RESULT_AVATAR_SIZE[breakpoint],
                  }}
                />
              )}
              {!person && loading && (
                <Skeleton
                  variant="rectangular"
                  sx={{
                    borderRadius: 3,
                    width: SEARCH_RESULT_AVATAR_SIZE[breakpoint],
                    height: SEARCH_RESULT_AVATAR_SIZE[breakpoint],
                  }}
                />
              )}
            </Grid>
            <Grid item xs>
              <Grid container alignItems="center" spacing={2}>
                {!!person?.firstName && (
                  <Grid item xs={breakpointInfo.isMobile ? 12 : undefined} sx={{ textAlign: "start" }}>
                    <Typography variant="subtitle1">
                      {parse(person.firstName.join(" "))} {parse(person.lastName)}
                    </Typography>
                  </Grid>
                )}
                {!!person?.title && (
                  <Grid item xs>
                    <Typography variant="body2" sx={{ color: "grey.500", textAlign: "start", wordBreak: "break-word" }}>
                      {parse(person.title ?? "")}
                    </Typography>
                  </Grid>
                )}
                {!person && loading && (
                  <Grid item xs={12}>
                    <Skeleton variant="text" width={230} sx={{ fontSize: theme.typography.body1 }} />
                  </Grid>
                )}

                {!!person && (
                  <Grid item xs={12}>
                    <Typography variant="body2" sx={{ textAlign: "start", wordBreak: "break-word" }}>
                      {parse(person.organizations?.map((organization) => organization.name).join(", "))}
                    </Typography>
                  </Grid>
                )}
                {!person && loading && (
                  <Grid>
                    <Skeleton variant="text" width={180} sx={{ fontSize: theme.typography.body1 }} />
                  </Grid>
                )}
                {!!person && person.phone.length > 0 && (
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      {!breakpointInfo.isMobile && (
                        <>
                          <Grid item>
                            <PhoneIcon style={{ color: theme.palette.accent1.main }} fontSize="medium" />
                          </Grid>
                          <Grid item xs>
                            <Typography variant="body2" sx={{ textAlign: "start" }}>
                              {person.phone.join(" | ")}
                            </Typography>
                          </Grid>
                        </>
                      )}
                      {breakpointInfo.isMobile && (
                        <>
                          <Grid item>
                            <PhoneIcon style={{ color: theme.palette.accent1.main }} fontSize="medium" />
                          </Grid>

                          <Grid item xs>
                            <Grid container spacing={2}>
                              {person.phone.map((phone) => (
                                <Grid item xs={12} key={`${person.id}-${phone}`}>
                                  <Typography variant="body2" sx={{ textAlign: "start" }}>
                                    {phone}
                                  </Typography>
                                </Grid>
                              ))}
                            </Grid>
                          </Grid>
                        </>
                      )}
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Link>
      </Grid>
      <Grid item>
        <Divider sx={{ backgroundColor: isLastItem ? "accent1.main" : "grey.100" }} />
      </Grid>
    </Grid>
  );
};
