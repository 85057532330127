import { Box, Grid, Skeleton, useTheme } from "@mui/material";
import React, { useContext, useMemo, useState } from "react";
import { InfocenterDetailContext } from "./context/infocenter-detail.context";
import { INFOCENTER_DETAIL_HEADER } from "../../theme/sizings.theme";
import { LayoutContext } from "../../components/layout/layout.context";
import { InfocenterDetailActionIcons } from "./infocenter-detail-action-icons.component";
import { InfocenterDetailHeaderText } from "./infocenter-detail-header-text.component";
import { getInfocenterMedia } from "../../utils/media.util";

interface IInfocenterDetailHeaderProps {}

export const InfocenterDetailHeader: React.FC<IInfocenterDetailHeaderProps> = (props) => {
  const { infocenterEntry, infocenterEntryLoading } = useContext(InfocenterDetailContext);
  const { breakpointInfo, breakpoint } = useContext(LayoutContext);
  const [imageLoadingError, setImageLoadingError] = useState(false);
  const theme = useTheme();

  const media = useMemo(() => {
    if (!infocenterEntry) return undefined;
    return getInfocenterMedia(infocenterEntry);
  }, [infocenterEntry]);

  return (
    <Grid container flexDirection="column" sx={{ overflowWrap: "break-word" }}>
      <Grid item xs>
        <Box
          sx={{
            height: INFOCENTER_DETAIL_HEADER[breakpoint],
            width: "100%",
            bgcolor: !media?.mediaUrl || imageLoadingError ? "primaryDark.main" : undefined,
            position: "relative",
            display: "flex",
          }}
        >
          {media && (
            <img
              style={{ objectFit: "cover", width: "100%" }}
              src={media.mediaUrl}
              alt={media.mediaAlt || ""}
              onError={() => setImageLoadingError(true)}
            />
          )}
          {infocenterEntryLoading && (
            <Skeleton
              variant="rectangular"
              sx={{
                width: "100%",
                height: "100%",
                bgcolor: "primaryDark.main",
                borderTopLeftRadius: theme.spacing(4),
                borderTopRightRadius: theme.spacing(4),
              }}
            />
          )}
          <Box sx={{ top: theme.spacing(6), right: theme.spacing(6), position: "absolute", zIndex: 999 }}>
            <InfocenterDetailActionIcons disabled={infocenterEntryLoading || !infocenterEntry} />
          </Box>
          {!infocenterEntryLoading && !breakpointInfo.isMobile && <InfocenterDetailHeaderText />}
        </Box>
      </Grid>
      {breakpointInfo.isMobile && (
        <Grid
          item
          sx={{
            width: "100%",
          }}
        >
          <InfocenterDetailHeaderText />
        </Grid>
      )}
    </Grid>
  );
};
