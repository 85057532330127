import { Box, Typography, useTheme } from "@mui/material";
import { FC, useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { TopicDescriptionContent } from "../../../api/muu-api/graphql/generated";
import { DoubleArrowsDownIcon, DoubleArrowsUpIcon } from "../../../assets/icons/icons.component";
import { EditorContent } from "../../../components/editor/editor-content.component";
import { LayoutContext } from "../../../components/layout/layout.context";
import { TOPIC_PAGE_DESCRIPTION_MAX_HEIGHT } from "../../../theme/sizings.theme";
import { TopicDescriptionContext } from "./topic-description.context";

interface ITopicDescriptionProps {
  content: TopicDescriptionContent;
}

export const TopicDescription: FC<ITopicDescriptionProps> = (props) => {
  const { content } = props;
  const { open, setOpen, descriptionRef } = useContext(TopicDescriptionContext);
  const { breakpoint } = useContext(LayoutContext);
  const theme = useTheme();
  const { t } = useTranslation();

  const maxHeight = useMemo(() => {
    if (open || !content.showMore) {
      return "unset";
    }
    return TOPIC_PAGE_DESCRIPTION_MAX_HEIGHT[breakpoint];
  }, [open, content, breakpoint]);

  return (
    <Box
      ref={descriptionRef}
      sx={{
        maxHeight,
        position: "relative",
        overflow: "hidden",
      }}
    >
      <EditorContent editorContentHtmlString={content.description} />
      {content.showMore && !open && (
        <Box
          sx={{
            height: TOPIC_PAGE_DESCRIPTION_MAX_HEIGHT[breakpoint] * 0.25,
            left: 0,
            right: 0,
            bottom: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "flex-end",
            position: "absolute",
            background: `linear-gradient(0deg,${theme.palette.background.paper} 15%, #ffffff00 100% )`,
          }}
        >
          <Box
            onClick={() => setOpen(true)}
            sx={{
              display: "flex",
              color: "primaryDark.main",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <DoubleArrowsDownIcon
              //optically center the icon
              sx={{ color: "primaryDark.main", height: theme.icon.small, width: theme.icon.small, mt: "2px" }}
            />
            <Typography sx={{ pl: 1, color: "primaryDark.main", cursor: "pointer" }} variant="body1">
              {t("general.show_more")}
            </Typography>
          </Box>
        </Box>
      )}
      {content.showMore && open && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box
            onClick={() => setOpen(false)}
            sx={{
              display: "flex",
              color: "primaryDark.main",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <DoubleArrowsUpIcon
              //optically center the icon
              sx={{ color: "primaryDark.main", height: theme.icon.small, width: theme.icon.small, mt: "2px" }}
            />
            <Typography sx={{ pl: 1, color: "primaryDark.main", cursor: "pointer" }} variant="body1">
              {t("general.show_less")}
            </Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
};
