export const cssStringToCssProps = (cssString: string | undefined): React.CSSProperties | undefined => {
  if (!cssString) return undefined;
  const splittedParts = cssString.split(";");

  let cssProps = {};
  for (const part of splittedParts) {
    // Transform kebab-case to camelCase
    const name = part.split(":")[0].replace(/-./g, (x) => x[1].toUpperCase());
    const value = part.split(":")[1];
    if (!name || !value) continue;

    cssProps = { ...cssProps, [name]: value.replace(";", "") };
  }

  return cssProps;
};
