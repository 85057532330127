import { useContext } from "react";
import { WidgetColor } from "../../../../api/muu-api/graphql/generated";
import { ThemeContext } from "../../../../components/layout/theme.context";

export const useWidgetColorToThemeColor = (
  widgetColor: WidgetColor,
  isInEditMode: boolean = false,
  overwriteIsContrastThemeActive: boolean = false,
) => {
  const { isContrastThemeActive } = useContext(ThemeContext);

  if (!overwriteIsContrastThemeActive && isContrastThemeActive && !isInEditMode) {
    return "widgetHeader";
  }
  if (isInEditMode) {
    return "accent6";
  }
  switch (widgetColor) {
    case WidgetColor.DEFAULT:
      return "widgetHeader";
    case WidgetColor.DARK_GREEN:
      return "primaryDark";
    case WidgetColor.PETROL:
      return "accent1";
    case WidgetColor.PINK:
      return "accent2";
    case WidgetColor.SUNNY_YELLOW:
      return "accent3";
    case WidgetColor.VIOLET:
      return "accent4";
    case WidgetColor.WINE_RED:
      return "accent5";

    default:
      return "widgetHeader";
  }
};
