import { IExtendedWidget } from "../types/widget.type";
import { removeEmptyRows } from "./remove-empty-rows.helper";

export const changeWidgetPosition = (
  widgets: IExtendedWidget[],
  id: string,
  row: number,
  column: number,
): IExtendedWidget[] | null => {
  const copy: IExtendedWidget[] = Object.assign([], widgets);
  let widget = copy.find((w) => w.id === id);

  if (!widget) {
    return null;
  }

  copy
    .filter((w) => w.row === (widget as IExtendedWidget).row && w.column > (widget as IExtendedWidget).column)
    .forEach((w) => {
      w.column -= 1;
    });

  copy
    .filter((w) => w.row === row && w.column >= column)
    .forEach((w) => {
      w.column += 1;
    });

  widget = {
    ...widget,
    row,
    column,
  };

  const newWidgets = [...copy.filter((w) => w.id !== widget?.id), widget];
  return removeEmptyRows(newWidgets);
};
