import { Divider, Grid, IconButton, Typography, useTheme } from "@mui/material";
import { FC, useCallback, useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { SearchInfocenter, WidgetType } from "../../../api/muu-api/graphql/generated";
import { CopyIcon, PinnedIcon } from "../../../assets/icons/icons.component";
import { SnackbarSeverity, useSnackbar } from "../../snackbar/snackbar.context";
import { WIDGET_CONTENT_ROW_HEIGHT, WIDGET_TOTAL_ROW_HEIGHT } from "../../../theme/sizings.theme";
import { useNavigate } from "react-router-dom";
import { getAbsoluteRoutingPath } from "../../../utils/get-absolute-routing-path.util";
import { encodeIdBase64 } from "../../../utils/base64.util";
import { NavMenuContext } from "../../layout/nav/context/nav-menu.context";
import { DETAIL_PATH } from "../../../router/routes.const";
import { LayoutContext } from "../../layout/layout.context";
import { stripHtml } from "string-strip-html";
import { InfocenterLink } from "../../link/infocenter-link.component";
import { CustomIconButton } from "../../icon/custom-icon-button.component";

interface IInfocenterPostRowProps {
  post: SearchInfocenter;
  icon: JSX.Element | undefined;
  isPinned: boolean;
}

export const InfocenterPostRow: FC<IInfocenterPostRowProps> = (props) => {
  const { post, isPinned, icon } = props;
  const { t } = useTranslation();
  const { showSnackbar } = useSnackbar();
  const { pageOfInterestDictionary } = useContext(NavMenuContext);
  const theme = useTheme();
  const navigate = useNavigate();
  const { breakpoint } = useContext(LayoutContext);

  const infocenterDetailRoute = useMemo(
    () => `${pageOfInterestDictionary.INFOCENTER?.path}/${DETAIL_PATH}`,
    [pageOfInterestDictionary],
  );

  const navigateToDetails = useCallback(() => {
    if (!infocenterDetailRoute) {
      return;
    }
    navigate(`${getAbsoluteRoutingPath(infocenterDetailRoute)}/?id=${encodeIdBase64(post.id, { type: post.type })}`);
  }, [navigate, post, infocenterDetailRoute]);

  const copyLink = useCallback(() => {
    if (!infocenterDetailRoute) {
      return;
    }
    navigator.clipboard.writeText(
      //TODO CHANGE
      `${window.location.protocol}//${window.location.hostname}:${
        window.location.port
      }/${infocenterDetailRoute}?id=${encodeIdBase64(post.id, { type: post.type })}`,
    );
    showSnackbar(t(`widgets.${WidgetType.INFOCENTER}.copy_link_success`), SnackbarSeverity.Success);
  }, [showSnackbar, t, post, infocenterDetailRoute]);

  return (
    <>
      <Grid
        id={`infocenter-row-${post.id}`}
        container
        alignItems="center"
        justifyContent="flex-start"
        sx={{ p: 2, pl: 4, pr: 4, height: WIDGET_TOTAL_ROW_HEIGHT[breakpoint] }}
      >
        <Grid item sx={{ cursor: "pointer" }}>
          <IconButton aria-label="Infocenter Details" onClick={navigateToDetails} sx={{ p: 0, m: 0 }}>
            {icon}
          </IconButton>
        </Grid>
        <Grid
          item
          container
          direction="column"
          justifyContent="space-between"
          xs
          sx={{ cursor: "pointer", pl: 4, pr: 4 }}
        >
          <InfocenterLink entry={post} localizeLink>
            {post.title && (
              <Grid
                item
                container
                alignItems="center"
                sx={{
                  height: WIDGET_CONTENT_ROW_HEIGHT[breakpoint],
                }}
              >
                <Typography
                  variant="body2"
                  sx={{
                    fontWeight: "bold",
                    overflow: "hidden",
                    display: "-webkit-box",
                    WebkitLineClamp: 1,
                    WebkitBoxOrient: "vertical",
                    wordBreak: "break-word",
                    textAlign: "start",
                  }}
                >
                  {stripHtml(post.title ?? "").result}
                </Typography>
              </Grid>
            )}
            {post.teaser && post.teaser.length > 0 && (
              <Grid
                item
                container
                alignItems="center"
                sx={{
                  height: WIDGET_CONTENT_ROW_HEIGHT[breakpoint],
                }}
              >
                <Typography
                  variant="body2"
                  sx={{
                    color: "grey.500",
                    overflow: "hidden",
                    display: "-webkit-box",
                    WebkitLineClamp: 1,
                    WebkitBoxOrient: "vertical",
                    wordBreak: "break-word",
                    textAlign: "start",
                  }}
                >
                  {stripHtml(post.teaser ?? "").result}
                </Typography>
              </Grid>
            )}
          </InfocenterLink>
        </Grid>
        <Grid item container justifyContent={"flex-end"} sx={{ width: "fit-content" }}>
          {isPinned && (
            <div style={{ width: theme.icon.medium }}>
              <PinnedIcon sx={{ color: "error.main" }} />
            </div>
          )}
          <CustomIconButton
            variant="contained"
            aria-label="Copy Link"
            sx={{ boxShadow: "none", p: 1, color: "grey.800" }}
            onClick={(event) => {
              event.stopPropagation();
              event.preventDefault();
              copyLink();
            }}
            title={t("dashboard.tooltip.widget.deeplink")}
          >
            <CopyIcon />
          </CustomIconButton>
        </Grid>
      </Grid>
      <Divider />
    </>
  );
};
