import { Route } from "react-router-dom";
import { MenuSection, MenuItem, SimplePage, Language } from "../../api/muu-api/graphql/generated";
import {
  ExtPageDictionary,
  PageComponentDictionary,
  PageOfInterestDictionaryType,
  TopicPageDictionary,
} from "../routes.const";
import { IMenuItemWithPortalRoute, IMenuWithPortalRoutes } from "../../components/layout/nav/context/nav-menu.context";
import { StaticPageDictionary } from "../routes.const";
import { DynamicTopicPage } from "../../pages/dynamic-topic-page/dynamic-topic-page.page";
import { ExternalPage } from "../../pages/external-page/external.page";

const checkIfMenuItemIsOfInterest = (
  route: string,
  menuItem: MenuItem,
  routesOfInterest: PageOfInterestDictionaryType,
): void => {
  const pageAsPage = menuItem.page as SimplePage;
  if (!pageAsPage?.component) {
    return;
  }
  const dictionaryEntry = PageComponentDictionary[pageAsPage.component.__typename];
  if (!dictionaryEntry?.pageOfInterestType) {
    return;
  }

  if (!routesOfInterest[dictionaryEntry.pageOfInterestType]) {
    routesOfInterest[dictionaryEntry.pageOfInterestType] = { ...dictionaryEntry, path: route };
    return;
  }

  if (routesOfInterest[dictionaryEntry.pageOfInterestType].path.length > route.length) {
    routesOfInterest[dictionaryEntry.pageOfInterestType] = { ...dictionaryEntry, path: route };
  }
};

const addRouteToMenuItemAndGenerateRouteComponents = (
  initialRoute: string,
  menuItem: MenuItem,
  routeComponents: JSX.Element[],
  pageOfInterestDictionary: PageOfInterestDictionaryType,
  menuItemsFlat: IMenuItemWithPortalRoute[],
  level: number,
): IMenuItemWithPortalRoute => {
  const routeForMenuItem = initialRoute === "" ? menuItem.url : `${initialRoute}/${menuItem.url}`;

  const menuItemWithPortalRoutes: IMenuItemWithPortalRoute = {
    ...menuItem,
    route: routeForMenuItem,
    isExternalLink: menuItem.isExternalLink,
    menuItems: [],
    level,
  };

  if (menuItem.menuItems && menuItem.menuItems.length > 0) {
    menuItemWithPortalRoutes.menuItems = menuItem.menuItems.map((subMenuItem) =>
      addRouteToMenuItemAndGenerateRouteComponents(
        routeForMenuItem,
        subMenuItem,
        routeComponents,
        pageOfInterestDictionary,
        menuItemsFlat,
        level + 1,
      ),
    );
  }

  switch (menuItem.page?.__typename) {
    case "SimplePage":
      if ((menuItem.page as SimplePage).component) {
        const page = PageComponentDictionary[(menuItem.page as SimplePage).component.__typename];
        routeComponents.push(<Route path={routeForMenuItem} key={routeForMenuItem} element={<page.component />} />);
        page.subPages.forEach((subPage) => {
          const routeForSubPage = `${routeForMenuItem}/${subPage.path}`;
          routeComponents.push(<Route path={routeForSubPage} key={routeForSubPage} element={<subPage.component />} />);
        });
        checkIfMenuItemIsOfInterest(routeForMenuItem, menuItem, pageOfInterestDictionary);
      }
      break;
    case "SimpleTopicPage":
      routeComponents.push(
        <Route
          path={routeForMenuItem}
          key={routeForMenuItem}
          element={<DynamicTopicPage id={menuItem.page.id} subMenuItems={menuItemWithPortalRoutes.menuItems} />}
        />,
      );
      TopicPageDictionary[menuItem.page.id] = { route: routeForMenuItem, language: Language.DE };
      (menuItem.page.localizations || []).forEach((localization) => {
        TopicPageDictionary[localization.id] = { route: routeForMenuItem, language: localization.language };
      });
      break;
    case "SimpleExtPage":
      routeComponents.push(
        <Route
          path={routeForMenuItem}
          key={routeForMenuItem}
          element={<ExternalPage id={menuItem.page.id} url={menuItem.page.url} />}
        />,
      );
      ExtPageDictionary[menuItem.page.id] = routeForMenuItem;
      break;
  }

  menuItemsFlat.push(menuItemWithPortalRoutes);
  return menuItemWithPortalRoutes;
};

const generateStaticRoutes = (routeComponents: JSX.Element[]) => {
  Object.values(StaticPageDictionary).forEach((entry) => {
    routeComponents.push(<Route path={entry.path} key={entry.path} element={<entry.component />} />);
    entry.subPages.forEach((subPage) => {
      routeComponents.push(<Route path={subPage.path} key={subPage.path} element={<subPage.component />} />);
    });
  });
};

export const generatePortalRoutesAndMenu = (
  menus: MenuSection[],
): {
  routeComponents: JSX.Element[];
  menusWithPortalRoutes: IMenuWithPortalRoutes[];
  menuItemsFlat: IMenuItemWithPortalRoute[];
  pageOfInterestDictionary: PageOfInterestDictionaryType;
} => {
  const routeComponents: JSX.Element[] = [];
  const menuItemsFlat: IMenuItemWithPortalRoute[] = [];
  const pageOfInterestDictionary: PageOfInterestDictionaryType = {} as PageOfInterestDictionaryType;
  const menusWithPortalRoutes = menus.map((menu) => ({
    ...menu,
    menuItems: menu.menuItems.map((menuItem) =>
      addRouteToMenuItemAndGenerateRouteComponents(
        "",
        menuItem,
        routeComponents,
        pageOfInterestDictionary,
        menuItemsFlat,
        1,
      ),
    ),
  }));
  generateStaticRoutes(routeComponents);

  return { routeComponents, menusWithPortalRoutes, pageOfInterestDictionary, menuItemsFlat };
};
