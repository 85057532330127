import {
  createContext,
  FunctionComponent,
  MutableRefObject,
  PropsWithChildren,
  ReactInstance,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from "react";
import {
  FavoriteType,
  InfocenterEntryAll,
  InfocenterType,
  useInfocenterDetailsLazyQuery,
  useInfocenterDetailsForLanguageLazyQuery,
} from "../../../api/muu-api/graphql/generated";
import { IFavoriteInputs } from "../../../components/button/page-action-icon-buttons.component";
import { infocenterSourceToFavoriteSource } from "../../../components/infocenter/helper/infocenter-mapping.helper";
import { useFavorite } from "../../../hooks/use-favorite.hook";
import { ApolloError } from "@apollo/client";
import { UserContext } from "../../../app/context/user.context";

interface IInfocenterDetailContext {
  infocenterEntry?: InfocenterEntryAll;
  infocenterEntryLoading: boolean;
  infocenterEntryError?: ApolloError;
  pageContentRef: MutableRefObject<ReactInstance | null>;
  favoriteInputs: IFavoriteInputs | undefined;
}

export const InfocenterDetailContext = createContext<IInfocenterDetailContext>({} as IInfocenterDetailContext);

interface IInfocenterDetailProviderProps extends PropsWithChildren {
  id: string | null;
  type: InfocenterType | null;
  localize: boolean | null;
}

export const InfocenterDetailProvider: FunctionComponent<IInfocenterDetailProviderProps> = (props) => {
  const { id, type, localize } = props;
  const pageContentRef = useRef<ReactInstance>(null);
  const { removeFavorite, createFavorite } = useFavorite();
  const { language } = useContext(UserContext);

  const [
    infocenterDetailsQuery,
    { data: infocenterDetailsData, loading: infocenterDetailsLoading, error: infocenterDetailsError },
  ] = useInfocenterDetailsLazyQuery();

  const [
    infocenterDetailsForLanguageQuery,
    {
      data: infocenterDetailsForLanguageData,
      loading: infocenterDetailsForLanguageLoading,
      error: infocenterDetailsForLanguageError,
    },
  ] = useInfocenterDetailsForLanguageLazyQuery();

  useEffect(() => {
    if (!id || !type) {
      return;
    }
    if (!localize) {
      infocenterDetailsQuery({ fetchPolicy: "no-cache", variables: { id, type, language } });
    } else {
      infocenterDetailsForLanguageQuery({ fetchPolicy: "no-cache", variables: { id, type, language } });
    }
  }, [infocenterDetailsQuery, infocenterDetailsForLanguageQuery, id, type, language, localize]);

  const { infocenterEntry, loading, error } = useMemo(
    () => ({
      infocenterEntry:
        infocenterDetailsData?.infocenterEntry ||
        infocenterDetailsForLanguageData?.infocenterEntryForLanguage ||
        undefined,
      loading: infocenterDetailsLoading || infocenterDetailsForLanguageLoading,
      error: infocenterDetailsError || infocenterDetailsForLanguageError || undefined,
    }),
    [
      infocenterDetailsData,
      infocenterDetailsForLanguageData,
      infocenterDetailsLoading,
      infocenterDetailsForLanguageLoading,
      infocenterDetailsError,
      infocenterDetailsForLanguageError,
    ],
  );

  const favoriteInputs = useMemo(() => {
    if (!infocenterEntry) return undefined;

    const onCreateFavorite = () => {
      const input = {
        entryId: infocenterEntry.id,
        description: infocenterEntry.title,
        teaser: infocenterEntry.description ?? undefined,
        type: FavoriteType.INFOCENTER,
        source: infocenterSourceToFavoriteSource(infocenterEntry.type),
      };

      createFavorite(input);
    };

    return {
      entryId: infocenterEntry.id,
      type: FavoriteType.INFOCENTER,
      source: infocenterSourceToFavoriteSource(infocenterEntry.type),
      onRemoveFavorite: removeFavorite,
      onCreateFavorite,
    };
  }, [createFavorite, infocenterEntry, removeFavorite]);

  return (
    <InfocenterDetailContext.Provider
      value={{
        infocenterEntry,
        infocenterEntryError: error,
        infocenterEntryLoading: loading,
        pageContentRef,
        favoriteInputs,
      }}
    >
      {props.children}
    </InfocenterDetailContext.Provider>
  );
};
