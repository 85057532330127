import { FormControlLabel, Grid, Radio, RadioGroup, TextField, Typography } from "@mui/material";
import React, { useMemo, useContext } from "react";
import { useTranslation } from "react-i18next";
import {
  Media,
  Role,
  ThinkTankCategory,
  ThinkTankType,
  useThinkTankCategoriesQuery,
} from "../../api/muu-api/graphql/generated";
import { Editor } from "../editor/editor.component";
import { CustomSelect, ISelectOption } from "../select/custom-select.component";
import { ThinkTankAttachments } from "../../pages/think-tank/think-tank-attachments.component";
import { UserContext } from "../../app/context/user.context";
import { NONE_CATEGORY } from "../../app/helpers/const.helper";

export interface IThinkTankPostFormFile {
  file: File;
  content: string;
}

export interface IThinkTankPostFormData {
  title: string | undefined;
  // this is the html as string of the ck editor
  description: string | undefined;
  role: Role;
  type: ThinkTankType | undefined;
  category: ThinkTankCategory | undefined;
  attachments: (IThinkTankPostFormFile | Media)[];
}

interface IThinkTankPostFormProps {
  formData: IThinkTankPostFormData;
  setFormData: (data: IThinkTankPostFormData) => void;
}

export const ThinkTankPostForm: React.FC<IThinkTankPostFormProps> = (props) => {
  const { language } = useContext(UserContext);
  const { formData, setFormData } = props;
  const { t } = useTranslation();

  const options: ISelectOption[] = useMemo(
    () => [
      {
        id: ThinkTankType.OFFER,
        value: t(`think_tank.type.${ThinkTankType.OFFER}`),
      },
      {
        id: ThinkTankType.REQUEST,
        value: t(`think_tank.type.${ThinkTankType.REQUEST}`),
      },
    ],
    [t],
  );

  const { data: thinkTankCategoriesResult } = useThinkTankCategoriesQuery({
    fetchPolicy: "no-cache",
    variables: {
      language,
      active: true,
    },
  });

  const thinkTankCategories = useMemo(
    () => thinkTankCategoriesResult?.thinkTankCategories || [],
    [thinkTankCategoriesResult],
  );

  const thinkTankCategoriesOptions: ISelectOption[] = useMemo(() => {
    const categories: ISelectOption[] = [
      {
        id: "0",
        value: t(`think_tank.${NONE_CATEGORY}`),
      },
    ];
    thinkTankCategories?.forEach((category) => {
      categories.push({
        id: category.id,
        value: category.title,
      });
    });
    return categories;
  }, [thinkTankCategories, t]);

  return (
    <Grid container direction="column" spacing={6}>
      <Grid item>
        <TextField
          fullWidth
          label={t("think_tank.title")}
          value={formData?.title}
          onChange={(e) => setFormData({ ...formData, title: e.target.value })}
        />
      </Grid>
      <Grid item>
        <CustomSelect
          label={t("think_tank.type.title")}
          labelId="think_tank_type"
          options={options}
          value={formData.type ?? ""}
          onChange={(event) => {
            const typeOption = options.find((option) => option.id === event.target.value);
            setFormData({ ...formData, type: typeOption?.id as ThinkTankType | undefined });
          }}
        />
      </Grid>
      <Grid item>
        <CustomSelect
          label={t("think_tank.category")}
          labelId="think_tank_category"
          options={thinkTankCategoriesOptions}
          value={formData.category?.id ?? ""}
          onChange={(event) => {
            const categoryOption = thinkTankCategories?.find((option) => option.id === event.target.value);
            setFormData({ ...formData, category: categoryOption as ThinkTankCategory | undefined });
          }}
        />
      </Grid>
      <Grid item container direction="column" spacing={3}>
        <Grid item>
          <Typography sx={{ fontWeight: 650 }}>{t("think_tank.description")}</Typography>
        </Grid>
        <Grid item sx={{ width: "100%" }}>
          <Editor data={formData?.description} setData={(data) => setFormData({ ...formData, description: data })} />
        </Grid>
      </Grid>
      <Grid item sx={{ width: "100%" }}>
        <ThinkTankAttachments
          attachments={formData.attachments}
          editMode
          setAttachments={(attachments) => setFormData({ ...formData, attachments })}
        />
      </Grid>
      <Grid item container direction="column">
        <Grid item>
          <Typography sx={{ fontWeight: 650 }}>{t("think_tank.role")}</Typography>
        </Grid>
        <Grid item>
          <RadioGroup
            row
            value={formData.role}
            onChange={(_, value) => setFormData({ ...formData, role: value as Role })}
          >
            <FormControlLabel value={Role.ALL} control={<Radio />} label={t(`roles.${Role.ALL}`)} sx={{ mr: 6 }} />
            <FormControlLabel
              value={Role.EMPLOYEE}
              control={<Radio />}
              label={t(`roles.${Role.EMPLOYEE}`)}
              sx={{ mr: 6 }}
            />
            <FormControlLabel value={Role.STUDENT} control={<Radio />} label={t(`roles.${Role.STUDENT}`)} />
          </RadioGroup>
        </Grid>
      </Grid>
    </Grid>
  );
};
