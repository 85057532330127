import { EventType } from "../../../../api/muu-api/graphql/generated";
import { DETAIL_PATH, PageOfInterestDictionaryType } from "../../../../router/routes.const";
import { encodeIdBase64 } from "../../../../utils/base64.util";
import { getAbsoluteRoutingPath } from "../../../../utils/get-absolute-routing-path.util";

export const getEventDetailUrl = (
  id: string,
  type: EventType,
  pageOfInterestDictionary: PageOfInterestDictionaryType,
) => {
  return `${getAbsoluteRoutingPath(pageOfInterestDictionary.EVENTS.path)}/${DETAIL_PATH}/?id=${encodeIdBase64(id, {
    type,
  })}`;
};
