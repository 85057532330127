import { Typography } from "@mui/material";
import React, { FC } from "react";
import { TopicTitleContent } from "../../../api/muu-api/graphql/generated";

interface ITopicTitleProps {
  content: TopicTitleContent;
}

export const TopicTitle: FC<ITopicTitleProps> = (props) => {
  const { content } = props;
  return <Typography variant="h4">{content.title}</Typography>;
};
