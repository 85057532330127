import { Typography } from "@mui/material";
import { ChildNode } from "domhandler";
import { HTMLReactParserOptions, domToReact } from "html-react-parser";

export const mapToBlockquote = (children: ChildNode[], options: HTMLReactParserOptions) => {
  return (
    <Typography
      variant="body1"
      sx={{
        fontStyle: "italic",
        pl: 4,
        ml: 6,
        borderLeftWidth: 4,
        borderLeftColor: "primary.500",
        borderLeftStyle: "solid",
      }}
    >
      {domToReact(children, options)}
    </Typography>
  );
};
