import { Box, useTheme } from "@mui/material";
import React, { useContext } from "react";
import { LayoutContext } from "../layout/layout.context";
import { LOGO_LARGE_WIDTH, PAGE_LOADER_LOGO, PAGE_LOGO_MARGIN_BOTTOM } from "../../theme/sizings.theme";
import { SyncLoader } from "react-spinners";
import Logo from "../../assets/images/logo-med-uni-graz-weiss.png";

interface IPageLoaderOverlayProps {}

export const PageLoaderOverlay: React.FC<IPageLoaderOverlayProps> = (props) => {
  const theme = useTheme();
  const { breakpoint } = useContext(LayoutContext);
  return (
    <Box
      sx={{
        position: "fixed",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        bgcolor: "primaryDark.main",
        zIndex: 9999,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <img
        style={{ width: PAGE_LOADER_LOGO[breakpoint], marginBottom: PAGE_LOGO_MARGIN_BOTTOM[breakpoint] }}
        src={Logo}
        width={LOGO_LARGE_WIDTH[breakpoint]}
        alt="Logo"
      />
      <SyncLoader color={theme.palette.primary.contrastText} />
    </Box>
  );
};
