import { Button, Typography } from "@mui/material";
import { FunctionComponent, useCallback, useMemo } from "react";
import { CustomMediaIcon } from "../icon/custom-media-icon.component";
import { IFullCategoryFilterInfo } from "./hooks/use-selected-categories.hook";

interface ICategoryFilterItemProps {
  isSelected: boolean;
  categoryFilterInfo: IFullCategoryFilterInfo;
  onClick: () => void;
}

export const CategoryFilterItem: FunctionComponent<ICategoryFilterItemProps> = (props) => {
  const { isSelected, categoryFilterInfo, onClick } = props;

  const selectedStyle = useMemo(
    () => ({
      borderColor: "primary.50",
      borderWidth: "1px",
      borderStyle: "solid",
      backgroundColor: "primary.50",
    }),
    [],
  );

  const defaultStyle = useMemo(
    () => ({
      borderColor: "grey.disabledBackground",
      borderWidth: "1px",
      borderStyle: "solid",
    }),
    [],
  );

  const lockedStyle = useMemo(
    () => ({
      borderColor: "grey.disabledBackground",
      borderWidth: "1px",
      borderStyle: "solid",
      backgroundColor: "grey.disabledBackground",
      "&:hover": {
        borderColor: "grey.disabledBackground",
        borderWidth: "1px",
        borderStyle: "solid",
        backgroundColor: "grey.disabledBackground",
        cursor: "default",
      },
    }),
    [],
  );

  const getAdditionalStyle = useCallback(() => {
    if (categoryFilterInfo.isLocked) return { ...lockedStyle };
    return isSelected ? { ...selectedStyle } : { ...defaultStyle };
  }, [categoryFilterInfo, isSelected, defaultStyle, lockedStyle, selectedStyle]);

  const getTextColor = useCallback(() => {
    if (categoryFilterInfo.isLocked) return "grey.800";
    return isSelected ? "primary.500" : "grey.800";
  }, [categoryFilterInfo, isSelected]);

  const handleClick = useCallback(() => {
    if (categoryFilterInfo.isLocked) return;
    onClick();
  }, [categoryFilterInfo, onClick]);

  return (
    <Button
      startIcon={
        categoryFilterInfo.category.icon ? (
          <CustomMediaIcon strapiMedia={categoryFilterInfo.category.icon} sx={{ pl: 8 }} />
        ) : undefined
      }
      onClick={handleClick}
      fullWidth
      sx={{
        br: 8,
        height: 48,
        justifyContent: "flex-start",
        fontWeight: 700,
        color: getTextColor(),
        ...getAdditionalStyle(),
        ".MuiCardMedia-root": {
          pl: 0,
        },
      }}
      variant="outlined"
    >
      <Typography variant="body2" sx={{ fontWeight: 700, overflow: "hidden" }}>
        {categoryFilterInfo.category.title}
      </Typography>
    </Button>
  );
};
