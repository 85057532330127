import { Grid, Skeleton, Typography, useTheme } from "@mui/material";
import React, { FC, useContext, useMemo } from "react";
import { InfocenterDetailContext } from "./context/infocenter-detail.context";
import {
  INFOCENTER_DETAIL_HEADER_TEXT,
  INFOCENTER_HEADER_PADDING_HORIZONTAL_FACTOR,
  INFOCENTER_HEADER_PADDING_TOP_FACTOR,
  INFOCENTER_HEADER_PADDING_BOTTOM_FACTOR,
  INFOCENTER_HEADER_SUBTITLE_PADDING_BOTTOM_FACTOR,
} from "../../theme/sizings.theme";
import { LayoutContext } from "../../components/layout/layout.context";
import { InfocenterEntry, InfocenterType } from "../../api/muu-api/graphql/generated";

export const InfocenterDetailHeaderText: FC = () => {
  const { infocenterEntry, infocenterEntryLoading } = useContext(InfocenterDetailContext);
  const { breakpointInfo, breakpoint } = useContext(LayoutContext);

  const theme = useTheme();

  const subheading = useMemo(() => {
    switch (infocenterEntry?.type) {
      case InfocenterType.STRAPI:
      case InfocenterType.TYPO3_NEWS:
        return (infocenterEntry as InfocenterEntry).teaser;
    }
    return undefined;
  }, [infocenterEntry]);

  return (
    <Grid
      container
      sx={{
        position: !breakpointInfo.isMobile ? "absolute" : "unset",
        minHeight: !breakpointInfo.isMobile ? INFOCENTER_DETAIL_HEADER_TEXT[breakpoint] : "auto",
        left: 0,
        right: 0,
        bottom: 0,
        bgcolor: !breakpointInfo.isMobile ? "overlay.main" : "",
        alignItems: "center",
        pt: INFOCENTER_HEADER_PADDING_TOP_FACTOR[breakpoint],
        pb: INFOCENTER_HEADER_PADDING_BOTTOM_FACTOR[breakpoint],
        pl: INFOCENTER_HEADER_PADDING_HORIZONTAL_FACTOR[breakpoint],
        pr: INFOCENTER_HEADER_PADDING_HORIZONTAL_FACTOR[breakpoint],
      }}
    >
      {infocenterEntry?.title && (
        <Grid item xs={12}>
          <Typography
            color={!breakpointInfo.isMobile ? "overlay.contrastText" : "primaryDark.main"}
            variant="h4"
            sx={
              !breakpointInfo.isMobile && infocenterEntry.type !== InfocenterType.RESEARCH
                ? { overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }
                : undefined
            }
          >
            {infocenterEntry?.title}
          </Typography>
        </Grid>
      )}
      {infocenterEntryLoading && (
        <Grid item xs={12}>
          <Skeleton variant="text" sx={{ fontSize: theme.typography.h2, width: "70%" }} />
        </Grid>
      )}
      {!!subheading && (
        <Grid item xs={12} sx={{ pb: INFOCENTER_HEADER_SUBTITLE_PADDING_BOTTOM_FACTOR[breakpoint] }}>
          <Typography
            color={!breakpointInfo.isMobile ? "overlay.contrastText" : "primaryDark.main"}
            variant="h6"
            sx={
              !breakpointInfo.isMobile
                ? { overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }
                : undefined
            }
          >
            {subheading}
          </Typography>
        </Grid>
      )}
      {infocenterEntryLoading && (
        <Grid item xs={12}>
          <Skeleton variant="text" sx={{ fontSize: theme.typography.h2, width: "100%" }} />
        </Grid>
      )}
    </Grid>
  );
};
