import { Favorite, FavoriteSource, FavoriteType } from "../../../api/muu-api/graphql/generated";

export const EMPTY_FAVORITE_ID = "EMPTY";

export interface ICreateFavoriteInput {
  entryId: string;
  description: string;
  teaser?: string;
  type: FavoriteType;
  source: FavoriteSource;
  link?: string;
}

export type MutateFavoritesFunc = (currentFavorites: Favorite[]) => Favorite[];
