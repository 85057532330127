import { useApolloClient } from "@apollo/client";
import { Button, DialogContent, useTheme } from "@mui/material";
import { FC, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Role,
  SearchThinkTankDocument,
  ThinkTankPostInput,
  useCreateThinkTankPostMutation,
} from "../../../../api/muu-api/graphql/generated";
import { PlusIcon } from "../../../../assets/icons/icons.component";
import { CustomDialogTitle } from "../../../../components/dialog/custom-dialog-title.component";
import {
  IThinkTankPostFormData,
  IThinkTankPostFormFile,
  ThinkTankPostForm,
} from "../../../../components/think-tank/think-tank-post-form.component";
import { IWidgetMenuItemBaseProps } from "../dictionaries/widget-menu.dictionary";
import { CustomDialogActions } from "../../../../components/dialog/custom-dialog-actions.component";

interface IWidgetAddThinkTankPostProps extends IWidgetMenuItemBaseProps {}

export const WidgetAddThinkTankPost: FC<IWidgetAddThinkTankPostProps> = (props) => {
  const { onClose } = props;
  const { t } = useTranslation();
  const theme = useTheme();
  const client = useApolloClient();
  const [createThinkTankPost] = useCreateThinkTankPostMutation();
  const [formData, setFormData] = useState<IThinkTankPostFormData>({
    role: Role.ALL,
    type: undefined,
    category: undefined,
    title: undefined,
    description: undefined,
    attachments: [],
  });

  const isPublishDisabled = useMemo(() => !formData.type || !formData.title || !formData.description, [formData]);

  const refetchThinkTankQueries = useCallback(() => {
    client.refetchQueries({
      include: [SearchThinkTankDocument],
    });
  }, [client]);

  const onSubmit = useCallback(() => {
    const { title, description, role, type, category } = formData;
    if (!title || !description || !type) return;

    const thinkTankPostInput: ThinkTankPostInput = {
      title,
      description,
      role,
      type,
      thinkTankCategory: category?.id ?? null,
      newAttachments: formData.attachments.map((attachment) => (attachment as IThinkTankPostFormFile).file),
      attachmentIds: [],
    };

    createThinkTankPost({
      variables: {
        thinkTankPostInput,
      },
      onCompleted() {
        // Wait 1 second before refetching think tank queries due to delay with sync
        setTimeout(refetchThinkTankQueries, 1000);
      },
    });
    onClose();
  }, [createThinkTankPost, formData, onClose, refetchThinkTankQueries]);

  return (
    <>
      <CustomDialogTitle
        title={t("widgets.menu_items.add_think_tank_post")}
        Icon={PlusIcon}
        iconProps={{ sx: { color: "primaryDark.main" } }}
        sx={{ borderTopLeftRadius: theme.spacing(4), borderTopRightRadius: theme.spacing(4) }}
      />
      <DialogContent>
        <ThinkTankPostForm formData={formData} setFormData={setFormData} />
      </DialogContent>
      <CustomDialogActions>
        <Button variant="outlined" onClick={onClose}>
          {t("general.cancel")}
        </Button>
        <Button variant="contained" onClick={onSubmit} disabled={isPublishDisabled}>
          {t("think_tank.publish")}
        </Button>
      </CustomDialogActions>
    </>
  );
};
