import { Box, Stack, Typography } from "@mui/material";
import { FC, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useThinkTankCategoriesQuery, WidgetType } from "../../../api/muu-api/graphql/generated";
import { IExtendedWidget } from "../../../pages/dashboard/widget-section/types/widget.type";
import { ThinkTankContentContext } from "../../think-tank/context/think-tank-content.context";
import { ThinkTankRow } from "./think-tank-row.component";
import { DefaultWidgetSkeleton } from "../widget-default-skeleton.component";
import { UserContext } from "../../../app/context/user.context";

interface IThinkTankRequestsProps {
  widget: IExtendedWidget;
}

export const ThinkTankRequests: FC<IThinkTankRequestsProps> = (props) => {
  const { widget } = props;
  const { t } = useTranslation();
  const { language } = useContext(UserContext);
  const { requests: thinkTankRequests, requestsLoading } = useContext(ThinkTankContentContext);
  const { data: thinkTankCategoriesResult } = useThinkTankCategoriesQuery({
    fetchPolicy: "no-cache",
    variables: {
      language,
      active: true,
    },
  });

  return (
    <Box sx={{ width: "100%" }}>
      <Stack direction="column" display="flex" flex={1} flexDirection="column">
        {!requestsLoading && thinkTankRequests.length === 0 && (
          <Typography variant="body1" sx={{ width: "100%", textAlign: "center", pt: 3, pb: 3 }}>
            {t(`widgets.${WidgetType.THINK_TANK}.no_data`)}
          </Typography>
        )}
        {thinkTankRequests.map((request) => (
          <Box key={`request-${request.id}`}>
            <ThinkTankRow
              thinkTankPost={request}
              currentColor={widget.color}
              thinkTankCategories={thinkTankCategoriesResult?.thinkTankCategories ?? []}
            />
          </Box>
        ))}
        {requestsLoading && <DefaultWidgetSkeleton rows={5} />}
      </Stack>
    </Box>
  );
};
