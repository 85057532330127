import { Divider, Grid, Link, Skeleton, Typography, useTheme } from "@mui/material";
import React, { useCallback, useContext } from "react";
import { useNavigate } from "react-router";
import { SearchThinkTank } from "../../api/muu-api/graphql/generated";
import { ThinkTankIcon } from "../../assets/icons/icons.component";
import { StaticPageDictionary } from "../../router/routes.const";
import { SEARCH_RESULT_ICON_HORIZONTAL_MARGIN_FACTOR, SEARCH_RESULT_SKELETON_WIDTH } from "../../theme/sizings.theme";
import { encodeIdBase64 } from "../../utils/base64.util";
import { getAbsoluteRoutingPath } from "../../utils/get-absolute-routing-path.util";
import { LayoutContext } from "../layout/layout.context";
import { THINK_TANK_SEARCH_ENTRY_EMPTY_ID } from "../think-tank/helper/think-tank-cache.helper";
import { useTracking } from "../../app/context/tracking/tracking.context";

interface IThinkTankSearchResultProps {
  searchThinkTank?: SearchThinkTank;
  loading: boolean;
  isLastItem: boolean;
}

export const ThinkTankSearchResult: React.FC<IThinkTankSearchResultProps> = (props) => {
  const { searchThinkTank, loading } = props;
  const theme = useTheme();
  const navigate = useNavigate();
  const { breakpoint } = useContext(LayoutContext);
  const { trackEvent } = useTracking();

  const onClick = useCallback(() => {
    if (!searchThinkTank) {
      return;
    }

    if (searchThinkTank.id === THINK_TANK_SEARCH_ENTRY_EMPTY_ID) return;
    const encodedId = encodeIdBase64(searchThinkTank.id, { type: null });
    trackEvent({
      event: "search_result_view",
      action: "redirect",
      category: "page_visit",
    });
    navigate({
      pathname: getAbsoluteRoutingPath(StaticPageDictionary.THINK_TANK_DETAIL.path),
      search: `id=${encodedId}`,
    });
  }, [searchThinkTank, navigate, trackEvent]);

  return (
    <Grid container direction="column">
      <Link component="button" onClick={onClick} style={{ textDecoration: "none", color: "inherit", font: "inherit" }}>
        <Grid container direction="row" alignItems="center" sx={{ p: 4 }}>
          <Grid
            item
            sx={{
              color: "accent1.main",
              ml: SEARCH_RESULT_ICON_HORIZONTAL_MARGIN_FACTOR[breakpoint],
              mr: SEARCH_RESULT_ICON_HORIZONTAL_MARGIN_FACTOR[breakpoint],
              pr: 4,
            }}
          >
            <ThinkTankIcon color="inherit" fontSize="medium" />
          </Grid>
          <Grid item xs container direction="column">
            <Grid item>
              {!!searchThinkTank && searchThinkTank.title && (
                <Typography variant="subtitle2" sx={{ textAlign: "start", wordBreak: "break-word" }}>
                  {searchThinkTank.title}
                </Typography>
              )}
              {!searchThinkTank && loading && (
                <Skeleton
                  variant="text"
                  width={SEARCH_RESULT_SKELETON_WIDTH[breakpoint]}
                  sx={{ fontSize: theme.typography.body1 }}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
      </Link>
      <Grid item>
        <Divider sx={{ backgroundColor: "grey.100" }} />
      </Grid>
    </Grid>
  );
};
