import React from "react";
import { Media } from "../../api/muu-api/graphql/generated";
import { IThinkTankPostFormFile } from "../../components/think-tank/think-tank-post-form.component";
import { useThinkTankAttachment } from "./hooks/use-think-tank-attachment.hook";
import { ThinkTankDocumentAttachment } from "./think-tank-document-attachment.component";
import { ThinkTankImageAttachment } from "./think-tank-image-attachment.component";

interface IThinkTankAttachmentProps {
  attachment: Media | IThinkTankPostFormFile;
  editMode?: boolean;
  onDelete?: () => void;
}

export const ThinkTankAttachment: React.FC<IThinkTankAttachmentProps> = (props) => {
  const { attachment } = props;
  const { mimeType } = useThinkTankAttachment(attachment);

  if (mimeType.startsWith("image/")) {
    return <ThinkTankImageAttachment {...props} />;
  }

  return <ThinkTankDocumentAttachment {...props} />;
};
