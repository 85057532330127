import React, { useCallback, useMemo } from "react";
import { Favorite } from "../../api/muu-api/graphql/generated";
import { FavoriteStarIcon, StarIcon } from "../../assets/icons/icons.component";
import { CustomIconButton } from "../icon/custom-icon-button.component";
import { isFavoriteStub } from "./helper/is-favorite-stub.helper";
import { useTranslation } from "react-i18next";

interface IFavoriteActionProps {
  onCreateFavorite: VoidFunction;
  onRemoveFavorite: (favorite: Favorite) => void;
  displayStyle: "icon" | "button";
  disabled?: boolean;
  favorite?: Favorite;
}

export const FavoriteAction: React.FC<IFavoriteActionProps> = (props) => {
  const { t } = useTranslation();
  const { onCreateFavorite, onRemoveFavorite, displayStyle, disabled, favorite } = props;

  const handleIconClicked = useCallback(
    (event: React.MouseEvent<SVGSVGElement | HTMLButtonElement, MouseEvent>) => {
      event.stopPropagation();
      event.preventDefault();
      if (favorite?.id && !isFavoriteStub(favorite)) {
        onRemoveFavorite(favorite);
      } else if (favorite == null) {
        onCreateFavorite();
      }
    },
    [favorite, onCreateFavorite, onRemoveFavorite],
  );

  const getIconComponent = useCallback(() => {
    return favorite ? (
      <CustomIconButton
        onClick={handleIconClicked}
        variant="contained"
        aria-label="Remove Favorite"
        title={t("dashboard.tooltip.favorite.remove")}
        sx={{
          p: 1,
          alignItems: "center",
          justifyContent: "center",
          boxShadow: "none",
        }}
      >
        <FavoriteStarIcon sx={{ color: "accent3.main" }} />
      </CustomIconButton>
    ) : (
      <CustomIconButton
        disabled={disabled}
        onClick={handleIconClicked}
        variant="contained"
        aria-label="Add Favorite"
        title={t("dashboard.tooltip.favorite.add")}
        sx={{
          p: 1,
          alignItems: "center",
          justifyContent: "center",
          boxShadow: "none",
        }}
      >
        <StarIcon sx={{ color: "grey.800" }} />
      </CustomIconButton>
    );
  }, [favorite, handleIconClicked, disabled]);

  const getIconButtonComponent = useCallback(() => {
    const icon = favorite ? <FavoriteStarIcon sx={{ color: "accent3.main" }} /> : <StarIcon />;

    return (
      <CustomIconButton
        aria-label="Add/Remove Favorite"
        disabled={disabled}
        onClick={handleIconClicked}
        variant="contained"
      >
        {icon}
      </CustomIconButton>
    );
  }, [favorite, handleIconClicked, disabled]);

  const component = useMemo(() => {
    return displayStyle === "icon" ? getIconComponent() : getIconButtonComponent();
  }, [getIconComponent, getIconButtonComponent, displayStyle]);

  return <>{component}</>;
};
