import { Element } from "domhandler";
import { CSSProperties } from "react";
import { HtmlElement, NullableEditorElement } from "../types/editor-content.type";
import { DEFAULT_TABLE_VARIANT } from "../mappers/editor-content-table.mapper";

export const isIncludedInHtmlElements = (elements: HtmlElement[], name: string): boolean => {
  return elements.find((element) => element === name) !== undefined;
};

export const isListItemNested = (node: Element) => {
  return (node.parent?.parent as NullableEditorElement)?.name === HtmlElement.Li;
};

export const getTableCellDefinitionFlags = (cellNode: Element) => {
  const parent = cellNode.parent as NullableEditorElement;
  const isFirstCell = parent?.name === HtmlElement.Tr && cellNode.prev === null;
  const isLastCell = parent?.name === HtmlElement.Tr && cellNode.next === null;
  const isInFirstRow = parent?.name === HtmlElement.Tr && parent?.prev === null;
  const isInLastRow = parent?.name === HtmlElement.Tr && parent?.next === null;

  return {
    isFirstCell,
    isLastCell,
    isInFirstRow,
    isInLastRow,
  };
};

export const getTableV1Style = (node: Element) => {
  const { isFirstCell, isLastCell, isInFirstRow, isInLastRow } = getTableCellDefinitionFlags(node);
  const firstCellBorder: CSSProperties =
    isFirstCell && !isLastCell
      ? {
          borderRightWidth: "1px",
          borderRightColor: "grey.100",
          borderRightStyle: "solid",
        }
      : {};
  const lastCellBorder: CSSProperties =
    isLastCell && !isInFirstRow
      ? {
          borderRight: "none",
        }
      : {};
  const firstRowCellBorder: CSSProperties =
    isInFirstRow && !isInLastRow
      ? {
          borderBottomWidth: "1px",
          borderBottomColor: "primary.500",
          borderBottomStyle: "solid",
        }
      : {};
  const lastRowCellBorder: CSSProperties =
    isInLastRow && !isInFirstRow
      ? {
          borderBottom: "none",
        }
      : {};
  const defaultCellBorder: CSSProperties =
    !isFirstCell && !isLastCell
      ? {
          borderRightWidth: "1px",
          borderRightColor: "grey.100",
          borderRightStyle: "solid",
        }
      : {};

  const styles: CSSProperties = {
    ...firstCellBorder,
    ...lastCellBorder,
    ...firstRowCellBorder,
    ...lastRowCellBorder,
    ...defaultCellBorder,
  };

  return styles;
};

export const getTableV2Style = (node: Element) => {
  const { isLastCell, isInLastRow } = getTableCellDefinitionFlags(node);
  const borderStyleRight: CSSProperties = !isLastCell
    ? {
        borderRightWidth: "1px",
        borderRightColor: "grey.100",
        borderRightStyle: "solid",
      }
    : {};
  const borderStyleBottom: CSSProperties = !isInLastRow
    ? {
        borderBottomWidth: "1px",
        borderBottomColor: "grey.100",
        borderBottomStyle: "solid",
      }
    : {};
  const styles = { ...borderStyleRight, ...borderStyleBottom };
  return styles;
};

export const getTableVariant = (node: Element): string => {
  if (node.name === "figure") {
    return node.attribs["variant"] || DEFAULT_TABLE_VARIANT;
  }

  if (node.parent === null) {
    return DEFAULT_TABLE_VARIANT;
  }

  return getTableVariant(node.parent as Element);
};
