import { Box, MenuItem, Select, SelectChangeEvent, useTheme } from "@mui/material";
import React, { useCallback, useContext } from "react";
import { ChevronDownIcon } from "../../../assets/icons/icons.component";
import { SEARCH_BAR_HEIGHT, SEARCH_MENU_MIN_WIDTH } from "../../../theme/sizings.theme";
import { LayoutContext } from "../layout.context";
import { ISearchFilter, SearchContext } from "./context/search.context";
import { SearchFilterItem } from "./search-filter-item.component";
import { useTranslation } from "react-i18next";

export const SearchFilter: React.FC = () => {
  const { t } = useTranslation();
  const { setSelectedFilter, selectedFilter, filterItems } = useContext(SearchContext);
  const theme = useTheme();
  const { breakpointInfo } = useContext(LayoutContext);

  const handleFilterChange = useCallback(
    (event: SelectChangeEvent) => {
      const filter = filterItems.find((item) => item.indexName === event.target.value);
      if (!filter) return;
      setSelectedFilter(filter);
    },
    [filterItems, setSelectedFilter],
  );

  return (
    <Select
      id="search-filter-select"
      value={selectedFilter.indexName}
      onChange={handleFilterChange}
      disableUnderline={true}
      // labelId="search-filter-label"
      // label={t("search.filter_label")}
      title={t("dashboard.tooltip.search.filter")}
      variant="standard"
      renderValue={(value: any) => {
        const item: ISearchFilter | null = filterItems.find((item) => item.indexName === value) ?? null;
        if (breakpointInfo.isMobile) {
          return <Box sx={{ height: 20 }}>{item && <item.icon fontSize="small" />}</Box>;
        }
        return (
          <Box
            id={`search-filter-item-${item?.id}`}
            justifyContent="flex-start"
            alignItems="center"
            sx={{ display: "flex", pr: 4 }}
            tabIndex={0}
          >
            {item && <SearchFilterItem item={item} />}
          </Box>
        );
      }}
      sx={{
        borderTopLeftRadius: breakpointInfo.isMobile ? "4px" : "16px",
        borderBottomLeftRadius: breakpointInfo.isMobile ? "4px" : "16px",
        borderTopRightRadius: breakpointInfo.isMobile ? "4px" : "0px",
        borderBottomRightRadius: breakpointInfo.isMobile ? "4px" : "0px",
        borderWidth: "1px",
        borderColor: "grey.searchBorder",
        borderStyle: "solid",
        p: breakpointInfo.isMobile ? 1 : 2,
        pr: breakpointInfo.isMobile ? 6 : 6,
        pl: breakpointInfo.isMobile ? 2 : 3,
        pt: 0,
        ml: breakpointInfo.isMobile ? 0.5 : 0,
        height: SEARCH_BAR_HEIGHT.DESKTOP,
        backgroundColor: "white",
        "&.Mui-focused": {
          backgroundColor: "white",
          borderColor: breakpointInfo.isMobile ? "grey.200" : "grey.800",
        },
        "& ul": {
          pt: 0,
        },
      }}
      IconComponent={(props) => <ChevronDownIcon {...props} sx={{ mr: breakpointInfo.isMobile ? 1 : 4 }} />}
      MenuProps={{
        elevation: 1,
        variant: "menu",
        disableAutoFocusItem: true,
        sx: {
          "& ul": {
            pt: 0,
            pb: 0,
            minWidth: SEARCH_MENU_MIN_WIDTH.DESKTOP,
          },
          "&.MuiPopover-paper": {
            boxShadow: theme.boxShadow.light,
          },
        },
        anchorOrigin: { vertical: "bottom", horizontal: "left" },
        transformOrigin: { vertical: -4, horizontal: 0 },
      }}
      inputProps={{ id: "search-filter-select-input" }}
    >
      {filterItems.map((item) => {
        return (
          <MenuItem
            key={item.id}
            value={item.indexName}
            sx={{
              m: 1,
              borderRadius: 2,
              p: 2,
            }}
          >
            <Box justifyContent="flex-start" alignItems="center" sx={{ display: "flex" }}>
              <SearchFilterItem item={item} />
            </Box>
          </MenuItem>
        );
      })}
    </Select>
  );
};
