import { Grid } from "@mui/material";
import React from "react";
import { LoadingText } from "../../components/loading/loading-text.component";

export const NotificationDetailSkeleton: React.FC = () => {
  return (
    <Grid container spacing={4} sx={{ p: 14, width: "100%" }}>
      <Grid item xs={12}>
        <LoadingText lines={2} variant="h3" />
      </Grid>
      <Grid item xs={12}>
        <LoadingText lines={10} variant="body1" />
      </Grid>
    </Grid>
  );
};
