import { useTheme } from "@mui/material";
import { Element } from "domhandler";
import parse, { DOMNode, HTMLReactParserOptions } from "html-react-parser";
import React, { useContext, useMemo } from "react";
import "./styles/ck-editor-image.css";
import { getTableVariant, isIncludedInHtmlElements } from "./helpers/editor-content-table.helper";
import { HeadingElements, HtmlElement, ListElements, TableElements } from "./types/editor-content.type";
import { LayoutContext } from "../layout/layout.context";
import { mapToLink } from "./mappers/editor-content-link.mapper";
import { mapToParagraph } from "./mappers/editor-content-paragraph.mapper";
import { mapToFigure } from "./mappers/editor-content-figure.mapper";
import { mapToHeading } from "./mappers/editor-content-heading.mapper";
import { mapToBlockquote } from "./mappers/editor-content-blockquote.mapper";
import { mapToHr } from "./mappers/editor-content-hr.mapper";
import { mapToImg } from "./mappers/editor-content-image.mapper";
import { mapToList } from "./mappers/editor-content-list.mapper";
import DOMPurify from "dompurify";

interface IEditorContentProps {
  editorContentHtmlString: string;
  purify?: boolean;
}

export const EditorContent: React.FC<IEditorContentProps> = (props) => {
  const { editorContentHtmlString, purify = false } = props;
  const theme = useTheme();
  const { breakpoint, breakpointInfo } = useContext(LayoutContext);

  const options: HTMLReactParserOptions = useMemo(() => {
    return {
      replace: (domNode: DOMNode) => {
        const node = domNode as Element;

        const { name, children } = node;

        if (!name) {
          return;
        }

        switch (name) {
          case HtmlElement.P:
            return mapToParagraph(node, options, breakpoint);
          case HtmlElement.Figure:
            return mapToFigure(node, options);
          case HtmlElement.Img:
            return mapToImg(node, breakpointInfo);
          case HtmlElement.A:
            return node.attribs.href ? mapToLink(node) : undefined;
          case HtmlElement.Blockquote:
            return mapToBlockquote(children, options);
          case HtmlElement.Hr:
            return mapToHr(breakpoint);
        }

        if (isIncludedInHtmlElements(HeadingElements, name)) {
          return mapToHeading(name, children, options);
        }

        if (isIncludedInHtmlElements(ListElements, name)) {
          return mapToList(name, node, children, options, breakpoint, theme);
        }

        if (isIncludedInHtmlElements(TableElements, name)) {
          const variant = getTableVariant(node);
          if (name === HtmlElement.Table) {
            // Set class attribute based on existing table variant
            node.attribs.class = `${node.attribs.class} table-v${variant}`;
          }
          if (name === HtmlElement.Tr) {
            node.attribs.class = `${node.attribs.class} table-v${variant}-row`;
          }
          if (name === HtmlElement.Td) {
            node.attribs.class = `${node.attribs.class} table-v${variant}-cell`;
          }
          if (name === HtmlElement.Th) {
            node.attribs.class = `${node.attribs.class} table-v${variant}-cell`;
          }
          return node;
        }
      },
    };
  }, [mapToHeading, breakpoint]);

  return <>{parse(purify ? DOMPurify.sanitize(editorContentHtmlString) : editorContentHtmlString, options)}</>;
};
