import { Divider, Grid, Link, Skeleton, Typography, useTheme } from "@mui/material";
import parse from "html-react-parser";
import React, { useCallback, useContext } from "react";
import { SearchConfluencePage } from "../../api/muu-api/graphql/generated";
import { BookIcon } from "../../assets/icons/icons.component";
import { SEARCH_RESULT_ICON_HORIZONTAL_MARGIN_FACTOR, SEARCH_RESULT_SKELETON_WIDTH } from "../../theme/sizings.theme";
import { CustomChip } from "../chip/custom-chip.component";
import { LayoutContext } from "../layout/layout.context";
import { useTracking } from "../../app/context/tracking/tracking.context";

interface IConfluencePagesSearchResultProps {
  searchConfluencePage?: SearchConfluencePage;
  loading: boolean;
  isLastItem: boolean;
}

export const ConfluencePagesSearchResult: React.FC<IConfluencePagesSearchResultProps> = (props) => {
  const { searchConfluencePage, loading } = props;
  const theme = useTheme();
  const { breakpoint } = useContext(LayoutContext);
  const { trackEvent } = useTracking();

  const onClick = useCallback(() => {
    if (!searchConfluencePage) {
      return;
    }
    trackEvent({
      event: "search_result_view",
      action: "redirect",
      category: "page_visit",
    });
    window.open(searchConfluencePage.url, "_blank");
  }, [searchConfluencePage, trackEvent]);

  return (
    <Grid container direction="column">
      <Link component="button" onClick={onClick} style={{ textDecoration: "none", color: "inherit", font: "inherit" }}>
        <Grid container direction="row" alignItems="center" sx={{ p: 4 }}>
          <Grid
            item
            sx={{
              color: "accent1.main",
              ml: SEARCH_RESULT_ICON_HORIZONTAL_MARGIN_FACTOR[breakpoint],
              mr: SEARCH_RESULT_ICON_HORIZONTAL_MARGIN_FACTOR[breakpoint],
              pr: 4,
            }}
          >
            <BookIcon color="inherit" fontSize="medium" />
          </Grid>
          <Grid item xs container direction="column">
            <Grid item>
              {!!searchConfluencePage && searchConfluencePage.title && (
                <Typography variant="subtitle2" sx={{ textAlign: "start", wordBreak: "break-word" }}>
                  {parse(searchConfluencePage.title)}
                </Typography>
              )}
              {!searchConfluencePage && loading && (
                <Skeleton
                  variant="text"
                  width={SEARCH_RESULT_SKELETON_WIDTH[breakpoint]}
                  sx={{ fontSize: theme.typography.body1 }}
                />
              )}
            </Grid>
            {(searchConfluencePage?.labels || []).length > 0 && (
              <Grid item container spacing={2}>
                {(searchConfluencePage?.labels || []).map((label) => (
                  <Grid item key={`${searchConfluencePage?.id}-${label}`}>
                    <CustomChip label={label} variant="body2" />
                  </Grid>
                ))}
              </Grid>
            )}
          </Grid>
        </Grid>
      </Link>
      <Grid item>
        <Divider sx={{ backgroundColor: "grey.100" }} />
      </Grid>
    </Grid>
  );
};
