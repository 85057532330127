import { Grid } from "@mui/material";
import React, { useCallback, useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Favorite, FavoriteType, SearchEvent } from "../../api/muu-api/graphql/generated";
import { AttendenceFeeIcon, AttendenceReservationIcon, CopyIcon } from "../../assets/icons/icons.component";
import { useFavorite } from "../../hooks/use-favorite.hook";
import { DETAIL_PATH } from "../../router/routes.const";
import { encodeIdBase64 } from "../../utils/base64.util";
import { getAbsoluteRoutingPath } from "../../utils/get-absolute-routing-path.util";
import { NavMenuContext } from "../../components/layout/nav/context/nav-menu.context";
import { SnackbarSeverity, useSnackbar } from "../../components/snackbar/snackbar.context";
import { FavoriteAction } from "../../components/favorite/favorite-action.component";
import { eventSourceToFavoriteSource } from "../../components/widgets/events/helper/event-mapping.helper";
import { CustomIconButton } from "../../components/icon/custom-icon-button.component";

interface IEventsActionIconBarProps {
  event: SearchEvent;
  favorite: Favorite | undefined;
}

export const EventsActionIconBar: React.FC<IEventsActionIconBarProps> = (props) => {
  const { event, favorite } = props;
  const { pageOfInterestDictionary } = useContext(NavMenuContext);

  const { createFavorite, removeFavorite } = useFavorite();
  const { showSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const onDeeplinkClicked = useCallback(
    (navigationPath: string) => {
      navigator.clipboard.writeText(window.location.host + navigationPath);
      showSnackbar(t("general.copy_link_success"), SnackbarSeverity.Success);
    },
    [showSnackbar, t],
  );

  const navigationPath = useMemo(() => {
    return `${getAbsoluteRoutingPath(pageOfInterestDictionary.EVENTS.path)}/${DETAIL_PATH}/?id=${encodeIdBase64(
      event.id,
      { type: event.eventType },
    )}`;
  }, [pageOfInterestDictionary, event]);

  const favoriteAction = useMemo(() => {
    return (
      <FavoriteAction
        favorite={favorite}
        onRemoveFavorite={removeFavorite}
        onCreateFavorite={() =>
          createFavorite({
            entryId: event.id,
            description: event.title ?? "no title",
            teaser: event.teaser ?? undefined,
            type: FavoriteType.EVENT,
            source: eventSourceToFavoriteSource(event.eventType),
          })
        }
        displayStyle="icon"
      />
    );
  }, [createFavorite, event, removeFavorite, favorite]);

  const actionIcons = useMemo(() => {
    const icons: React.ReactNode[] = [];
    if (event.hasRegistration) {
      icons.push(
        <CustomIconButton
          aria-label="Needs reservation"
          variant="contained"
          disableRipple={true}
          sx={{ boxShadow: "none", p: 1, color: "grey.200", "&:hover": { color: "grey.200" } }}
          title={t("dashboard.tooltip.widget.events.attendence_registration")}
        >
          <AttendenceReservationIcon />
        </CustomIconButton>,
      );
    }
    if (event.hasAttendingFee) {
      icons.push(
        <CustomIconButton
          aria-label="Has attendence fee"
          variant="contained"
          disableRipple={true}
          sx={{ boxShadow: "none", p: 1, color: "grey.200", "&:hover": { color: "grey.200" } }}
          title={t("dashboard.tooltip.widget.events.attendence_fee")}
        >
          <AttendenceFeeIcon />
        </CustomIconButton>,
      );
    }
    icons.push(favoriteAction);
    icons.push(
      <CustomIconButton
        variant="contained"
        aria-label="Copy Link"
        sx={{ boxShadow: "none", p: 1, color: "grey.800" }}
        title={t("dashboard.tooltip.widget.deeplink")}
        onClick={(event) => {
          event.stopPropagation();
          event.preventDefault();
          onDeeplinkClicked(navigationPath);
        }}
      >
        <CopyIcon />
      </CustomIconButton>,
    );
    return icons;
  }, [event, onDeeplinkClicked, navigationPath, favoriteAction, t]);

  return (
    <Grid container>
      {actionIcons.map((icon, index) => (
        <Grid key={`events-action-icon-${event.id}-${index}`} item xs="auto">
          {icon}
        </Grid>
      ))}
    </Grid>
  );
};
