import { Box, Stack, useTheme } from "@mui/material";
import { FC, useCallback, useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import {
  SearchInfocenter,
  useCategoriesQuery,
  useSearchInfocenterQuery,
  WidgetType,
} from "../../../api/muu-api/graphql/generated";
import { getCategoriesForSearchQueries } from "../../../app/helpers/category.helper";
import { InfoCenterIcon } from "../../../assets/icons/icons.component";
import { IExtendedWidget } from "../../../pages/dashboard/widget-section/types/widget.type";
import { getAbsoluteRoutingPath } from "../../../utils/get-absolute-routing-path.util";
import { CustomMediaIcon } from "../../icon/custom-media-icon.component";
import { NavMenuContext } from "../../layout/nav/context/nav-menu.context";
import { WidgetNoEntry } from "../widget-no-entry.component";
import { InfocenterPostRow } from "./infocenter-post-row.component";
import { UserContext } from "../../../app/context/user.context";
import { DefaultWidgetSkeleton } from "../widget-default-skeleton.component";
import { WidgetContentShowAll } from "../widget-content-show-all.component";
import { POLLING_INTERVAL } from "../../../pages/dashboard/widget-section/dashboard-widgets.context";

const MAX_INFO_CENTER_POST_COUNT = 20;

interface IInfocenterWidgetContentProps {
  widget: IExtendedWidget;
}

export const InfocenterWidgetContent: FC<IInfocenterWidgetContentProps> = (props) => {
  const { widget } = props;
  const { language } = useContext(UserContext);
  const { data: categoriesResult } = useCategoriesQuery({
    variables: {
      language,
    },
  });
  const { pageOfInterestDictionary } = useContext(NavMenuContext);
  const navigate = useNavigate();
  const theme = useTheme();
  const categories = useMemo(() => categoriesResult?.categories || [], [categoriesResult]);
  const widgetCategories: string[] = useMemo(
    () => getCategoriesForSearchQueries(widget.categoryFilter, categories ?? []),
    [categories, widget],
  );

  const { data: postResult, loading: postsLoading } = useSearchInfocenterQuery({
    variables: { categories: widgetCategories, limit: MAX_INFO_CENTER_POST_COUNT, offset: 0, language },
    skip: false,
    pollInterval: POLLING_INTERVAL,
  });

  const posts: SearchInfocenter[] = useMemo(() => {
    return postResult?.searchInfocenter.results ?? [];
  }, [postResult]);

  // TODO MUU-383 display pinned posts
  const pinnedPosts: SearchInfocenter[] = useMemo(() => [], []);

  const allPostsCount = useMemo(() => posts.length + pinnedPosts.length, [posts, pinnedPosts]);

  const getIcon = useCallback(
    (p: SearchInfocenter) => {
      if (!categories) return undefined;

      const category = categories.find((c) => c.searchFilter === p.mainCategory);
      if (!category) {
        return <InfoCenterIcon height={theme.icon.medium} width={theme.icon.medium} />;
      }
      return category.icon ? <CustomMediaIcon strapiMedia={category.icon} size={"medium"} /> : undefined;
    },
    [categories, theme.icon.medium],
  );

  const onShowAllClick = useCallback(() => {
    if (!pageOfInterestDictionary.INFOCENTER) {
      return;
    }
    navigate({
      pathname: getAbsoluteRoutingPath(pageOfInterestDictionary.INFOCENTER.path),
    });
  }, [navigate, pageOfInterestDictionary]);

  return (
    <Box sx={{ width: "100%", overflowY: "auto", flex: 1, display: "flex" }}>
      <Stack direction="column" display="flex" flex={1} flexDirection="column">
        {postsLoading && <DefaultWidgetSkeleton rows={5} />}
        {!postsLoading && allPostsCount === 0 && <WidgetNoEntry widgetType={WidgetType.INFOCENTER} />}
        {pinnedPosts.map((pinnedPost) => (
          <Box key={`pinned-post-${pinnedPost.id}`}>
            <InfocenterPostRow key={pinnedPost.id} post={pinnedPost} icon={getIcon(pinnedPost)} isPinned />
          </Box>
        ))}
        {posts.map((post) => (
          <Box key={`post-${post.id}`}>
            <InfocenterPostRow key={post.id} post={post} icon={getIcon(post)} isPinned={false} />
          </Box>
        ))}
        {allPostsCount >= MAX_INFO_CENTER_POST_COUNT && <WidgetContentShowAll onShowAllClick={onShowAllClick} />}
      </Stack>
    </Box>
  );
};
