import { useCallback, useMemo, useState } from "react";

export const usePagination = (args: { limit: number; defaultOffset: number }, count?: number) => {
  const { defaultOffset } = args;

  const [page, setPage] = useState<number>(1);

  const offset = useMemo(() => (page - 1) * defaultOffset, [page, defaultOffset]);
  const totalNumberOfPages = useMemo(() => Math.ceil(count ? count / defaultOffset : 0), [count, defaultOffset]);
  const onChangeHandler = useCallback((_: React.ChangeEvent<unknown>, newPage: number) => {
    setPage(newPage);
  }, []);

  const resetPage = useCallback(() => setPage(1), []);

  return {
    offset,
    onChangeHandler,
    page,
    resetPage,
    totalNumberOfPages,
  };
};
