import { Grid, Skeleton } from "@mui/material";
import React from "react";

export const TopicTeamMemberLoading: React.FC = () => {
  return (
    <Grid item container columnSpacing={4} direction="row">
      <Grid item xs={4}>
        <Skeleton variant="text" height={40} />
      </Grid>
      <Grid item xs={4}>
        <Skeleton variant="text" height={40} />
      </Grid>
      <Grid item xs={4}>
        <Skeleton variant="text" height={40} />
      </Grid>
    </Grid>
  );
};
