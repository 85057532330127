import { Grid, Skeleton } from "@mui/material";
import React, { useContext } from "react";
import { LoadingText } from "../../components/loading/loading-text.component";
import { PAGE_PAPER_PADDING_HORIZONTAL_FACTOR, PAGE_PAPER_PADDING_VERTICAL_FACTOR } from "../../theme/sizings.theme";
import { LayoutContext } from "../../components/layout/layout.context";

export const EventsDetailSkeleton: React.FC = () => {
  const { breakpoint } = useContext(LayoutContext);
  return (
    <Grid
      container
      spacing={4}
      sx={{
        pt: PAGE_PAPER_PADDING_VERTICAL_FACTOR[breakpoint],
        pb: PAGE_PAPER_PADDING_VERTICAL_FACTOR[breakpoint],
        pl: PAGE_PAPER_PADDING_HORIZONTAL_FACTOR[breakpoint],
        pr: PAGE_PAPER_PADDING_HORIZONTAL_FACTOR[breakpoint],
        width: "100%",
      }}
    >
      <Grid item xs={12}>
        <LoadingText lines={2} variant="h3" />
        <LoadingText lines={2} variant="h6" />
      </Grid>
      <Grid item xs={12}>
        <Skeleton variant="rectangular" height="340px" width="100%" />
      </Grid>
      <Grid item container xs={12} spacing={2}>
        <Grid item xs={6} sx={{ display: "flex" }}>
          <Skeleton variant="circular" width={20} height={20} />
          <Skeleton variant="text" width="50%" sx={{ ml: 4 }} />
        </Grid>
      </Grid>
      <Grid item xs={6}>
        <LoadingText lines={5} variant="body1" />
      </Grid>
      <Grid item xs={12}>
        <LoadingText lines={10} variant="body1" />
      </Grid>
    </Grid>
  );
};
