import { Box, useTheme } from "@mui/material";
import { FC, useCallback, useContext, useEffect, useRef } from "react";
import { DragDropContext, DragStart, DragUpdate, DropResult } from "react-beautiful-dnd";
import { DashboardWidgetsContext } from "./dashboard-widgets.context";
import { getPositionForWidgetId, getWidgetOptionsFromDragUpdate } from "./helpers/get-widget-options.helper";
import { WidgetRowWithDraggable } from "./widget-row-with-draggable.component";
import { WidgetRowWithoutDraggable } from "./widget-row-without-draggable.component";

interface IWidgetSectionProps {}

export const WidgetSection: FC<IWidgetSectionProps> = (props) => {
  const {
    rows,
    fixedRows,
    widgets,
    setCurrentlyDraggingWidget,
    moveWidget,
    setWidgetOptions,
    scrollToLastRow,
    setScrollToLastRow,
  } = useContext(DashboardWidgetsContext);

  const theme = useTheme();
  const lastRowRef = useRef<HTMLDivElement>(null);

  const onDragEnd = useCallback(
    (result: DropResult) => {
      setCurrentlyDraggingWidget(undefined);
      setWidgetOptions(null);
      if (!result.destination) {
        return;
      }

      // only trigger function if location actually changed
      if (
        result.destination.droppableId !== result.source.droppableId ||
        result.destination.index !== result.source.index
      ) {
        moveWidget(result.draggableId, Number(result.destination.droppableId), result.destination.index);
      }
    },
    [moveWidget, setCurrentlyDraggingWidget, setWidgetOptions],
  );

  const onDragUpdate = useCallback(
    (update: DragUpdate) => {
      if (!update.destination) {
        return;
      }

      const options = getWidgetOptionsFromDragUpdate(
        update,
        widgets.filter(
          (widget) => widget.row === Number(update.destination?.droppableId) && widget.id !== update.draggableId,
        ),
        parseFloat(theme.spacing(6)),
      );

      setWidgetOptions(options);
    },
    [setWidgetOptions, widgets, theme],
  );

  const onBeforeDrag = useCallback(
    (start: DragStart) => {
      const position = getPositionForWidgetId(start.draggableId);

      setCurrentlyDraggingWidget({ id: start.draggableId, initialX: position.x, initialY: position.y });
    },
    [setCurrentlyDraggingWidget],
  );

  useEffect(() => {
    if (scrollToLastRow) {
      lastRowRef.current?.scrollIntoView({ behavior: "smooth" });
      setScrollToLastRow(false);
    }
  }, [widgets, scrollToLastRow, setScrollToLastRow]);

  return (
    <Box>
      {/* fixed widgets */}
      {fixedRows.map((widgets, index) => (
        <WidgetRowWithoutDraggable widgets={widgets} key={`fixed_row_${index}`} />
      ))}
      {/* normal widgets */}
      <DragDropContext onDragEnd={onDragEnd} onDragUpdate={onDragUpdate} onBeforeDragStart={onBeforeDrag}>
        {rows.map((widgets, index) => (
          <WidgetRowWithDraggable
            // ref={index === rows.length - 1 ? lastRowRef : null}
            widgets={widgets}
            index={index}
            key={`row_${index}`}
          />
        ))}
        {/* empty row to allow dragging elements into a new row */}
        <WidgetRowWithDraggable
          ref={lastRowRef}
          widgets={[]}
          index={rows.length}
          // key={`row_${index}`}
        />
      </DragDropContext>
    </Box>
  );
};
