import React, { useCallback, useContext, useMemo } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Button, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ChevronLeftIcon } from "../../assets/icons/icons.component";
import { InfocenterDetailProvider } from "./context/infocenter-detail.context";
import { InfocenterDetailPageContent } from "./infocenter-detail.page-content";
import { getAbsoluteRoutingPath } from "../../utils/get-absolute-routing-path.util";
import { NavMenuContext } from "../../components/layout/nav/context/nav-menu.context";
import { InfocenterType } from "../../api/muu-api/graphql/generated";
import { decodeIdBase64 } from "../../utils/base64.util";
import { LayoutContext } from "../../components/layout/layout.context";

interface IInfocenterDetailPageProps {}

export const InfocenterDetailPage: React.FC<IInfocenterDetailPageProps> = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { pageOfInterestDictionary } = useContext(NavMenuContext);
  const { breakpointInfo } = useContext(LayoutContext);

  const navigateToOverview = useCallback(() => {
    navigate(getAbsoluteRoutingPath(pageOfInterestDictionary.INFOCENTER.path));
  }, [navigate, pageOfInterestDictionary]);

  const { id, type, localize } = useMemo(() => {
    const decoded = decodeIdBase64<InfocenterType>(searchParams.get("id") || "");

    return {
      id: decoded?.id || null,
      type: Object.values(InfocenterType).includes(decoded?.type!) ? decoded.type || null : null,
      localize: decoded.localize || null,
    };
  }, [searchParams]);

  return (
    <Grid container direction="column" spacing={6}>
      <Grid item>
        <Button
          variant="outlined"
          startIcon={<ChevronLeftIcon size="small" />}
          onClick={navigateToOverview}
          fullWidth={breakpointInfo.isMobile}
        >
          {t("general.back_to_overview")}
        </Button>
      </Grid>
      <Grid item xs container>
        <InfocenterDetailProvider id={id} type={type} localize={localize}>
          <InfocenterDetailPageContent />
        </InfocenterDetailProvider>
      </Grid>
    </Grid>
  );
};
