import { useMemo } from "react";
import { Favorite, FavoritesFilterInput, useFavoritesQuery } from "../api/muu-api/graphql/generated";

interface IFavoritesFilterInput extends FavoritesFilterInput {
  entryId?: string;
}

type FavoritesDict = { [key: string]: { [key: string]: { [key: string]: Favorite } } };

export const useFavorites = (filter: IFavoritesFilterInput, skip: boolean) => {
  const { data: favoritesData } = useFavoritesQuery({
    variables: { filter },
    skip,
  });

  const favoritesDict = useMemo(() => {
    const dict: FavoritesDict = {} as FavoritesDict;
    favoritesData?.favorites?.map((favorite) => {
      if (!dict[favorite.type]) {
        dict[favorite.type] = { [favorite.source]: { [favorite.entryId]: favorite } };
      } else if (!dict[favorite.type][favorite.source]) {
        dict[favorite.type][favorite.source] = { [favorite.entryId]: favorite };
      } else {
        dict[favorite.type][favorite.source][favorite.entryId] = favorite;
      }
    });

    return dict;
  }, [favoritesData]);

  return { favoritesDict };
};
