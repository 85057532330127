import { Button, Grid } from "@mui/material";
import React, { useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { ChevronLeftIcon, TrashIcon } from "../../assets/icons/icons.component";
import { ThinkTankContext, ThinkTankMode } from "./context/think-tank.context";
import { ConfirmDialog } from "../../components/dialog/confirm.dialog";
import { LayoutContext } from "../../components/layout/layout.context";

interface IThinkTankDetailHeaderProps {}

export const ThinkTankDetailHeader: React.FC<IThinkTankDetailHeaderProps> = (props) => {
  const {
    isPostOwner,
    mode,
    setMode,
    handleNavigateToDashboard,
    handleUpdateThinkTankPost,
    handleDeleteThinkTankPost,
    handleCancelEditThinkTankPost,
    isLoading,
  } = useContext(ThinkTankContext);
  const { t } = useTranslation();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const { breakpointInfo } = useContext(LayoutContext);

  const detailButtons = useMemo(() => {
    return (
      <>
        <Grid item>
          <Button
            variant="outlined"
            onClick={() => setDeleteDialogOpen(true)}
            fullWidth={breakpointInfo.isMobile}
            disabled={isLoading}
          >
            {t("think_tank.delete_post")}
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            onClick={() => setMode(ThinkTankMode.EDIT)}
            fullWidth={breakpointInfo.isMobile}
            disabled={isLoading}
          >
            {t("think_tank.edit_post")}
          </Button>
        </Grid>
      </>
    );
  }, [setMode, t, isLoading]);

  const editButtons = useMemo(() => {
    return (
      <>
        <Grid item>
          <Button
            variant="outlined"
            onClick={handleCancelEditThinkTankPost}
            fullWidth={breakpointInfo.isMobile}
            disabled={isLoading}
          >
            {t("general.cancel")}
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            onClick={handleUpdateThinkTankPost}
            fullWidth={breakpointInfo.isMobile}
            disabled={isLoading}
          >
            {t("general.save")}
          </Button>
        </Grid>
      </>
    );
  }, [t, handleUpdateThinkTankPost, handleCancelEditThinkTankPost, isLoading]);

  const buttons = useMemo(() => {
    if (!isPostOwner) return <></>;

    return mode === ThinkTankMode.DETAIL ? detailButtons : editButtons;
  }, [detailButtons, editButtons, mode, isPostOwner]);

  return (
    <>
      <ConfirmDialog
        isOpen={deleteDialogOpen}
        handleClose={() => setDeleteDialogOpen(false)}
        handleConfirmation={handleDeleteThinkTankPost}
        title={t("think_tank.dialog.delete_title")}
        content={t("think_tank.dialog.confirm")}
        icon={TrashIcon}
      />
      <Grid item>
        <Grid
          container
          direction={breakpointInfo.isMobile ? "column" : "row"}
          justifyContent="space-between"
          spacing={4}
        >
          <Grid item xs>
            <Button
              variant="outlined"
              startIcon={<ChevronLeftIcon size="small" />}
              onClick={handleNavigateToDashboard}
              fullWidth={breakpointInfo.isMobile}
              disabled={isLoading}
            >
              {t("general.back_to_dashboard")}
            </Button>
          </Grid>
          {buttons}
        </Grid>
      </Grid>
    </>
  );
};
