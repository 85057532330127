import { Theme } from "@mui/material";
import React, { PropsWithChildren, createContext, useContext, useEffect, useMemo, useState } from "react";
import { Theme as ThemeEnum } from "../../api/muu-api/graphql/generated";
import { UserContext } from "../../app/context/user.context";
import { ContrastTheme } from "../../theme/contrast.theme";
import { LightTheme } from "../../theme/light.theme";

interface IThemeContext {
  theme: Theme;
  setTheme: (theme: Theme) => void;
  isContrastThemeActive: boolean;
}

export const ThemeContext = createContext<IThemeContext>({} as IThemeContext);

interface IThemeContextProviderProps extends PropsWithChildren {}

export const ThemeContextProvider: React.FC<IThemeContextProviderProps> = (props) => {
  const { children } = props;
  const { currentUser } = useContext(UserContext);
  const [theme, setTheme] = useState<Theme>(LightTheme);

  useEffect(() => {
    if (currentUser?.theme === ThemeEnum.CONTRAST) {
      setTheme(ContrastTheme);
    } else {
      setTheme(LightTheme);
    }
  }, [currentUser]);

  const isContrastThemeActive = useMemo(() => theme === ContrastTheme, [theme]);

  return (
    <ThemeContext.Provider
      value={{
        theme,
        setTheme,
        isContrastThemeActive,
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
};
