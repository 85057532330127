import { Box, Menu, MenuItem, MenuList, Popover, PopoverProps, Stack, Typography, useTheme } from "@mui/material";
import { FC } from "react";
import { PopoverArrow } from "./popover-arrow";

export interface ISelectionPopoverOption {
  key: string;
  label: string;
  icon?: JSX.Element;
}

export interface ISelectionPopoverProps extends PopoverProps {
  options: ISelectionPopoverOption[];
  onOptionSelected: (option: ISelectionPopoverOption) => void;
  selectedKey: string | null;
}

export const SelectionPopover: FC<ISelectionPopoverProps> = (props) => {
  const { options, onOptionSelected, selectedKey, ...other } = props;
  const theme = useTheme();
  return (
    <Popover
      role="presentation"
      aria-label="language-selection"
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      elevation={1}
      PaperProps={{
        sx: {
          borderRadius: theme.spacing(1),
          overflow: "visible",
          p: 1,
          mt: 0.5,
          ml: 0.75,
          minWidth: 100,
          "& .MuiMenuItem-root": {
            px: 1,
            typography: "body2",
            borderRadius: 0.75,
          },
        },
      }}
      {...other}
    >
      <PopoverArrow />
      <MenuList role="listbox" tabIndex={0}>
        {options.map((option) => (
          <MenuItem
            tabIndex={-1}
            role="option"
            key={option.key}
            selected={selectedKey === option.key}
            onClick={() => onOptionSelected(option)}
          >
            <Stack direction="row" spacing={2}>
              <Box sx={{ display: "flex" }} alignItems="center">
                {option.icon && (
                  <Box alignItems="center" sx={{ display: "flex", pr: 1 }}>
                    {option.icon}
                  </Box>
                )}
                <Box alignItems="center">
                  <Typography variant="body2">{option.label}</Typography>
                </Box>
              </Box>
            </Stack>
          </MenuItem>
        ))}
      </MenuList>
    </Popover>
  );
};
