import { useTheme } from "@mui/material";
import { ThemeContext } from "../../theme.context";
import { useContext, useMemo } from "react";

export const useNavMenuItemColor = (isHovering: boolean, isSelected: boolean, isOpen: boolean) => {
  const theme = useTheme();
  const { isContrastThemeActive } = useContext(ThemeContext);

  return useMemo(() => {
    if (!isContrastThemeActive) {
      return isOpen || isSelected ? theme.palette.menuBar.contrastText : theme.palette.grey[500];
    }
    if (isHovering) {
      return theme.palette.primary.contrastText;
    }
    if (isSelected) {
      return theme.palette.primary.contrastText;
    }
    if (isOpen) {
      return theme.palette.primaryDark.main;
    }
  }, [
    isContrastThemeActive,
    isHovering,
    isOpen,
    isSelected,
    theme.palette.grey,
    theme.palette.menuBar.contrastText,
    theme.palette.primary.contrastText,
    theme.palette.primaryDark.main,
  ]);
};
