import { Box, useTheme } from "@mui/material";
import React, { useContext } from "react";
import {
  APP_BAR_OFFSET,
  PAGE_PADDING_HORIZONTAL_FACTOR,
  PAGE_PADDING_VERTICAL_FACTOR,
  PAGE_PAPER_PADDING_VERTICAL_FACTOR,
} from "../../../theme/sizings.theme";
import { LayoutContext } from "../layout.context";

interface IPageProps {
  children?: React.ReactNode;
  ariaLabel?: string;
  id?: string;
  role?: string;
}

export const Page: React.FC<IPageProps> = (props) => {
  const { children, ariaLabel, id, role } = props;
  const theme = useTheme();
  const { breakpoint } = useContext(LayoutContext);

  return (
    <Box
      aria-label={ariaLabel}
      id={id}
      role={role}
      sx={{
        flex: 1,
        overflow: "auto",
        display: "block",
        minHeight: `calc(100vh - ${APP_BAR_OFFSET[breakpoint]}px - ${PAGE_PAPER_PADDING_VERTICAL_FACTOR[breakpoint]}px * 4)`,
        backgroundColor: theme.palette.grey[50],
        pb: PAGE_PADDING_VERTICAL_FACTOR[breakpoint],
        pl: PAGE_PADDING_HORIZONTAL_FACTOR[breakpoint],
        pr: PAGE_PADDING_HORIZONTAL_FACTOR[breakpoint],
        pt: `${APP_BAR_OFFSET[breakpoint]}px`,
      }}
      component={"div"}
    >
      {children}
    </Box>
  );
};
