import { FunctionComponent, useContext } from "react";
import { PageActionIconButtons } from "../../components/button/page-action-icon-buttons.component";
import { InfocenterDetailContext } from "./context/infocenter-detail.context";

interface IInfocenterDetailActionIconsProps {
  disabled: boolean;
}

export const InfocenterDetailActionIcons: FunctionComponent<IInfocenterDetailActionIconsProps> = (props) => {
  const { disabled } = props;
  const { pageContentRef, favoriteInputs } = useContext(InfocenterDetailContext);

  return <PageActionIconButtons disabled={disabled} printRef={pageContentRef} favoriteInputs={favoriteInputs} />;
};
