import { Grid, SxProps, Theme, Typography } from "@mui/material";
import React, { MutableRefObject, ReactInstance, useContext } from "react";
import { IFavoriteInputs, PageActionIconButtons } from "../button/page-action-icon-buttons.component";
import { LayoutContext } from "../layout/layout.context";
import { CONTENT_TITLE_MARGIN_BOTTOM_FACTOR } from "../../theme/sizings.theme";

interface IContentTitleProps {
  title: string;
  loading: boolean;
  pageContentRef?: MutableRefObject<ReactInstance | null>;
  favoriteInputs?: IFavoriteInputs;
  sx?: SxProps<Theme> | undefined;
}

export const ContentTitle: React.FC<IContentTitleProps> = (props) => {
  const { title, loading, pageContentRef, favoriteInputs, sx } = props;

  const { breakpointInfo, breakpoint } = useContext(LayoutContext);

  return (
    <Grid
      container
      direction={breakpointInfo.isMobile ? "column" : "row"}
      sx={{ mb: CONTENT_TITLE_MARGIN_BOTTOM_FACTOR[breakpoint], ...sx }}
      spacing={4}
    >
      <Grid item xs>
        <Typography variant="h4">{title}</Typography>
      </Grid>
      {pageContentRef && (
        <Grid item>
          <PageActionIconButtons disabled={loading} printRef={pageContentRef} favoriteInputs={favoriteInputs} />
        </Grid>
      )}
    </Grid>
  );
};
