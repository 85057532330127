import React, { FC, useContext } from "react";
import { DialogActions, DialogActionsProps, useTheme } from "@mui/material";
import { LayoutContext } from "../layout/layout.context";

interface ICustomDialogActionsProps extends DialogActionsProps {}

export const CustomDialogActions: FC<ICustomDialogActionsProps> = (props) => {
  const { sx, children, ...rest } = props;
  const { breakpointInfo } = useContext(LayoutContext);
  const theme = useTheme();

  return (
    <DialogActions
      disableSpacing={breakpointInfo.isMobile}
      {...rest}
      sx={{
        flexDirection: breakpointInfo.isMobile ? "column-reverse" : "row",
        alignItems: "stretch",
        gridGap: theme.spacing(3),
        ...sx,
      }}
    >
      {children}
    </DialogActions>
  );
};
