import { Divider, Grid, Skeleton, Typography, useTheme } from "@mui/material";
import React, { useContext, useMemo } from "react";
import { SearchTopicPage } from "../../api/muu-api/graphql/generated";
import { FilterTopicsIcon } from "../../assets/icons/icons.component";
import { SEARCH_RESULT_ICON_HORIZONTAL_MARGIN_FACTOR, SEARCH_RESULT_SKELETON_WIDTH } from "../../theme/sizings.theme";
import { LayoutContext } from "../layout/layout.context";
import { useTracking } from "../../app/context/tracking/tracking.context";
import { CustomLink } from "../link/custom-link.component";
import { TopicPageDictionary } from "../../router/routes.const";

interface ITopicPageSearchResultProps {
  searchTopicPage?: SearchTopicPage;
  loading: boolean;
  isLastItem: boolean;
}

export const TopicPageSearchResult: React.FC<ITopicPageSearchResultProps> = (props) => {
  const { searchTopicPage, loading } = props;
  const theme = useTheme();
  const { breakpoint } = useContext(LayoutContext);
  const { trackEvent } = useTracking();

  const href = useMemo(() => {
    if (!searchTopicPage) {
      return;
    }
    trackEvent({
      event: "search_result_view",
      action: "redirect",
      category: "page_visit",
    });

    const topicPageDictionaryEntry = TopicPageDictionary[searchTopicPage.id];
    if (!topicPageDictionaryEntry) {
      return `/page/${searchTopicPage.id}`;
    }
    return `/${topicPageDictionaryEntry.route}?language=${topicPageDictionaryEntry.language}`;
  }, [searchTopicPage, trackEvent]);

  return (
    <Grid container direction="column">
      <CustomLink href={href} style={{ textDecoration: "none", color: "inherit", font: "inherit" }}>
        <Grid container direction="row" alignItems="center" sx={{ p: 4 }}>
          <Grid
            item
            sx={{
              color: "accent1.main",
              ml: SEARCH_RESULT_ICON_HORIZONTAL_MARGIN_FACTOR[breakpoint],
              mr: SEARCH_RESULT_ICON_HORIZONTAL_MARGIN_FACTOR[breakpoint],
              pr: 4,
            }}
          >
            <FilterTopicsIcon color="inherit" fontSize="medium" />
          </Grid>
          <Grid item xs container direction="column">
            <Grid item sx={{ display: "flex" }}>
              {!!searchTopicPage && searchTopicPage.title && (
                <Typography variant="subtitle2" sx={{ textAlign: "start", wordBreak: "break-word" }}>
                  {searchTopicPage.title}
                </Typography>
              )}
              {!searchTopicPage && loading && (
                <Skeleton
                  variant="text"
                  width={SEARCH_RESULT_SKELETON_WIDTH[breakpoint]}
                  sx={{ fontSize: theme.typography.body1 }}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
      </CustomLink>
      <Grid item>
        <Divider sx={{ backgroundColor: "grey.100" }} />
      </Grid>
    </Grid>
  );
};
