import { Grid } from "@mui/material";
import { DateTime } from "luxon";
import React, { useCallback, useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { FilterCalendarIcon } from "../../../assets/icons/icons.component";
import { FILTER_POPOVER_MAX_WIDTH } from "../../../theme/sizings.theme";
import { CategoryFilter } from "../category-filter.component";
import { DateRangeFilter } from "../date-range-filter.component";
import { IFullCategoryFilterInfo, useSelectedCategories } from "../hooks/use-selected-categories.hook";
import { FilterButtonPopover } from "../popover/filter-popover-button.component";
import { FilterBar } from "./filter-bar.component";
import { LayoutContext } from "../../layout/layout.context";

interface ICategoryDateRangeFilterBarProps {
  applyDateRangeFilter: (startDate: DateTime, endDate: DateTime) => void;
  resetDateRangeFilter: () => void;
  applyCategoryFilter: (categories: IFullCategoryFilterInfo[]) => void;
  resetCategoryFilter: () => void;
  appliedCategories: IFullCategoryFilterInfo[];
  appliedToDate: DateTime | null;
  appliedFromDate: DateTime | null;
  disableFuture?: boolean;
  additionalFilters?: JSX.Element[];
}

export const CategoryDateRangeFilterBar: React.FC<ICategoryDateRangeFilterBarProps> = (props) => {
  const [startDate, setStartDate] = useState<DateTime | null>(null);
  const [endDate, setEndDate] = useState<DateTime | null>(null);
  const {
    applyDateRangeFilter,
    resetDateRangeFilter,
    applyCategoryFilter,
    resetCategoryFilter,
    appliedCategories,
    appliedFromDate,
    appliedToDate,
    disableFuture,
    additionalFilters,
  } = props;
  const { t, i18n } = useTranslation();
  const { breakpoint } = useContext(LayoutContext);
  const { selectedCategories, setSelectedCategories, categories } = useSelectedCategories({
    initialCategories: appliedCategories,
  });

  const dateFilterTitle = useMemo(() => {
    return startDate
      ? `${startDate.toFormat("dd.MM")} - ${endDate ? endDate.toFormat("dd.MM.yyyy") : ""}`
      : t("filter.daterange.filter_label");
  }, [startDate, endDate, t, i18n.language]);

  const categoryFilterTitle = useMemo(() => {
    const listedCategories = selectedCategories
      .filter((_, index) => index < 2)
      .map((category) => category.category.title);

    const categoriesString = `${listedCategories.join(", ")}${selectedCategories.length > 2 ? ", ..." : ""}`;

    return categoriesString.length ? categoriesString : t("filter.category.filter_label");
  }, [selectedCategories, t]);

  const isFilledDateRange = useMemo(() => {
    return startDate !== null || endDate !== null;
  }, [startDate, endDate]);

  const isFilledCategory = useMemo(() => {
    return selectedCategories.length ? true : false;
  }, [selectedCategories]);

  const onApplyDateRangeFilter = useCallback(() => {
    if (!startDate || !endDate) return;
    applyDateRangeFilter(startDate, endDate);
  }, [startDate, endDate, applyDateRangeFilter]);

  const onResetDateRangeFilter = useCallback(() => {
    setStartDate(null);
    setEndDate(null);
    resetDateRangeFilter();
  }, [resetDateRangeFilter]);

  const onApplyCategoryFilter = useCallback(() => {
    applyCategoryFilter(selectedCategories);
  }, [selectedCategories, applyCategoryFilter]);

  const onResetCategoryFilter = useCallback(() => {
    setSelectedCategories([]);
    resetCategoryFilter();
  }, [resetCategoryFilter, setSelectedCategories]);

  const onUnapplyCategoryFilter = useCallback(() => {
    setSelectedCategories(appliedCategories);
  }, [appliedCategories, setSelectedCategories]);

  const onUnapplyDateRangeFilter = useCallback(() => {
    setStartDate(appliedFromDate);
    setEndDate(appliedToDate);
  }, [appliedToDate, appliedFromDate]);

  return (
    <FilterBar>
      <Grid item>
        <FilterButtonPopover
          key="daterange-filter"
          filterTitle={dateFilterTitle}
          title={t("filter.daterange.filter_label")}
          filled={isFilledDateRange}
          startIcon={<FilterCalendarIcon size="small" />}
          applyFilter={onApplyDateRangeFilter}
          resetFilter={onResetDateRangeFilter}
          closeFilter={onUnapplyDateRangeFilter}
        >
          <DateRangeFilter
            startDate={startDate}
            endDate={endDate}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            disableFuture={disableFuture}
          />
        </FilterButtonPopover>
      </Grid>
      <Grid item>
        <FilterButtonPopover
          key="categories-filter"
          filterTitle={categoryFilterTitle}
          title={t("filter.category.filter_label")}
          filled={isFilledCategory}
          maxWidth={FILTER_POPOVER_MAX_WIDTH[breakpoint]}
          applyFilter={onApplyCategoryFilter}
          resetFilter={onResetCategoryFilter}
          closeFilter={onUnapplyCategoryFilter}
        >
          <CategoryFilter
            categories={categories}
            setSelectedCategories={setSelectedCategories}
            selectedCategories={selectedCategories}
          />
        </FilterButtonPopover>
      </Grid>
      {(additionalFilters ?? []).map((customFilter, index) => (
        <Grid item key={`${index}-grid-item`}>
          {customFilter}
        </Grid>
      ))}
    </FilterBar>
  );
};
