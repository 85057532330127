import { Box, Link, Typography } from "@mui/material";
import React, { useContext, useMemo } from "react";
import { MonitorIcon } from "../../../assets/icons/icons.component";
import { useTranslation } from "react-i18next";
import { useWebExRoomQuery } from "../../../api/muu-api/graphql/generated";
import { ThemeContext } from "../../layout/theme.context";

export const WorkSpacesWebexRoom: React.FC = () => {
  const { t } = useTranslation();
  const { data } = useWebExRoomQuery();
  const { isContrastThemeActive } = useContext(ThemeContext);

  const personalWebexLink = useMemo(() => {
    return data?.webExRoom?.url ?? "#";
  }, [data]);

  return (
    <Link
      tabIndex={0}
      id="webex-room-link"
      underline="none"
      href={personalWebexLink}
      target="_blank"
      sx={{ font: "inherhit", color: "inherit", display: "block" }}
    >
      <Box id="workspaces-webex-room" sx={{ width: "100%", pt: 3, pb: 3 }}>
        <Box sx={{ textAlign: "center", color: "primaryDark.main" }}>
          <MonitorIcon />
        </Box>
        <Typography variant="body1" sx={{ textAlign: "center", color: isContrastThemeActive ? "text" : "grey.400" }}>
          {t("work_spaces.webex_room")}
        </Typography>
      </Box>
    </Link>
  );
};
