import { Grid, Typography } from "@mui/material";
import React, { useMemo } from "react";
import { TopicTeamContent } from "../../../api/muu-api/graphql/generated";
import { TopicTeamMembers } from "./topic-team-members.content-component";

interface ITopicTeamContentProps {
  content: TopicTeamContent;
}

export const TopicTeam: React.FC<ITopicTeamContentProps> = (props) => {
  const { content } = props;

  const existingTeamMembers = useMemo(() => {
    return content.teamMembers?.filter((member) => member.medUniId) ?? [];
  }, [content]);

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="h6" sx={{ color: "primaryDark.main", mb: 2 }}>
          {content.title}
        </Typography>
      </Grid>
      {existingTeamMembers?.length > 0 && <TopicTeamMembers teamMembers={existingTeamMembers} />}
    </Grid>
  );
};
