import { Divider, Grid, Link, Skeleton, Typography, useTheme } from "@mui/material";
import React, { useCallback, useContext } from "react";
import { useNavigate } from "react-router";
import { SearchNotification } from "../../api/muu-api/graphql/generated";
import { FilterNotificationsIcon } from "../../assets/icons/icons.component";
import { DETAIL_PATH } from "../../router/routes.const";
import { SEARCH_RESULT_ICON_HORIZONTAL_MARGIN_FACTOR, SEARCH_RESULT_SKELETON_WIDTH } from "../../theme/sizings.theme";
import { encodeIdBase64 } from "../../utils/base64.util";
import { getAbsoluteRoutingPath } from "../../utils/get-absolute-routing-path.util";
import { LayoutContext } from "../layout/layout.context";
import { NavMenuContext } from "../layout/nav/context/nav-menu.context";
import { useTracking } from "../../app/context/tracking/tracking.context";

interface INotificationsSearchResultProps {
  searchNotification?: SearchNotification;
  loading: boolean;
  isLastItem: boolean;
}

export const NotificationsSearchResult: React.FC<INotificationsSearchResultProps> = (props) => {
  const { searchNotification, loading } = props;
  const theme = useTheme();
  const navigate = useNavigate();
  const { pageOfInterestDictionary } = useContext(NavMenuContext);
  const { breakpoint } = useContext(LayoutContext);
  const { trackEvent } = useTracking();

  const onClick = useCallback(() => {
    if (!searchNotification) {
      return;
    }
    trackEvent({
      event: "search_result_view",
      action: "redirect",
      category: "page_visit",
    });
    navigate(
      `${getAbsoluteRoutingPath(pageOfInterestDictionary.NOTIFICATIONS.path)}/${DETAIL_PATH}/?id=${encodeIdBase64(
        searchNotification.id,
      )}&language=${searchNotification.language}`,
    );
  }, [searchNotification, navigate, pageOfInterestDictionary, trackEvent]);

  return (
    <Grid container direction="column">
      <Link component="button" onClick={onClick} style={{ textDecoration: "none", color: "inherit", font: "inherit" }}>
        <Grid container direction="row" alignItems="center" sx={{ p: 4 }}>
          <Grid
            item
            sx={{
              color: "accent1.main",
              ml: SEARCH_RESULT_ICON_HORIZONTAL_MARGIN_FACTOR[breakpoint],
              mr: SEARCH_RESULT_ICON_HORIZONTAL_MARGIN_FACTOR[breakpoint],
              pr: 4,
            }}
          >
            <FilterNotificationsIcon color="inherit" fontSize="medium" />
          </Grid>
          <Grid item xs container direction="column">
            {!!searchNotification && searchNotification.title && (
              <Grid item>
                <Typography variant="subtitle2" sx={{ textAlign: "start", wordBreak: "break-word" }}>
                  {searchNotification.title}
                </Typography>
              </Grid>
            )}
            {!searchNotification && loading && (
              <Grid>
                <Skeleton
                  variant="text"
                  width={SEARCH_RESULT_SKELETON_WIDTH[breakpoint]}
                  sx={{ fontSize: theme.typography.body1 }}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Link>
      <Grid item>
        <Divider sx={{ backgroundColor: "grey.100" }} />
      </Grid>
    </Grid>
  );
};
