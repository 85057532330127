import { Grid } from "@mui/material";
import { FC, useContext, useMemo } from "react";
import { Outlet } from "react-router-dom";
import { CONTENT_MAX_WIDTH } from "../../theme/sizings.theme";
import { PageHeader } from "./header/page-header.component";
import { LayoutContext } from "./layout.context";
import { BreadcrumbsContextProvider } from "./nav/context/breadcrumbs.context";
import { Page } from "./page/page.component";
import { PageHeaderProvider } from "./header/page-header.context";

interface ILayoutContentProps {
  NavComponent: FC;
  HeaderComponent: FC;
}

export const LayoutContent: FC<ILayoutContentProps> = (props) => {
  const { HeaderComponent, NavComponent } = props;
  const { breakpoint } = useContext(LayoutContext);

  //This makes the content not rerender when resizing the page
  const content = useMemo(
    () => (
      <Page ariaLabel="Main Content" id="main-content" role="main">
        <Grid
          container
          id="page-content"
          direction="column"
          sx={{
            flex: 1,
            width: "100%",
            maxWidth: CONTENT_MAX_WIDTH[breakpoint],
            mr: "auto",
            ml: "auto",
          }}
        >
          <PageHeaderProvider>
            <PageHeader />
          </PageHeaderProvider>
          <Outlet />
        </Grid>
      </Page>
    ),
    [breakpoint],
  );

  return useMemo(
    () => (
      <BreadcrumbsContextProvider>
        <HeaderComponent />
        <NavComponent />
        {content}
      </BreadcrumbsContextProvider>
    ),
    [HeaderComponent, NavComponent, content],
  );
};
