import { FC } from "react";
import {
  TopicAccordionContent,
  TopicContactBoxContent,
  TopicDescriptionContent,
  TopicLinksContent,
  TopicPageContentAll,
  TopicTeamContent,
  TopicTeaserContent,
  TopicTitleContent,
} from "../../api/muu-api/graphql/generated";
import { TopicAccordion } from "./content-components/topic-accordion.content-component";
import { TopicDescription } from "./content-components/topic-description.content-component";
import { TopicLinks } from "./content-components/topic-links.content-component";
import { TopicTeaser } from "./content-components/topic-teaser.content-component";
import { TopicTitle } from "./content-components/topic-title.content-component";
import { TopicContactBox } from "./content-components/topic-contact-box.content-component";
import { TopicTeam } from "./content-components/topic-team.content-component";
import { TopicDescriptionProvider } from "./content-components/topic-description.context";

interface ITopicDynamicContentProps {
  content: TopicPageContentAll;
}

export const TopicDynamicContent: FC<ITopicDynamicContentProps> = (props) => {
  const { content } = props;

  switch (content.__typename) {
    case "TopicTitleContent":
      return <TopicTitle content={content as TopicTitleContent} />;
    case "TopicTeaserContent":
      return <TopicTeaser content={content as TopicTeaserContent} />;
    case "TopicDescriptionContent":
      return (
        <TopicDescriptionProvider>
          <TopicDescription content={content as TopicDescriptionContent} />
        </TopicDescriptionProvider>
      );
    case "TopicAccordionContent":
      return <TopicAccordion content={content as TopicAccordionContent} />;
    case "TopicLinksContent":
      return <TopicLinks content={content as TopicLinksContent} />;
    case "TopicContactBoxContent":
      return <TopicContactBox content={content as TopicContactBoxContent} />;
    case "TopicTeamContent":
      return <TopicTeam content={content as TopicTeamContent} />;
    default:
      return <></>;
  }
};
