import { fromWeb, requiredStr } from "@protomodule/instruments";

export const config = fromWeb({
  REACT_APP_GRAPHQL_URL: requiredStr(),
  REACT_APP_AUTH_URL: requiredStr(),
  REACT_APP_RELATIVE_LINK_URL: requiredStr(),
  REACT_APP_GRAPHQL_WEBSOCKET_URL: requiredStr(),
  REACT_APP_PIWIK_URL: requiredStr(),
  REACT_APP_PIWIK_SITE_ID: requiredStr(),
});
