import { HTMLReactParserOptions, domToReact } from "html-react-parser";
import { Element, ChildNode } from "domhandler";
import { isListItemNested } from "../helpers/editor-content-table.helper";
import { Grid, List, ListItem, ListItemText, Theme } from "@mui/material";
import { UnorderedListItemIcon, UnorderedListNestedItemIcon } from "../../../assets/icons/icons.component";
import { Breakpoint, EDITOR_CONTENT_LARGE_SPACING } from "../../../theme/sizings.theme";
import { HtmlElement, NullableEditorElement } from "../types/editor-content.type";

export const mapToList = (
  name: string,
  node: Element,
  children: ChildNode[],
  options: HTMLReactParserOptions,
  breakpoint: Breakpoint,
  theme: Theme,
) => {
  if (name === HtmlElement.Ul || name === HtmlElement.Ol) {
    return (
      <List
        disablePadding
        sx={{
          counterReset: "numeric-counter lower-alpha-counter",
          listStyleType: "none",
        }}
      >
        {domToReact(children, options)}
      </List>
    );
  }
  if (name === HtmlElement.Li) {
    const parent = node.parent as NullableEditorElement;
    const isOrdered = parent?.name === HtmlElement.Ol;
    return isOrdered
      ? mapToOrderedListItem(node, children, options, breakpoint)
      : mapToUnOrderedListItem(node, children, options, breakpoint, theme);
  }
};

const mapToOrderedListItem = (
  node: Element,
  children: ChildNode[],
  options: HTMLReactParserOptions,
  breakpoint: Breakpoint,
) => {
  const isNested = isListItemNested(node);
  const counter = isNested ? `counter(lower-alpha-counter, lower-alpha) "."` : `counter(numeric-counter, decimal) "."`;

  const pl = isNested ? 6 : 0;
  return (
    <ListItem
      disablePadding
      sx={{
        display: "list-item",
        pl,
        pr: 2,
        pt: 2,
        counterIncrement: "numeric-counter lower-alpha-counter",
        "&:last-child": {
          pb: isNested ? 0 : `${EDITOR_CONTENT_LARGE_SPACING[breakpoint]}px`,
        },
      }}
    >
      <Grid container>
        <Grid item xs="auto" alignItems="center">
          <ListItemText
            primaryTypographyProps={{
              sx: {
                "&::before": {
                  content: counter,
                  color: "primary.500",
                },
              },
              pr: 3,
            }}
          >
            <div></div>
          </ListItemText>
        </Grid>
        <Grid item xs>
          <ListItemText>{domToReact(children, options)}</ListItemText>
        </Grid>
      </Grid>
    </ListItem>
  );
};

const mapToUnOrderedListItem = (
  node: Element,
  children: ChildNode[],
  options: HTMLReactParserOptions,
  breakpoint: Breakpoint,
  theme: Theme,
) => {
  const isNested = isListItemNested(node);
  const Icon = isNested ? UnorderedListNestedItemIcon : UnorderedListItemIcon;

  return (
    <ListItem
      disablePadding
      sx={{
        display: "list-item",
        listStyleType: "none",
        pt: 2,
        m: 0,
        counterIncrement: "numeric-counter lower-alpha-counter",
        "&:first-of-type": {
          pt: isNested ? 2 : 0,
        },
        "&:last-child": {
          pb: isNested ? 0 : `${EDITOR_CONTENT_LARGE_SPACING[breakpoint]}px`,
        },
      }}
    >
      <Grid container direction="row">
        <Grid item xs="auto" sx={{ height: theme.icon.medium, pr: 2, pb: 0.5 }} container alignItems="center">
          <Icon
            sx={{ color: "primary.500", height: theme.icon.xsmall, width: theme.icon.xsmall }}
            viewBox="0 0 16 16"
          />
        </Grid>
        <Grid item xs>
          <ListItemText sx={{ m: 0 }}>{domToReact(children, options)}</ListItemText>
        </Grid>
      </Grid>
    </ListItem>
  );
};
