import React, { createContext, PropsWithChildren, useRef, useState } from "react";

interface ITopicDescriptionContext {
  open: boolean;
  setOpen: (open: boolean) => void;
  descriptionRef: React.RefObject<HTMLDivElement>;
}

export const TopicDescriptionContext = createContext<ITopicDescriptionContext>({} as ITopicDescriptionContext);

interface ITopicDescriptionProviderProps extends PropsWithChildren {}

export const TopicDescriptionProvider: React.FC<ITopicDescriptionProviderProps> = (props) => {
  const [open, setOpen] = useState<boolean>(false);
  const descriptionRef = useRef(null);
  return (
    <TopicDescriptionContext.Provider value={{ open, setOpen, descriptionRef }}>
      {props.children}
    </TopicDescriptionContext.Provider>
  );
};
