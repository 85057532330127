import { Card, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { RestaurantSpecial, WidgetColor, WidgetType } from "../../../api/muu-api/graphql/generated";
import { CustomLink } from "../../link/custom-link.component";

export interface ILunchSpecialsProps {
  specials: RestaurantSpecial[];
  currentColor: WidgetColor;
}

// TODO: Apparently we forgot to include some colors for the widget theming... (see sketch https://www.sketch.com/s/5b663d8c-9a64-49e6-98e7-0fd55b35977c) Add missing colors and use here
export const LunchSpecials: React.FC<ILunchSpecialsProps> = (props) => {
  const { specials } = props;
  const { t } = useTranslation();

  return (
    <Card
      elevation={0}
      sx={{
        p: 4,
        m: 4,
        mb: 2,
        borderRadius: 4,
        backgroundColor: "primary.25",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <Typography variant="body2" sx={{ m: "auto", pb: 2, color: "primary.500", fontWeight: 600 }}>
        {t(`widgets.${WidgetType.LUNCH}.weekly_special`)}
      </Typography>
      {specials.map((special) => {
        return special.document ? (
          <CustomLink
            key={`special-${special.id}`}
            href={`${special.document}`}
            variant="body2"
            sx={{ m: "auto", color: "primaryDark.850" }}
            target="_blank"
          >
            {special.description}
          </CustomLink>
        ) : (
          <Typography key={`special-${special.id}`} variant="body2" sx={{ m: "auto", color: "primaryDark.850" }}>
            {special.description}
          </Typography>
        );
      })}
    </Card>
  );
};
