import { Card, Grid } from "@mui/material";
import React from "react";
import { LoadingText } from "../../components/loading/loading-text.component";

export const ExternalPageSkeleton: React.FC = () => {
  return (
    <Card sx={{ borderRadius: 4 }}>
      <Grid container spacing={4} sx={{ width: "100%", p: 6 }}>
        <Grid item xs={12}>
          <LoadingText lines={15} variant="body1" />
        </Grid>
      </Grid>
    </Card>
  );
};
