import React, { useContext, useMemo } from "react";
import { Header } from "./header/header.component";
import { Nav } from "./nav/nav.component";
import { SearchContextProvider } from "./searchbar/context/search.context";
import { LayoutContext } from "./layout.context";
import { MobileNav } from "./mobile-nav/mobile-nav.component";
import { MobileHeader } from "./header/mobile-header.component";
import { Box } from "@mui/material";
import { Breakpoint } from "../../theme/sizings.theme";
import { LayoutContent } from "./layout-content.component";

export const Layout: React.FC = () => {
  const { breakpoint } = useContext(LayoutContext);

  const { sx, HeaderComponent, NavComponent } = useMemo(() => {
    switch (breakpoint) {
      case Breakpoint.MOBILE: {
        return {
          sx: {
            minHeight: "-webkit-fill-available",
            overflow: "hidden",
          },
          HeaderComponent: MobileHeader,
          NavComponent: MobileNav,
        };
      }
      default: {
        return {
          sx: { display: "flex", minHeight: "100vh", overflow: "hidden" },
          HeaderComponent: Header,
          NavComponent: Nav,
        };
      }
    }
  }, [breakpoint]);

  return (
    <SearchContextProvider>
      <Box id="main-frame-content" sx={sx}>
        <LayoutContent HeaderComponent={HeaderComponent} NavComponent={NavComponent} />
      </Box>
    </SearchContextProvider>
  );
};
