import { useApolloClient } from "@apollo/client";
import React, { useCallback, useContext } from "react";
import { useTranslation } from "react-i18next";
import { Theme, useUpdateUserMutation } from "../../../api/muu-api/graphql/generated";
import { ContrastIcon } from "../../../assets/icons/icons.component";
import { ContrastTheme } from "../../../theme/contrast.theme";
import { LightTheme } from "../../../theme/light.theme";
import { CustomIconButton } from "../../icon/custom-icon-button.component";
import { ThemeContext } from "../theme.context";

export const ThemeSelect: React.FC = () => {
  const { t } = useTranslation();
  const { setTheme, isContrastThemeActive } = useContext(ThemeContext);
  const client = useApolloClient();

  const [updateUser] = useUpdateUserMutation();

  const handleClick = useCallback(() => {
    const [newThemeObj, newThemeEnum] = isContrastThemeActive
      ? [LightTheme, Theme.LIGHT]
      : [ContrastTheme, Theme.CONTRAST];

    setTheme(newThemeObj);
    updateUser({
      variables: {
        theme: newThemeEnum,
      },
    });
  }, [client, isContrastThemeActive, setTheme, updateUser]);

  return (
    <CustomIconButton
      role="button"
      aria-label="Enable contrast theme"
      onClick={handleClick}
      title={t("dashboard.tooltip.theme")}
      sx={{
        ...(isContrastThemeActive && {
          color: "primary.contrastText",
          backgroundColor: "primary.500",
          "&:hover": {
            backgroundColor: "primaryDark.25",
          },
        }),
      }}
    >
      <ContrastIcon />
    </CustomIconButton>
  );
};
