import { Grid, SxProps, Typography, useTheme } from "@mui/material";
import { MealType, RestaurantMeal, WidgetColor } from "../../../api/muu-api/graphql/generated";
import { VeganIcon, VeggieIcon } from "../../../assets/icons/icons.component";
import { useContext, useMemo } from "react";
import { useWidgetColorToThemeColor } from "../../../pages/dashboard/widget-section/hooks/use-widget-color-to-theme-color.hook";
import { LUNCH_WIDGET_PRICE_MIN_WIDTH } from "../../../theme/sizings.theme";
import { LayoutContext } from "../../layout/layout.context";

export interface ILunchEntryProps {
  meal: RestaurantMeal;
  currentColor: WidgetColor;
}

export const LunchMeal: React.FC<ILunchEntryProps> = (props) => {
  const { meal, currentColor } = props;
  const theme = useTheme();
  const { breakpoint } = useContext(LayoutContext);
  const widgetThemeColor = useWidgetColorToThemeColor(currentColor, false);

  const iconSx: SxProps = useMemo(() => {
    return {
      width: theme.icon.small,
      height: theme.icon.small,
      color: currentColor === WidgetColor.DEFAULT ? "primary.main" : `${widgetThemeColor}.main`,
    };
  }, [theme, widgetThemeColor, currentColor]);

  return (
    <Grid container sx={{ display: "flex", pl: 4, pr: 4 }} alignItems="center" justifyItems="flex-end" direction="row">
      <Grid item xs>
        <Typography variant="body2" sx={{ color: "grey.700" }}>
          {meal.description}
        </Typography>
      </Grid>
      <Grid
        item
        xs="auto"
        columnSpacing={1}
        container
        sx={{ display: "flex", justifyContent: "end", alignItems: "center", pl: 2 }}
      >
        <Grid
          item
          xs="auto"
          sx={{
            width: theme.icon.small,
            display: "inline-grid",
            justifyContent: "center",
          }}
        >
          {meal.type === MealType.VEGAN && <VeganIcon sx={{ ...iconSx }} />}
          {meal.type === MealType.VEGETARIAN && <VeggieIcon sx={{ ...iconSx }} />}
        </Grid>
        <Grid
          item
          xs
          sx={{
            minWidth: LUNCH_WIDGET_PRICE_MIN_WIDTH[breakpoint],
            display: "inline-grid",
            justifyContent: "center",
          }}
        >
          {meal.price && (
            <Typography variant="body2" sx={{ color: "grey.700", whiteSpace: "nowrap" }}>
              {`${meal.price.toFixed(2).replace(".", ",")} €`}
            </Typography>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};
