import { HTMLReactParserOptions, domToReact } from "html-react-parser";
import { TypographyVariantsExtended } from "../../../theme/types/theme";
import { HtmlElement } from "../types/editor-content.type";
import { Divider, Typography } from "@mui/material";
import { ChildNode } from "domhandler";

export const mapToHeading = (name: string, children: ChildNode[], options: HTMLReactParserOptions) => {
  let variant: TypographyVariantsExtended;
  let renderDivider = false;
  switch (name) {
    case HtmlElement.H1:
      variant = "editorH1";
      break;
    case HtmlElement.H2:
      variant = "editorH2";
      break;
    case HtmlElement.H3:
      variant = "editorH3";
      break;
    case HtmlElement.H4:
      variant = "editorH4";
      renderDivider = true;
      break;
    case HtmlElement.H5:
      variant = "editorH5";
      break;
    case HtmlElement.H6:
      variant = "editorH6";
      renderDivider = true;
      break;
  }

  return (
    <>
      <Typography component="p" variant={variant}>
        {domToReact(children, options)}
      </Typography>
      {renderDivider && <Divider sx={{ mb: 2 }} />}
    </>
  );
};
