import React, { useContext, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { NotificationDetailPageWrapper } from "./notification-detail.page-wrapper";
import { decodeIdBase64 } from "../../utils/base64.util";
import { checkIfValueIsTypeOfEnum } from "../../utils/enum.util";
import { Language } from "../../api/muu-api/graphql/generated";
import { UserContext } from "../../app/context/user.context";

interface INotificationDetailPageProps {}

export const NotificationDetailPage: React.FC<INotificationDetailPageProps> = (props) => {
  const [searchParams] = useSearchParams();

  const { language: userLanguage } = useContext(UserContext);

  const { id, language } = useMemo(() => {
    const decoded = decodeIdBase64(searchParams.get("id") || "");
    const languageFromSearchParams = searchParams.get("language") || "";
    const language = checkIfValueIsTypeOfEnum(Language, languageFromSearchParams)
      ? (languageFromSearchParams as Language)
      : null;

    return {
      id: decoded.id || null,
      language,
    };
  }, [searchParams]);

  if (!id) {
    return null;
  }

  return <NotificationDetailPageWrapper id={id} language={language || userLanguage} />;
};
