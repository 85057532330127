import React, { useCallback, useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { ICreateCustomLink } from "../types/create-link.type";
import { Button, Divider, Grid, TextField } from "@mui/material";
import { CreateFavoriteCustomLinkRow } from "./add-favorite-custom-link-row.component";
import { v4 } from "uuid";
import { LayoutContext } from "../../layout/layout.context";

interface IAddFavoriteCustomLinkTabProps {
  customLinks: ICreateCustomLink[];
  setCustomLinks: (customLinks: ICreateCustomLink[]) => void;
}

export const AddFavoriteCustomLinkTab: React.FC<IAddFavoriteCustomLinkTabProps> = (props) => {
  const { customLinks, setCustomLinks } = props;
  const { t } = useTranslation();
  const { breakpointInfo } = useContext(LayoutContext);
  const getEmptyLink = useCallback(() => {
    return { link: "", title: "", tempId: v4() };
  }, []);
  const [currentCustomLink, setCurrentCustomLink] = useState<ICreateCustomLink>(getEmptyLink());

  const canAdd = useMemo(
    () => currentCustomLink.link.length > 0 && currentCustomLink.title.length > 0,
    [currentCustomLink],
  );

  const removeLink = useCallback(
    (link: ICreateCustomLink) => {
      const filtered = customLinks.filter((customLink) => customLink.tempId !== link.tempId);

      setCustomLinks(filtered);
    },
    [customLinks, setCustomLinks],
  );
  const addLink = useCallback(() => {
    setCustomLinks([...customLinks, currentCustomLink]);
    setCurrentCustomLink(getEmptyLink());
  }, [customLinks, setCustomLinks, currentCustomLink, getEmptyLink]);

  return (
    <Grid container spacing={4} sx={{ pt: 2, pb: 2 }} direction="column">
      <Grid item>
        <TextField
          fullWidth
          label={t("favorites.title")}
          value={currentCustomLink?.title}
          onChange={(event) => setCurrentCustomLink({ ...currentCustomLink, title: event.target.value })}
        />
      </Grid>
      <Grid item>
        <TextField
          fullWidth
          label={t("favorites.link")}
          value={currentCustomLink?.link}
          onChange={(event) => setCurrentCustomLink({ ...currentCustomLink, link: event.target.value })}
        />
      </Grid>
      <Grid item container direction="row" justifyContent="flex-end">
        <Button
          variant="contained"
          sx={{ backgroundColor: "primary.500" }}
          disabled={!canAdd}
          onClick={addLink}
          fullWidth={breakpointInfo.isMobile}
        >
          {t("general.add")}
        </Button>
      </Grid>
      <Grid item>
        <Divider />
      </Grid>
      {customLinks.map((link, index) => {
        return (
          <Grid item key={index}>
            <CreateFavoriteCustomLinkRow customLink={link} onClick={() => removeLink(link)} />
          </Grid>
        );
      })}
    </Grid>
  );
};
