import { Divider, Grid, Skeleton, Typography, useTheme } from "@mui/material";
import React, { useContext } from "react";
import { SearchInfocenter } from "../../api/muu-api/graphql/generated";
import { FilterNewsIcon } from "../../assets/icons/icons.component";
import { SEARCH_RESULT_ICON_HORIZONTAL_MARGIN_FACTOR, SEARCH_RESULT_SKELETON_WIDTH } from "../../theme/sizings.theme";
import { stripHtml } from "string-strip-html";
import { InfocenterLink } from "../link/infocenter-link.component";
import { LayoutContext } from "../layout/layout.context";

interface IInfoCenterSearchResultProps {
  infocenter: SearchInfocenter;
  loading: boolean;
  isLastItem: boolean;
}

export const InfocenterSearchResult: React.FC<IInfoCenterSearchResultProps> = (props) => {
  const { infocenter, loading } = props;
  const theme = useTheme();

  const { breakpoint } = useContext(LayoutContext);

  return (
    <Grid container direction="column">
      <InfocenterLink entry={infocenter} localizeLink={false}>
        <Grid
          container
          alignItems="center"
          sx={{
            p: 4,
            display: "flex",
          }}
        >
          <Grid
            item
            sx={{
              color: "accent1.main",
              pr: 4,
              ml: SEARCH_RESULT_ICON_HORIZONTAL_MARGIN_FACTOR[breakpoint],
              mr: SEARCH_RESULT_ICON_HORIZONTAL_MARGIN_FACTOR[breakpoint],
            }}
          >
            <FilterNewsIcon color="inherit" fontSize="medium" />
          </Grid>
          <Grid item xs>
            <Grid container direction="column">
              {!!infocenter && infocenter.title && (
                <Grid item>
                  <Typography variant="subtitle2" sx={{ textAlign: "start", wordBreak: "break-word" }}>
                    {stripHtml(infocenter.title).result}
                  </Typography>
                </Grid>
              )}
              {!infocenter && loading && (
                <Grid item>
                  <Skeleton
                    variant="text"
                    width={SEARCH_RESULT_SKELETON_WIDTH[breakpoint]}
                    sx={{ fontSize: theme.typography.body1 }}
                  />
                </Grid>
              )}

              {!!infocenter && infocenter.teaser && (
                <Grid item>
                  <Typography variant="body2" sx={{ color: "grey.500", textAlign: "start", wordBreak: "break-word" }}>
                    {stripHtml(infocenter.teaser).result}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Divider sx={{ backgroundColor: "grey.100" }} />
        </Grid>
      </InfocenterLink>
    </Grid>
  );
};
