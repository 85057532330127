import { Grid, Typography } from "@mui/material";
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { PhoneOrMailTypography } from "../../../components/typography/phone-or-mail-typography.component";

interface IEventDetailInfoTableProps {
  organizer?: string | null;
  location?: string | null;
  contactPerson?: string | null;
  email?: string | null;
  dfpPoints?: number | null;
}

export const EventDetailInfoTable: React.FC<IEventDetailInfoTableProps> = (props) => {
  const { organizer, location, contactPerson, email, dfpPoints } = props;
  const { t } = useTranslation();

  const getDefaultTypography = useCallback((text: string) => <Typography variant="body1">{text}</Typography>, []);

  const rows = useMemo(() => {
    const info = [];
    if (organizer) {
      info.push({
        heading: t("events.organizer"),
        data: getDefaultTypography(organizer),
      });
    }
    if (location) {
      info.push({
        heading: t("events.location"),
        data: getDefaultTypography(location),
      });
    }
    if (contactPerson) {
      const data = email ? (
        <Grid container>
          <Grid item>
            <Typography variant="body1" sx={{ pr: 1 }}>{`${contactPerson} |`}</Typography>
          </Grid>
          <Grid item>
            <PhoneOrMailTypography type="mail" value={email} />
          </Grid>
        </Grid>
      ) : (
        getDefaultTypography(contactPerson)
      );
      info.push({
        heading: t("events.contact_person"),
        data,
      });
    }
    if (dfpPoints) {
      info.push({
        heading: t("events.dfp_points"),
        data: getDefaultTypography(dfpPoints.toString()),
      });
    }
    return info;
  }, [organizer, location, contactPerson, dfpPoints, email, t, getDefaultTypography]);

  return (
    <Grid container sx={{ p: 0 }}>
      {rows.map((row, index) => (
        <Grid key={`row-${row.heading}-${index}`} item xs={12} container>
          <Grid item xs={12} md="auto">
            <Typography variant="body1" fontWeight={700} sx={{ minWidth: 152 }}>
              {row.heading}
            </Typography>
          </Grid>
          <Grid item xs={12} md>
            {row.data}
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
};
