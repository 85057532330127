import { Box, Grid, Paper, Skeleton } from "@mui/material";
import React, { ReactInstance, useContext, useMemo, useRef } from "react";
import { LayoutContext } from "../../components/layout/layout.context";
import {
  PAGE_PAPER_PADDING_HORIZONTAL_FACTOR,
  PAGE_PAPER_PADDING_VERTICAL_FACTOR,
  TOPIC_PAGE_DESCRIPTION_MAX_HEIGHT,
  TOPIC_PAGE_TITLE_MARGIN_FACTOR,
} from "../../theme/sizings.theme";
import { TopicPageContext } from "./context/topic-page.context";
import { TopicDynamicContent } from "./topic-dynamic-content.component";
import { useFavorite } from "../../hooks/use-favorite.hook";
import { FavoriteSource, FavoriteType } from "../../api/muu-api/graphql/generated";
import { LoadingText } from "../../components/loading/loading-text.component";
import { LastEditedTypography } from "../../components/typography/last-edited-typography.component";
import { ContentTitle } from "../../components/content/content.title";

const VALID_CONTENT_TYPES = [
  "TopicTitleContent",
  "TopicTeaserContent",
  "TopicDescriptionContent",
  "TopicAccordionContent",
  "TopicLinksContent",
  "TopicContactBoxContent",
  "TopicTeamContent",
];

interface ITopicPageContentProps {}

export const TopicPageContent: React.FC<ITopicPageContentProps> = (props) => {
  const { topicPage, topicPageLoading } = useContext(TopicPageContext);
  const { breakpoint } = useContext(LayoutContext);
  const pageContentRef = useRef<ReactInstance>(null);
  const { removeFavorite, createFavorite } = useFavorite();

  const favoriteInputs = useMemo(() => {
    if (!topicPage) return undefined;

    const onCreateFavorite = () => {
      createFavorite({
        entryId: topicPage.id,
        description: topicPage?.heading ?? "",
        teaser: undefined,
        type: FavoriteType.TOPIC,
        source: FavoriteSource.STRAPI,
      });
    };

    return {
      entryId: topicPage.id,
      type: FavoriteType.TOPIC,
      source: FavoriteSource.STRAPI,
      onRemoveFavorite: removeFavorite,
      onCreateFavorite,
    };
  }, [createFavorite, topicPage, removeFavorite]);

  const date = useMemo(() => {
    return topicPage?.updatedAt ?? topicPage?.publishedAt;
  }, [topicPage]);

  const pageContent = useMemo(() => {
    return topicPage?.content.filter((content) => VALID_CONTENT_TYPES.includes(content.__typename)) ?? [];
  }, [topicPage]);

  if (!topicPage) return null;

  return (
    <Paper
      id="topic-page-content"
      sx={{
        borderRadius: 4,
        p: 0,
        flex: 1,
        display: "flex",
        overflow: "hidden",
        flexDirection: "column",
        position: "relative",
        width: "100%",
      }}
    >
      <Box ref={pageContentRef}>
        <Grid
          container
          sx={{
            pt: PAGE_PAPER_PADDING_VERTICAL_FACTOR[breakpoint],
            pb: PAGE_PAPER_PADDING_VERTICAL_FACTOR[breakpoint],
            pl: PAGE_PAPER_PADDING_HORIZONTAL_FACTOR[breakpoint],
            pr: PAGE_PAPER_PADDING_HORIZONTAL_FACTOR[breakpoint],
          }}
        >
          <Grid item xs={12}>
            <ContentTitle
              loading={topicPageLoading}
              title={topicPage.title}
              pageContentRef={pageContentRef}
              favoriteInputs={favoriteInputs}
              sx={{ mb: TOPIC_PAGE_TITLE_MARGIN_FACTOR[breakpoint] }}
            />
          </Grid>
          <Grid item xs={12} container>
            {topicPageLoading && (
              <>
                <Grid
                  item
                  sx={{
                    mb: 6,
                  }}
                >
                  <LoadingText variant="body1" lines={4} />
                </Grid>
                <Grid
                  item
                  sx={{
                    mb: 6,
                  }}
                >
                  <Skeleton variant="rounded" height={TOPIC_PAGE_DESCRIPTION_MAX_HEIGHT[breakpoint]} />
                </Grid>
                <Grid>
                  <LoadingText variant="body1" lines={15} />
                </Grid>
              </>
            )}
            {!topicPageLoading &&
              pageContent.map((content, index) => {
                if (content.__typename === "TopicContactBoxContent" && !content.isValid) return null;
                return (
                  <Grid
                    item
                    key={`${content.__typename}-${index}`}
                    sx={{
                      width: "100%",
                      mb: index < topicPage.content.length - 1 ? 10 : 0,
                    }}
                  >
                    <Grid item>
                      <TopicDynamicContent content={content} />
                    </Grid>
                  </Grid>
                );
              })}
            {!topicPageLoading && date && (
              <Grid item container justifyContent="flex-end">
                <LastEditedTypography date={date} />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};
