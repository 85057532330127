import { Divider, Grid, Typography } from "@mui/material";
import React from "react";
import { AttachIcon, CloseIcon } from "../../../assets/icons/icons.component";
import { ICreateCustomLink } from "../types/create-link.type";

interface ICreateFavoriteCustomLinkRowProps {
  customLink: ICreateCustomLink;
  onClick: VoidFunction;
}

export const CreateFavoriteCustomLinkRow: React.FC<ICreateFavoriteCustomLinkRowProps> = (props) => {
  const { customLink, onClick } = props;

  return (
    <>
      <Grid container alignItems="center" spacing={4} justifyContent="flex-start" sx={{ pt: 2, pb: 2 }}>
        <Grid item xs="auto" container direction={"row"} justifyContent={"center"}>
          <AttachIcon />
        </Grid>
        <Grid item xs>
          <Typography variant="body1">{customLink.title}</Typography>
        </Grid>
        <Grid item xs="auto" container direction={"row"} justifyContent={"center"}>
          <CloseIcon sx={{ color: "accent5.main", "&:hover": { cursor: "pointer" } }} onClick={onClick} />
        </Grid>
      </Grid>
      <Divider sx={{ pt: 4 }} />
    </>
  );
};
