import { Box, Grid, Skeleton, Stack, SxProps, Theme } from "@mui/material";
import React, { useContext, useMemo } from "react";
import { InfocenterCarouselArticle } from "../../../api/muu-api/graphql/generated";
import { WIDGET_HEIGHT } from "../../../theme/sizings.theme";
import { LayoutContext } from "../../layout/layout.context";
import { Carousel } from "./carousel.component";
import { DashboardWidgetsContext } from "../../../pages/dashboard/widget-section/dashboard-widgets.context";

interface ICarouselWidgetContentProps {
  carouselContent: InfocenterCarouselArticle[];
  loading: boolean;
}

export const CarouselWidgetContent: React.FC<ICarouselWidgetContentProps> = (props) => {
  const { carouselContent, loading } = props;
  const { breakpoint } = useContext(LayoutContext);
  const { isInEditMode: isEditingWidgets } = useContext(DashboardWidgetsContext);

  const containerStyle: SxProps<Theme> = useMemo(
    () => (isEditingWidgets ? { opacity: 0.2, pointerEvents: "none" } : {}),
    [isEditingWidgets],
  );

  return (
    <Grid
      id="carousel-widget-content"
      container
      direction="row"
      sx={{
        ...containerStyle,
      }}
    >
      <Box
        sx={{
          width: "100%",
        }}
      >
        <Stack direction="column">
          {loading && (
            <Skeleton
              variant="rectangular"
              width={"100%"}
              height={WIDGET_HEIGHT[breakpoint]}
              sx={{ borderRadius: 4 }}
            />
          )}
          {!loading && carouselContent.length > 0 && <Carousel slides={carouselContent} />}
        </Stack>
      </Box>
    </Grid>
  );
};
