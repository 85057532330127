import { WidgetType } from "../../../../api/muu-api/graphql/generated";
import { WidgetActionItemDictionary, WidgetTypeActionDictionary } from "../dictionaries/widget-action.dictionary";

export const getActionItemsByWidgetType = (widgetType: WidgetType) => {
  const actionByType = WidgetTypeActionDictionary[widgetType];
  if (!actionByType) return [];

  const items = [];
  for (const type of actionByType) {
    const item = WidgetActionItemDictionary[type];
    if (!item) continue;
    items.push(item);
  }

  return items;
};
