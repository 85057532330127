import { Typography, useTheme } from "@mui/material";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { NotificationState } from "../../api/muu-api/graphql/generated";
import { notificationStatusToColor } from "./helpers/notification-status-to-color.helper";

interface NotificationStatusBoxProps {
  status: NotificationState;
}

export const NotificationStatusBox: React.FC<NotificationStatusBoxProps> = (props) => {
  const { status } = props;
  const { t } = useTranslation();
  const theme = useTheme();

  const notificationStatusColor = useMemo(() => notificationStatusToColor(status), [status]);

  return (
    <Typography
      variant="overline"
      sx={{
        color: "primary.contrastText",
        p: theme.spacing(1, 2),
        borderRadius: 1,
        backgroundColor: notificationStatusColor,
        textTransform: "none",
      }}
    >
      {t(`notifications.status.${status}`)}
    </Typography>
  );
};
