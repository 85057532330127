import { Typography } from "@mui/material";
import React from "react";
import { ISearchFilter } from "./context/search.context";

interface ISearchFilterItemProps {
  item: ISearchFilter;
}

export const SearchFilterItem: React.FC<ISearchFilterItemProps> = (props) => {
  const { item } = props;

  return (
    <>
      <item.icon fontSize="medium" sx={{ pr: 2, pl: 1 }} />
      <Typography sx={{ flexGrow: 1 }} variant="body1">
        {item.title}
      </Typography>
    </>
  );
};
