import React from "react";
import { CustomSvgIcon, ICustomSvgIconProps } from "../../components/icon/custom-svg-icon.component";
import { ReactComponent as AttachSvg } from "../svg/attach_file.svg";
import { ReactComponent as AttendenceFeeSvg } from "../svg/attendence-fee.svg";
import { ReactComponent as AttendenceRegisterSvg } from "../svg/attendence-register.svg";
import { ReactComponent as AttendenceReservationSvg } from "../svg/attendence-reservation.svg";
import { ReactComponent as BookSvg } from "../svg/book.svg";
import { ReactComponent as BurgerSvg } from "../svg/burger-menu.svg";
import { ReactComponent as CategoryFilterSvg } from "../svg/category-filter.svg";
import { ReactComponent as ChangeColorSvg } from "../svg/change-color.svg";
import { ReactComponent as CheckSvg } from "../svg/check.svg";
import { ReactComponent as ChevronDownSvg } from "../svg/chevron-down.svg";
import { ReactComponent as ChevronLeftSvg } from "../svg/chevron-left.svg";
import { ReactComponent as ChevronRightSvg } from "../svg/chevron-right.svg";
import { ReactComponent as ClockSvg } from "../svg/clock.svg";
import { ReactComponent as CloseSvg } from "../svg/close.svg";
import { ReactComponent as CopySvg } from "../svg/copy.svg";
import { ReactComponent as DeeplinkSvg } from "../svg/deeplink.svg";
import { ReactComponent as DownloadSvg } from "../svg/download.svg";
import { ReactComponent as EditSvg } from "../svg/edit.svg";
import { ReactComponent as ExchangeSvg } from "../svg/exchange.svg";
import { ReactComponent as ExclamationSvg } from "../svg/exclamation.svg";
import { ReactComponent as FavoriteStarSvg } from "../svg/favorite_star.svg";
import { ReactComponent as FavoritesSvg } from "../svg/favorites.svg";
import { ReactComponent as FaxSvg } from "../svg/fax.svg";
import { ReactComponent as FilterAllSvg } from "../svg/filter-all.svg";
import { ReactComponent as FilterNotificationsSvg } from "../svg/filter-bulletins.svg";
import { ReactComponent as FilterCalendarSvg } from "../svg/filter-calendar.svg";
import { ReactComponent as FilterDocumentsSvg } from "../svg/filter-documents.svg";
import { ReactComponent as FilterEventsSvg } from "../svg/filter-events.svg";
import { ReactComponent as FilterNewsSvg } from "../svg/filter-news.svg";
import { ReactComponent as FilterOrganizationSvg } from "../svg/filter-organization.svg";
import { ReactComponent as FilterThemenSvg } from "../svg/filter-themen.svg";
import { ReactComponent as FilterUserSvg } from "../svg/filter-user.svg";
import { ReactComponent as FolderSvg } from "../svg/folder.svg";
import { ReactComponent as GiveSvg } from "../svg/give.svg";
import { ReactComponent as InfoFilledSvg } from "../svg/info-filled.svg";
import { ReactComponent as InfoSvg } from "../svg/info.svg";
import { ReactComponent as NotificationSvg } from "../svg/invoice.svg";
import { ReactComponent as LunchSvg } from "../svg/lunch.svg";
import { ReactComponent as MailSvg } from "../svg/mail.svg";
import { ReactComponent as MuuSvg } from "../svg/muu.svg";
import { ReactComponent as InfoCenterSvg } from "../svg/news.svg";
import { ReactComponent as NotificationAcceptedSvg } from "../svg/notification-accepted.svg";
import { ReactComponent as NotificationReadSvg } from "../svg/notification-read.svg";
import { ReactComponent as NotificationUnacceptedSvg } from "../svg/notification-unaccepted.svg";
import { ReactComponent as NotificationUnreadSvg } from "../svg/notification-unread.svg";
import { ReactComponent as NotificationsSvg } from "../svg/notifications.svg";
import { ReactComponent as OrganizationSvg } from "../svg/organization.svg";
import { ReactComponent as OrganizationalUnitSvg } from "../svg/organizational_unit.svg";
import { ReactComponent as PhoneSvg } from "../svg/phone.svg";
import { ReactComponent as PinnedSvg } from "../svg/pinned.svg";
import { ReactComponent as PlusSvg } from "../svg/plus.svg";
import { ReactComponent as PrintSvg } from "../svg/print.svg";
import { ReactComponent as SearchSvg } from "../svg/search.svg";
import { ReactComponent as SettingsSvg } from "../svg/settings.svg";
import { ReactComponent as StarSvg } from "../svg/star.svg";
import { ReactComponent as TramSvg } from "../svg/tram.svg";
import { ReactComponent as TrashSvg } from "../svg/trash.svg";
import { ReactComponent as UnorderedListNestedItemSvg } from "../svg/ul-inner.svg";
import { ReactComponent as UnorderedListItemSvg } from "../svg/ul-outer.svg";
import { ReactComponent as UserOutlinedSvg } from "../svg/user.svg";
import { ReactComponent as VeganSvg } from "../svg/vegan.svg";
import { ReactComponent as VeggieSvg } from "../svg/veggie.svg";
import { ReactComponent as WebsiteSvg } from "../svg/website.svg";
import { ReactComponent as WorldSvg } from "../svg/world.svg";
import { ReactComponent as ArrowLeftSvg } from "../svg/arrow-left.svg";
import { ReactComponent as ArrowRightSvg } from "../svg/arrow-right.svg";
import { ReactComponent as CalendarExportSvg } from "../svg/calendar-export.svg";
import { ReactComponent as WorkSpacesSvg } from "../svg/work-spaces.svg";
import { ReactComponent as MonitorSvg } from "../svg/monitor.svg";
import { ReactComponent as CarouselDotSvg } from "../svg/carousel-dot.svg";
import { ReactComponent as PdfSvg } from "../svg/pdf_download.svg";
import { ReactComponent as DoubleArrowDownSvg } from "../svg/double-arrows-down.svg";
import { ReactComponent as DoubleArrowUpSvg } from "../svg/double-arrows-up.svg";
import { ReactComponent as ContrastSvg } from "../svg/contrast.svg";
import { ReactComponent as PersonSvg } from "../svg/person.svg";
import { ReactComponent as PlaceOfWorkSvg } from "../svg/place_of_work.svg";

export interface ISvgIconBaseProps extends Omit<ICustomSvgIconProps, "component"> {}

export const ContrastIcon: React.FC<ISvgIconBaseProps> = (props) => {
  return <CustomSvgIcon {...props} component={ContrastSvg} />;
};

export const DoubleArrowsUpIcon: React.FC<ISvgIconBaseProps> = (props) => {
  return <CustomSvgIcon {...props} component={DoubleArrowUpSvg} />;
};

export const DoubleArrowsDownIcon: React.FC<ISvgIconBaseProps> = (props) => {
  return <CustomSvgIcon {...props} component={DoubleArrowDownSvg} />;
};

export const PdfIcon: React.FC<ISvgIconBaseProps> = (props) => {
  return <CustomSvgIcon {...props} component={PdfSvg} />;
};

export const CarouselDotIcon: React.FC<ISvgIconBaseProps> = (props) => {
  return <CustomSvgIcon {...props} component={CarouselDotSvg} />;
};

export const UserOutlinedIcon: React.FC<ISvgIconBaseProps> = (props) => {
  return <CustomSvgIcon {...props} component={UserOutlinedSvg} />;
};

export const TrashIcon: React.FC<ISvgIconBaseProps> = (props) => {
  return <CustomSvgIcon {...props} component={TrashSvg} />;
};

export const EditIcon: React.FC<ISvgIconBaseProps> = (props) => {
  return <CustomSvgIcon {...props} component={EditSvg} />;
};

export const GiveIcon: React.FC<ISvgIconBaseProps> = (props) => {
  return <CustomSvgIcon {...props} component={GiveSvg} />;
};

export const ExclamationIcon: React.FC<ISvgIconBaseProps> = (props) => {
  return <CustomSvgIcon {...props} component={ExclamationSvg} />;
};

export const InfoFilledIcon: React.FC<ISvgIconBaseProps> = (props) => {
  return <CustomSvgIcon {...props} component={InfoFilledSvg} />;
};

export const NotificationReadIcon: React.FC<ISvgIconBaseProps> = (props) => {
  return <CustomSvgIcon {...props} component={NotificationReadSvg} />;
};

export const NotificationUnreadIcon: React.FC<ISvgIconBaseProps> = (props) => {
  return <CustomSvgIcon {...props} component={NotificationUnreadSvg} />;
};

export const NotificationAcceptedIcon: React.FC<ISvgIconBaseProps> = (props) => {
  return <CustomSvgIcon {...props} component={NotificationAcceptedSvg} />;
};

export const NotificationUnacceptedIcon: React.FC<ISvgIconBaseProps> = (props) => {
  return <CustomSvgIcon {...props} component={NotificationUnacceptedSvg} />;
};

export const NotificationIcon: React.FC<ISvgIconBaseProps> = (props) => {
  return <CustomSvgIcon {...props} component={NotificationSvg} />;
};

export const AttachIcon: React.FC<ISvgIconBaseProps> = (props) => {
  return <CustomSvgIcon {...props} component={AttachSvg} />;
};

export const FavoriteStarIcon: React.FC<ISvgIconBaseProps> = (props) => {
  return <CustomSvgIcon {...props} component={FavoriteStarSvg} />;
};

export const ThinkTankIcon: React.FC<ISvgIconBaseProps> = (props) => {
  return <CustomSvgIcon {...props} component={ExchangeSvg} />;
};

export const BookIcon: React.FC<ISvgIconBaseProps> = (props) => {
  return <CustomSvgIcon {...props} component={BookSvg} />;
};

export const FolderIcon: React.FC<ISvgIconBaseProps> = (props) => {
  return <CustomSvgIcon {...props} component={FolderSvg} />;
};

export const FavoritesIcon: React.FC<ISvgIconBaseProps> = (props) => {
  return <CustomSvgIcon {...props} component={FavoritesSvg} />;
};

export const UnorderedListItemIcon: React.FC<ISvgIconBaseProps> = (props) => {
  return <CustomSvgIcon {...props} component={UnorderedListItemSvg} />;
};

export const UnorderedListNestedItemIcon: React.FC<ISvgIconBaseProps> = (props) => {
  return <CustomSvgIcon {...props} component={UnorderedListNestedItemSvg} />;
};

export const ChevronLeftIcon: React.FC<ISvgIconBaseProps> = (props) => {
  return <CustomSvgIcon {...props} component={ChevronLeftSvg} />;
};

export const ChangeColorIcon: React.FC<ISvgIconBaseProps> = (props) => {
  return <CustomSvgIcon {...props} component={ChangeColorSvg} />;
};

export const ChevronRightIcon: React.FC<ISvgIconBaseProps> = (props) => {
  return <CustomSvgIcon {...props} component={ChevronRightSvg} />;
};

export const ChevronDownIcon: React.FC<ISvgIconBaseProps> = (props) => {
  return <CustomSvgIcon {...props} component={ChevronDownSvg} />;
};

export const CategoryFilterIcon: React.FC<ISvgIconBaseProps> = (props) => {
  return <CustomSvgIcon {...props} component={CategoryFilterSvg} />;
};

export const BurgerIcon: React.FC<ISvgIconBaseProps> = (props) => {
  return <CustomSvgIcon {...props} component={BurgerSvg} />;
};

export const SearchIcon: React.FC<ISvgIconBaseProps> = (props) => {
  return <CustomSvgIcon {...props} component={SearchSvg} />;
};

export const NotificationsIcon: React.FC<ISvgIconBaseProps> = (props) => {
  return <CustomSvgIcon {...props} component={NotificationsSvg} />;
};

export const SettingsIcon: React.FC<ISvgIconBaseProps> = (props) => {
  return <CustomSvgIcon {...props} component={SettingsSvg} />;
};

export const TramIcon: React.FC<ISvgIconBaseProps> = (props) => {
  return <CustomSvgIcon {...props} component={TramSvg} />;
};

export const PinnedIcon: React.FC<ISvgIconBaseProps> = (props) => {
  return <CustomSvgIcon {...props} component={PinnedSvg} />;
};

export const CopyIcon: React.FC<ISvgIconBaseProps> = (props) => {
  return <CustomSvgIcon {...props} component={CopySvg} />;
};

export const InfoCenterIcon: React.FC<ISvgIconBaseProps> = (props) => {
  return <CustomSvgIcon {...props} component={InfoCenterSvg} />;
};

export const WorldIcon: React.FC<ISvgIconBaseProps> = (props) => {
  return <CustomSvgIcon {...props} component={WorldSvg} />;
};

export const OrganizationIcon: React.FC<ISvgIconBaseProps> = (props) => {
  return <CustomSvgIcon {...props} component={OrganizationSvg} />;
};

export const OrganizationalUnitIcon: React.FC<ISvgIconBaseProps> = (props) => {
  return <CustomSvgIcon {...props} component={OrganizationalUnitSvg} />;
};

export const FilterAllIcon: React.FC<ISvgIconBaseProps> = (props) => {
  return <CustomSvgIcon {...props} component={FilterAllSvg} />;
};

export const FilterUserIcon: React.FC<ISvgIconBaseProps> = (props) => {
  return <CustomSvgIcon {...props} component={FilterUserSvg} />;
};

export const FilterOrganizationIcon: React.FC<ISvgIconBaseProps> = (props) => {
  return <CustomSvgIcon {...props} component={FilterOrganizationSvg} />;
};

export const FilterNotificationsIcon: React.FC<ISvgIconBaseProps> = (props) => {
  return <CustomSvgIcon {...props} component={FilterNotificationsSvg} />;
};

export const FilterDocumentsIcon: React.FC<ISvgIconBaseProps> = (props) => {
  return <CustomSvgIcon {...props} component={FilterDocumentsSvg} />;
};

export const FilterEventsIcon: React.FC<ISvgIconBaseProps> = (props) => {
  return <CustomSvgIcon {...props} component={FilterEventsSvg} />;
};

export const FilterNewsIcon: React.FC<ISvgIconBaseProps> = (props) => {
  return <CustomSvgIcon {...props} component={FilterNewsSvg} />;
};

export const FilterTopicsIcon: React.FC<ISvgIconBaseProps> = (props) => {
  return <CustomSvgIcon {...props} component={FilterThemenSvg} />;
};

export const InfoIcon: React.FC<ISvgIconBaseProps> = (props) => {
  return <CustomSvgIcon {...props} component={InfoSvg} />;
};

export const ClockIcon: React.FC<ISvgIconBaseProps> = (props) => {
  return <CustomSvgIcon {...props} component={ClockSvg} />;
};

export const MailIcon: React.FC<ISvgIconBaseProps> = (props) => {
  return <CustomSvgIcon {...props} component={MailSvg} />;
};

export const PhoneIcon: React.FC<ISvgIconBaseProps> = (props) => {
  return <CustomSvgIcon {...props} component={PhoneSvg} />;
};

export const FaxIcon: React.FC<ISvgIconBaseProps> = (props) => {
  return <CustomSvgIcon {...props} component={FaxSvg} />;
};

export const FilterCalendarIcon: React.FC<ISvgIconBaseProps> = (props) => {
  return <CustomSvgIcon {...props} component={FilterCalendarSvg} />;
};

export const CloseIcon: React.FC<ISvgIconBaseProps> = (props) => {
  return <CustomSvgIcon {...props} component={CloseSvg} />;
};

export const PlusIcon: React.FC<ISvgIconBaseProps> = (props) => {
  return <CustomSvgIcon {...props} component={PlusSvg} />;
};

export const CheckIcon: React.FC<ISvgIconBaseProps> = (props) => {
  return <CustomSvgIcon {...props} component={CheckSvg} />;
};

export const StarIcon: React.FC<ISvgIconBaseProps> = (props) => {
  return <CustomSvgIcon {...props} component={StarSvg} />;
};

export const PrintIcon: React.FC<ISvgIconBaseProps> = (props) => {
  return <CustomSvgIcon {...props} component={PrintSvg} />;
};

export const DeeplinkIcon: React.FC<ISvgIconBaseProps> = (props) => {
  return <CustomSvgIcon {...props} component={DeeplinkSvg} />;
};

export const AttendenceFeeIcon: React.FC<ISvgIconBaseProps> = (props) => {
  return <CustomSvgIcon {...props} component={AttendenceFeeSvg} />;
};

export const AttendenceReservationIcon: React.FC<ISvgIconBaseProps> = (props) => {
  return <CustomSvgIcon {...props} component={AttendenceReservationSvg} />;
};

export const MuuIcon: React.FC<ISvgIconBaseProps> = (props) => {
  return <CustomSvgIcon {...props} component={MuuSvg} />;
};
export const AttendenceRegisterIcon: React.FC<ISvgIconBaseProps> = (props) => {
  return <CustomSvgIcon {...props} component={AttendenceRegisterSvg} />;
};

export const WebsiteIcon: React.FC<ISvgIconBaseProps> = (props) => {
  return <CustomSvgIcon {...props} component={WebsiteSvg} />;
};

export const DownloadIcon: React.FC<ISvgIconBaseProps> = (props) => {
  return <CustomSvgIcon {...props} component={DownloadSvg} />;
};

export const LunchIcon: React.FC<ISvgIconBaseProps> = (props) => {
  return <CustomSvgIcon {...props} component={LunchSvg} />;
};

export const VeggieIcon: React.FC<ISvgIconBaseProps> = (props) => {
  return <CustomSvgIcon {...props} component={VeggieSvg} />;
};

export const VeganIcon: React.FC<ISvgIconBaseProps> = (props) => {
  return <CustomSvgIcon {...props} component={VeganSvg} />;
};

export const ArrowLeftIcon: React.FC<ISvgIconBaseProps> = (props) => {
  return <CustomSvgIcon {...props} component={ArrowLeftSvg} />;
};

export const ArrowRightIcon: React.FC<ISvgIconBaseProps> = (props) => {
  return <CustomSvgIcon {...props} component={ArrowRightSvg} />;
};

export const CalendarExportIcon: React.FC<ISvgIconBaseProps> = (props) => {
  return <CustomSvgIcon {...props} component={CalendarExportSvg} />;
};

export const WorkSpacesIcon: React.FC<ISvgIconBaseProps> = (props) => {
  return <CustomSvgIcon {...props} component={WorkSpacesSvg} />;
};

export const MonitorIcon: React.FC<ISvgIconBaseProps> = (props) => {
  return <CustomSvgIcon {...props} component={MonitorSvg} />;
};

export const PersonIcon: React.FC<ISvgIconBaseProps> = (props) => {
  return <CustomSvgIcon {...props} component={PersonSvg} />;
};

export const PlaceOfWorkIcon: React.FC<ISvgIconBaseProps> = (props) => {
  return <CustomSvgIcon {...props} component={PlaceOfWorkSvg} />;
};
