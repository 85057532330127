import { Grid, Typography } from "@mui/material";
import React, { useMemo } from "react";
interface IContentSubtitleProps {
  subtitle: string | JSX.Element;
}

export const ContentSubtitle: React.FC<IContentSubtitleProps> = (props) => {
  const { subtitle } = props;

  const subTitleElement = useMemo(() => {
    if (typeof subtitle === "string") {
      return <Typography variant="h6">{subtitle}</Typography>;
    } else {
      return subtitle;
    }
  }, [subtitle]);

  return (
    <Grid container direction="row">
      <Grid item xs>
        {subTitleElement}
      </Grid>
    </Grid>
  );
};
