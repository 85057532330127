import { DragUpdate } from "react-beautiful-dnd";
import { IExtendedWidget, IWidgetOptions } from "../types/widget.type";

const queryAttr = "data-rbd-drag-handle-draggable-id";

export const getWidgetOptionsFromDragUpdate = (
  update: DragUpdate,
  widgetsInDestinationRow: IExtendedWidget[],
  spacing: number,
): IWidgetOptions | null => {
  if (!update.destination) {
    return null;
  }

  const draggedDOM = getDraggedDom(update.draggableId);

  const destinationDOM =
    widgetsInDestinationRow.length === 0
      ? getDraggedDom(update.destination.droppableId)
      : getDraggedDom(
          widgetsInDestinationRow.find((widget) => widget.row === Number(update.destination?.droppableId))?.id || "",
        );

  if (!draggedDOM || !destinationDOM) {
    return null;
  }

  let { clientHeight } = destinationDOM;

  const parentWidth = Number(draggedDOM.parentElement?.getBoundingClientRect().width);
  const width =
    (Number(parentWidth) - (widgetsInDestinationRow.length + 1) * spacing) / (widgetsInDestinationRow.length + 1);

  const destinationIndex = update.destination.index;
  const sourceIndex = update.source.index;

  // calcule x offset based on index in row
  const placeHolderX =
    parseFloat(window.getComputedStyle(draggedDOM.parentNode as Element).paddingLeft) +
    destinationIndex * width +
    (destinationIndex + 1) * spacing;

  // y offset of destination row
  const placeHolderY =
    (destinationDOM.parentNode as Element).getBoundingClientRect().y +
    parseFloat(window.getComputedStyle(destinationDOM.parentNode as Element).paddingTop) +
    window.scrollY;

  return {
    currentDropIndex: destinationIndex,
    sourceIndex,
    height: clientHeight,
    width,
    // add x offset of destination row
    placeHolderX:
      placeHolderX +
      (draggedDOM.parentNode as Element).getBoundingClientRect().x +
      parseFloat(window.getComputedStyle(draggedDOM.parentNode as Element).paddingLeft) +
      window.scrollX,
    placeHolderY,
  };
};

export const getPositionForWidgetId = (draggableId: string) => {
  const draggedDOM = getDraggedDom(draggableId);
  return { x: Number(draggedDOM?.getBoundingClientRect().x), y: Number(draggedDOM?.getBoundingClientRect().x) };
};

const getDraggedDom = (draggableId: string) => {
  const domQuery = `[${queryAttr}='${draggableId}']`;
  const draggedDOM = document.querySelector(domQuery);

  return draggedDOM;
};
