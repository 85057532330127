import { WidgetType } from "../../../../api/muu-api/graphql/generated";
import { WidgetMenuItemDictionary, WidgetTypeMenuDictionary } from "../dictionaries/widget-menu.dictionary";

export const getMenuItemsByWidgetType = (widgetType: WidgetType) => {
  const menuByType = WidgetTypeMenuDictionary[widgetType];
  if (!menuByType) return [];

  const items = [];
  for (const type of menuByType) {
    const item = WidgetMenuItemDictionary[type];
    if (!item) continue;
    items.push(item);
  }

  return items;
};
