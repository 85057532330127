import { Divider, Grid, Typography, useTheme } from "@mui/material";
import React, { useCallback, useContext, useMemo, useState } from "react";
import { stripHtml } from "string-strip-html";
import { Favorite, FavoriteType } from "../../../api/muu-api/graphql/generated";
import {
  AttachIcon,
  BookIcon,
  DeeplinkIcon,
  EditIcon,
  FavoriteStarIcon,
  FilterDocumentsIcon,
  FilterEventsIcon,
} from "../../../assets/icons/icons.component";
import { WIDGET_CONTENT_ROW_HEIGHT, WIDGET_TOTAL_ROW_HEIGHT } from "../../../theme/sizings.theme";
import { getFavoriteRedirectUrl } from "../../favorite/helper/favorite.helper";
import { isFavoriteStub } from "../../favorite/helper/is-favorite-stub.helper";
import { NavMenuContext } from "../../layout/nav/context/nav-menu.context";
import { LayoutContext } from "../../layout/layout.context";
import { CustomIconButton } from "../../icon/custom-icon-button.component";
import { FavoriteEditDialog } from "./favorite-edit-dialog.component";
import { CustomLink } from "../../link/custom-link.component";
import { useTranslation } from "react-i18next";

interface IFavoritePostRowProps {
  favorite: Favorite;
  onRemove: (favorite: Favorite) => Promise<void>;
}

export const FavoriteRow: React.FC<IFavoritePostRowProps> = (props) => {
  const { t } = useTranslation();
  const { favorite, onRemove } = props;
  const { pageOfInterestDictionary } = useContext(NavMenuContext);
  const { breakpoint } = useContext(LayoutContext);
  const theme = useTheme();

  const [showEditDialog, setShowEditDialog] = useState<boolean>(false);

  const icon = useMemo(() => {
    switch (favorite.type) {
      case FavoriteType.QUICK_LINK:
        return <DeeplinkIcon sx={{ color: "grey.800" }} />;
      case FavoriteType.CUSTOM_LINK:
        return <AttachIcon sx={{ color: "grey.800" }} />;
      case FavoriteType.LEGAL_REGISTER:
        return <FilterDocumentsIcon sx={{ color: "grey.800" }} />;
      case FavoriteType.EVENT:
        return <FilterEventsIcon sx={{ color: "grey.800" }} />;
      case FavoriteType.INFOCENTER:
      case FavoriteType.NOTIFICATION:
      case FavoriteType.TOPIC:
      case FavoriteType.THINK_TANK:
        return <BookIcon sx={{ color: "grey.800" }} />;
    }
  }, [favorite]);

  const handleRemoveClicked = useCallback(() => {
    if (isFavoriteStub(favorite)) return;
    onRemove(favorite);
  }, [favorite, onRemove]);

  const redirectUrl = useMemo(() => {
    const url = getFavoriteRedirectUrl(favorite, pageOfInterestDictionary);
    if (!url) return "#";

    return url;
  }, [favorite, pageOfInterestDictionary]);

  return (
    <>
      <FavoriteEditDialog linkToUpdate={favorite} open={showEditDialog} onClose={() => setShowEditDialog(false)} />
      <Grid
        id={`favorite-row-${favorite.id}`}
        container
        alignItems="center"
        justifyContent="flex-start"
        sx={{ pl: 4, pr: 4, flexWrap: "nowrap", height: WIDGET_TOTAL_ROW_HEIGHT[breakpoint] }}
      >
        <Grid item sx={{ height: theme.icon["medium"], width: theme.icon["medium"] }}>
          {icon}
        </Grid>

        <Grid item container xs direction="column" sx={{ pl: 4, pr: 4 }}>
          <CustomLink
            id={`favorite-row-link-${favorite.id}`}
            tabIndex={0}
            href={redirectUrl}
            underline={"none"}
            sx={{ font: "inherhit", color: "inherit" }}
          >
            <Grid
              item
              container
              alignItems="center"
              sx={{
                height: WIDGET_CONTENT_ROW_HEIGHT[breakpoint],
              }}
            >
              <Typography
                variant="body2"
                sx={{
                  fontWeight: "bold",
                  overflow: "hidden",
                  display: "-webkit-box",
                  WebkitLineClamp: 1,
                  WebkitBoxOrient: "vertical",
                  wordBreak: "break-word",
                }}
              >
                {stripHtml(favorite.description).result}
              </Typography>
            </Grid>

            {favorite.teaser && (
              <Grid
                item
                container
                alignItems="center"
                sx={{
                  height: WIDGET_CONTENT_ROW_HEIGHT[breakpoint],
                }}
              >
                <Typography
                  variant="body2"
                  sx={{
                    color: "grey.500",
                    overflow: "hidden",
                    display: "-webkit-box",
                    WebkitLineClamp: 1,
                    WebkitBoxOrient: "vertical",
                    wordBreak: "break-word",
                  }}
                >
                  {stripHtml(favorite.teaser).result}
                </Typography>
              </Grid>
            )}
          </CustomLink>
        </Grid>

        <Grid item container justifyContent={"flex-end"} sx={{ width: "fit-content" }}>
          {favorite.type === FavoriteType.CUSTOM_LINK && (
            <CustomIconButton
              aria-label="Edit Favorite"
              variant="contained"
              sx={{ boxShadow: "none", p: 1 }}
              onClick={() => setShowEditDialog(true)}
            >
              <EditIcon sx={{ color: `grey.800`, cursor: "pointer" }} />
            </CustomIconButton>
          )}
          <CustomIconButton
            aria-label="Remove Favorite"
            variant="contained"
            sx={{ boxShadow: "none", p: 1 }}
            onClick={handleRemoveClicked}
            title={t("dashboard.tooltip.favorite.remove")}
          >
            <FavoriteStarIcon sx={{ color: `accent3.main`, cursor: "pointer" }} />
          </CustomIconButton>
        </Grid>
      </Grid>
      <Divider />
    </>
  );
};
