import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { IconButton, PaginationRenderItemParams, Typography, useTheme } from "@mui/material";
import React, { useMemo } from "react";

export const PaginationItem: React.FC<PaginationRenderItemParams> = (props) => {
  const theme = useTheme();

  const { color, backgroundColor, backgroundHoverColor, cursor, label, disabled } = useMemo(() => {
    if (props.type === "end-ellipsis" || props.type === "start-ellipsis") {
      return {
        disabled: true,
        label: "...",
        color: "text.main",
        backgroundColor: undefined,
        backgroundHoverColor: undefined,
        cursor: "default",
      };
    }

    if (props.selected) {
      return {
        disabled: false,
        label: props.page,
        color: "primaryDark.main",
        backgroundColor: "primary.50",
        backgroundHoverColor: undefined,
        cursor: "pointer",
      };
    }

    if (props.disabled) {
      return {
        disabled: true,
        label: props.page,
        color: "grey.300",
        backgroundColor: undefined,
        backgroundHoverColor: undefined,
      };
    }

    return {
      disabled: false,
      label: props.page,
      color: "text.main",
      backgroundColor: undefined,
      backgroundHoverColor: "grey.100",
      cursor: "pointer",
    };
  }, [props.page, props.disabled, props.selected]);

  return (
    <IconButton
      aria-label={label?.toString()}
      disableRipple={disabled}
      onClick={disabled ? undefined : props.onClick}
      sx={{
        backgroundColor,
        color,
        width: theme.spacing(8),
        height: theme.spacing(8),
        ml: 2,
        mr: 2,
        borderRadius: 2,
        cursor,
        "&:hover": {
          backgroundColor: backgroundHoverColor,
        },
      }}
    >
      {props.type === "previous" && <ChevronLeft sx={{ pt: 0.5 }} />}
      {(props.type === "page" || props.type === "end-ellipsis" || props.type === "start-ellipsis") && (
        <Typography>{label}</Typography>
      )}
      {props.type === "next" && <ChevronRight sx={{ pt: 0.5 }} />}
    </IconButton>
  );
};
