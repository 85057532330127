import { Grid, useTheme } from "@mui/material";
import React, { useContext } from "react";
import { PageHeaderContext } from "./page-header.context";
import { Breadcrumb } from "./breadcrumb.component";
import { ChevronLeftIcon } from "../../../assets/icons/icons.component";
import { CustomIconButton } from "../../icon/custom-icon-button.component";

interface IPageHeaderBreadcrumbsMobileProps {}

export const PageHeaderBreadcrumbsMobile: React.FC<IPageHeaderBreadcrumbsMobileProps> = (props) => {
  const { shouldRenderChips, lastClickableBreadcrumb, onClickMenuItem, getMenuItemSx } = useContext(PageHeaderContext);
  const theme = useTheme();

  return (
    <Grid container item xs={12} sx={{ mb: shouldRenderChips ? 6 : 0 }} spacing={4} alignItems="center">
      <Grid item>
        <CustomIconButton size="small" onClick={() => onClickMenuItem(lastClickableBreadcrumb!)} aria-label="Back">
          <ChevronLeftIcon sx={{ width: theme.icon.small, height: theme.icon.small }} />
        </CustomIconButton>
      </Grid>
      <Grid item>
        <Breadcrumb
          breadcrumb={lastClickableBreadcrumb!}
          onClick={() => onClickMenuItem(lastClickableBreadcrumb!)}
          getMenuItemSx={getMenuItemSx}
        />
      </Grid>
    </Grid>
  );
};
