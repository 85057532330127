import { Typography } from "@mui/material";
import { Element, HTMLReactParserOptions, domToReact } from "html-react-parser";
import { Breakpoint, EDITOR_CONTENT_LARGE_SPACING } from "../../../theme/sizings.theme";
import { cssStringToCssProps } from "../helpers/editor-content.helper";
import { mapToFigure } from "./editor-content-figure.mapper";

const getImageNode = (node: Element, styles: string): { image: Element | undefined; styles: string } => {
  for (const child of node.children) {
    if ((child as Element)?.attribs?.style) {
      styles = `${styles}${(child as Element)?.attribs?.style}`;
    }
    if ((child as Element).name === "img") {
      return { image: child as Element, styles };
    }

    if ((child as Element).children) {
      return getImageNode(child as Element, styles);
    }
  }
  return { image: undefined, styles };
};

export const mapToParagraph = (node: Element, options: HTMLReactParserOptions, breakpoint: Breakpoint) => {
  const { image, styles } = getImageNode(node, "");

  if (!!image && !node.attribs?.class?.includes("image-style-side")) {
    // Only add styles if image-style-side is provided
    if (node.attribs?.class?.includes("image-style-side")) {
      return mapToFigure(node, options, styles);
    }
    return mapToFigure(node, options);
  }

  const cssProps = cssStringToCssProps(node.attribs.style);

  return (
    <Typography style={cssProps} sx={{ mb: `${EDITOR_CONTENT_LARGE_SPACING[breakpoint]}px` }}>
      {domToReact(node.children, options)}
    </Typography>
  );
};
