import { Box, SwipeableDrawer } from "@mui/material";
import { useContext } from "react";
import { NAV_WIDTH, SMALL_NAV_WIDTH } from "../../../theme/sizings.theme";
import { LayoutContext } from "../layout.context";
import { NavMenuContext } from "./context/nav-menu.context";
import { NavHandle } from "./nav-handle.component";
import { NavHeader } from "./nav-header.component";
import { NavMenu } from "./nav-menu/nav-menu.component";

export const Nav: React.FC = () => {
  const { sidebarOpen: isOpen, setSidebarOpen, breakpoint } = useContext(LayoutContext);
  const { menusWithPortalRoutes } = useContext(NavMenuContext);

  return (
    <Box sx={{ flexShrink: { xs: 0 }, width: isOpen ? NAV_WIDTH[breakpoint] : SMALL_NAV_WIDTH[breakpoint] }}>
      <NavHandle />
      <SwipeableDrawer
        aria-label="sidebar-drawer"
        role="region"
        onClose={() => setSidebarOpen(false)}
        onOpen={() => setSidebarOpen(true)}
        open
        variant="permanent"
        PaperProps={{
          sx: {
            width: isOpen ? NAV_WIDTH[breakpoint] : SMALL_NAV_WIDTH[breakpoint],
            bgcolor: "background.default",
            borderRightStyle: "solid",
          },
        }}
      >
        <NavHeader />
        <Box component="nav" aria-label="Sidebar Navigation">
          {menusWithPortalRoutes.map((menu) => (
            <NavMenu key={menu.id} menu={menu} />
          ))}
        </Box>
      </SwipeableDrawer>
    </Box>
  );
};
