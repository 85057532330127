import { Box, Skeleton, Typography, useTheme } from "@mui/material";
import React, { useContext } from "react";
import { Person } from "../../api/muu-api/graphql/generated";
import { LayoutContext } from "../layout/layout.context";

interface IPersonDetailPersonHeaderProps {
  person?: Person;
  loading: boolean;
  imageSize: number;
}

export const PersonDetailPersonHeader: React.FC<IPersonDetailPersonHeaderProps> = (props) => {
  const { person, loading, imageSize } = props;
  const { breakpointInfo } = useContext(LayoutContext);

  const theme = useTheme();
  return (
    <Box
      sx={{
        backgroundColor: "grey.25",
        p: 6,
        position: "relative",
      }}
    >
      {!person && loading && (
        <>
          <Skeleton variant="text" width={370} sx={{ fontSize: theme.typography.h3 }} />
          <Skeleton variant="text" width={150} sx={{ fontSize: theme.typography.body1 }} />
        </>
      )}
      {person && (
        <>
          <Typography variant="h4" color="primaryDark.main" sx={{ fontWeight: "normal" }}>
            {person?.prefixTitle}
          </Typography>
          <Box sx={{ display: "flex", alignContent: "center" }}>
            <Typography variant="h4" color="primaryDark.main">
              {person?.firstName} {person?.lastName}
            </Typography>
            <Typography variant="h4" color="primaryDark.main" sx={{ fontWeight: "normal" }}>
              {person?.suffixTitle && `, ${person?.suffixTitle}`}
            </Typography>
          </Box>
        </>
      )}

      <Box
        sx={{
          position: breakpointInfo.isMobile ? "relative" : "absolute",
          top: breakpointInfo.isMobile ? theme.spacing(2) : theme.spacing(6),
          right: breakpointInfo.isMobile ? 0 : theme.spacing(6),
          alignItems: breakpointInfo.isMobile ? "left" : "none",
          overflow: "hidden",
          borderRadius: 3,
          display: breakpointInfo.isMobile ? "block" : "flex",
          backgroundColor: loading ? "grey.25" : undefined,
          width: imageSize,
          height: person?.avatar || loading ? imageSize : "auto",
        }}
      >
        {person?.avatar && (
          <img
            src={person?.avatar}
            width={imageSize}
            height={imageSize}
            style={{
              objectFit: breakpointInfo.isMobile ? "cover" : "cover",
              flex: 1,
            }}
            alt={person.firstName}
          />
        )}
        {!person && loading && (
          <Skeleton variant="rectangular" sx={{ width: imageSize, height: imageSize, borderRadius: 3 }} />
        )}
      </Box>
    </Box>
  );
};
