import { Box, Button, Stack, Typography } from "@mui/material";
import React, { useCallback, useContext, useMemo } from "react";
import {
  INFOCENTER_CAROUSEL_BUTTON_HEIGHT,
  INFOCENTER_CAROUSEL_BUTTON_WIDTH,
  INFOCENTER_CAROUSEL_HEIGHT,
  INFOCENTER_CAROUSEL_WIDTH,
} from "../../../theme/sizings.theme";
import { CarouselSlideIndicator } from "../../widgets/carousel/carousel-slide-indicator.component";
import { getAbsoluteRoutingPath } from "../../../utils/get-absolute-routing-path.util";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { LayoutContext } from "../../layout/layout.context";
import { NavMenuContext } from "../../layout/nav/context/nav-menu.context";
import { DETAIL_PATH } from "../../../router/routes.const";
import { encodeIdBase64 } from "../../../utils/base64.util";
import { InfocenterCarouselArticle, InfocenterType } from "../../../api/muu-api/graphql/generated";
import { parseRelativeLink } from "../../../app/helpers/relative-link.helper";
import { DashboardWidgetsContext } from "../../../pages/dashboard/widget-section/dashboard-widgets.context";

const SLIDE_MIN_AMOUNT = 2;

interface IInfocenterCarouselSlideInfoProps {
  carouselArticle: InfocenterCarouselArticle;
  setSlideIndicatorSlide: (index: number) => void;
  index: number;
  slideIndices: number[];
}

export const InfocenterCarouselSlideInfo: React.FC<IInfocenterCarouselSlideInfoProps> = (props) => {
  const { carouselArticle, setSlideIndicatorSlide, index, slideIndices } = props;

  const { t } = useTranslation();
  const navigate = useNavigate();
  const { breakpoint, breakpointInfo } = useContext(LayoutContext);
  const { pageOfInterestDictionary } = useContext(NavMenuContext);
  const { isInEditMode } = useContext(DashboardWidgetsContext);

  const infocenterDetailRoute = useMemo(
    () => `${pageOfInterestDictionary.INFOCENTER.path}/${DETAIL_PATH}`,
    [pageOfInterestDictionary],
  );

  const handleOnClick = useCallback(() => {
    navigate(
      `${getAbsoluteRoutingPath(infocenterDetailRoute)}/?id=${encodeIdBase64(carouselArticle.id, {
        type: InfocenterType.STRAPI,
        localize: true,
      })}`,
    );
  }, [navigate, carouselArticle, infocenterDetailRoute]);

  const buttonProps = useMemo(() => {
    return carouselArticle.link
      ? { target: "_blank", href: carouselArticle.link ? parseRelativeLink(carouselArticle.link).link : "" }
      : { onClick: handleOnClick };
  }, [carouselArticle.link, handleOnClick]);

  return (
    <Box
      aria-label="Infocenter Carousel Slide Info"
      role="contentinfo"
      sx={{
        color: "overlay.contrastText",
        backgroundColor: "overlay.main",
        borderTopRightRadius: !breakpointInfo.isMobile ? 16 : 0,
        borderTopLeftRadius: !breakpointInfo.isMobile ? 16 : 0,
        borderBottomLeftRadius: 16,
        borderBottomRightRadius: 16,
        position: "absolute",
        width: !breakpointInfo.isMobile ? INFOCENTER_CAROUSEL_WIDTH.DESKTOP : undefined,
        height: INFOCENTER_CAROUSEL_HEIGHT[breakpoint],
        bottom: 0,
        left: 0,
        right: breakpointInfo.isMobile ? 0 : undefined,
      }}
    >
      <Typography
        id="carousel-slide-title"
        variant={"h5"}
        sx={{
          display: "-webkit-box",
          WebkitLineClamp: 2,
          WebkitBoxOrient: "vertical",
          overflow: "hidden",
          pl: 6,
          pt: 6,
          pr: 6,
        }}
      >
        {carouselArticle.title}
      </Typography>
      <Typography
        id="carousel-slide-teaser"
        variant={"body2"}
        sx={{
          display: "-webkit-box",
          WebkitLineClamp: 3,
          WebkitBoxOrient: "vertical",
          overflow: "hidden",
          pl: 6,
          pt: 0,
          pr: 6,
        }}
      >
        {carouselArticle.teaser}
      </Typography>
      <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
        sx={{
          position: "absolute",
          bottom: 24,
          left: 24,
          right: 24,
        }}
      >
        <Button
          id="carousel-read-more"
          {...buttonProps}
          variant="contained"
          disabled={isInEditMode}
          fullWidth={!breakpointInfo.isMobile}
          sx={{
            backgroundColor: "primary.contrastText",
            color: "text.primary",
            width: INFOCENTER_CAROUSEL_BUTTON_WIDTH[breakpoint],
            height: INFOCENTER_CAROUSEL_BUTTON_HEIGHT[breakpoint],
            zIndex: 1001,
            boxShadow: "none",
            "&:hover": {
              boxShadow: "none",
              backgroundColor: "primary.contrastText",
              color: "text.primary",
              opacity: 0.8,
            },
            p: 0,
          }}
        >
          <Typography variant="body2" sx={{ fontWeight: 700 }}>
            {t("dashboard.read_more")}
          </Typography>
        </Button>

        {slideIndices.length >= SLIDE_MIN_AMOUNT && (
          <CarouselSlideIndicator slides={slideIndices} setSlide={setSlideIndicatorSlide} activeSlide={index} />
        )}
      </Stack>
    </Box>
  );
};
