import React, { createContext, PropsWithChildren, useState } from "react";
import { Language, useRegisterCookieQuery, UserResult, useUserDetailsQuery } from "../../api/muu-api/graphql/generated";
import { getCurrentLanguage } from "../../translations/i18n";

interface IUserContext {
  currentUser: UserResult | undefined;
  language: Language;
  setLanguage: (language: Language) => void;
}

export const UserContext = createContext<IUserContext>({} as IUserContext);

interface IUserProviderProps extends PropsWithChildren {}

export const UserContextProvider: React.FC<IUserProviderProps> = (props) => {
  const { data: userDetails } = useUserDetailsQuery();
  useRegisterCookieQuery({
    skip: !userDetails,
  });
  const [language, setLanguage] = useState<Language>(getCurrentLanguage() as Language);

  return (
    <UserContext.Provider
      value={{
        language,
        setLanguage,
        currentUser: userDetails?.userDetails,
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
};
