import React, { useContext, useMemo } from "react";
import { IMenuItemWithPortalRoute } from "../nav/context/nav-menu.context";
import { Box, Link, SxProps } from "@mui/material";
import { LayoutContext } from "../layout.context";

interface IBreadcrumbProps {
  breadcrumb: IMenuItemWithPortalRoute;
  onClick: () => void;
  getMenuItemSx: (menuItem: IMenuItemWithPortalRoute) => SxProps;
}

export const Breadcrumb: React.FC<IBreadcrumbProps> = (props) => {
  const { breakpointInfo } = useContext(LayoutContext);
  const { breadcrumb, onClick, getMenuItemSx } = props;

  const title = useMemo(() => {
    if (breakpointInfo.isMobile) {
      return breadcrumb.title;
    }
    if (breadcrumb.title.length > 13) {
      return breadcrumb.title.slice(0, 13) + "...";
    }
    return breadcrumb.title;
  }, [breadcrumb, breakpointInfo]);

  return (
    <Box sx={getMenuItemSx(breadcrumb)} title={breadcrumb.title}>
      <Link
        component="button"
        onClick={onClick}
        variant="caption"
        sx={{
          color: "text.primary",
          "&:hover": {
            textDecoration: "underline",
          },
        }}
      >
        {title}
      </Link>
    </Box>
  );
};
