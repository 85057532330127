import React, { useContext } from "react";
import { Button, ButtonProps, Typography } from "@mui/material";
import { LayoutContext } from "../../layout/layout.context";

interface IFilerButtonProps extends ButtonProps {
  filled?: boolean;
  fontWeight?: number;
}

export const FilterButton: React.FC<IFilerButtonProps> = (props) => {
  const { title, id, onClick, children, filled, fontWeight, ...rest } = props;

  const { breakpointInfo } = useContext(LayoutContext);

  return (
    <Button
      title={title}
      aria-describedby={id}
      onClick={onClick}
      variant="text"
      sx={{
        backgroundColor: filled ? "primary.50" : "grey.100",
        color: filled ? "primaryDark.main" : "grey.500",
        borderRadius: 2,
        pr: 4,
        pl: 4,
        pt: 2,
        pb: 2,
        textTransform: "none",
        width: breakpointInfo.isMobile ? "100%" : "auto",
      }}
      {...rest}
    >
      {children && (
        <Typography variant="body2" fontWeight={fontWeight ?? 700}>
          {children}
        </Typography>
      )}
    </Button>
  );
};
