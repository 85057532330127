import { Typography } from "@mui/material";
import React, { useContext } from "react";
import { formatMillisToLocaleDateWithTime } from "../../utils/date.util";
import { useTranslation } from "react-i18next";
import { ThemeContext } from "../layout/theme.context";

interface ILastEditedTypographyProps {
  date: number;
}

export const LastEditedTypography: React.FC<ILastEditedTypographyProps> = (props) => {
  const { date } = props;
  const { t, i18n } = useTranslation();
  const { isContrastThemeActive } = useContext(ThemeContext);

  return (
    <Typography
      color={isContrastThemeActive ? "text.primary" : "grey"}
      sx={{ fontStyle: "italic", fontSize: 13, pt: 10 }}
    >
      {t("general.last_edit_at", { date: formatMillisToLocaleDateWithTime(date, i18n.language) })}
    </Typography>
  );
};
