import { Box, Card, CardActionArea, CardMedia, Grid, Typography } from "@mui/material";
import parse from "html-react-parser";
import React, { useCallback, useContext, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SearchInfocenter } from "../../../api/muu-api/graphql/generated";
import { parseRelativeLink } from "../../../app/helpers/relative-link.helper";
import { INFOCENTER_CARD_HEIGHT } from "../../../theme/sizings.theme";
import { formatShortDate } from "../../../utils/date.util";
import { LayoutContext } from "../../layout/layout.context";
import { NavMenuContext } from "../../layout/nav/context/nav-menu.context";
import { getInfocenterDetailUrl } from "../helper/infocenter.helper";
import { useTracking } from "../../../app/context/tracking/tracking.context";

interface IInfocenterProminentCardProps {
  infocenter: SearchInfocenter;
}

export const InfocenterProminentCard: React.FC<IInfocenterProminentCardProps> = (props) => {
  const { infocenter } = props;
  const navigate = useNavigate();
  const { breakpoint } = useContext(LayoutContext);
  const { pageOfInterestDictionary } = useContext(NavMenuContext);
  const [imageLoadingError, setImageLoadingError] = useState(false);
  const { trackEvent } = useTracking();

  const navigateToDetails = useCallback(() => {
    trackEvent({
      event: "detail_view",
      action: "redirect",
      category: "page_visit",
    });
    navigate(getInfocenterDetailUrl(infocenter.id, infocenter.type, pageOfInterestDictionary));
  }, [navigate, infocenter, pageOfInterestDictionary, trackEvent]);

  const buttonProps = useMemo(() => {
    return infocenter.link
      ? { target: "_blank", href: infocenter.link ? parseRelativeLink(infocenter.link).link : "" }
      : { onClick: navigateToDetails };
  }, [infocenter.link, navigateToDetails]);

  return (
    <Card
      sx={{
        cursor: "pointer",
        width: "100%",
        height: INFOCENTER_CARD_HEIGHT[breakpoint],
        backgroundColor: !infocenter.media?.mediaUrl || imageLoadingError ? "primary.main" : undefined,
        position: "relative",
        borderRadius: 4,
      }}
    >
      <CardActionArea {...buttonProps}>
        <Box sx={{ display: "flex" }}>
          <CardMedia
            component="img"
            height={INFOCENTER_CARD_HEIGHT[breakpoint]}
            image={infocenter.media?.mediaUrl ?? ""}
            onError={() => setImageLoadingError(true)}
            alt={infocenter.media?.mediaAlt ?? infocenter.media?.mediaTitle ?? ""}
            title={infocenter.media?.mediaTitle ?? ""}
            sx={{ backgroundColor: "primaryDark.main", objectFit: "cover" }}
          />
          <Box
            sx={{
              position: "absolute",
              bottom: 0,
              left: 0,
              width: "100%",
              color: "white",
              backgroundColor: "rgba(0, 0, 0, 0.4)",
              overflow: "hidden",
            }}
          >
            <Grid container sx={{ overflow: "hidden", pr: 20, pl: 20, pt: 7, pb: 9 }}>
              <Grid container item xs={12} direction={"row"}>
                <Grid item xs sx={{ overflow: "hidden", pr: 6 }}>
                  <Typography
                    variant="h4"
                    color="inherit"
                    sx={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}
                  >
                    {parse(infocenter.title ?? "")}
                  </Typography>
                </Grid>
                <Grid item container xs={"auto"} sx={{ pt: 1 }}>
                  <Grid item xs sx={{ flexGrow: 1 }}>
                    <Typography variant="overline" color="inherit">
                      {formatShortDate(infocenter.createdAt)}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} sx={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
                <Typography
                  variant="h6"
                  sx={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}
                  color="inherit"
                >
                  {parse(infocenter.teaser ?? "")}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </CardActionArea>
    </Card>
  );
};
