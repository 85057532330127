import { Grid, Typography } from "@mui/material";
import { DateCalendar } from "@mui/x-date-pickers";
import { DateTime } from "luxon";
import React, { Dispatch, SetStateAction, useCallback, useContext } from "react";
import { useTranslation } from "react-i18next";
import { LayoutContext } from "../layout/layout.context";

interface IDateRangeFilterProps {
  startDate: DateTime | null;
  endDate: DateTime | null;
  setStartDate: Dispatch<SetStateAction<DateTime | null>>;
  setEndDate: Dispatch<SetStateAction<DateTime | null>>;
  disableFuture?: boolean;
}

export const DateRangeFilter: React.FC<IDateRangeFilterProps> = (props) => {
  const { startDate, endDate, setStartDate, setEndDate, disableFuture } = props;
  const { t } = useTranslation();
  const { breakpointInfo } = useContext(LayoutContext);

  const onSetStartDate = useCallback(
    (newValue: DateTime | null) => {
      if (startDate && newValue && startDate.startOf("day").hasSame(newValue.startOf("day"), "day")) {
        setStartDate(null);
        return;
      } else {
        setStartDate(newValue);
      }

      if (!endDate) {
        setEndDate(newValue);
      }
    },
    [endDate, setEndDate, startDate, setStartDate],
  );

  const onSetEndDate = useCallback(
    (newValue: DateTime | null) => {
      if (endDate && newValue && endDate.startOf("day").hasSame(newValue.startOf("day"), "day")) {
        setEndDate(null);
      } else {
        setEndDate(newValue);
      }
    },
    [endDate, setEndDate],
  );

  return (
    <Grid
      item
      xs={12}
      container
      alignItems="center"
      sx={{
        borderBottomColor: "grey.50",
        borderBottomWidth: 1,
        borderBottomStyle: "solid",
      }}
    >
      <Grid
        item
        xs={12}
        md={6}
        sx={{
          borderRightWidth: 1,
          borderRightStyle: breakpointInfo.isMobile ? "none" : "solid",
          borderBottomStyle: breakpointInfo.isMobile ? "solid" : "none",
          borderRightColor: "grey.50",
          borderBottomColor: "grey.50",
        }}
      >
        <Typography variant="body2" sx={{ pt: 4, pl: 6, color: "primaryDark.main" }}>
          {t("filter.daterange.from")}
        </Typography>
        <DateCalendar
          disableFuture={disableFuture}
          value={startDate}
          onChange={onSetStartDate}
          sx={{ width: "100%" }}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Typography variant="body2" sx={{ pt: 4, pl: 6, color: "primaryDark.main" }}>
          {t("filter.daterange.to")}
        </Typography>
        <DateCalendar disableFuture={disableFuture} value={endDate} onChange={onSetEndDate} sx={{ width: "100%" }} />
      </Grid>
    </Grid>
  );
};
