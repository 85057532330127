import { Grid, ListItem, Typography, useTheme } from "@mui/material";
import { FC } from "react";
import { TopicLinkContent } from "../../../api/muu-api/graphql/generated";
import { CustomLink } from "../../../components/link/custom-link.component";
import { UnorderedListItemIcon } from "../../../assets/icons/icons.component";

interface ITopicLinkProps {
  link: TopicLinkContent;
}

export const TopicLink: FC<ITopicLinkProps> = (props) => {
  const { link } = props;
  const theme = useTheme();

  return (
    <ListItem
      disablePadding
      sx={{
        display: "list-item",
        listStyleType: "none",
        pt: 0,
        pb: 2,
      }}
    >
      <Grid container direction="row" alignItems="center">
        <Grid item xs="auto" sx={{ height: theme.icon.medium, pr: 2 }} container alignItems="center">
          <UnorderedListItemIcon
            sx={{ color: "primary.500", height: theme.icon.xsmall, width: theme.icon.xsmall }}
            viewBox="0 0 16 16"
          />
        </Grid>
        <Grid item xs>
          <CustomLink href={link.link}>
            <Typography>{link.title}</Typography>
          </CustomLink>
        </Grid>
      </Grid>
    </ListItem>
  );
};
