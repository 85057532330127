import { Box, Divider, Stack, Typography } from "@mui/material";
import { useMemo } from "react";
import { RestaurantMenu, RestaurantSpecial, WidgetColor } from "../../../api/muu-api/graphql/generated";
import { CustomLink } from "../../link/custom-link.component";
import { LunchMeal } from "./lunch-meal.component";
import { LunchSpecials } from "./lunch-specials.component";
import { useWidgetColorToThemeColor } from "../../../pages/dashboard/widget-section/hooks/use-widget-color-to-theme-color.hook";

export interface IRestaurantEntryProps {
  restaurant: RestaurantMenu;
  currentColor: WidgetColor;
  role?: string;
}

export const RestaurantEntry: React.FC<IRestaurantEntryProps> = (props) => {
  const { restaurant, currentColor } = props;

  const widgetThemeColor = useWidgetColorToThemeColor(currentColor, false);

  const typographySx = useMemo(() => {
    return {
      fontWeight: 600,
      color: currentColor === WidgetColor.DEFAULT ? "primary.main" : `${widgetThemeColor}.main`,
    };
  }, [currentColor, widgetThemeColor]);

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          pt: 2,
          pb: 2,
        }}
      >
        {restaurant.url ? (
          <CustomLink href={restaurant.url} variant="body2" sx={typographySx}>
            {restaurant.name}
          </CustomLink>
        ) : (
          <Typography variant="body2" sx={typographySx}>
            {restaurant.name}
          </Typography>
        )}
      </Box>
      <Stack spacing={2} divider={<Divider orientation="horizontal" flexItem />}>
        {restaurant.meals?.map((meal) => (
          <LunchMeal key={`meal-${meal.id}`} meal={meal} currentColor={currentColor} />
        ))}
      </Stack>
      {restaurant.specials && restaurant.specials.length > 0 && (
        <LunchSpecials specials={restaurant.specials as RestaurantSpecial[]} currentColor={currentColor} />
      )}
    </Box>
  );
};
