import { ThemeOptions } from "@mui/material/styles";
import { SPACING } from "./sizings.theme";

export const BaseThemeOptions: ThemeOptions = {
  spacing: SPACING.DESKTOP,
  shape: {
    borderRadius: SPACING.DESKTOP,
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1430,
    },
  },
  typography: {
    h1: {
      fontSize: 40,
      fontWeight: 700,
      lineHeight: 1.5,
    },
    h2: {
      fontSize: 32,
      fontWeight: 700,
      lineHeight: 1.5,
    },
    h3: {
      fontSize: 28,
      fontWeight: 700,
      lineHeight: 1.5,
    },
    h4: {
      fontSize: 22,
      fontWeight: 700,
      lineHeight: "28px",
    },
    h5: {
      fontSize: 20,
      fontWeight: 700,
      lineHeight: 1.5,
    },
    h6: {
      fontSize: 18,
      fontWeight: 700,
      lineHeight: "28px",
    },
    body1: {
      fontSize: 16,
      lineHeight: "21px",
    },
    body2: {
      fontSize: 14,
      lineHeight: "18px",
    },
    overline: {
      fontSize: 12,
    },
    subtitle1: {
      fontSize: 16,
      fontWeight: 700,
      lineHeight: "21px",
    },
    subtitle2: {
      fontSize: 14,
      fontWeight: 700,
      lineHeight: "18px",
    },
    caption: {
      fontSize: 12,
      lineHeight: 1.5,
      fontWeight: 400,
    },
    editorH1: {
      fontSize: 22,
      lineHeight: 2,
      fontWeight: 700,
      marginBottom: 0,
    },
    editorH2: {
      fontSize: 20,
      lineHeight: 2,
      fontWeight: 700,
      marginBottom: 0,
    },
    editorH3: {
      fontSize: 18,
      lineHeight: 2,
      fontWeight: 700,
      marginBottom: 0,
    },
    editorH4: {
      fontSize: 18,
      lineHeight: 2,
      fontWeight: 700,
      marginBottom: 0,
    },
    editorH5: {
      fontSize: 16,
      lineHeight: 2,
      fontWeight: 700,
      marginBottom: 0,
    },
    editorH6: {
      fontSize: 16,
      lineHeight: 2,
      fontWeight: 700,
      marginBottom: 0,
    },
    allVariants: {
      fontFamily: '"Open Sans", "Arial", "sans-serif"',
      letterSpacing: 0,
    },
  },
  icon: {
    xxsmall: 12,
    xsmall: 16,
    small: 20,
    medium: 24,
    large: 28,
    xlarge: 32,
    xxlarge: 52,
  },
  components: {
    MuiCardHeader: {
      styleOverrides: {
        action: {
          alignSelf: "center",
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          ":focus": {
            borderRadius: SPACING.DESKTOP * 4,
            backgroundColor: "transparent",
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: SPACING.DESKTOP * 2,
          textTransform: "none",
          fontWeight: "bold",
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paperWidthSm: {
          borderRadius: SPACING.DESKTOP * 4,
        },
        paper: {
          borderRadius: SPACING.DESKTOP * 4,
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          paddingTop: SPACING.DESKTOP * 6,
          paddingBottom: SPACING.DESKTOP * 6,
          paddingLeft: SPACING.DESKTOP * 4,
          paddingRight: SPACING.DESKTOP * 4,
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: SPACING.DESKTOP * 6,
          paddingTop: `${SPACING.DESKTOP * 6}!important`,
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontSize: 16,
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          maxWidth: 500,
        },
      },
    },
  },
};
