import React, { useContext, createContext, PropsWithChildren, useCallback, useEffect, useMemo } from "react";
import { SxProps } from "@mui/material";
import { BreadcrumbsContext } from "../nav/context/breadcrumbs.context";
import { useLocation, useNavigate } from "react-router-dom";
import { IMenuItemWithPortalRoute } from "../nav/context/nav-menu.context";
import { checkIfMenuItemNavigates } from "../nav/helper/check-if-menu-item-navigates.helper";
import { useTracking } from "../../../app/context/tracking/tracking.context";
import { StaticPageDictionaryByPath } from "../../../router/routes.const";

interface IPageHeaderContext {
  shouldRenderChips: boolean;
  heading?: string;
  breadcrumbsToRender: IMenuItemWithPortalRoute[];
  lastClickableBreadcrumb?: IMenuItemWithPortalRoute;
  getMenuItemSx: (menuItem: IMenuItemWithPortalRoute) => SxProps;
  onClickMenuItem: (menuItem: IMenuItemWithPortalRoute) => void;
}

const CHIPS_VISIBILITY_LEVEL = 2;

export const PageHeaderContext = createContext<IPageHeaderContext>({} as IPageHeaderContext);

interface IPageHeaderProviderProps extends PropsWithChildren {}

export const PageHeaderProvider: React.FC<IPageHeaderProviderProps> = (props) => {
  const location = useLocation();
  const { currentMenuItem, breadcrumbs } = useContext(BreadcrumbsContext);
  const { trackPageView } = useTracking();
  const navigate = useNavigate();

  const heading = useMemo(() => {
    return currentMenuItem?.title;
  }, [currentMenuItem]);

  const onClickMenuItem = useCallback(
    (menuItem: IMenuItemWithPortalRoute) => {
      if (checkIfMenuItemNavigates(menuItem)) {
        navigate(menuItem.route);
      }
    },
    [navigate],
  );

  const getMenuItemSx = useCallback((menuItem: IMenuItemWithPortalRoute): SxProps => {
    if (checkIfMenuItemNavigates(menuItem)) {
      return { cursor: "pointer" };
    }
    return { cursor: "default" };
  }, []);

  useEffect(() => {
    if (!location.pathname) return;
    if (heading) {
      trackPageView({ title: heading });
      return;
    }

    const path = location.pathname.split("/")[1];
    if (StaticPageDictionaryByPath[path]) {
      trackPageView({ title: StaticPageDictionaryByPath[path].title });
      return;
    }
    trackPageView({ title: undefined });
  }, [heading, location.pathname, trackPageView]);

  const shouldRenderChips = useMemo(
    () => (currentMenuItem?.level || 0) >= CHIPS_VISIBILITY_LEVEL && (currentMenuItem?.menuItems || []).length > 0,
    [currentMenuItem],
  );

  const breadcrumbsToRender = useMemo(() => {
    if (currentMenuItem?.page?.__typename !== "SimpleTopicPage") {
      const _breadcrumbsToRender = [...breadcrumbs];
      _breadcrumbsToRender.splice(0, 2);
      return breadcrumbs;
    }

    return breadcrumbs;
  }, [breadcrumbs, currentMenuItem]);

  const lastClickableBreadcrumb = useMemo(() => {
    let clickable;
    for (let i = breadcrumbsToRender.length - 1; i >= 0; i--) {
      if (checkIfMenuItemNavigates(breadcrumbsToRender[i])) {
        clickable = breadcrumbsToRender[i];
        break;
      }
    }
    return clickable;
  }, [breadcrumbsToRender, checkIfMenuItemNavigates]);

  return (
    <PageHeaderContext.Provider
      value={{
        shouldRenderChips,
        lastClickableBreadcrumb,
        getMenuItemSx,
        onClickMenuItem,
        heading,
        breadcrumbsToRender,
      }}
    >
      {props.children}
    </PageHeaderContext.Provider>
  );
};
