import { createContext, FC, RefObject, PropsWithChildren, useMemo, useRef, useContext } from "react";
import { useThinkTankWidgetPostsWithFetchMore } from "../hooks/use-think-tank-widget-posts-with-fetch-more.hook";
import { SearchThinkTank, ThinkTankType, useThinkTankCategoriesQuery } from "../../../api/muu-api/graphql/generated";
import { IExtendedWidget } from "../../../pages/dashboard/widget-section/types/widget.type";
import { UserContext } from "../../../app/context/user.context";
import { getThinkTankCategoriesForSearchQueries } from "../../../app/helpers/think-tank-category.helper";

interface IThinkTankContentContext {
  boxRef: RefObject<HTMLElement>;
  offers: SearchThinkTank[];
  requests: SearchThinkTank[];
  offersLoading: boolean;
  requestsLoading: boolean;
}

export const ThinkTankContentContext = createContext<IThinkTankContentContext>({} as IThinkTankContentContext);

interface IThinkTankContentProviderProps extends PropsWithChildren {
  widget: IExtendedWidget;
}

export const ThinkTankContentContextProvider: FC<IThinkTankContentProviderProps> = (props) => {
  const { widget } = props;
  const boxRef = useRef<HTMLElement>(null);
  const { language } = useContext(UserContext);
  const { data: categoriesResult } = useThinkTankCategoriesQuery({
    variables: {
      language,
    },
  });
  const categories = useMemo(() => categoriesResult?.thinkTankCategories || [], [categoriesResult]);
  const widgetCategories: string[] = useMemo(
    () => getThinkTankCategoriesForSearchQueries(widget.categoryFilter, categories ?? []),
    [categories, widget],
  );

  const {
    searchResults: searchOffersResults,
    isLoadingMore: isLoadingMoreOffers,
    isInitialQueryLoading: isInitialOffersQueryLoading,
  } = useThinkTankWidgetPostsWithFetchMore(boxRef, ThinkTankType.OFFER, widgetCategories);
  const {
    searchResults: searchRequestsResults,
    isLoadingMore: isLoadingMoreRequests,
    isInitialQueryLoading: isInitialRequestsQueryLoading,
  } = useThinkTankWidgetPostsWithFetchMore(boxRef, ThinkTankType.REQUEST, widgetCategories);

  const offers = useMemo(() => searchOffersResults ?? [], [searchOffersResults]);
  const offersLoading = useMemo(
    () => isLoadingMoreOffers || isInitialOffersQueryLoading,
    [isLoadingMoreOffers, isInitialOffersQueryLoading],
  );
  const requests = useMemo(() => searchRequestsResults ?? [], [searchRequestsResults]);
  const requestsLoading = useMemo(
    () => isLoadingMoreRequests || isInitialRequestsQueryLoading,
    [isLoadingMoreRequests, isInitialRequestsQueryLoading],
  );

  return (
    <ThinkTankContentContext.Provider
      value={{
        boxRef,
        offers,
        requests,
        requestsLoading,
        offersLoading,
      }}
    >
      {props.children}
    </ThinkTankContentContext.Provider>
  );
};
