import { ExpandMore } from "@mui/icons-material";
import { IconButton, Popover, useTheme } from "@mui/material";
import { FC, useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { IconSize } from "../../theme/types/theme";
import { FilterButton } from "../filter/button/filter-button.component";
import { FILTER_BUTTON_POPOVER_TOP_SPACING_FACTOR } from "../filter/popover/filter-popover-button.component";
import { NotificationStateWithAll } from "./context/notification-state-filter.context";
import { getNotificationStatusIcon } from "./helpers/notification-icon.helper";
import { NotificationStatusFilter } from "./notification-status-filter.component";
import { LayoutContext } from "../layout/layout.context";
import { NOTIFICATION_STATE_FILTER_MIN_HEIGHT } from "../../theme/sizings.theme";

interface IDefaultNotificationStateFilterProps {
  notificationState: NotificationStateWithAll;
  setNotificationState: (state: NotificationStateWithAll) => void;
  variant: "filled" | "outlined";
  iconSize?: IconSize | undefined;
  color?: string;
  fontWeight?: number;
  isSmall?: boolean;
  iconButton?: boolean;
}

export const DefaultNotificationStateFilter: FC<IDefaultNotificationStateFilterProps> = (props) => {
  const {
    notificationState,
    setNotificationState,
    variant,
    iconSize = "small",
    color,
    fontWeight,
    isSmall = false,
    iconButton = false,
  } = props;
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const theme = useTheme();

  const statusIcon = useMemo(
    () => getNotificationStatusIcon(notificationState, iconSize, color),
    [notificationState, iconSize, color],
  );

  const { breakpointInfo, breakpoint } = useContext(LayoutContext);

  return (
    <>
      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: parseFloat(theme.spacing(FILTER_BUTTON_POPOVER_TOP_SPACING_FACTOR)),
          horizontal: 0,
        }}
      >
        <NotificationStatusFilter
          onClick={(state) => {
            setAnchorEl(null);
            setNotificationState(state);
          }}
          isSelected={(state) => state === notificationState}
        />
      </Popover>

      {iconButton && (
        <IconButton
          title={t("dashboard.tooltip.widget.notifications.filter")}
          aria-label="Notification Status Filter"
          onClick={(event) => setAnchorEl(event.currentTarget)}
        >
          {statusIcon}
        </IconButton>
      )}
      {!iconButton && (
        <FilterButton
          title={t(`notifications.status.${notificationState}`)}
          onClick={(event) => setAnchorEl(event.currentTarget)}
          endIcon={<ExpandMore sx={{ color: variant === "filled" ? "grey.500" : color ?? "primary.500" }} />}
          filled={false}
          startIcon={statusIcon}
          fontWeight={fontWeight}
          fullWidth={breakpointInfo.isMobile}
          sx={{
            backgroundColor: variant === "outlined" ? "none" : "grey.100",
            minHeight: NOTIFICATION_STATE_FILTER_MIN_HEIGHT[breakpoint],
            pl: isSmall ? 0 : 4,
            pr: isSmall ? 0 : 4,
            color: variant === "filled" ? "grey.500" : color ?? "primary.500",
            fontWeight: variant === "filled" ? 700 : undefined,
          }}
        >
          {!isSmall && t(`notifications.status.${notificationState}`)}
        </FilterButton>
      )}
    </>
  );
};
