import { useCallback, useMemo } from "react";
import {
  IThinkTankPostFormData,
  IThinkTankPostFormFile,
} from "../../../components/think-tank/think-tank-post-form.component";
import { useApolloClient } from "@apollo/client";
import {
  Media,
  SearchThinkTankDocument,
  ThinkTankPost,
  ThinkTankPostDocument,
  ThinkTankPostInput,
  ThinkTankType,
  useDeleteThinkTankPostMutation,
  useUpdateThinkTankPostMutation,
} from "../../../api/muu-api/graphql/generated";
import { ThinkTankMode } from "../context/think-tank.context";
import { useNavigate } from "react-router-dom";
import { StaticPageDictionary } from "../../../router/routes.const";
import { getAbsoluteRoutingPath } from "../../../utils/get-absolute-routing-path.util";
import { SnackbarSeverity, useSnackbar } from "../../../components/snackbar/snackbar.context";
import { useTranslation } from "react-i18next";

export const useThinkTankPostCrud = (args: {
  post: ThinkTankPost | undefined;
  formData: IThinkTankPostFormData | undefined;
  setMode: (mode: ThinkTankMode) => void;
}) => {
  const { formData, post, setMode } = args;
  const client = useApolloClient();
  const navigate = useNavigate();

  const { showSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const [updateThinkTankPost, { loading: updateLoading }] = useUpdateThinkTankPostMutation();
  const [deleteThinkTankPost, { loading: deleteLoading }] = useDeleteThinkTankPostMutation();

  const loading = useMemo(() => updateLoading || deleteLoading, [updateLoading, deleteLoading]);

  const hasValidFormData = useMemo(() => {
    return !!formData && !!formData.description && !!formData.title && !!formData.type;
  }, [formData]);

  const refetchThinkTankQueries = useCallback(() => {
    client.refetchQueries({
      include: [SearchThinkTankDocument, ThinkTankPostDocument],
    });
  }, [client]);

  const handleNavigateToDashboard = useCallback(() => {
    navigate(getAbsoluteRoutingPath(StaticPageDictionary.DASHBOARD.path));
  }, [navigate]);

  const handleUpdateThinkTankPost = useCallback(() => {
    if (!post || !hasValidFormData || !formData || loading) return;

    const { newAttachments, remainingMedia } = formData.attachments?.reduce<{
      newAttachments: File[];
      remainingMedia: Media[];
    }>(
      (acc, attachment) => {
        const file = attachment as IThinkTankPostFormFile;
        const media = attachment as Media;
        if (file.file) {
          acc.newAttachments.push(file.file);
        } else {
          acc.remainingMedia.push(media);
        }
        return acc;
      },
      { newAttachments: [], remainingMedia: [] },
    );

    const updateData: ThinkTankPostInput = {
      description: formData.description ?? "",
      role: formData.role,
      title: formData.title ?? "",
      type: formData.type ?? ThinkTankType.OFFER,
      thinkTankCategory: formData.category?.id ?? undefined,
      newAttachments,
      attachmentIds: remainingMedia.map((media) => media.id),
    };

    updateThinkTankPost({
      variables: { id: post.id, thinkTankPostInput: updateData },
      onCompleted: (data) => {
        client.writeQuery({ query: ThinkTankPostDocument, data: { thinkTankPost: data.updateThinkTankPost } });
        refetchThinkTankQueries();
        setMode(ThinkTankMode.DETAIL);
      },
      onError: () => {
        showSnackbar(t("think_tank.update_error"), SnackbarSeverity.Error);
        setMode(ThinkTankMode.DETAIL);
      },
    });
  }, [
    post,
    updateThinkTankPost,
    formData,
    hasValidFormData,
    refetchThinkTankQueries,
    loading,
    client,
    setMode,
    showSnackbar,
    t,
  ]);

  const handleDeleteThinkTankPost = useCallback(() => {
    if (!post || loading) return;
    deleteThinkTankPost({
      variables: { id: post.id },
      onCompleted: () => {
        setTimeout(refetchThinkTankQueries, 1000);
      },
    });
    handleNavigateToDashboard();
  }, [post, deleteThinkTankPost, handleNavigateToDashboard, refetchThinkTankQueries, loading]);

  return {
    handleDeleteThinkTankPost,
    handleUpdateThinkTankPost,
    handleNavigateToDashboard,
    loading,
  };
};
