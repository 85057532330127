import { useCallback, useContext, useMemo } from "react";
import { Category, useCategoriesQuery } from "../../../api/muu-api/graphql/generated";
import { useSearchParams } from "react-router-dom";
import { UserContext } from "../../../app/context/user.context";

export const CATEGORIES_SEARCH_PARAM = "categories";

export const useURLSelectedCategories = () => {
  const { language } = useContext(UserContext);
  const { data: categoriesResult } = useCategoriesQuery({
    variables: {
      language,
    },
  });
  const [searchParams, setSearchParams] = useSearchParams();
  const categories: Category[] = useMemo(() => categoriesResult?.categories ?? [], [categoriesResult]);

  const selectedCategories = useMemo(() => {
    const categoryIds = searchParams.get(CATEGORIES_SEARCH_PARAM);
    const categoryIdsArray = (categoryIds || "")?.split(",");
    if (categoryIdsArray.length <= 0) {
      return [];
    }

    return categories.filter((category) => categoryIdsArray.find((categoryName) => categoryName === category.id));
  }, [searchParams, categories]);

  const setSelectedCategories = useCallback(
    (newCategories: Category[]) => {
      setSearchParams({ [CATEGORIES_SEARCH_PARAM]: newCategories.map((category) => category.id).join(",") });
    },
    [setSearchParams],
  );

  return { selectedCategories, setSelectedCategories, categories };
};
