import { CardMedia } from "@mui/material";
import React, { useContext } from "react";
import { InfocenterCarouselArticle } from "../../../api/muu-api/graphql/generated";
import { WIDGET_HEIGHT } from "../../../theme/sizings.theme";
import { LayoutContext } from "../../layout/layout.context";

interface IInfocenterCarouselSlideProps {
  carouselArticle: InfocenterCarouselArticle;
}

export const InfocenterCarouselSlide: React.FC<IInfocenterCarouselSlideProps> = (props) => {
  const { carouselArticle } = props;
  const { breakpoint } = useContext(LayoutContext);

  return (
    <CardMedia
      role="img"
      component="img"
      height={WIDGET_HEIGHT[breakpoint]}
      image={carouselArticle.media?.mediaUrl ?? ""}
      alt={carouselArticle.media?.mediaAlt ?? ""}
      title={carouselArticle.media?.mediaAlt ?? ""}
      sx={{ backgroundColor: "primaryDark.main", objectFit: "cover", borderRadius: 4 }}
    />
  );
};
