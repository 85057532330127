import { Box, Typography } from "@mui/material";
import { Restaurant } from "../../api/muu-api/graphql/generated";
import { PlusIcon } from "../../assets/icons/icons.component";
import { CustomIconButton } from "../icon/custom-icon-button.component";

interface IRestaurantFilterItemProps {
  restaurant: Restaurant;
  onClick: () => void;
}

export const RestaurantFilterItem: React.FC<IRestaurantFilterItemProps> = (props) => {
  const { restaurant, onClick } = props;
  return (
    <Box onClick={onClick} sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
      <Typography variant="body1">{restaurant.name}</Typography>
      <CustomIconButton variant="default" sx={{ ml: "auto" }} onClick={onClick}>
        <PlusIcon />
      </CustomIconButton>
    </Box>
  );
};
