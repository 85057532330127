import { Box, SxProps, Theme, useTheme } from "@mui/material";
import React, { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { LayoutContext } from "../../../components/layout/layout.context";
import { Widget } from "../../../components/widgets/widget.component";
import { OVERVIEW_SPACING_FACTOR, WIDGET_HEIGHT } from "../../../theme/sizings.theme";
import { DashboardWidgetsContext } from "./dashboard-widgets.context";
import { getWidgetContentByType } from "./helpers/get-widget-content-by-type.helper";
import { IExtendedWidget } from "./types/widget.type";

interface IWidgetRowWithoutDraggableProps {
  widgets: IExtendedWidget[];
}

export const WidgetRowWithoutDraggable: React.FC<IWidgetRowWithoutDraggableProps> = (props) => {
  const { widgets } = props;
  const { t } = useTranslation();
  const theme = useTheme();
  const { breakpoint } = useContext(LayoutContext);
  const { isInEditMode: isEditingWidgets } = useContext(DashboardWidgetsContext);
  const containerStyle: SxProps<Theme> = useMemo(
    () => (isEditingWidgets ? { opacity: 0.2, pointerEvents: "none" } : {}),
    [isEditingWidgets],
  );

  return (
    <Box
      component="div"
      sx={{
        mt: -OVERVIEW_SPACING_FACTOR[breakpoint],
        ml: -OVERVIEW_SPACING_FACTOR[breakpoint],
        display: "flex",
        flexDirection: {
          xs: "column",
          lg: "row",
        },
        height: {
          lg: widgets.length > 0 ? WIDGET_HEIGHT[breakpoint] + parseFloat(theme.spacing(6)) : 0,
        },
        ...containerStyle,
      }}
    >
      {widgets.map((widget, index) => (
        <Widget
          key={`${widget.id}-${index}`}
          widget={widget}
          title={`${t(`widgets.${widget.type}.title`)}`}
          sx={{
            mt: OVERVIEW_SPACING_FACTOR[breakpoint],
            ml: OVERVIEW_SPACING_FACTOR[breakpoint],
            width: {
              lg: `calc(${100 / widgets.length}% - ${theme.spacing(6)})`,
            },
          }}
          isFixedWidget
        >
          {getWidgetContentByType(widget, widgets.length > 2)}
        </Widget>
      ))}
    </Box>
  );
};
