import { Paper } from "@mui/material";
import React, { useContext } from "react";
import { EventsDetailContext } from "./context/events-detail.context";
import { EventsDetailSkeleton } from "./events-detail.skeleton";
import { EventsDetailContent } from "./events-detail-content.component";
import { NoContentFound } from "../../components/error/no-content-found.component";

interface IEventsDetailPageContentProps {}

export const EventsDetailPageContent: React.FC<IEventsDetailPageContentProps> = (props) => {
  const { eventLoading, event, eventError } = useContext(EventsDetailContext);

  if (eventError) {
    return <NoContentFound variant="eventError" />;
  }

  if (eventLoading || !!event) {
    return (
      <Paper
        sx={{
          borderRadius: 4,
          flex: 1,
          width: "100%",
        }}
      >
        {eventLoading && <EventsDetailSkeleton />}
        {!eventLoading && event && <EventsDetailContent />}
      </Paper>
    );
  }

  return <NoContentFound variant="event" />;
};
