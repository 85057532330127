import { Box } from "@mui/system";
import { useNavigate } from "react-router";
import React, { useCallback, useContext } from "react";
import { LayoutContext } from "../layout.context";
import { Logo } from "../../../assets/logo.component";
import { getAbsoluteRoutingPath } from "../../../utils/get-absolute-routing-path.util";
import { Button } from "@mui/material";
import { StaticPageDictionary } from "../../../router/routes.const";

export const NavHeader: React.FC = () => {
  const navigate = useNavigate();

  const { sidebarOpen: isOpen } = useContext(LayoutContext);

  const handleLogoClick = useCallback(() => {
    navigate(getAbsoluteRoutingPath(StaticPageDictionary.DASHBOARD.path));
  }, [navigate]);

  return (
    <Box
      component={Button}
      aria-label="Go to Dashboard"
      sx={{
        display: "flex",
        cursor: "pointer",
        pt: 8,
        pr: 0,
        pl: 0,
        m: 0,
        "&:hover": {
          backgroundColor: "transparent",
        },
      }}
      onClick={handleLogoClick}
      disableRipple={true}
    >
      {isOpen ? <Logo size="large" /> : <Logo size="small" />}
    </Box>
  );
};
