import React, { useContext, useMemo } from "react";
import { IWidgetActionItemDictionaryEntry } from "../../pages/dashboard/widget-section/dictionaries/widget-action.dictionary";
import { Box } from "@mui/material";
import { IExtendedWidget } from "../../pages/dashboard/widget-section/types/widget.type";
import { CustomIconButton } from "../icon/custom-icon-button.component";
import { SettingsIcon } from "../../assets/icons/icons.component";
import { LayoutContext } from "../layout/layout.context";
import { useTranslation } from "react-i18next";

interface IWidgetActionsProps {
  widget: IExtendedWidget;
  isEditMode: boolean;
  color: string;
  widgetActions: IWidgetActionItemDictionaryEntry[];
  actions: JSX.Element[] | undefined;
  onClickSettings: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  isSmall: boolean;
}

export const WidgetActions: React.FC<IWidgetActionsProps> = (props) => {
  const { t } = useTranslation();
  const { widget, isEditMode, color, widgetActions, actions, onClickSettings, isSmall } = props;
  const { breakpointInfo, breakpoint } = useContext(LayoutContext);

  const isSmallSize = useMemo(() => isSmall || breakpointInfo.isMobile, [isSmall, breakpointInfo]);

  const actionsToShow = useMemo(
    () =>
      widgetActions.reduce((accumulatedActions: JSX.Element[], action) => {
        if (action.showOnBreakpoint.includes(breakpoint)) {
          accumulatedActions.push(
            <action.Component key={action.id} widget={widget} color={color} isSmall={isSmallSize} />,
          );
        }

        return accumulatedActions;
      }, []),
    [widgetActions, breakpoint],
  );

  if (isEditMode) {
    return null;
  }

  return (
    <Box id={`widget-action-bar-${widget.type}`} sx={{ display: "flex", alignItems: "center" }}>
      {actionsToShow}
      {actions}
      <CustomIconButton
        aria-label="Widget Settings"
        onClick={onClickSettings}
        title={t("dashboard.tooltip.widget.settings")}
      >
        <SettingsIcon sx={{ color }} />
      </CustomIconButton>
    </Box>
  );
};
