import { FC, useContext } from "react";
import { InfocenterDetailContext } from "./context/infocenter-detail.context";
import { InfocenterDetailContent } from "./infocenter-detail-content.component";
import { NoContentFound } from "../../components/error/no-content-found.component";

interface IInfocenterDetailPageContentProps {}

export const InfocenterDetailPageContent: FC<IInfocenterDetailPageContentProps> = (props) => {
  const { infocenterEntry, infocenterEntryLoading, infocenterEntryError } = useContext(InfocenterDetailContext);

  if (infocenterEntryError) {
    return <NoContentFound variant="infocenterError" />;
  }

  if (infocenterEntryLoading || !!infocenterEntry) {
    return <InfocenterDetailContent />;
  }

  return <NoContentFound variant="infocenter" />;
};
