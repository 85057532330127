import { Box, SxProps, useTheme } from "@mui/material";
import React, { useContext } from "react";
import { Media } from "../../../../api/muu-api/graphql/generated";
import { ISvgIconBaseProps } from "../../../../assets/icons/icons.component";
import { IconSize } from "../../../../theme/types/theme";
import { CustomMediaIcon } from "../../../icon/custom-media-icon.component";
import { LayoutContext } from "../../layout.context";
import { useNavMenuItemColor } from "../hooks/use-nav-menu-item-color.hook";

interface INavMenuItemIconProps {
  strapiMedia?: Media;
  IconComponent?: React.FC<ISvgIconBaseProps>;
  isOpen: boolean;
  isSelected: boolean;
  isHovering: boolean;
  sx?: SxProps;
  size?: IconSize;
}

export const NavMenuItemIcon: React.FC<INavMenuItemIconProps> = (props) => {
  const { strapiMedia, isOpen, isSelected, isHovering, sx, IconComponent, size = "medium" } = props;
  const theme = useTheme();
  const { sidebarOpen } = useContext(LayoutContext);
  const color = useNavMenuItemColor(isHovering, isSelected, isOpen);

  return (
    <Box sx={{ p: 0, pl: sidebarOpen ? 1 : 0, minWidth: 0, height: theme.icon[size], ...sx }}>
      {strapiMedia && <CustomMediaIcon strapiMedia={strapiMedia} />}
      {IconComponent && <IconComponent size={size} style={{ color }} />}
    </Box>
  );
};
