import { Button, Grid, Typography, useTheme } from "@mui/material";
import React, { useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { DashboardWidgetsContext } from "./dashboard-widgets.context";
import { AddWidgetDialog } from "./add-widget.dialog";
import { APP_BAR_OFFSET, PAGE_PADDING_HORIZONTAL_FACTOR } from "../../../theme/sizings.theme";
import { LayoutContext } from "../../../components/layout/layout.context";

interface IEditWidgetsButtonBar {}

export const EditWidgetsButtonBar: React.FC<IEditWidgetsButtonBar> = (props) => {
  const { t } = useTranslation();
  const { breakpoint } = useContext(LayoutContext);
  const theme = useTheme();
  const {
    isInEditMode: isEditingDashboardWidgets,
    widgets,
    fixedWidgets,
    saveWidgets,
    cancelWidgetUpdate,
  } = useContext(DashboardWidgetsContext);
  const [addDialogOpen, setAddDialogOpen] = useState<boolean>(false);

  const actionButtonsPositionRight = useMemo(() => PAGE_PADDING_HORIZONTAL_FACTOR[breakpoint] * 4, [breakpoint]);
  const actionButtonsPositionTop = useMemo(() => APP_BAR_OFFSET[breakpoint] - 4, [breakpoint]);

  if (!isEditingDashboardWidgets) return null;

  return (
    <>
      <AddWidgetDialog
        open={addDialogOpen}
        onClose={() => setAddDialogOpen(false)}
        widgets={[...widgets, ...fixedWidgets]}
      />
      <Grid container alignItems="center" justifyContent="flex-end" spacing={6}>
        <Grid item xs>
          <Typography variant="h4">{t("dashboard.edit_dashboard")}</Typography>
        </Grid>
        <Grid id="floating-action-buttons" item>
          <Grid
            container
            alignItems="center"
            justifyContent={"flex-end"}
            spacing={6}
            sx={{
              position: "fixed",
              top: actionButtonsPositionTop,
              right: actionButtonsPositionRight,
              zIndex: 1000,
            }}
          >
            <Grid item>
              <Button
                variant="outlined"
                sx={{
                  background: theme.palette.grey[50],
                  "&:hover": {
                    backgroundColor: theme.palette.grey[100],
                  },
                }}
                onClick={() => setAddDialogOpen(true)}
              >
                {t("dashboard.add_widget")}
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                sx={{
                  background: theme.palette.grey[50],
                  "&:hover": {
                    backgroundColor: theme.palette.grey[100],
                  },
                }}
                onClick={cancelWidgetUpdate}
              >
                {t("general.cancel")}
              </Button>
            </Grid>
            <Grid item>
              <Button variant="contained" onClick={saveWidgets}>
                {t("dashboard.save_widgets")}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
