import { Grid, Pagination, Paper, Typography } from "@mui/material";
import React, { FunctionComponent, useMemo, useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { SearchResultAll, useSearchLazyQuery } from "../../api/muu-api/graphql/generated";
import { SearchContext } from "../../components/layout/searchbar/context/search.context";
import { PaginationItem } from "../../components/pagination/pagination-item.component";
import { SearchResults } from "../../components/search/search-results.component";
import { usePagination } from "../../hooks/use-pagination.hook";

interface ISearchPageProps {}

export const SearchPage: FunctionComponent<ISearchPageProps> = (props) => {
  const [searchParams] = useSearchParams();
  const { selectedFilter } = useContext(SearchContext);
  const { t } = useTranslation();

  const [searchQuery, { data, loading }] = useSearchLazyQuery({
    fetchPolicy: "no-cache",
  });

  const search = useMemo(() => searchParams.get("search"), [searchParams]);
  const searchTooShort = useMemo(() => (search?.length || 0) < 2, [search]);

  const [currentSearchString, setCurrentSearchString] = useState<string>(search || "");

  const { results, count } = useMemo(
    () => ({
      results: data?.search.results || [],
      count: data?.search.count,
    }),
    [data],
  );

  const { page, onChangeHandler, totalNumberOfPages, offset, resetPage } = usePagination(
    { limit: 20, defaultOffset: 20 },
    count,
  );

  useEffect(() => {
    if (!search || search.length < 2) {
      return;
    }

    searchQuery({
      fetchPolicy: "no-cache",
      variables: { search, limit: 20, offset, filter: { indices: [selectedFilter.indexName] } },
    });
  }, [searchQuery, search, offset, page, selectedFilter.indexName]);

  useEffect(() => {
    if (!search || search === currentSearchString) {
      return;
    }
    setCurrentSearchString(search);
    resetPage();
  }, [search, currentSearchString, resetPage]);

  return (
    <Grid container direction="column">
      <Grid item>
        <Typography variant="h4" color="primaryDark.main" sx={{ mb: 6 }}>
          {t("search.search_results")}
        </Typography>
      </Grid>
      {!searchTooShort && results?.length > 0 && (
        <Grid item xs container>
          <Paper sx={{ borderRadius: 4, p: 0, flex: 1, display: "flex" }}>
            <Grid item container direction="column">
              <Grid item xs>
                <SearchResults loading={loading} results={results as SearchResultAll[]} />
              </Grid>

              <Grid item container justifyContent="center" sx={{ p: 6 }}>
                <Pagination
                  tabIndex={0}
                  page={page}
                  count={totalNumberOfPages}
                  onChange={onChangeHandler}
                  renderItem={PaginationItem}
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      )}
      {!loading && !searchTooShort && results?.length <= 0 && (
        <Grid item xs container alignItems="center" justifyContent="center">
          <Typography variant="h6" sx={{ color: "grey.500" }}>
            {t("search.no_results")}
          </Typography>
        </Grid>
      )}
      {!loading && searchTooShort && (
        <Grid item xs container alignItems="center" justifyContent="center">
          <Typography variant="h6" sx={{ color: "grey.500" }}>
            {t("search.search_string_too_short")}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};
