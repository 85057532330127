import React, { FunctionComponent, useCallback, useContext, useEffect, useState } from "react";
import { CustomDialogTitle } from "../../dialog/custom-dialog-title.component";
import { EditIcon } from "../../../assets/icons/icons.component";
import { useTranslation } from "react-i18next";
import { Button, Dialog, DialogContent, Grid, TextField } from "@mui/material";
import { DIALOG_MARGIN_FACTOR, WIDGET_DIALOG_WIDTH } from "../../../theme/sizings.theme";
import { LayoutContext } from "../../layout/layout.context";
import {
  Favorite,
  FavoritesDocument,
  FavoritesForUserDocument,
  SearchEventByFavoriteDocument,
  useUpdateCustomLinkMutation,
} from "../../../api/muu-api/graphql/generated";
import { useApolloClient } from "@apollo/client";
import { SnackbarSeverity, useSnackbar } from "../../snackbar/snackbar.context";
import { CustomDialogActions } from "../../dialog/custom-dialog-actions.component";

interface FavoriteEditDialogProps {
  linkToUpdate: Favorite;
  open: boolean;
  onClose: VoidFunction;
}

export const FavoriteEditDialog: FunctionComponent<FavoriteEditDialogProps> = (props) => {
  const { linkToUpdate, open, onClose } = props;

  const { t } = useTranslation();
  const { breakpoint } = useContext(LayoutContext);
  const { showSnackbar } = useSnackbar();
  const client = useApolloClient();

  const [description, setDescription] = useState("");
  const [link, setLink] = useState("");

  const [updateCustomLink] = useUpdateCustomLinkMutation({
    onError: () => {
      showSnackbar(t("general.save_error"), SnackbarSeverity.Error);
    },
    onCompleted: () => {
      client.refetchQueries({
        include: [FavoritesForUserDocument, FavoritesDocument, SearchEventByFavoriteDocument],
      });
      showSnackbar(t("general.save_success"), SnackbarSeverity.Success);
    },
  });

  const onUpdateCustomLink = useCallback(
    (description: string, link: string) => {
      updateCustomLink({ variables: { link, description, id: linkToUpdate.id } });
      onClose();
    },
    [updateCustomLink, linkToUpdate, onClose],
  );

  useEffect(() => {
    setDescription(linkToUpdate.description);
    setLink(linkToUpdate.link ?? "");
  }, [linkToUpdate]);

  return (
    <>
      <Dialog
        id="widget-menu-item-dialog"
        onClose={onClose}
        open={open}
        PaperProps={{
          sx: { width: WIDGET_DIALOG_WIDTH[breakpoint], minWidth: "auto", p: 0, m: DIALOG_MARGIN_FACTOR[breakpoint] },
        }}
      >
        <CustomDialogTitle
          title={t("widgets.menu_items.edit_link_favorite")}
          Icon={EditIcon}
          iconProps={{ sx: { color: "primaryDark.main" } }}
        />

        <DialogContent>
          <Grid container spacing={4} sx={{ pt: 2, pb: 2 }} direction="column">
            <Grid item>
              <TextField
                fullWidth
                label={t("favorites.title")}
                value={description}
                onChange={(event) => setDescription(event.target.value)}
              />
            </Grid>
            <Grid item>
              <TextField
                fullWidth
                label={t("favorites.link")}
                value={link}
                onChange={(event) => setLink(event.target.value)}
              />
            </Grid>
          </Grid>
        </DialogContent>

        <CustomDialogActions>
          <Button variant="outlined" onClick={() => onClose()}>
            {t("general.cancel")}
          </Button>
          <Button variant="contained" onClick={() => onUpdateCustomLink(description, link)} disabled={false}>
            {t("favorites.save_favorite")}
          </Button>
        </CustomDialogActions>
      </Dialog>
    </>
  );
};
