import { Box } from "@mui/material";
import React, { useCallback, useContext, useMemo } from "react";
import { ThinkTankAttachment } from "./think-tank-attachment.component";
import { Media } from "../../api/muu-api/graphql/generated";
import { AttachIcon } from "../../assets/icons/icons.component";
import { useFilePicker } from "use-file-picker";
import { IThinkTankPostFormFile } from "../../components/think-tank/think-tank-post-form.component";
import { THINK_TANK_IMAGE_PREVIEW_SIZE } from "../../theme/sizings.theme";
import { LayoutContext } from "../../components/layout/layout.context";
import { SnackbarSeverity, useSnackbar } from "../../components/snackbar/snackbar.context";
import { useTranslation } from "react-i18next";
import { THINK_TANK_MAX_ATTACHMENTS } from "./context/think-tank.context";

interface IThinkTankAttachmentsProps {
  attachments: (Media | IThinkTankPostFormFile)[];
  editMode?: boolean;
  setAttachments?: (attachments: (Media | IThinkTankPostFormFile)[]) => void;
}

export const ThinkTankAttachments: React.FC<IThinkTankAttachmentsProps> = (props) => {
  const { attachments, editMode, setAttachments } = props;
  const { breakpoint } = useContext(LayoutContext);
  const { t } = useTranslation();
  const { showSnackbar } = useSnackbar();

  const { openFilePicker } = useFilePicker({
    onFilesSuccessfullySelected: async (data) => {
      const mapped: IThinkTankPostFormFile[] = data.plainFiles.map((file, index) => ({
        file,
        content: data.filesContent[index].content,
      }));

      if (mapped.length + attachments.length > THINK_TANK_MAX_ATTACHMENTS) {
        showSnackbar(t("think_tank.too_many_attachments"), SnackbarSeverity.Error);
        return;
      }

      setAttachments && setAttachments([...attachments, ...mapped]);
    },

    readAs: "DataURL",
    accept: [".png", ".jpg", ".jpeg", ".docx", ".pdf", ".xlsx"],
    multiple: true,
  });

  const onDelete = useCallback(
    (index: number) => {
      const newAttachments = attachments.filter((_, i) => i !== index);
      setAttachments && setAttachments(newAttachments);
    },
    [attachments, setAttachments],
  );

  const disableAddNew = attachments.length >= THINK_TANK_MAX_ATTACHMENTS;

  const { images, documents } = useMemo(
    () =>
      attachments.reduce<{
        images: { attachment: IThinkTankPostFormFile | Media; index: number }[];
        documents: { attachment: IThinkTankPostFormFile | Media; index: number }[];
      }>(
        (acc, attachment, index) => {
          const mimeType = (attachment as Media).mimeType || (attachment as IThinkTankPostFormFile).file.type;

          if (mimeType.startsWith("image")) {
            acc.images.push({ attachment, index });
          } else {
            acc.documents.push({ attachment, index });
          }

          return acc;
        },
        { images: [], documents: [] },
      ),
    [attachments],
  );

  return (
    <>
      <Box sx={{ mb: documents.length > 0 ? 4 : 0 }}>
        {documents.map((document) => (
          <ThinkTankAttachment
            key={`attachment-${document.index}`}
            attachment={document.attachment}
            editMode={editMode}
            onDelete={() => onDelete(document.index)}
          />
        ))}
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "nowrap",
          overflowX: "scroll",
          alignItems: "center",
          scrollbarWidth: 0,
          "-ms-overflow-style": "none",
          "::-webkit-scrollbar": {
            display: "none",
          },
        }}
      >
        {images.map((image, indexOfImageInImages) => (
          <Box key={`attachment-${image.index}`} sx={{ ml: indexOfImageInImages === 0 ? 0 : 4 }}>
            <ThinkTankAttachment
              key={image.index}
              attachment={image.attachment}
              editMode={editMode}
              onDelete={() => onDelete(image.index)}
            />
          </Box>
        ))}
        {editMode && (
          <Box
            onClick={disableAddNew ? undefined : openFilePicker}
            sx={{
              ml: images.length === 0 ? 0 : 4,
              minWidth: THINK_TANK_IMAGE_PREVIEW_SIZE[breakpoint],
              width: THINK_TANK_IMAGE_PREVIEW_SIZE[breakpoint],
              height: THINK_TANK_IMAGE_PREVIEW_SIZE[breakpoint],
              minHeight: THINK_TANK_IMAGE_PREVIEW_SIZE[breakpoint],
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              zIndex: 99,
              bgcolor: disableAddNew ? "grey.25" : "accent1.25",
              cursor: disableAddNew ? "auto" : "pointer",
              borderRadius: 2,
            }}
          >
            <AttachIcon size="large" sx={{ color: disableAddNew ? "grey.400" : "accent1.main" }} />
          </Box>
        )}
      </Box>
    </>
  );
};
