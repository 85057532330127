import { Box, Divider, Grid, Typography, useTheme } from "@mui/material";
import { FC, useCallback, useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { stripHtml } from "string-strip-html";
import { SearchThinkTank, ThinkTankCategory, WidgetColor, WidgetType } from "../../../api/muu-api/graphql/generated";
import { DeeplinkIcon, ThinkTankIcon } from "../../../assets/icons/icons.component";
import { StaticPageDictionary } from "../../../router/routes.const";
import { encodeIdBase64 } from "../../../utils/base64.util";
import { WIDGET_CONTENT_ROW_HEIGHT } from "../../../theme/sizings.theme";
import { getAbsoluteRoutingPath } from "../../../utils/get-absolute-routing-path.util";
import { LayoutContext } from "../../layout/layout.context";
import { SnackbarSeverity, useSnackbar } from "../../snackbar/snackbar.context";
import { THINK_TANK_SEARCH_ENTRY_EMPTY_ID } from "../../think-tank/helper/think-tank-cache.helper";
import { CustomMediaIcon } from "../../icon/custom-media-icon.component";

interface IThinkTankRowProps {
  thinkTankPost: SearchThinkTank;
  currentColor: WidgetColor;
  thinkTankCategories: ThinkTankCategory[];
}

export const ThinkTankRow: FC<IThinkTankRowProps> = (props) => {
  const { thinkTankPost, thinkTankCategories } = props;
  const { breakpoint } = useContext(LayoutContext);
  const { showSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();

  const copyLink = useCallback(() => {
    if (thinkTankPost.id === THINK_TANK_SEARCH_ENTRY_EMPTY_ID) return;
    const encodedId = encodeIdBase64(thinkTankPost.id, { type: null });
    const path = `${getAbsoluteRoutingPath(StaticPageDictionary.THINK_TANK_DETAIL.path)}?id=${encodedId}`;
    navigator.clipboard.writeText(window.location.host + path);
    showSnackbar(t(`widgets.${WidgetType.THINK_TANK}.copy_link_success`), SnackbarSeverity.Success);
  }, [showSnackbar, t, thinkTankPost]);

  const navigateToDetails = useCallback(() => {
    if (thinkTankPost.id === THINK_TANK_SEARCH_ENTRY_EMPTY_ID) return;
    const encodedId = encodeIdBase64(thinkTankPost.id, { type: null });
    navigate({
      pathname: getAbsoluteRoutingPath(StaticPageDictionary.THINK_TANK_DETAIL.path),
      search: `id=${encodedId}`,
    });
  }, [thinkTankPost, navigate]);

  const encodedId = useMemo(() => `id=${encodeIdBase64(thinkTankPost.id, { type: null })}`, [thinkTankPost]);

  const navigationPath = useMemo(
    () => `${getAbsoluteRoutingPath(StaticPageDictionary.THINK_TANK_DETAIL.path)}?${encodedId}`,
    [encodedId],
  );

  const icon = useMemo(() => {
    // TODO use image of post in future
    const thinkTankCategory = thinkTankCategories.find(
      (category) => category.searchFilter === thinkTankPost.thinkTankCategory,
    );

    return (
      <Box
        sx={{
          p: 0,
          m: 0,
          display: "flex",
        }}
      >
        {thinkTankCategory && thinkTankCategory.icon ? (
          <CustomMediaIcon strapiMedia={thinkTankCategory.icon} size="medium" />
        ) : (
          <ThinkTankIcon height={theme.icon.medium} width={theme.icon.medium} />
        )}
      </Box>
    );
  }, [thinkTankCategories, thinkTankPost.thinkTankCategory, theme.icon.medium]);

  return (
    <>
      <Divider />
      <Grid container alignItems="center" spacing={4} justifyContent="flex-start" sx={{ p: 2, pl: 4, pr: 4 }}>
        <Grid item>{icon}</Grid>
        <Grid item container direction="column" xs onClick={navigateToDetails} sx={{ cursor: "pointer" }}>
          <Link to={navigationPath} style={{ textDecoration: "none", color: "inherit", font: "inherit" }}>
            <Grid
              item
              container
              alignItems="center"
              sx={{
                height: WIDGET_CONTENT_ROW_HEIGHT[breakpoint],
              }}
            >
              <Typography
                variant="body2"
                sx={{
                  fontWeight: "bold",
                  overflow: "hidden",
                  display: "-webkit-box",
                  WebkitLineClamp: 1,
                  WebkitBoxOrient: "vertical",
                  wordBreak: "break-word",
                }}
              >
                {thinkTankPost.title}
              </Typography>
            </Grid>
            <Grid
              item
              container
              alignItems="center"
              sx={{
                height: WIDGET_CONTENT_ROW_HEIGHT[breakpoint],
              }}
            >
              <Typography
                variant="body2"
                sx={{
                  color: "grey.500",
                  overflow: "hidden",
                  display: "-webkit-box",
                  WebkitLineClamp: 1,
                  WebkitBoxOrient: "vertical",
                  wordBreak: "break-word",
                }}
              >
                {stripHtml(thinkTankPost.description ?? "").result}
              </Typography>
            </Grid>
          </Link>
        </Grid>
        <Grid item xs="auto" container direction="column" justifyContent="center">
          <DeeplinkIcon
            title={t("dashboard.tooltip.widget.deeplink")}
            sx={{ color: `grey.800`, cursor: "pointer" }}
            onClick={copyLink}
          />
        </Grid>
      </Grid>
    </>
  );
};
