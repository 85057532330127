import { BellDataSettings } from "../../../api/muu-api/graphql/generated";
import { IBellDataSettings } from "../types/notification-settings-input.type";

export const getBellDataSettingsInitialValues = (initValues: BellDataSettings | null): IBellDataSettings => {
  if (!initValues) {
    return {
      notificationsWithoutReadReceipt: false,
      categoryIds: [],
    };
  }

  return {
    notificationsWithoutReadReceipt: initValues.receiveNotificationsWithoutReadReceipt,
    categoryIds: initValues.categoryIds,
  };
};
