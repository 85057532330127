import { Box, Divider, Grid, Typography, useTheme } from "@mui/material";
import React, { useContext, useMemo } from "react";
import { PersonExtra } from "../../api/muu-api/graphql/generated";
import {
  ClockIcon,
  FaxIcon,
  InfoIcon,
  MailIcon,
  PersonIcon,
  PhoneIcon,
  PlaceOfWorkIcon,
} from "../../assets/icons/icons.component";
import { PersonDetailRow } from "./person-detail-row.component";
import parse from "html-react-parser";
import { PhoneOrMailTypography } from "../typography/phone-or-mail-typography.component";
import { PERSON_SMALL_PROFILE_IMG_SIZE } from "../../theme/sizings.theme";
import { LayoutContext } from "../layout/layout.context";
import { PersonDetailOrganizationRow } from "./person-detail-organization-row.component";
import { useTranslation } from "react-i18next";

export interface IPersonDetailCardImageInformation {
  firstName?: string;
  lastName?: string;
  email?: string;
  mobile?: string;
  phone?: string;
  fax?: string;
  phoneExternal?: string;
  consultation?: string;
  personExtra?: PersonExtra;
  organizationRaw?: string;
  organizationRawId?: string;
  prefixedAcademicTitle?: string;
  suffixedAcademicTitle?: string;
  avatar?: string;
  appendix?: string;
  prefixTitle?: string;
  suffixTitle?: string;
}

interface IPersonDetailCardImageInformationProps {
  person: IPersonDetailCardImageInformation;
  showImage?: boolean;
  iconColor?: string;
}

export const PersonDetailCardImageInformation: React.FC<IPersonDetailCardImageInformationProps> = (props) => {
  const theme = useTheme();
  const { person, iconColor = theme.palette.primaryDark.main, showImage } = props;
  const { breakpoint } = useContext(LayoutContext);
  const { t } = useTranslation();

  const personImage = useMemo(() => {
    if (!person.avatar) {
      return (
        <Box
          sx={{
            width: PERSON_SMALL_PROFILE_IMG_SIZE[breakpoint],
            height: PERSON_SMALL_PROFILE_IMG_SIZE[breakpoint],
            backgroundColor: "primaryDark.50",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            borderRadius: 2,
          }}
        >
          <PersonIcon size="xxlarge" sx={{ color: "primaryDark.main", display: "flex" }} />
        </Box>
      );
    }
    return (
      <Box
        sx={{
          overflow: "hidden",
          borderRadius: 2,
          display: "flex",
        }}
      >
        {person.avatar && (
          <img
            src={person.avatar}
            width={PERSON_SMALL_PROFILE_IMG_SIZE[breakpoint]}
            height={PERSON_SMALL_PROFILE_IMG_SIZE[breakpoint]}
            style={{ objectFit: "cover", flex: 1 }}
            alt="avatar"
          />
        )}
      </Box>
    );
  }, [person, breakpoint]);

  return (
    <Grid container direction="column">
      <Grid item xs={12} container direction="row" justifyContent="flex-start" alignItems="center" columnSpacing={4}>
        {showImage && (
          <Grid item xs="auto">
            {personImage}
          </Grid>
        )}
        <Grid item>
          <Grid container direction="column" justifyContent="center" alignItems="flex-start">
            {person.firstName && (
              <Grid item xs={12}>
                {person.prefixTitle && <Typography>{person.prefixTitle}</Typography>}
                <Box sx={{ display: "flex" }}>
                  <Typography variant="subtitle1">
                    {person.firstName} {person.lastName}
                  </Typography>
                  {person.suffixTitle && <Typography>{`, ${person.suffixTitle}`}</Typography>}
                </Box>
              </Grid>
            )}
            {!person.prefixTitle && !person.suffixTitle && person.prefixedAcademicTitle && (
              <Grid item xs={12}>
                <Typography>{person.prefixedAcademicTitle}</Typography>
              </Grid>
            )}
            {!person.prefixTitle && !person.suffixTitle && person.suffixedAcademicTitle && (
              <Grid item xs={12}>
                <Typography>{person.suffixedAcademicTitle}</Typography>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
      {(person.firstName || person.lastName || showImage) && (
        <Grid item sx={{ mt: 4, mb: 4 }}>
          <Divider />
        </Grid>
      )}

      {person.personExtra?.organizations &&
        person.personExtra?.organizations.map((organization, index) => (
          <PersonDetailOrganizationRow
            key={organization.id}
            id={organization.id}
            name={organization.name}
            organizationFunction={organization.organizationFunction ?? undefined}
            iconColor={iconColor}
            iconSize="xsmall"
          />
        ))}
      {person.organizationRaw && (
        <PersonDetailOrganizationRow
          id={person.organizationRawId}
          name={person.organizationRaw}
          iconColor={iconColor}
          iconSize="xsmall"
        />
      )}
      {person.email && (
        <PersonDetailRow
          left={<MailIcon style={{ color: iconColor }} fontSize="medium" size="xsmall" />}
          right={<PhoneOrMailTypography type="mail" value={person.email} />}
        />
      )}
      {person.phone && (
        <PersonDetailRow
          left={<PhoneIcon style={{ color: iconColor }} fontSize="medium" size="xsmall" />}
          right={<Typography>{parse(person.phone)}</Typography>}
        />
      )}
      {person.phoneExternal && (
        <PersonDetailRow
          left={<PhoneIcon style={{ color: iconColor }} fontSize="medium" size="xsmall" />}
          right={<Typography>{parse(person.phoneExternal)}</Typography>}
        />
      )}
      {person.mobile && (
        <PersonDetailRow
          left={<PhoneIcon style={{ color: iconColor }} fontSize="medium" size="xsmall" />}
          right={<Typography>{parse(person.mobile)}</Typography>}
        />
      )}
      {person?.personExtra?.placeOfWork && (
        <PersonDetailRow
          left={<PlaceOfWorkIcon style={{ color: theme.palette.primaryDark.main }} fontSize="medium" size="xsmall" />}
          right={
            <Typography>
              {t("person_detail.place_of_work", {
                address: person.personExtra.placeOfWork.address,
                room: person.personExtra.placeOfWork.room,
              })}
            </Typography>
          }
        />
      )}
      {person.fax && (
        <PersonDetailRow
          left={<FaxIcon style={{ color: iconColor }} fontSize="medium" size="xsmall" />}
          right={<Typography>{parse(person.fax)}</Typography>}
        />
      )}
      {person.consultation && (
        <PersonDetailRow
          left={<ClockIcon style={{ color: iconColor }} fontSize="medium" size="xsmall" />}
          right={<Typography>{parse(person.consultation)}</Typography>}
        />
      )}
      {person?.appendix && (
        <PersonDetailRow
          left={<InfoIcon style={{ color: theme.palette.primaryDark.main }} fontSize="medium" size="xsmall" />}
          right={<Typography>{parse(person.appendix)}</Typography>}
        />
      )}
    </Grid>
  );
};
