import { Box, IconButton } from "@mui/material";
import { FC, useContext, useState } from "react";
import { PlusIcon } from "../../../../assets/icons/icons.component";
import { LayoutContext } from "../../../../components/layout/layout.context";
import { MenuItemDialog } from "../../../../components/widgets/menu-item.dialog";
import { THINK_TANK_DIALOG_WIDTH } from "../../../../theme/sizings.theme";
import { IWidgetActionItemBaseProps } from "../dictionaries/widget-action.dictionary";
import { useWidgetColorToThemeColor } from "../hooks/use-widget-color-to-theme-color.hook";
import { WidgetAddThinkTankPost } from "./widget-add-think-tank-post.component";
import { useTranslation } from "react-i18next";

interface IWidgetAddThinkTankPostActionProp extends IWidgetActionItemBaseProps {}

export const WidgetAddThinkTankPostAction: FC<IWidgetAddThinkTankPostActionProp> = (props) => {
  const { t } = useTranslation();
  const { widget, color } = props;
  const [isOpen, setIsOpen] = useState(false);
  const widgetColorPaletteOption = useWidgetColorToThemeColor(widget.color, false);
  const { breakpoint } = useContext(LayoutContext);

  return (
    <>
      <MenuItemDialog
        widget={widget}
        open={!!isOpen}
        onClose={() => setIsOpen(false)}
        Component={WidgetAddThinkTankPost}
        disableEnforceFocus
        widthOverride={`${THINK_TANK_DIALOG_WIDTH[breakpoint]}px`}
      />
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <IconButton
          aria-label="Add think tank/g post"
          onClick={() => setIsOpen(true)}
          title={t("dashboard.tooltip.widget.think_tank.add")}
        >
          <PlusIcon sx={{ color: color ?? `${widgetColorPaletteOption}.contrastText` }} />
        </IconButton>
      </Box>
    </>
  );
};
