import React, { createContext, Dispatch, PropsWithChildren, SetStateAction, useState } from "react";

interface IWidgetFavoriteFilterContext {
  showOnlyFavorites: boolean;
  setShowOnlyFavorites: Dispatch<SetStateAction<boolean>>;
}

export const WidgetFavoriteFilterContext = createContext<IWidgetFavoriteFilterContext>(
  {} as IWidgetFavoriteFilterContext,
);

interface IWidgetFavoriteFilterContextProviderProps extends PropsWithChildren {}

export const WidgetFavoriteFilterContextProvider: React.FC<IWidgetFavoriteFilterContextProviderProps> = (props) => {
  const [showOnlyFavorites, setShowOnlyFavorites] = useState<boolean>(false);

  return (
    <WidgetFavoriteFilterContext.Provider value={{ showOnlyFavorites, setShowOnlyFavorites }}>
      {props.children}
    </WidgetFavoriteFilterContext.Provider>
  );
};
