import { useApolloClient } from "@apollo/client";
import { Box, Stack } from "@mui/material";
import React, { useCallback, useMemo } from "react";
import {
  Favorite,
  FavoritesDocument,
  FavoritesForUserDocument,
  SearchEventByFavoriteDocument,
  useFavoritesForUserQuery,
  useRemoveFavoriteMutation,
  WidgetType,
} from "../../../api/muu-api/graphql/generated";
import { WidgetNoEntry } from "../widget-no-entry.component";
import { FavoriteRow } from "./favorite-row.component";
import { DefaultWidgetSkeleton } from "../widget-default-skeleton.component";

export const FavoritesWidgetContent: React.FC = () => {
  const client = useApolloClient();
  const { data, loading } = useFavoritesForUserQuery({
    fetchPolicy: "no-cache",
  });
  const favorites = useMemo(() => data?.favorites ?? [], [data]);
  const [removeFavorite] = useRemoveFavoriteMutation({
    onCompleted: () => {
      client.refetchQueries({
        include: [FavoritesForUserDocument, FavoritesDocument, SearchEventByFavoriteDocument],
      });
    },
  });

  const onRemoveFavorite = useCallback(
    async (favorite: Favorite) => {
      removeFavorite({ variables: { id: favorite.id } });
    },
    [removeFavorite],
  );

  return (
    <Box
      id="favorites-widget"
      sx={{ width: "100%", overflowY: "auto", overscrollBehavior: "contain", flex: 1, display: "flex" }}
    >
      <Stack direction="column" display="flex" flex={1} flexDirection="column">
        {loading && <DefaultWidgetSkeleton rows={5} />}
        {!loading && favorites.length === 0 && <WidgetNoEntry widgetType={WidgetType.FAVORITES} />}
        {!loading &&
          favorites.map((favorite) => (
            <Box key={`favorite-widget-${favorite.id}`}>
              <FavoriteRow favorite={favorite} onRemove={onRemoveFavorite} />
            </Box>
          ))}
      </Stack>
    </Box>
  );
};
