import i18nImpl from "i18next";
import { initReactI18next } from "react-i18next";
import { TRANSLATIONS_EN } from "./en.translation";
import { TRANSLATIONS_DE } from "./de.translation";
import LngDetector from "i18next-browser-languagedetector";
import { Language } from "../api/muu-api/graphql/generated";

export const AVAILABLE_LANGUAGES: Language[] = [Language.DE, Language.EN];
export const FALLBACK_LANGUAGE = Language.DE;

i18nImpl
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LngDetector)
  .init({
    resources: {
      [Language.DE]: {
        translation: TRANSLATIONS_DE,
      },
      [Language.EN]: {
        translation: TRANSLATIONS_EN,
      },
    },
    supportedLngs: AVAILABLE_LANGUAGES,
    fallbackLng: FALLBACK_LANGUAGE,
    debug: false,
    react: {
      transSupportBasicHtmlNodes: true,
    },
    interpolation: {
      escapeValue: false,
    },

    detection: {
      order: ["querystring", "localStorage", "cookie", "navigator", "htmlTag", "path", "subdomain"],
    },
  });

export const getCurrentLanguage = () => {
  return i18nImpl.language.split("-")[0];
};

export default i18nImpl;
export const i18n = i18nImpl;
