import { Divider } from "@mui/material";
import { Breakpoint, EDITOR_CONTENT_LARGE_SPACING } from "../../../theme/sizings.theme";

export const mapToHr = (breakpoint: Breakpoint) => (
  <Divider
    sx={{
      borderBottom: 1,
      mt: `${EDITOR_CONTENT_LARGE_SPACING[breakpoint]}px`,
      mb: `${EDITOR_CONTENT_LARGE_SPACING[breakpoint]}px`,
      color: "grey.100",
    }}
  />
);
