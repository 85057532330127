import React from "react";
import { IMenuItemWithPortalRoute } from "../../components/layout/nav/context/nav-menu.context";
import { TopicPageContextProvider } from "./context/topic-page.context";
import { TopicPageLayout } from "./topic-page-layout.component";
import { Language } from "../../api/muu-api/graphql/generated";

interface ITopicPageProps {
  id: string;
  subMenuItems: IMenuItemWithPortalRoute[];
  localize?: boolean;
  language?: Language;
}

export const TopicPage: React.FC<ITopicPageProps> = (props) => {
  const { id, subMenuItems, localize = true, language } = props;

  return (
    <TopicPageContextProvider id={id} localize={localize} language={language}>
      <TopicPageLayout subMenuItems={subMenuItems} />
    </TopicPageContextProvider>
  );
};
