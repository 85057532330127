import { AppBar, Grid, Toolbar, useTheme } from "@mui/material";
import React, { useContext } from "react";
import { APP_BAR, SMALL_NAV_WIDTH, NAV_WIDTH } from "../../../theme/sizings.theme";
import { LanguageSelect } from "../language-select/language-select.component";
import { LayoutContext } from "../layout.context";
import { BellDataIndicator } from "../bell-data/bell-data-indicator.component";
import { Profile } from "../profile/profile.component";
import { Searchbar } from "../searchbar/searchbar.component";
import { ThemeSelect } from "../theme-select/theme-select.component";

export const Header: React.FC = () => {
  const { sidebarOpen: isOpen } = useContext(LayoutContext);
  const theme = useTheme();

  return (
    <AppBar
      sx={{
        boxShadow: "none",
        width: `calc(100% - ${isOpen ? NAV_WIDTH.DESKTOP + 1 : SMALL_NAV_WIDTH.DESKTOP + 1}px)`,
        backgroundColor: "background.default",
      }}
    >
      <Toolbar
        sx={{
          height: APP_BAR.DESKTOP,
          backgroundColor: "background.default",
          borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
          padding: theme.spacing(0, 10),
        }}
        disableGutters
      >
        <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={3}>
          <Grid item xs>
            <Searchbar />
          </Grid>
          <Grid item xs="auto" container alignItems="center" justifyContent="space-between" spacing={3}>
            <Grid item>
              <LanguageSelect />
            </Grid>
            <Grid item>
              <ThemeSelect />
            </Grid>
            <Grid item>
              <BellDataIndicator />
            </Grid>
            <Grid item>
              <Profile />
            </Grid>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};
