import React, { useContext, useMemo } from "react";
import { Link } from "@mui/material";
import { WidgetType } from "../../../api/muu-api/graphql/generated";
import { IExtendedWidget } from "./types/widget.type";
import { useNavigate } from "react-router-dom";
import { getAbsoluteRoutingPath } from "../../../utils/get-absolute-routing-path.util";
import { NavMenuContext } from "../../../components/layout/nav/context/nav-menu.context";

interface WidgetTitleProps {
  widget: IExtendedWidget;
  title: string;
}

export const WidgetTitle: React.FC<WidgetTitleProps> = (props) => {
  const { widget, title } = props;
  const navigate = useNavigate();
  const { pageOfInterestDictionary } = useContext(NavMenuContext);

  const linkedTitle = useMemo(() => {
    switch (widget.type) {
      case WidgetType.INFOCENTER:
        return (
          <Link
            sx={{ textDecoration: "none", color: "inherit", "&:hover": { textDecoration: "none" } }}
            onClick={() => {
              navigate({
                pathname: getAbsoluteRoutingPath(pageOfInterestDictionary.INFOCENTER.path),
              });
            }}
          >
            {title}
          </Link>
        );
      case WidgetType.BUS_BAHN_BIM:
        return title;
      case WidgetType.EVENTS:
        return (
          <Link
            sx={{ textDecoration: "none", color: "inherit", "&:hover": { textDecoration: "none" } }}
            onClick={() => {
              navigate({
                pathname: getAbsoluteRoutingPath(pageOfInterestDictionary.EVENTS.path),
              });
            }}
          >
            {title}
          </Link>
        );
      case WidgetType.FAVORITES:
        return title;
      case WidgetType.NOTIFICATIONS:
        return (
          <Link
            sx={{ textDecoration: "none", color: "inherit", "&:hover": { textDecoration: "none" } }}
            onClick={() => {
              navigate({
                pathname: getAbsoluteRoutingPath(pageOfInterestDictionary.NOTIFICATIONS.path),
              });
            }}
          >
            {title}
          </Link>
        );
      case WidgetType.THINK_TANK:
        return title;
      case WidgetType.LUNCH:
        return title;
      case WidgetType.WORK_SPACES:
        return title;
      default:
        return title;
    }
  }, [title, widget, navigate, pageOfInterestDictionary]);

  return <>{linkedTitle}</>;
};
