import React from "react";
import { TopicTeamMemberContent } from "../../../api/muu-api/graphql/generated";
import { Grid } from "@mui/material";
import { TopicTeamMember } from "./topic-team-member.content-component";

interface ITopicTeamMembersProps {
  teamMembers: TopicTeamMemberContent[];
}

export const TopicTeamMembers: React.FC<ITopicTeamMembersProps> = (props) => {
  const { teamMembers } = props;

  return (
    <Grid item container xs={12} rowSpacing={2}>
      {teamMembers.length > 0 &&
        teamMembers.map((member, index) => {
          const isLastItem = teamMembers!.length === index + 1;
          return <TopicTeamMember key={`${member.medUniId}-${index}`} isLastItem={isLastItem} content={member} />;
        })}
    </Grid>
  );
};
