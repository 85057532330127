import React, { createContext, PropsWithChildren, useEffect, useCallback, useContext } from "react";
import { config } from "../../../utils/config.util";
import { TrackingAction, TrackingCategory, TrackingEvent } from "./tracking.types";
import { UserContext } from "../user.context";

declare global {
  interface Window {
    _paq?: string[][];
  }
}

interface ITrackEventArgs {
  event: TrackingEvent;
  category?: TrackingCategory;
  action?: TrackingAction;
}

interface ITrackPageViewArgs {
  title?: string;
}

interface ITrackingContext {
  trackEvent: (args: ITrackEventArgs) => void;
  trackPageView: (args: ITrackPageViewArgs) => void;
}

export const TrackingContext = createContext<ITrackingContext>({} as ITrackingContext);

interface ITrackingProviderProps extends PropsWithChildren {}

export const TrackingProvider: React.FC<ITrackingProviderProps> = (props) => {
  const { currentUser } = useContext(UserContext);

  useEffect(() => {
    if (!currentUser || window._paq) {
      return;
    }

    var _paq: string[][] = (window._paq = window._paq || []);
    _paq.push(["enableLinkTracking"]);
    const initTracking = function () {
      _paq.push(["setTrackerUrl", config.REACT_APP_PIWIK_URL + "piwik.php"]);
      _paq.push(["setSiteId", config.REACT_APP_PIWIK_SITE_ID]);
      // Custom Variables not working for now - uncomment when customVariables are activated in matomo
      // _paq.push(["deleteCustomVariables", "visit"]);
      // _paq.push(["setCustomVariable", 1, "role-type", data.userDetails.role, "visit"]);
      var d = document,
        g = d.createElement("script"),
        s = d.getElementsByTagName("script")[0];
      g.async = true;
      g.src = config.REACT_APP_PIWIK_URL + "piwik.js";
      s?.parentNode?.insertBefore(g, s);
    };

    initTracking();
  }, [currentUser]);

  const trackEvent = useCallback((args: ITrackEventArgs) => {
    const { event, action = "none", category = "none" } = args;
    if (window._paq === undefined) {
      return;
    }
    window._paq?.push(["trackEvent", category, action, event]);
  }, []);

  const trackPageView = useCallback((args: ITrackPageViewArgs) => {
    const { title } = args;
    if (window._paq === undefined) {
      return;
    }

    const location = window.location.toString();
    if (location.includes("?token=")) {
      return;
    }

    // Set custom url since matomo can not handle history push events
    window._paq.push(["setCustomUrl", location]);
    window._paq.push(["trackPageView", title ? title : location]);
  }, []);

  return <TrackingContext.Provider value={{ trackEvent, trackPageView }}>{props.children}</TrackingContext.Provider>;
};

export const useTracking = () => useContext(TrackingContext);
