import { ApolloError } from "@apollo/client";
import { Grid } from "@mui/material";
import React, { useContext } from "react";
import { NoContentFound } from "../error/no-content-found.component";
import { NotificationCard } from "./notification-card.component";
import { INotificationCardData } from "./types/notification.types";
import { LoadingCard } from "../loading/loading-card.component";
import { OVERVIEW_LOADING_CARD_HEIGHT, OVERVIEW_SPACING_FACTOR } from "../../theme/sizings.theme";
import { LayoutContext } from "../layout/layout.context";

interface INotificationsOverviewProps {
  results: INotificationCardData[];
  loading: boolean;
  error?: ApolloError;
}

export const NotificationsOverview: React.FC<INotificationsOverviewProps> = (props) => {
  const { results, loading, error } = props;

  const { breakpoint } = useContext(LayoutContext);

  return (
    <Grid container spacing={OVERVIEW_SPACING_FACTOR[breakpoint]}>
      {loading && (
        <>
          {[...Array(6)].map((_, index) => (
            <Grid item xs={12} md={6} key={`event-loading-${index}`}>
              <LoadingCard height={OVERVIEW_LOADING_CARD_HEIGHT[breakpoint]} />
            </Grid>
          ))}
        </>
      )}
      {!loading && results.length <= 0 && !error && <NoContentFound variant="notificationOverview" />}
      {!loading && error && results.length <= 0 && (
        <Grid item xs={12}>
          <NoContentFound variant="notificationOverviewError" />
        </Grid>
      )}
      {!loading &&
        results.map((notification) => {
          return (
            <Grid item xs={12} md={6} key={notification.id}>
              <NotificationCard entry={notification} />
            </Grid>
          );
        })}
    </Grid>
  );
};
