import { Button, Dialog, DialogContent, Typography } from "@mui/material";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { CustomDialogTitle } from "./custom-dialog-title.component";
import { ISvgIconBaseProps } from "../../assets/icons/icons.component";
import { CustomDialogActions } from "./custom-dialog-actions.component";
import { DIALOG_MARGIN_FACTOR } from "../../theme/sizings.theme";
import { LayoutContext } from "../layout/layout.context";

interface IConfirmDialogProps {
  handleClose: () => void;
  handleConfirmation: () => void;
  isOpen: boolean;
  title: string;
  content: string;
  icon: React.FC<ISvgIconBaseProps>;
  cancelText?: string;
  confirmText?: string;
}

export const ConfirmDialog: React.FC<IConfirmDialogProps> = (props) => {
  const { handleClose, handleConfirmation, isOpen, title, content, cancelText, confirmText, icon } = props;
  const { t } = useTranslation();

  const { breakpoint } = useContext(LayoutContext);

  return (
    <Dialog
      id="confirmation-dialog"
      open={isOpen}
      onClose={handleClose}
      sx={{
        minWidth: "auto",
        p: 0,
        m: DIALOG_MARGIN_FACTOR[breakpoint],
      }}
    >
      <CustomDialogTitle title={title} Icon={icon} iconProps={{ sx: { color: "primaryDark.main" } }} />
      <DialogContent>
        <Typography variant="body1">{content}</Typography>
      </DialogContent>
      <CustomDialogActions>
        <Button variant="outlined" onClick={handleClose}>
          {cancelText ?? t("general.cancel")}
        </Button>
        <Button variant="contained" onClick={handleConfirmation}>
          {confirmText ?? t("general.confirm")}
        </Button>
      </CustomDialogActions>
    </Dialog>
  );
};
