import { Box, FormLabel, Switch, Typography } from "@mui/material";
import { FC, useContext } from "react";
import { useTranslation } from "react-i18next";
import { IWidgetActionItemBaseProps } from "../dictionaries/widget-action.dictionary";
import { WidgetFavoriteFilterContext } from "../context/widget-favorite-filter.context";

interface IWidgetFavoriteFilterProp extends IWidgetActionItemBaseProps {}

export const WidgetFavoriteFilter: FC<IWidgetFavoriteFilterProp> = (props) => {
  const { color, isSmall } = props;

  const { setShowOnlyFavorites, showOnlyFavorites } = useContext(WidgetFavoriteFilterContext);
  const { t } = useTranslation();

  return (
    <Box sx={{ display: isSmall ? "block" : "flex" }}>
      <Switch
        id="filter-favorite"
        size="small"
        inputProps={{ "aria-labelledby": "favorite-switch-text" }}
        sx={{
          color,
          "&.MuiSwitch-root .MuiSwitch-switchBase": {
            width: 14,
            height: 14,
            p: 0,
            m: 0,
            "&.Mui-checked": {
              transform: "translateX(6px)",
              color: "primary.contrastText",
              "& + .MuiSwitch-track": {
                backgroundColor: color,
                opacity: 0.3,
              },
            },
          },
          "&.MuiSwitch-root .MuiSwitch-track": {
            opacity: 1,
            backgroundColor: "transparent",
          },
          "&.MuiSwitch-root .MuiSwitch-thumb": {
            width: 10,
            height: 10,
            mb: 0.5,
            color,
          },
          "&.MuiSwitch-root": {
            width: 24,
            height: 16,
            borderColor: color,
            borderWidth: 2,
            borderStyle: "solid",
            borderRadius: 10,
            p: 0,
            m: 0,
          },
        }}
        value={showOnlyFavorites}
        onChange={(_, checked: boolean) => {
          setShowOnlyFavorites(checked);
        }}
      />
      <FormLabel id="favorite-switch-label">
        <Typography id="favorite-switch-text" variant={isSmall ? "caption" : "body2"} sx={{ color, pl: 2 }}>
          {isSmall ? t("widgets.filter.favorites_short") : t("widgets.filter.favorites")}
        </Typography>
      </FormLabel>
    </Box>
  );
};
