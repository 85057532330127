import { EventType, FavoriteSource } from "../../../../api/muu-api/graphql/generated";

export const eventSourceToFavoriteSource = (type: EventType) => {
  switch (type) {
    case EventType.CAMPUSONLINE:
      return FavoriteSource.CAMPUS_ONLINE;
    case EventType.STRAPI_EVENT:
      return FavoriteSource.STRAPI;
    case EventType.TYPO3_EVENT:
      return FavoriteSource.TYPO3;
  }
};

export const favoriteSourceToEventSource = (source: FavoriteSource) => {
  switch (source) {
    case FavoriteSource.CAMPUS_ONLINE:
      return EventType.CAMPUSONLINE;
    case FavoriteSource.STRAPI:
      return EventType.STRAPI_EVENT;
    case FavoriteSource.TYPO3:
      return EventType.TYPO3_EVENT;
    default:
      return EventType.STRAPI_EVENT;
  }
};
