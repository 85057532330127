import { Box, Grid, Typography } from "@mui/material";
import { FC } from "react";
import { TopicLinksContent } from "../../../api/muu-api/graphql/generated";
import { TopicLink } from "./topic-link.content-component";

interface ITopicLinksProps {
  content: TopicLinksContent;
}

export const TopicLinks: FC<ITopicLinksProps> = (props) => {
  const { content } = props;

  return (
    <Box>
      <Typography variant="h6" sx={{ color: "primaryDark.main", mb: 2 }}>
        {content.title}
      </Typography>
      <Grid container flexDirection="column" spacing={1}>
        {content.links.map((link, index) => (
          <Grid item key={`${link.link}-${index}`} sx={{ flex: 0, width: "fit-content" }}>
            <TopicLink link={link} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};
