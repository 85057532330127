import { Box } from "@mui/material";
import { useNavigate } from "react-router";
import React, { useCallback } from "react";
import { APP_BAR } from "../../../theme/sizings.theme";
import { Logo } from "../../../assets/logo.component";
import { getAbsoluteRoutingPath } from "../../../utils/get-absolute-routing-path.util";
import { StaticPageDictionary } from "../../../router/routes.const";

export const MobileNavHeader: React.FC = () => {
  const navigate = useNavigate();

  const handleLogoClick = useCallback(() => {
    navigate(getAbsoluteRoutingPath(StaticPageDictionary.DASHBOARD.path));
  }, [navigate]);

  return (
    <Box
      sx={{
        display: "flex",
        cursor: "pointer",
        height: APP_BAR.DESKTOP,
        pl: 10,
        pt: 2,
      }}
      onClick={handleLogoClick}
    >
      <Logo size="large" />
    </Box>
  );
};
