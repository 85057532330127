import { Box, Grid, Paper, Typography } from "@mui/material";
import { FunctionComponent, useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { usePersonDetailsQuery } from "../../api/muu-api/graphql/generated";
import { PERSON_PROFILE_CARD_WIDTH, PERSON_PROFILE_IMG_SIZE, SPACING } from "../../theme/sizings.theme";
import { PersonDetailPersonHeader } from "../../components/person-detail/person-detail-person-header.component";
import { PersonDetails } from "../../components/person-detail/person-details.component";
import { LayoutContext } from "../../components/layout/layout.context";
import { NoContentFound } from "../../components/error/no-content-found.component";

interface IPersonDetailPageProps {}

export const PersonDetailPage: FunctionComponent<IPersonDetailPageProps> = (props) => {
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();
  const { breakpoint, breakpointInfo } = useContext(LayoutContext);

  const id = useMemo(() => searchParams.get("id"), [searchParams]);

  const { data, loading, error } = usePersonDetailsQuery({
    variables: { id: id! },
    skip: !id,
  });

  const minHeight = useMemo(() => {
    return PERSON_PROFILE_IMG_SIZE[breakpoint] + SPACING[breakpoint] * 12;
  }, [breakpoint]);

  const person = useMemo(() => data?.person || undefined, [data]);

  return (
    <Grid container direction="column" sx={{ display: "block" }}>
      <Grid item>
        <Typography variant="h4" color="primaryDark.main" sx={{ mb: 6 }}>
          {t("person_detail.profile")}
        </Typography>
      </Grid>
      <Grid item container>
        {(loading || !!person) && (
          <Paper
            sx={{
              borderRadius: 4,
              p: 0,
              minHeight,
              overflow: "hidden",
              width: "100%",
              maxWidth: PERSON_PROFILE_CARD_WIDTH[breakpoint],
            }}
          >
            <PersonDetailPersonHeader
              person={person}
              loading={loading}
              imageSize={PERSON_PROFILE_IMG_SIZE[breakpoint]}
            />
            <Box
              sx={{
                position: "relative",
                pt: 6,
                pl: 6,
                pb: 6,
                pr: breakpointInfo.isMobile ? 6 : `${minHeight}px`,
              }}
            >
              <PersonDetails person={person} loading={loading} />
            </Box>
          </Paper>
        )}
        {!loading && !person && !error && <NoContentFound variant="person" />}
        {error && <NoContentFound variant="personError" />}
      </Grid>
    </Grid>
  );
};
