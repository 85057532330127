import { Grid, Skeleton } from "@mui/material";
import React from "react";
import { LoadingText } from "../../components/loading/loading-text.component";

interface IThinkTankDetailSkeletonProps {}

export const ThinkTankDetailSkeleton: React.FC<IThinkTankDetailSkeletonProps> = (props) => {
  return (
    <Grid container spacing={4} sx={{ p: 14, width: "100%" }}>
      <Grid item xs={12}>
        <LoadingText lines={2} variant="h3" />
      </Grid>
      <Grid item container xs={12} spacing={2}>
        <Grid item xs={6} sx={{ display: "flex" }}>
          <Skeleton variant="circular" width={20} height={20} />
          <Skeleton variant="text" width="20%" sx={{ ml: 4 }} />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <LoadingText lines={5} variant="body1" />
      </Grid>
      <Grid item xs={3}>
        <LoadingText lines={5} variant="body1" />
      </Grid>
    </Grid>
  );
};
