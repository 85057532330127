import { Box, Divider, Grid, SwipeableDrawer, Typography, useTheme } from "@mui/material";
import React, { useContext, useEffect, useMemo } from "react";
import { LayoutContext } from "../layout.context";
import { BELL_DATA_DRAWER_WIDTH } from "../../../theme/sizings.theme";
import { useTranslation } from "react-i18next";
import { CustomIconButton } from "../../icon/custom-icon-button.component";
import { CloseIcon } from "../../../assets/icons/icons.component";
import { BellDataItem } from "./bell-data-item.component";
import { BellDataLoadingItem } from "./bell-data-loading-item.component";
import { BellData, useBellDataLazyQuery } from "../../../api/muu-api/graphql/generated";

interface IBellDataDrawerProps {
  open: boolean;
  onClose: VoidFunction;
  onOpen: VoidFunction;
}

export const BellDataDrawer: React.FC<IBellDataDrawerProps> = (props) => {
  const { open, onClose, onOpen } = props;
  const { breakpoint, breakpointInfo } = useContext(LayoutContext);
  const { t } = useTranslation();
  const theme = useTheme();

  const [bellDataQuery, { loading, data }] = useBellDataLazyQuery({
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    if (!open) {
      return;
    }

    bellDataQuery();
  }, [open, bellDataQuery]);

  const bellData = useMemo(() => (data?.bellData || []) as BellData[], [data]);

  return (
    <SwipeableDrawer
      open={open}
      anchor="right"
      onClose={onClose}
      disableBackdropTransition
      onOpen={onOpen}
      hysteresis={0.1}
      disableSwipeToOpen
      slotProps={{
        backdrop: {
          sx: { background: "transparent" },
        },
      }}
      PaperProps={{
        sx: {
          backdropFilter: "blur(8px)",
          backgroundColor: "transparentOverlay.main",
          width: breakpointInfo.isMobile ? "100%" : BELL_DATA_DRAWER_WIDTH[breakpoint],
          display: "flex",
        },
      }}
    >
      <Box>
        <Grid container alignItems="center" sx={{ p: 5 }}>
          <Grid item xs>
            <Typography variant="h6">{t("bell_data_drawer.title")}</Typography>
          </Grid>
          <Grid item>
            <CustomIconButton onClick={onClose}>
              <CloseIcon sx={{ color: theme.palette.text.primary }} />
            </CustomIconButton>
          </Grid>
        </Grid>
        <Divider />
        {loading && [...Array(4)].map((_, index) => <BellDataLoadingItem key={`bell-data-loading-${index}`} />)}
        {!loading &&
          bellData.length > 0 &&
          bellData.map((data) => (
            <BellDataItem key={`${data.entryId}-${data.type}`} bellData={data} onClose={onClose} />
          ))}
        {!loading && bellData.length <= 0 && (
          <Box sx={{ mt: 12 }}>
            <Typography sx={{ textAlign: "center", color: "grey.500" }}>
              {t("bell_data_drawer.no_notifications")}
            </Typography>
          </Box>
        )}
      </Box>
    </SwipeableDrawer>
  );
};
