import { IWidgetOptions } from "../types/widget.type";

export const getTransformValueForWidget = (
  isDraggingWidget: boolean,
  isDropAnimating: boolean,
  isDraggingOverRow: boolean,
  isDraggedWidgetInCurrentRow: boolean,
  currentWidgetIndex: number,
  spacing: number,
  widgetOptions: IWidgetOptions | null,
  initialX?: number,
  providedTransform?: string,
) => {
  if (isDraggingWidget || !widgetOptions) {
    // calculate correct transform (especially when moving widget from one row to another)
    if (isDropAnimating && initialX !== undefined && widgetOptions && providedTransform) {
      const translateY = providedTransform.split(", ")[1].split("px")[0];

      if (!translateY) {
        return providedTransform;
      }

      return `translate(${widgetOptions.placeHolderX - initialX}px, ${translateY}px)`;
    }

    // always display currently dragged widget in provided position
    // or show provided position if no calculated position is available
    return providedTransform;
  }

  if (!isDraggingOverRow) {
    return undefined;
  }

  // adjust x position for widgets in same row (if dragged widget is from same row)
  if (
    isDraggedWidgetInCurrentRow &&
    (currentWidgetIndex > widgetOptions.currentDropIndex ||
      (currentWidgetIndex === widgetOptions.currentDropIndex && currentWidgetIndex < widgetOptions.sourceIndex))
  ) {
    return `translate(${widgetOptions.width + spacing}px, 0px)`;
  }

  // adjust x position for widgets in other row (if dragged widget is not from same row)
  if (!isDraggedWidgetInCurrentRow && currentWidgetIndex >= widgetOptions.currentDropIndex) {
    return `translate(${widgetOptions.width + spacing}px, 0px)`;
  }

  return undefined;
};
