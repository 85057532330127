import React, { FunctionComponent } from "react";
import { Person } from "../../api/muu-api/graphql/generated";
import { ClockIcon, FaxIcon, InfoIcon, MailIcon, PhoneIcon, PlaceOfWorkIcon } from "../../assets/icons/icons.component";
import { PersonDetailRow } from "./person-detail-row.component";
import { Skeleton, Stack, Typography, useTheme } from "@mui/material";
import parse from "html-react-parser";
import { PhoneOrMailTypography } from "../typography/phone-or-mail-typography.component";
import { PersonDetailOrganizationRow } from "./person-detail-organization-row.component";
import { useTranslation } from "react-i18next";

interface IPersonDetailsProps {
  person?: Person;
  loading: boolean;
}

export const PersonDetails: FunctionComponent<IPersonDetailsProps> = (props) => {
  const { person, loading } = props;

  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <Stack id="person-details-container" sx={{ width: "100%" }} spacing={2}>
      {!person && loading && (
        <>
          <Skeleton variant="text" width={230} sx={{ fontSize: theme.typography.body1 }} />
          <Skeleton variant="text" width={100} sx={{ fontSize: theme.typography.body1 }} />
          <Skeleton variant="text" width={360} sx={{ fontSize: theme.typography.body1 }} />
        </>
      )}

      {person?.personExtra?.organizations &&
        person.personExtra?.organizations.map((organization) => (
          <PersonDetailOrganizationRow
            key={`organization-${organization.id}`}
            id={organization.id}
            name={organization.name}
            organizationFunction={organization.organizationFunction ?? undefined}
            iconColor={theme.palette.primaryDark.main}
          />
        ))}
      {person?.personExtra?.nonOrganizations &&
        person.personExtra.nonOrganizations.map((organization, index) => (
          <PersonDetailOrganizationRow
            key={`non-organization-${organization.id}`}
            id={organization.id}
            name={organization.name}
            organizationFunction={organization.organizationFunction ?? undefined}
            iconColor={theme.palette.primaryDark.main}
          />
        ))}
      {person?.email && (
        <PersonDetailRow
          left={<MailIcon style={{ color: theme.palette.primaryDark.main }} fontSize="medium" size={"xsmall"} />}
          right={<PhoneOrMailTypography type="mail" value={person.email} />}
        />
      )}
      {person?.phone && (
        <PersonDetailRow
          left={<PhoneIcon style={{ color: theme.palette.primaryDark.main }} fontSize="medium" size={"xsmall"} />}
          right={<Typography variant="body1">{parse(person?.phone)}</Typography>}
        />
      )}
      {person?.phoneExternal && (
        <PersonDetailRow
          left={<PhoneIcon style={{ color: theme.palette.primaryDark.main }} fontSize="medium" size={"xsmall"} />}
          right={<Typography variant="body1">{parse(person?.phoneExternal)}</Typography>}
        />
      )}
      {person?.mobile && (
        <PersonDetailRow
          left={<PhoneIcon style={{ color: theme.palette.primaryDark.main }} fontSize="medium" size={"xsmall"} />}
          right={<Typography variant="body1">{parse(person?.mobile)}</Typography>}
        />
      )}
      {person?.fax && (
        <PersonDetailRow
          left={<FaxIcon style={{ color: theme.palette.primaryDark.main }} fontSize="medium" size={"xsmall"} />}
          right={<Typography variant="body1">{parse(person?.fax)}</Typography>}
        />
      )}
      {person?.personExtra.placeOfWork && (
        <PersonDetailRow
          left={<PlaceOfWorkIcon style={{ color: theme.palette.primaryDark.main }} fontSize="medium" size={"xsmall"} />}
          right={
            <Typography>
              {t("person_detail.place_of_work", {
                address: person.personExtra.placeOfWork.address,
                room: person.personExtra.placeOfWork.room,
              })}
            </Typography>
          }
        />
      )}
      {person?.consultation && (
        <PersonDetailRow
          left={<ClockIcon style={{ color: theme.palette.primaryDark.main }} fontSize="medium" size={"xsmall"} />}
          right={<Typography variant="body1">{parse(person?.consultation)}</Typography>}
        />
      )}
      {person?.appendix && (
        <PersonDetailRow
          left={<InfoIcon style={{ color: theme.palette.primaryDark.main }} fontSize="medium" size={"xsmall"} />}
          right={<Typography variant="body1">{parse(person?.appendix)}</Typography>}
        />
      )}
    </Stack>
  );
};
