import { alpha, Box, useTheme } from "@mui/material";
import React, { FC } from "react";

const SIZE = 12;
const POSITION = -(SIZE / 2);

interface IPopoverArrowProps {}

export const PopoverArrow: FC<IPopoverArrowProps> = (props) => {
  const theme = useTheme();

  const borderStyle = `solid 1px ${alpha(theme.palette.grey[500], 0.12)}`;

  return (
    <Box
      sx={{
        width: SIZE,
        height: SIZE,
        display: "block",
        position: "absolute",
        content: "''",
        transform: "rotate(-135deg)",
        background: theme.palette.background.paper,
        borderRadius: "0 0 3px 0",
        top: POSITION,
        right: 20,
        borderBottom: borderStyle,
        borderRight: borderStyle,
      }}
      component="span"
    ></Box>
  );
};
