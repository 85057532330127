import {
  createContext,
  FunctionComponent,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Role, ThinkTankPost, useThinkTankPostLazyQuery } from "../../../api/muu-api/graphql/generated";
import { UserContext } from "../../../app/context/user.context";
import { IThinkTankPostFormData } from "../../../components/think-tank/think-tank-post-form.component";
import { useThinkTankPostCrud } from "../hooks/use-think-tank-post-crud.hook";

export enum ThinkTankMode {
  DETAIL = "DETAIL",
  EDIT = "EDIT",
}

export const THINK_TANK_MAX_ATTACHMENTS = 3;

interface IThinkTankContext {
  post: ThinkTankPost | undefined;
  isPostOwner: boolean;
  postLinksToProfile: boolean;
  isLoading: boolean;
  mode: ThinkTankMode;
  setMode: (mode: ThinkTankMode) => void;
  formData: IThinkTankPostFormData | undefined;
  setFormData: (formData: IThinkTankPostFormData) => void;
  handleNavigateToDashboard: VoidFunction;
  handleUpdateThinkTankPost: VoidFunction;
  handleDeleteThinkTankPost: VoidFunction;
  handleCancelEditThinkTankPost: VoidFunction;
}

export const ThinkTankContext = createContext<IThinkTankContext>({} as IThinkTankContext);

interface IThinkTankProviderProps extends PropsWithChildren {
  id: string | null;
}

export const ThinkTankProvider: FunctionComponent<IThinkTankProviderProps> = (props) => {
  const { id } = props;
  const { currentUser, language } = useContext(UserContext);
  const [mode, setMode] = useState(ThinkTankMode.DETAIL);
  const [formData, setFormData] = useState<IThinkTankPostFormData | undefined>();

  const [thinkTankPostQuery, { data, loading: postLoading }] = useThinkTankPostLazyQuery({
    variables: { id: id ?? "", language },
    onCompleted(data) {
      const post = data.thinkTankPost;
      if (!post) return;
      const initData = {
        role: post.role,
        type: post.type,
        title: post.title,
        category: post.thinkTankCategory ?? undefined,
        description: post.description,
        attachments: post.attachments,
      };
      setFormData(initData);
    },
  });

  useEffect(() => {
    if (!id) return;
    thinkTankPostQuery();
  }, [id, thinkTankPostQuery]);

  const post = useMemo(() => data?.thinkTankPost, [data]);

  const {
    handleUpdateThinkTankPost,
    handleDeleteThinkTankPost,
    handleNavigateToDashboard,
    loading: crudLoading,
  } = useThinkTankPostCrud({
    post,
    formData,
    setMode,
  });

  const isPostOwner = useMemo(
    () =>
      currentUser !== undefined &&
      currentUser.medUniId.toString() === post?.creatorMedUniId &&
      currentUser.role === post?.creatorRole,
    [currentUser, post],
  );

  const postLinksToProfile = useMemo(() => post?.creatorRole === Role.EMPLOYEE, [post]);

  const handleCancelEditThinkTankPost = useCallback(() => {
    setMode(ThinkTankMode.DETAIL);
    if (!post) return;
    const initData = {
      role: post.role,
      type: post.type,
      title: post.title,
      category: post.thinkTankCategory ?? undefined,
      description: post.description,
      attachments: post.attachments ?? [],
    };
    setFormData(initData);
  }, [post, setFormData, setMode]);

  return (
    <ThinkTankContext.Provider
      value={{
        post,
        isPostOwner,
        postLinksToProfile,
        isLoading: crudLoading || postLoading,
        mode,
        setMode,
        formData,
        setFormData,
        handleNavigateToDashboard,
        handleUpdateThinkTankPost,
        handleDeleteThinkTankPost,
        handleCancelEditThinkTankPost,
      }}
    >
      {props.children}
    </ThinkTankContext.Provider>
  );
};
