import { DateTime } from "luxon";
import { useContext, useEffect, useMemo } from "react";
import { useSearchEventByFavoriteLazyQuery, useSearchEventLazyQuery } from "../../../../api/muu-api/graphql/generated";
import { WidgetFavoriteFilterContext } from "../../../../pages/dashboard/widget-section/context/widget-favorite-filter.context";
import { formatDateTimeToShort } from "../../../../utils/date.util";
import { UserContext } from "../../../../app/context/user.context";
import { POLLING_INTERVAL } from "../../../../pages/dashboard/widget-section/dashboard-widgets.context";

export const useEventsForWidgetHook = (args: { limit: number; categoryNames: string[]; date: DateTime }) => {
  const { limit, categoryNames, date } = args;
  const { language } = useContext(UserContext);
  const { showOnlyFavorites } = useContext(WidgetFavoriteFilterContext);

  const variables = useMemo(
    () => ({
      limit,
      offset: 0,
      categories: categoryNames,
      dateRange: {
        from: formatDateTimeToShort(date),
        to: formatDateTimeToShort(date.plus({ months: 1 }).minus({ day: 1 })),
      },
      language,
    }),
    [date, limit, categoryNames, language],
  );

  const [searchEventsQuery, { data: searchData, loading: searchLoading }] = useSearchEventLazyQuery();

  const [searchEventsByFavoriteQuery, { data: searchFavoritesData, loading: searchFavoritesLoading }] =
    useSearchEventByFavoriteLazyQuery();

  useEffect(() => {
    if (showOnlyFavorites) {
      searchEventsByFavoriteQuery({ variables, pollInterval: POLLING_INTERVAL });
    } else {
      searchEventsQuery({ variables, pollInterval: POLLING_INTERVAL });
    }
  }, [searchEventsQuery, searchEventsByFavoriteQuery, showOnlyFavorites, variables]);

  const events = useMemo(() => {
    if (showOnlyFavorites) {
      return searchFavoritesData?.searchEventByFavorite.results || [];
    } else {
      return searchData?.searchEvent.results || [];
    }
  }, [showOnlyFavorites, searchData, searchFavoritesData]);

  return { events, loading: searchFavoritesLoading || searchLoading };
};
