import { Grid } from "@mui/material";
import { DateTime } from "luxon";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useSearchNotificationQuery } from "../../api/muu-api/graphql/generated";
import { CategoryDateRangeFilterBar } from "../../components/filter/bar/category-date-range-filter-bar.component";
import { IFullCategoryFilterInfo } from "../../components/filter/hooks/use-selected-categories.hook";
import { LoadingCard } from "../../components/loading/loading-card.component";
import { NotificationStateWithAll } from "../../components/notification/context/notification-state-filter.context";
import { DefaultNotificationStateFilter } from "../../components/notification/default-notification-state-filter.component";
import { NotificationsOverview } from "../../components/notification/notifications-overview.component";
import { INotificationCardData } from "../../components/notification/types/notification.types";
import { INFINITE_SCROLL_PAGE_OFFSET, OVERVIEW_SPACING_FACTOR } from "../../theme/sizings.theme";
import { formatDateTimeToShort } from "../../utils/date.util";
import { UserContext } from "../../app/context/user.context";
import { LayoutContext } from "../../components/layout/layout.context";

interface INotificationsPageProps {}

export const NotificationsPage: React.FC<INotificationsPageProps> = () => {
  const [from, setFrom] = useState<string | null>(null);
  const [to, setTo] = useState<string | null>(null);
  const { language } = useContext(UserContext);
  const [appliedCategories, setAppliedCategories] = useState<IFullCategoryFilterInfo[]>([]);
  const [fetchMoreOffset, setFetchMoreOffset] = useState<number>(20);
  const [isLoadingMore, setIsLoadingMore] = useState<boolean>(false);
  const [notificationState, setNotificationState] = useState<NotificationStateWithAll>("ALL");

  const { breakpoint } = useContext(LayoutContext);

  const { data, loading, error, fetchMore, refetch } = useSearchNotificationQuery({
    variables: {
      limit: 20,
      offset: 0,
      categories: appliedCategories.map((category) => category.category.searchFilter),
      dateRange: {
        from,
        to,
      },
      notificationState,
      language,
    },
  });

  useEffect(() => {
    refetch();
    setFetchMoreOffset(20);
  }, [from, to, appliedCategories, refetch, language]);

  const onApplyDateRangeFilter = useCallback((startDate: DateTime, endDate: DateTime) => {
    setFrom(formatDateTimeToShort(startDate));
    setTo(formatDateTimeToShort(endDate));
  }, []);

  const onApplyCategoryFilter = useCallback((categories: IFullCategoryFilterInfo[]) => {
    setAppliedCategories(categories);
  }, []);

  const onResetDateRangeFilter = useCallback(() => {
    setFrom(null);
    setTo(null);
  }, []);

  const onResetCategoryFilter = useCallback(() => {
    setAppliedCategories([]);
  }, []);

  const notificationEntries: INotificationCardData[] = useMemo(() => {
    setIsLoadingMore(false);
    return (data?.searchNotification.results ?? []).map((notification) => {
      return {
        id: notification.id,
        title: notification.title ?? "",
        teaser: notification.teaser ?? "",
        text: notification.text ?? "",
        publishedAt: new Date(notification.publishedAt),
        notificationState: notification.notificationState,
      };
    });
  }, [data]);

  const handleFetchMore = useCallback(
    (count: number) => {
      fetchMore({
        variables: {
          limit: 20,
          offset: count,
        },
        updateQuery: (previousQueryResult, { fetchMoreResult }) => {
          if (!fetchMoreResult || fetchMoreResult.searchNotification.results.length === 0) return previousQueryResult;
          return {
            ...previousQueryResult,
            searchNotification: {
              ...previousQueryResult.searchNotification,
              results: previousQueryResult.searchNotification.results.concat(
                fetchMoreResult.searchNotification.results,
              ),
            },
          };
        },
      });
    },
    [fetchMore],
  );

  useEffect(() => {
    const handleScroll = () => {
      const { scrollTop, scrollHeight, clientHeight } = document.documentElement;
      if (
        scrollTop + clientHeight >= scrollHeight - INFINITE_SCROLL_PAGE_OFFSET.DESKTOP &&
        !loading &&
        fetchMoreOffset < (data?.searchNotification.count ?? 0)
      ) {
        setIsLoadingMore(true);
        handleFetchMore(fetchMoreOffset);
        setFetchMoreOffset(fetchMoreOffset + 20);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [loading, fetchMoreOffset, setFetchMoreOffset, data?.searchNotification.count, handleFetchMore]);

  return (
    <Grid container id="events-overview-page" direction="row" sx={{ display: "block" }}>
      <CategoryDateRangeFilterBar
        applyDateRangeFilter={onApplyDateRangeFilter}
        resetDateRangeFilter={onResetDateRangeFilter}
        applyCategoryFilter={onApplyCategoryFilter}
        resetCategoryFilter={onResetCategoryFilter}
        appliedCategories={appliedCategories}
        appliedFromDate={from ? DateTime.fromFormat(from, "yyyy-MM-dd") : null}
        appliedToDate={to ? DateTime.fromFormat(to, "yyyy-MM-dd") : null}
        additionalFilters={[
          <DefaultNotificationStateFilter
            notificationState={notificationState}
            setNotificationState={(state) => setNotificationState(state)}
            variant="filled"
          />,
        ]}
      />
      <Grid item xs>
        <Grid container direction="column" sx={{ borderRadius: 4, p: 0, flex: 1, display: "flex" }}>
          <Grid item xs>
            <NotificationsOverview results={notificationEntries} loading={loading} error={error} />
          </Grid>
        </Grid>
      </Grid>
      {(loading || isLoadingMore) && (
        <Grid
          item
          xs={12}
          container
          direction="row"
          sx={{ borderRadius: 4, p: 0, pt: 6, flex: 1, display: "flex" }}
          spacing={OVERVIEW_SPACING_FACTOR[breakpoint]}
        >
          <Grid item xs>
            <LoadingCard height={190} />
          </Grid>
          <Grid item xs>
            <LoadingCard height={190} />
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};
