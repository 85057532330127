import { Grid } from "@mui/material";
import React, { useContext } from "react";
import { LayoutContext } from "../../layout/layout.context";

interface IFilterBarProps {
  children: React.ReactNode;
}

export const FilterBar: React.FC<IFilterBarProps> = (props) => {
  const { children } = props;

  const { breakpointInfo } = useContext(LayoutContext);

  return (
    <Grid
      item
      xs={12}
      container
      alignContent={breakpointInfo.isMobile ? "stretch" : "flex-start"}
      justifyContent="flex-start"
      spacing={3}
      sx={{ pb: 6 }}
      flexDirection={breakpointInfo.isMobile ? "column" : "row"}
    >
      {children}
    </Grid>
  );
};
