import React, { Fragment, useCallback } from "react";
import {
  SearchConfluencePage,
  SearchEvent,
  SearchInfocenter,
  SearchLegalDocument,
  SearchNotification,
  SearchOrganizationalUnit,
  SearchPerson,
  SearchResultAll,
  SearchThinkTank,
  SearchTopicPage,
} from "../../api/muu-api/graphql/generated";
import { EventsSearchResult } from "./events-search-result.component";
import { InfocenterSearchResult } from "./infocenter-search-result.component";
import { NotificationsSearchResult } from "./notifications-search-result.component";
import { PersonsSearchResult } from "./persons-search-result.component";
import { OrganizationalUnitSearchResult } from "./organizational-unit-search-result.component";
import { LegalDocumentsSearchResult } from "./legal-documents-search-result.component";
import { ConfluencePagesSearchResult } from "./confluence-pages-search-result.component";
import { ThinkTankSearchResult } from "./think-tank-search-result.component";
import { TopicPageSearchResult } from "./topics-search-result.component";

const LOADING_ITEMS_PLACEHOLDER_ARRAY = [1, 2, 3, 4, 5, 6];

interface ISearchResultsProps {
  results: SearchResultAll[];
  loading: boolean;
}

export const SearchResults: React.FC<ISearchResultsProps> = (props) => {
  const { results, loading } = props;

  const renderResult = useCallback(
    (result: SearchResultAll, index: number) => {
      switch (result.__typename) {
        case "SearchPerson":
          return (
            <PersonsSearchResult
              key={result.id}
              person={result as SearchPerson}
              isLastItem={index === results.length - 1}
              loading={false}
            />
          );
        case "SearchInfocenter":
          return (
            <InfocenterSearchResult
              key={result.id}
              infocenter={result as SearchInfocenter}
              isLastItem={index === results.length - 1}
              loading={false}
            />
          );
        case "SearchEvent":
          return (
            <EventsSearchResult
              key={result.id}
              searchEvent={result as SearchEvent}
              loading={false}
              isLastItem={index === results.length - 1}
            />
          );
        case "SearchNotification":
          return (
            <NotificationsSearchResult
              key={result.id}
              searchNotification={result as SearchNotification}
              loading={false}
              isLastItem={index === results.length - 1}
            />
          );
        case "SearchTopicPage":
          return (
            <TopicPageSearchResult
              key={result.id}
              searchTopicPage={result as SearchTopicPage}
              loading={false}
              isLastItem={index === results.length - 1}
            />
          );
        case "SearchOrganizationalUnit":
          return (
            <OrganizationalUnitSearchResult
              key={result.id}
              searchOrganizationalUnit={result as SearchOrganizationalUnit}
              loading={false}
              isLastItem={index === results.length - 1}
            />
          );
        case "SearchConfluencePage":
          return (
            <ConfluencePagesSearchResult
              key={result.id}
              searchConfluencePage={result as SearchConfluencePage}
              loading={false}
              isLastItem={index === results.length - 1}
            />
          );
        case "SearchLegalDocument":
          return (
            <LegalDocumentsSearchResult
              key={result.id}
              legalDocument={result as SearchLegalDocument}
              loading={false}
              isLastItem={index === results.length - 1}
            />
          );
        case "SearchThinkTank":
          return (
            <ThinkTankSearchResult
              key={result.id}
              searchThinkTank={result as SearchThinkTank}
              loading={false}
              isLastItem={index === results.length - 1}
            />
          );
        default:
          return null;
      }
    },
    [results],
  );

  const renderLoadingItem = useCallback((_: number, index: number) => {
    // TODO: add new loading placeholder types once we search for more data
    return (
      <PersonsSearchResult
        key={`loading-${index}`}
        isLastItem={index === LOADING_ITEMS_PLACEHOLDER_ARRAY.length - 1}
        loading={true}
      />
    );
  }, []);

  return (
    <Fragment>
      {loading && LOADING_ITEMS_PLACEHOLDER_ARRAY.map(renderLoadingItem)}
      {!loading && results.map(renderResult)}
    </Fragment>
  );
};
