import { Drawer } from "@mui/material";
import { Box } from "@mui/system";
import { NAV_WIDTH } from "../../../theme/sizings.theme";
import { useContext } from "react";
import { LayoutContext } from "../layout.context";
import { NavMenu } from "../nav/nav-menu/nav-menu.component";
import { MobileNavHeader } from "./mobile-nav-header.component";
import { NavMenuContext } from "../nav/context/nav-menu.context";

export const MobileNav: React.FC = () => {
  const { sidebarOpen: isOpen, setSidebarOpen: setIsOpen, breakpoint } = useContext(LayoutContext);
  const { menusWithPortalRoutes } = useContext(NavMenuContext);

  return (
    <Box component="nav" sx={{ flexShrink: { xl: 0 }, width: { xl: NAV_WIDTH[breakpoint] } }}>
      <Drawer
        open={isOpen}
        variant="temporary"
        PaperProps={{
          sx: {
            width: NAV_WIDTH[breakpoint],
            bgcolor: "background.default",
            borderRightStyle: "solid",
            borderColor: "rgba(0, 0, 0, 0.12)",
          },
        }}
        onClose={() => setIsOpen(!isOpen)}
      >
        <MobileNavHeader />
        {menusWithPortalRoutes.map((menu) => (
          <NavMenu key={menu.id} menu={menu} />
        ))}
      </Drawer>
    </Box>
  );
};
