import { Grid, useTheme } from "@mui/material";
import { useContext, useMemo } from "react";
import { MuuIcon } from "../../../../assets/icons/icons.component";
import { LayoutContext } from "../../layout.context";
import { IMenuItemWithPortalRoute } from "../context/nav-menu.context";
import { NavMenuItemIcon } from "./nav-menu-item-icon.component";
import { NavMenuItemSubmenuIndicator } from "./nav-menu-item-submenu-indicator.component";
import { NavMenuItemText } from "./nav-menu-item-text.component";
import { Media } from "../../../../api/muu-api/graphql/generated";
import { isValidIcon } from "../../../../app/helpers/strapi-media.helper";

interface NavMenuItemContentProps {
  item: IMenuItemWithPortalRoute;
  isOpen: boolean;
  isSelected: boolean;
  hasSubMenu: boolean;
  isHovering: boolean;
}

export const NavMenuItemContent: React.FC<NavMenuItemContentProps> = (props) => {
  const { item, isOpen, isSelected, hasSubMenu, isHovering } = props;
  const { sidebarOpen } = useContext(LayoutContext);
  const theme = useTheme();

  const hasStrapiLogo = useMemo(() => isValidIcon(item.icon), [item]);

  return (
    <Grid
      container
      sx={{ flex: 1, height: sidebarOpen ? theme.icon.medium : "unset" }}
      alignItems="center"
      alignContent="center"
      justifyContent={sidebarOpen ? "unset" : "center"}
      flexDirection={sidebarOpen ? "row" : "column"}
    >
      {item.level === 1 && (
        <Grid
          item
          xs="auto"
          sx={{
            position: "relative",
            width: sidebarOpen ? "unset" : "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <NavMenuItemIcon
            strapiMedia={hasStrapiLogo ? (item.icon as Media) : undefined}
            IconComponent={hasStrapiLogo ? undefined : MuuIcon}
            isSelected={isSelected}
            isOpen={isOpen}
            isHovering={isHovering}
          />
          {!sidebarOpen && hasSubMenu && (
            <NavMenuItemSubmenuIndicator
              sx={{ right: -theme.icon.medium / 1.4 }}
              isOpen={false}
              isSelected={isSelected}
              isHovering={isHovering}
            />
          )}
        </Grid>
      )}

      <Grid item xs sx={{ overflow: "hidden" }}>
        <NavMenuItemText isOpen={isOpen} isSelected={isSelected} isHovering={isHovering}>
          {item.title}
        </NavMenuItemText>
      </Grid>
    </Grid>
  );
};
