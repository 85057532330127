import { useEffect } from "react";
import {
  BellLiveCountDocument,
  BellLiveCountSubscription,
  useBellDataCountQuery,
} from "../../../../api/muu-api/graphql/generated";

export const useBellDataCount = () => {
  const { data, subscribeToMore } = useBellDataCountQuery();

  useEffect(() => {
    subscribeToMore({
      document: BellLiveCountDocument,
      updateQuery: (previous, data) => {
        const { subscriptionData } = data;

        if (!subscriptionData.data) {
          return previous;
        }

        return {
          ...previous,
          bellDataCount: (subscriptionData.data as unknown as BellLiveCountSubscription).bellLiveCount,
        };
      },
    });
  }, [subscribeToMore]);

  return data?.bellDataCount || 0;
};
