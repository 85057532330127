import { Avatar, Box } from "@mui/material";
import React, { useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Role, useProfilePictureQuery, useUserDetailsQuery } from "../../../api/muu-api/graphql/generated";
import { EDIT_DASHBOARD_PATH } from "../../../pages/dashboard/widget-section/types/widget.type";
import { StaticPageDictionary } from "../../../router/routes.const";
import { getAbsoluteRoutingPath } from "../../../utils/get-absolute-routing-path.util";
import { CustomIconButton } from "../../icon/custom-icon-button.component";
import { ISelectionPopoverOption, SelectionPopover } from "../../popover/selection-popover";
import { useProfileSelectionPopoverOptions } from "../nav/hooks/use-profile-selection-popover-options.hook";
import { useTranslation } from "react-i18next";

export enum ProfileOptions {
  EDIT_DASHBOARD = "EDIT_DASHBOARD",
  PROFILE_CARD = "PROFILE_CARD",
  SETTINGS = "SETTINGS",
}

export const Profile: React.FC = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState<HTMLButtonElement | null>(null);

  const { data: userDetails } = useUserDetailsQuery();
  const navigate = useNavigate();
  const { options } = useProfileSelectionPopoverOptions();

  const { medUniId, role } = useMemo(() => {
    return { medUniId: userDetails?.userDetails.medUniId, role: userDetails?.userDetails.role };
  }, [userDetails]);

  const { data: profilePictureDetails } = useProfilePictureQuery({
    variables: { id: medUniId ? medUniId.toString() : "" },
    skip: !medUniId || role !== Role.EMPLOYEE, // only query details when user is employee, as we are not allowed to use images of students!
  });

  const avatar = useMemo(() => profilePictureDetails?.person?.avatar ?? undefined, [profilePictureDetails]);

  const onClose = useCallback(() => {
    setOpen(null);
  }, []);

  const filteredOptions = useMemo(() => {
    if (role === Role.STUDENT) {
      return options.filter((option) => option.key !== ProfileOptions.PROFILE_CARD);
    }
    return options;
  }, [options, role]);

  const onOptionSelected = useCallback(
    (option: ISelectionPopoverOption) => {
      onClose();

      switch (option.key) {
        case ProfileOptions.EDIT_DASHBOARD:
          navigate(`${StaticPageDictionary.DASHBOARD.path}${EDIT_DASHBOARD_PATH}`);
          break;
        case ProfileOptions.PROFILE_CARD:
          navigate({
            pathname: getAbsoluteRoutingPath(StaticPageDictionary.PERSON_DETAIL.path),
            search: `id=${userDetails?.userDetails.medUniId}`,
          });
          break;
        case ProfileOptions.SETTINGS:
          navigate({
            pathname: getAbsoluteRoutingPath(StaticPageDictionary.SETTINGS.path),
          });
          break;
      }
    },
    [onClose, navigate, userDetails],
  );

  const onProfileClicked = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      if (options.length) {
        setOpen(event.currentTarget);
      }
    },
    [options],
  );

  return (
    <Box sx={{ pl: 2 }}>
      <CustomIconButton
        onClick={onProfileClicked}
        aria-label="Profil"
        sx={{
          p: 0,
        }}
        title={t("dashboard.tooltip.profile")}
      >
        <Avatar
          src={avatar}
          alt="profileUrl"
          sx={{
            borderRadius: 2.5,
          }}
        />
      </CustomIconButton>
      <SelectionPopover
        options={filteredOptions}
        open={Boolean(open && filteredOptions.length)}
        anchorEl={open}
        onClose={onClose}
        onOptionSelected={onOptionSelected}
        selectedKey={null}
      />
    </Box>
  );
};
