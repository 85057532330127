import { Grid, Typography } from "@mui/material";
import { FC, useCallback, useMemo } from "react";
import { ThinkTankCategoryFilterItem } from "./think-tank-category-filter-item.component";
import { IFullThinkTankCategoryFilterInfo } from "./hooks/use-selected-think-tank-categories.hook";
import { ThinkTankCategory, WidgetType } from "../../api/muu-api/graphql/generated";
import { NONE_CATEGORY } from "../../app/helpers/const.helper";
import { useTranslation } from "react-i18next";

interface ICategoryFilterProps {
  categories: ThinkTankCategory[];
  selectedCategories: IFullThinkTankCategoryFilterInfo[];
  setSelectedCategories: (newCategories: IFullThinkTankCategoryFilterInfo[]) => void;
}

export const ThinkTankCategoryFilter: FC<ICategoryFilterProps> = (props) => {
  const { selectedCategories, setSelectedCategories, categories } = props;
  const { t } = useTranslation();

  const thinkTankCategoryFilterOptions: IFullThinkTankCategoryFilterInfo[] = useMemo(() => {
    return categories.map((category) => {
      const selectedCategory = selectedCategories.find(
        (selectedCategory) => selectedCategory.category.id === category.id,
      );
      return {
        category,
        isLocked: selectedCategory?.isLocked ?? false,
      };
    });
  }, [categories, selectedCategories]);

  const onClick = useCallback(
    (categoryFilterInfo: IFullThinkTankCategoryFilterInfo) => {
      const selectedCategoryInfo = selectedCategories.find(
        (categoryInfo) => categoryInfo.category.id === categoryFilterInfo.category.id,
      );
      if (selectedCategoryInfo && selectedCategoryInfo.isLocked) return;

      if (selectedCategoryInfo) {
        setSelectedCategories([
          ...selectedCategories.filter(
            (selectedCategory) => selectedCategory.category.id !== categoryFilterInfo.category.id,
          ),
        ]);
      } else {
        setSelectedCategories([...selectedCategories, categoryFilterInfo]);
      }
    },
    [selectedCategories, setSelectedCategories],
  );

  return (
    <Grid container direction="row" spacing={2}>
      {thinkTankCategoryFilterOptions.length === 0 && (
        <Typography variant="body1">{t(`widgets.${WidgetType.THINK_TANK}.no_category`)}</Typography>
      )}
      {thinkTankCategoryFilterOptions
        .filter((entry) => entry.category.searchFilter !== NONE_CATEGORY)
        .map((categoryFilterInfo) => (
          <Grid item xs={12} sm={6} key={categoryFilterInfo.category.id}>
            <ThinkTankCategoryFilterItem
              categoryFilterInfo={categoryFilterInfo}
              isSelected={selectedCategories
                .map((selectedCategory) => selectedCategory.category.id)
                .includes(categoryFilterInfo.category.id)}
              onClick={() => onClick(categoryFilterInfo)}
            />
          </Grid>
        ))}
    </Grid>
  );
};
