import { useContext, useEffect, useMemo } from "react";
import { Category, SearchInfocenterQuery, useSearchInfocenterQuery } from "../../../api/muu-api/graphql/generated";
import { UpdateQueryType, useInfiniteScrolling } from "../../../hooks/use-infinite-scrolling.hook";
import { UserContext } from "../../../app/context/user.context";

export const useInfocenterEntriesWithFetchMore = (args: {
  from: string | null;
  to: string | null;
  appliedCategories: Category[];
}) => {
  const { from, to, appliedCategories } = args;
  const { language } = useContext(UserContext);
  const {
    data,
    loading: isInitialQueryLoading,
    fetchMore,
    error,
  } = useSearchInfocenterQuery({
    variables: {
      limit: 21,
      offset: 0,
      categories: appliedCategories.map((category) => category.searchFilter),
      dateRange: {
        from,
        to,
      },
      language,
    },
  });

  const updateQuery: UpdateQueryType<SearchInfocenterQuery> = (previousQueryResult, { fetchMoreResult }) => {
    if (!fetchMoreResult || fetchMoreResult.searchInfocenter.results.length === 0) return previousQueryResult;
    return {
      ...previousQueryResult,
      searchInfocenter: {
        ...previousQueryResult.searchInfocenter,
        results: previousQueryResult.searchInfocenter.results.concat(fetchMoreResult.searchInfocenter.results),
      },
    };
  };

  const { isLoadingMore, setIsLoadingMore, setFetchMoreOffset } = useInfiniteScrolling({
    fetchMore,
    updateQuery,
    isInitialQueryLoading,
    itemCount: data?.searchInfocenter.count,
  });

  useEffect(() => {
    setFetchMoreOffset(20);
  }, [from, to, appliedCategories, language]);

  const searchResults = useMemo(() => {
    setIsLoadingMore(false);
    return data?.searchInfocenter.results || [];
  }, [data]);

  return { isLoadingMore, error, isInitialQueryLoading, setIsLoadingMore, searchResults };
};
