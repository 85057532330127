import { Divider, Grid, Skeleton } from "@mui/material";
import React from "react";
import { LoadingText } from "../loading/loading-text.component";

export const PersonDetailCardImageInformationSkeleton: React.FC = () => {
  return (
    <Grid container direction="column">
      <Grid item xs={12} container direction="row" justifyContent="flex-start" alignItems="center" columnSpacing={4}>
        <Grid item xs={2}>
          <Skeleton variant="rectangular" width={88} height={88} />
        </Grid>
        <Grid item xs={10}>
          <Grid item xs={12}>
            <LoadingText lines={2} variant="subtitle1" />
          </Grid>
          <Grid item xs={12}>
            <LoadingText lines={2} variant="body1" />
          </Grid>
        </Grid>
      </Grid>
      <Grid item sx={{ mt: 4, mb: 4 }}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <LoadingText lines={2} variant="body1" />
      </Grid>
      <Grid item xs={12}>
        <LoadingText lines={2} variant="body1" />
      </Grid>
    </Grid>
  );
};
