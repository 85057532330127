import { Box } from "@mui/material";
import React, { useContext } from "react";
import { ThinkTankPostForm } from "../../components/think-tank/think-tank-post-form.component";
import { ThinkTankContext } from "./context/think-tank.context";
import { PAGE_PAPER_PADDING_HORIZONTAL_FACTOR, PAGE_PAPER_PADDING_VERTICAL_FACTOR } from "../../theme/sizings.theme";
import { LayoutContext } from "../../components/layout/layout.context";

interface IThinkTankEditPageContentProps {}

export const ThinkTankEditPageContent: React.FC<IThinkTankEditPageContentProps> = (props) => {
  const { formData, setFormData } = useContext(ThinkTankContext);
  const { breakpoint } = useContext(LayoutContext);

  return (
    <Box
      sx={{
        pt: PAGE_PAPER_PADDING_VERTICAL_FACTOR[breakpoint],
        pb: PAGE_PAPER_PADDING_VERTICAL_FACTOR[breakpoint],
        pl: PAGE_PAPER_PADDING_HORIZONTAL_FACTOR[breakpoint],
        pr: PAGE_PAPER_PADDING_HORIZONTAL_FACTOR[breakpoint],
      }}
    >
      {formData && <ThinkTankPostForm formData={formData} setFormData={setFormData} />}
    </Box>
  );
};
