import { Accordion, AccordionDetails, AccordionProps, AccordionSummary, Box, Typography } from "@mui/material";
import React, { useState } from "react";
import { ChevronDownIcon } from "../../assets/icons/icons.component";

interface ICustomAccordionProps extends AccordionProps {
  icon?: JSX.Element;
  title?: string;
  isFirstInAccordionList?: boolean;
}

export const CustomAccordion: React.FC<ICustomAccordionProps> = (props) => {
  const { children, title, icon, isFirstInAccordionList = false, ...rest } = props;

  const [open, setOpen] = useState<boolean>(false);

  return (
    <Accordion
      {...rest}
      disableGutters
      expanded={open}
      sx={{
        "&:before": {
          display: "none",
        },
        borderStyle: "solid",
        boxShadow: "none",
        borderColor: "grey.100",
        borderLeft: "none",
        borderRight: "none",
        borderWidth: 1,
        transition: ".3s",
        "&.MuiAccordion-root:first-of-type": {
          borderRadius: 0,
        },
        "&.MuiAccordion-root:last-of-type": {
          borderRadius: 0,
        },
        "&.MuiAccordion-root": {
          borderTop: !isFirstInAccordionList ? "none" : undefined,
        },
      }}
    >
      <AccordionSummary
        expandIcon={<ChevronDownIcon size="small" />}
        onClick={() => setOpen(!open)}
        sx={{
          backgroundColor: "transparent",
          pl: 0,
          pb: open ? 2 : 0,
          pt: open ? 2 : 0,
          transition: ".3s",
          ".MuiAccordionSummary-expandIconWrapper": {
            color: "grey.850",
          },
        }}
      >
        {icon && <Box sx={{ mr: 4 }}>{icon}</Box>}
        {title && (
          <Typography variant="subtitle1" fontWeight={700} sx={{ color: "text.primary" }}>
            {title}
          </Typography>
        )}
      </AccordionSummary>
      <AccordionDetails sx={{ borderRadius: 0, p: 0, pb: 4, pt: 2 }}>{children}</AccordionDetails>
    </Accordion>
  );
};
