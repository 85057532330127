import { Box, Chip, Divider, Stack, Typography } from "@mui/material";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Restaurant } from "../../api/muu-api/graphql/generated";
import { RestaurantFilterItem } from "./restaurant-filter-item.component";

interface IRestaurantFilterProps {
  restaurants: Restaurant[];
  selectedRestaurants: Restaurant[];
  setSelectedRestaurants: (newRestaurants: Restaurant[]) => void;
}

export const RestaurantFilter: React.FC<IRestaurantFilterProps> = (props) => {
  const { selectedRestaurants, setSelectedRestaurants, restaurants } = props;

  const { t } = useTranslation();

  const onAddSelectedRestaurant = useCallback(
    (restaurant: Restaurant) => {
      setSelectedRestaurants([...selectedRestaurants, restaurant]);
    },
    [selectedRestaurants, setSelectedRestaurants],
  );

  const onRemoveSelectedRestaurant = useCallback(
    (restaurant: Restaurant) => {
      setSelectedRestaurants([
        ...selectedRestaurants.filter((selectedRestaurant) => selectedRestaurant.id !== restaurant.id),
      ]);
    },
    [selectedRestaurants, setSelectedRestaurants],
  );

  const unselectedRestaurants = useMemo(() => {
    return restaurants.filter((restaurant) => !selectedRestaurants.includes(restaurant));
  }, [restaurants, selectedRestaurants]);

  const areAnyRestaurantsSelected = useMemo(() => selectedRestaurants.length > 0, [selectedRestaurants]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        rowGap: 2,
      }}
    >
      <Typography variant="body1" sx={{ fontWeight: 700 }}>
        {t("widgets.filter.selected_restaurants")}
      </Typography>
      {!areAnyRestaurantsSelected && (
        <Typography variant="body1">{t("widgets.filter.no_restaurants_selected")}</Typography>
      )}

      <Box sx={{ flexDirection: "column" }}>
        {selectedRestaurants.map((restaurant) => (
          <Chip
            key={restaurant.id}
            label={restaurant.name}
            onDelete={() => onRemoveSelectedRestaurant(restaurant)}
            sx={{ m: 1, width: "fit-content" }}
          />
        ))}
      </Box>

      {restaurants.length - selectedRestaurants.length > 0 && <Divider orientation="horizontal" sx={{ pt: 2 }} />}

      <Stack spacing={2} divider={<Divider orientation="horizontal" />}>
        {unselectedRestaurants.map((restaurant) => (
          <RestaurantFilterItem
            key={restaurant.id}
            restaurant={restaurant}
            onClick={() => onAddSelectedRestaurant(restaurant)}
          />
        ))}
      </Stack>

      {restaurants.length - selectedRestaurants.length > 0 && <Divider orientation="horizontal" />}
    </Box>
  );
};
