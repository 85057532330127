import { Paper, Typography } from "@mui/material";
import React from "react";
import { TopicPageDictionary } from "../../router/routes.const";
import { CustomLink } from "../../components/link/custom-link.component";

export interface ISubTopicPageData {
  itemName: string;
  pageTitle: string;
  pageId: string;
}

interface ITopicPageSubMenuItemProps {
  subPageData: ISubTopicPageData;
}

export const TopicPageSubMenuItem: React.FC<ITopicPageSubMenuItemProps> = (props) => {
  const { subPageData } = props;

  return (
    <CustomLink
      href={`/${TopicPageDictionary[subPageData.pageId].route}`}
      aria-label={subPageData.itemName}
      style={{ textDecoration: "none", color: "inherit", font: "inherit", textAlign: "left" }}
    >
      <Paper
        sx={{
          borderRadius: 4,
          pr: 4,
          pl: 4,
          pt: 9,
          pb: 9,
        }}
      >
        <Typography variant="h6">{subPageData.pageTitle}</Typography>
      </Paper>
    </CustomLink>
  );
};
