export const getAbsoluteRoutingPath = (path: string) => `/${path}`;
export const getAbsoluteRoutingPathNameAndSearchParams = (path: string) => {
  const fullPath = path.split("?");
  let navigationParams = {
    pathname: `/${fullPath[0]}`,
    search: "",
  };

  if (fullPath.length > 1) {
    navigationParams.search = fullPath[1];
  }
  return navigationParams;
};
