import { useMemo } from "react";
import { parseRelativeLink } from "../app/helpers/relative-link.helper";

export enum LinkState {
  VALID = "VALID",
  NOT_A_HTTP_LINK = "NOT_A_HTTP_LINK",
  EMPTY = "EMPTY",
}

export const useCheckLink = (link: string | undefined) => {
  const {
    link: parsedLink,
    relativeLink,
    isExternal,
    isImage,
    isAnchor,
  } = useMemo(() => {
    if (link) {
      return parseRelativeLink(link);
    }
    return {
      link: undefined,
      relativeLink: undefined,
      isExternal: undefined,
      isImage: undefined,
      isAnchor: undefined,
    };
  }, [link]);

  const linkStatus = useMemo(() => {
    if (!link) {
      return LinkState.EMPTY;
    }

    return LinkState.VALID;
  }, [link]);

  return { linkStatus, relativeLink, isExternal, isImage, parsedLink, isAnchor };
};
