//NOTE: btoa and atob are not deprecated in web applications, VSCode falsely marks it as deprecated

interface IURLArgs<T> {
  type?: T;
  localize?: boolean;
}

interface IURLArgsWithId<T> extends IURLArgs<T> {
  id?: string;
}

export const encodeIdBase64 = <T>(id: string, args?: IURLArgs<T>): string => btoa(JSON.stringify({ id, ...args }));

export const decodeIdBase64 = <T>(encoded: string): IURLArgsWithId<T> => JSON.parse(atob(encoded));

export const encodeArgsBase64 = <T>(args?: IURLArgs<T>): string => btoa(JSON.stringify(args));

export const decodeArgsBase64 = <T>(encoded: string): IURLArgs<T> => JSON.parse(atob(encoded));
