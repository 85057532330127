import { Grid } from "@mui/material";
import { DateTime } from "luxon";
import React, { useCallback, useContext, useState } from "react";
import { CategoryDateRangeFilterBar } from "../../components/filter/bar/category-date-range-filter-bar.component";
import { IFullCategoryFilterInfo } from "../../components/filter/hooks/use-selected-categories.hook";
import { useURLSelectedCategories } from "../../components/filter/hooks/use-url-selected-categories.hook";
import { InfocenterOverview } from "../../components/infocenter/infocenter-overview.component";
import { LoadingCard } from "../../components/loading/loading-card.component";
import { formatDateTimeToShort } from "../../utils/date.util";
import { useInfocenterEntriesWithFetchMore } from "./hooks/use-infocenter-entries-with-fetch-more.hook";
import { OVERVIEW_SPACING_FACTOR } from "../../theme/sizings.theme";
import { LayoutContext } from "../../components/layout/layout.context";

interface IInfocenterPageProps {}

export const InfocenterPage: React.FC<IInfocenterPageProps> = () => {
  const [from, setFrom] = useState<string | null>(null);
  const [to, setTo] = useState<string | null>(null);
  const { breakpoint } = useContext(LayoutContext);
  const { selectedCategories: appliedCategories, setSelectedCategories: setAppliedCategories } =
    useURLSelectedCategories();

  const { isLoadingMore, isInitialQueryLoading, error, searchResults } = useInfocenterEntriesWithFetchMore({
    from,
    to,
    appliedCategories,
  });

  const onApplyDateRangeFilter = useCallback((startDate: DateTime, endDate: DateTime) => {
    setFrom(formatDateTimeToShort(startDate));
    setTo(formatDateTimeToShort(endDate));
  }, []);

  const onApplyCategoryFilter = useCallback(
    (categories: IFullCategoryFilterInfo[]) => {
      setAppliedCategories(categories.map((category) => category.category));
    },
    [setAppliedCategories],
  );

  const onResetDateRangeFilter = useCallback(() => {
    setFrom(null);
    setTo(null);
  }, []);

  const onResetCategoryFilter = useCallback(() => {
    setAppliedCategories([]);
  }, [setAppliedCategories]);

  return (
    <Grid container id="infocenter-overview-page" direction="row" sx={{ display: "block" }}>
      <CategoryDateRangeFilterBar
        applyDateRangeFilter={onApplyDateRangeFilter}
        resetDateRangeFilter={onResetDateRangeFilter}
        applyCategoryFilter={onApplyCategoryFilter}
        resetCategoryFilter={onResetCategoryFilter}
        appliedCategories={appliedCategories.map((category) => {
          return { category, isLocked: false };
        })}
        appliedFromDate={from ? DateTime.fromFormat(from, "yyyy-MM-dd") : null}
        appliedToDate={to ? DateTime.fromFormat(to, "yyyy-MM-dd") : null}
        disableFuture={true}
      />
      <InfocenterOverview loading={isInitialQueryLoading} error={error} results={searchResults} />
      {isLoadingMore && (
        <Grid
          item
          xs
          container
          direction="row"
          sx={{ borderRadius: 4, p: 0, pt: 4 }}
          spacing={OVERVIEW_SPACING_FACTOR[breakpoint]}
        >
          <Grid item xs>
            <LoadingCard height={190} />
          </Grid>
          <Grid item xs>
            <LoadingCard height={190} />
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};
