import React from "react";
import { WidgetType } from "../../api/muu-api/graphql/generated";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

interface IWidgetNoEntryProps {
  widgetType: WidgetType;
}

export const WidgetNoEntry: React.FC<IWidgetNoEntryProps> = (props) => {
  const { widgetType } = props;
  const { t } = useTranslation();

  return (
    <Box sx={{ pt: 6 }}>
      <Typography variant="body1" sx={{ width: "100%", textAlign: "center", pt: 3, pb: 3 }}>
        {t(`widgets.${widgetType}.no_data`)}
      </Typography>
    </Box>
  );
};
