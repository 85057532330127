import { useCallback, useContext, useEffect, useState } from "react";
import { LayoutContext } from "../components/layout/layout.context";
import { INFINITE_SCROLL_PAGE_OFFSET } from "../theme/sizings.theme";

export type UpdateQueryType<T> = (previousQueryResult: T, args: { fetchMoreResult: T }) => void;

export const useInfiniteScrolling = (args: {
  fetchMore: Function;
  updateQuery: UpdateQueryType<any>;
  isInitialQueryLoading: boolean;
  itemCount?: number;
}) => {
  const { fetchMore, updateQuery, isInitialQueryLoading, itemCount } = args;
  const { breakpoint } = useContext(LayoutContext);
  const [fetchMoreOffset, setFetchMoreOffset] = useState<number>(20);
  const [isLoadingMore, setIsLoadingMore] = useState<boolean>(false);

  const handleFetchMore = useCallback(
    (count: number) => {
      fetchMore({
        variables: {
          limit: 20,
          offset: count,
        },
        updateQuery,
      });
    },
    [fetchMore, updateQuery],
  );

  useEffect(() => {
    const handleScroll = () => {
      const { scrollTop, scrollHeight, clientHeight } = document.documentElement;
      if (
        scrollTop + clientHeight >= scrollHeight - INFINITE_SCROLL_PAGE_OFFSET[breakpoint] &&
        !isInitialQueryLoading &&
        fetchMoreOffset < (itemCount ?? 0)
      ) {
        setIsLoadingMore(true);
        handleFetchMore(fetchMoreOffset);
        setFetchMoreOffset(fetchMoreOffset + 20);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [isInitialQueryLoading, fetchMoreOffset, setFetchMoreOffset, itemCount, handleFetchMore, breakpoint]);

  return { isLoadingMore, setIsLoadingMore, fetchMoreOffset, setFetchMoreOffset };
};
