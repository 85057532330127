import { SwitchClasses, Theme } from "@mui/material";
import { OverridesStyleRules } from "@mui/material/styles/overrides";
import { IThemeColorDefinition } from "../light.theme";

export const getMuiSwitchStyleOverride = (
  primary: IThemeColorDefinition,
  grey: IThemeColorDefinition,
): Partial<OverridesStyleRules<keyof SwitchClasses, "MuiSwitch", Omit<Theme, "components">>> => {
  return {
    root: {
      height: 20,
      padding: 0,
      width: 33,
      marginRight: "12px",
      marginLeft: "12px",
    },
    switchBase: {
      paddingLeft: "4px",
      paddingRight: "4px",
      paddingTop: "3px",
      paddingBottom: "3px",
      "&.Mui-checked": {
        transform: "translateX(10px)",
        color: primary.contrastText,
        "& + .MuiSwitch-track": {
          backgroundColor: primary[500],
          opacity: 1,
        },
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: grey[500],
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color: primary.contrastText,
      },
    },
    thumb: {
      boxSizing: "border-box",
      width: 14,
      height: 14,
      padding: 0,
    },
    track: {
      opacity: 1,
      backgroundColor: grey[500],
      borderRadius: 10,
    },
  };
};
