import { Box } from "@mui/system";
import React, { useContext } from "react";
import { LayoutContext } from "../../layout.context";

interface INavMenuBoxProps {
  children?: React.ReactNode;
  label?: string;
  id?: string;
}

export const NavMenuBox: React.FC<INavMenuBoxProps> = (props) => {
  const { children, id } = props;
  const { sidebarOpen } = useContext(LayoutContext);

  return (
    <Box
      id={id}
      sx={{
        pl: sidebarOpen ? 4 : 2,
        pr: sidebarOpen ? 4 : 2,
      }}
    >
      {children}
    </Box>
  );
};
