import { Button, Grid, SxProps, Theme, Typography, useTheme } from "@mui/material";
import React, { useCallback, useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { InfocenterImportantArticle, InfocenterType } from "../../../api/muu-api/graphql/generated";
import { ExclamationIcon } from "../../../assets/icons/icons.component";
import { LayoutContext } from "../../../components/layout/layout.context";
import { NavMenuContext } from "../../../components/layout/nav/context/nav-menu.context";
import { DETAIL_PATH } from "../../../router/routes.const";
import { encodeIdBase64 } from "../../../utils/base64.util";
import { getAbsoluteRoutingPath } from "../../../utils/get-absolute-routing-path.util";
import { DashboardWidgetsContext } from "../widget-section/dashboard-widgets.context";
import { INFOCENTER_IMPORTANT_ARTICLE_HEIGHT } from "../../../theme/sizings.theme";
import { parseRelativeLink } from "../../../app/helpers/relative-link.helper";
import { ThemeContext } from "../../../components/layout/theme.context";

interface IImportantNewsProps {
  article: InfocenterImportantArticle;
}

export const ImportantNews: React.FC<IImportantNewsProps> = (props) => {
  const { article } = props;
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isContrastThemeActive } = useContext(ThemeContext);
  const { breakpointInfo, breakpoint } = useContext(LayoutContext);
  const { pageOfInterestDictionary } = useContext(NavMenuContext);
  const { isInEditMode: isEditingWidgets } = useContext(DashboardWidgetsContext);

  const infocenterDetailRoute = useMemo(
    () => `${pageOfInterestDictionary.INFOCENTER.path}/${DETAIL_PATH}`,
    [pageOfInterestDictionary],
  );

  const handleOnClick = useCallback(() => {
    if (!infocenterDetailRoute) {
      return;
    }
    navigate(
      `${getAbsoluteRoutingPath(infocenterDetailRoute)}/?id=${encodeIdBase64(article.id.toString(), {
        type: InfocenterType.STRAPI,
      })}`,
    );
  }, [navigate, article, infocenterDetailRoute]);

  const buttonProps = useMemo(() => {
    return article.link
      ? { target: "_blank", href: article.link ? parseRelativeLink(article.link).link : "" }
      : { onClick: handleOnClick };
  }, [article.link, handleOnClick]);

  const containerStyle: SxProps<Theme> = useMemo(
    () => (isEditingWidgets ? { opacity: 0.2, pointerEvents: "none" } : {}),
    [isEditingWidgets],
  );

  return (
    <Grid
      id="important-news"
      container
      direction="row"
      sx={{
        minHeight: INFOCENTER_IMPORTANT_ARTICLE_HEIGHT[breakpoint],
        borderRadius: 4,
        backgroundColor: "importantNews.main",
        ...containerStyle,
        boxShadow: "0px 16px 32px -4px rgba(145, 158, 171, 0.16)",
        p: 4,
      }}
      alignItems={"center"}
    >
      <Grid item sx={{ height: theme.icon.xxlarge, width: theme.icon.xxlarge }}>
        <ExclamationIcon size={"xxlarge"} sx={{ color: "primary.contrastText" }} />
      </Grid>
      <Grid item xs sx={{ flexGrow: 1, pr: 1.5, pl: breakpointInfo.isMobile ? 4 : 1.5 }}>
        <Typography
          variant="h5"
          sx={{
            color: "primary.contrastText",
            minHeight: 30,
            overflow: "hidden",
            display: "-webkit-box",
            WebkitLineClamp: breakpointInfo.isMobile ? 3 : 2,
            WebkitBoxOrient: "vertical",
          }}
        >
          {article.title}
        </Typography>
      </Grid>
      <Grid item xs={12} md="auto" sx={{ pt: breakpointInfo.isMobile ? 4 : 0 }}>
        <Button
          {...buttonProps}
          variant="contained"
          fullWidth={breakpointInfo.isMobile}
          sx={{
            backgroundColor: "primary.contrastText",
            color: isContrastThemeActive ? "text.primary" : "accent1.main",
            boxShadow: "none",
            "&:hover": {
              boxShadow: "none",
              backgroundColor: "primary.contrastText",
              color: "accent1.main",
              opacity: 0.8,
            },
          }}
        >
          <Typography variant="body2" sx={{ fontWeight: 700 }}>
            {t("dashboard.read_more")}
          </Typography>
        </Button>
      </Grid>
    </Grid>
  );
};
