import { Link as MuiLink } from "@mui/material";
import React, { useCallback, useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { SearchInfocenter } from "../../api/muu-api/graphql/generated";
import { NavMenuContext } from "../layout/nav/context/nav-menu.context";
import { DETAIL_PATH } from "../../router/routes.const";
import { getAbsoluteRoutingPath } from "../../utils/get-absolute-routing-path.util";
import { encodeIdBase64 } from "../../utils/base64.util";
import { parseRelativeLink } from "../../app/helpers/relative-link.helper";

interface IInfocenterLinkProps {
  entry: SearchInfocenter;
  children: React.ReactNode;
  localizeLink?: boolean;
}

export const InfocenterLink: React.FC<IInfocenterLinkProps> = (props) => {
  const { entry, children, localizeLink = false } = props;
  const navigate = useNavigate();

  const { pageOfInterestDictionary } = useContext(NavMenuContext);

  const infocenterDetailRoute = useMemo(
    () => `${pageOfInterestDictionary.INFOCENTER?.path}/${DETAIL_PATH}`,
    [pageOfInterestDictionary],
  );

  const onClick = useCallback(() => {
    if (!entry) {
      return;
    }
    navigate({
      pathname: getAbsoluteRoutingPath(infocenterDetailRoute),
      search: `id=${encodeIdBase64(entry.id, { type: entry.type, localize: localizeLink })}`,
    });
  }, [navigate, entry, infocenterDetailRoute]);

  return (
    <>
      {entry.link ? (
        <MuiLink
          href={parseRelativeLink(entry.link).link}
          target="_blank"
          style={{ textDecoration: "none", color: "inherit", font: "inherit" }}
        >
          {children}
        </MuiLink>
      ) : (
        <MuiLink
          onClick={onClick}
          component="button"
          style={{ textDecoration: "none", color: "inherit", font: "inherit" }}
        >
          {children}
        </MuiLink>
      )}
    </>
  );
};
