import { FC, PropsWithChildren, createContext, useContext, useMemo, useState } from "react";
import { MenuItem, MenuSection, useMenuItemsQuery } from "../../../../api/muu-api/graphql/generated";
import { generatePortalRoutesAndMenu } from "../../../../router/helper/generate-portal-routes-and-menu.helper";
import { PageOfInterestDictionaryType } from "../../../../router/routes.const";
import { UserContext } from "../../../../app/context/user.context";

export interface IMenuItemWithPortalRoute extends Omit<MenuItem, "menuItems"> {
  route: string;
  level: number;
  menuItems: IMenuItemWithPortalRoute[];
}

export interface IMenuWithPortalRoutes extends Omit<MenuSection, "menuItems"> {
  menuItems: IMenuItemWithPortalRoute[];
}

interface INavMenuContext {
  routeComponents: JSX.Element[];
  pageOfInterestDictionary: PageOfInterestDictionaryType;
  menusWithPortalRoutes: IMenuWithPortalRoutes[];
  menuItemsFlat: IMenuItemWithPortalRoute[];
}

export const NavMenuContext = createContext<INavMenuContext>({} as INavMenuContext);

interface INavMenuContextProviderProps extends PropsWithChildren {}

export const NavMenuContextProvider: FC<INavMenuContextProviderProps> = (props) => {
  const { language, currentUser } = useContext(UserContext);

  // we need to use state, because otherwise data will be [] while changing language
  const [menus, setMenus] = useState<MenuSection[]>([] as MenuSection[]);

  useMenuItemsQuery({
    variables: { language },
    skip: currentUser === undefined,
    onCompleted: (data) => setMenus((data.menu || []) as MenuSection[]),
  });

  const { routeComponents, menusWithPortalRoutes, pageOfInterestDictionary, menuItemsFlat } = useMemo(
    () => generatePortalRoutesAndMenu(menus),
    [menus],
  );

  return (
    <NavMenuContext.Provider
      value={{ routeComponents, menusWithPortalRoutes, pageOfInterestDictionary, menuItemsFlat }}
    >
      {props.children}
    </NavMenuContext.Provider>
  );
};
