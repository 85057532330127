import { Link, LinkProps, useTheme } from "@mui/material";
import { useCallback, useContext, useMemo } from "react";
import { ThemeContext } from "../layout/theme.context";
import { LinkState, useCheckLink } from "../../hooks/use-check-link.hook";
import { useNavigate } from "react-router-dom";

// Styles were also overwritten in contrast theme, but still use the custom link component to ensure link color and underline are set correctly when the contrast theme is active
export const CustomLink: React.FC<LinkProps> = (props) => {
  const { children, underline, sx, href, onClick, target, ...rest } = props;
  const navigate = useNavigate();
  const { isContrastThemeActive } = useContext(ThemeContext);
  const theme = useTheme();
  const { linkStatus, isExternal, parsedLink, relativeLink } = useCheckLink(href);

  const handleLinkClicked = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      if (linkStatus === null || linkStatus !== LinkState.VALID) {
        event.preventDefault();
        return;
      }

      if (!isExternal && relativeLink) {
        event.preventDefault();
        navigate(relativeLink);
      }

      onClick?.(event);
    },
    [onClick, relativeLink, navigate, isExternal, linkStatus],
  );

  const parsedHref = useMemo(() => {
    if (linkStatus === LinkState.VALID) {
      return parsedLink;
    }

    return href;
  }, [parsedLink, linkStatus, href]);

  const correctTarget = useMemo(() => {
    if (target) return target;
    if (linkStatus === LinkState.VALID) return parsedLink;
    if (isExternal) return "_blank";
    return "_self";
  }, [target, isExternal, linkStatus, parsedLink]);

  return (
    <Link
      {...rest}
      target={correctTarget}
      href={parsedHref}
      underline={isContrastThemeActive ? "always" : underline}
      sx={{
        ...sx,
        ...(isContrastThemeActive && {
          color: theme.palette.grey[600],
          textDecorationColor: theme.palette.grey[600],
        }),
      }}
      onClick={handleLinkClicked}
    >
      {children}
    </Link>
  );
};
