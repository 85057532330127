import { DETAIL_PATH, PageOfInterestDictionaryType } from "../../../router/routes.const";
import { encodeIdBase64 } from "../../../utils/base64.util";
import { getAbsoluteRoutingPath } from "../../../utils/get-absolute-routing-path.util";

export const getNotificationDetailUrl = (id: string, pageOfInterestDictionary: PageOfInterestDictionaryType) => {
  return `${getAbsoluteRoutingPath(pageOfInterestDictionary.NOTIFICATIONS.path)}/${DETAIL_PATH}?id=${encodeIdBase64(
    id,
    { type: null },
  )}`;
};
