import { Box, Grid, Typography } from "@mui/material";
import React, { ReactInstance, useContext, useMemo, useRef } from "react";
import { Favorite, FavoriteSource, FavoriteType, Notification } from "../../api/muu-api/graphql/generated";
import { ContentTitle } from "../../components/content/content.title";
import { EditorContent } from "../../components/editor/editor-content.component";
import { LayoutContext } from "../../components/layout/layout.context";
import { useFavorite } from "../../hooks/use-favorite.hook";
import { PAGE_PAPER_PADDING_HORIZONTAL_FACTOR, PAGE_PAPER_PADDING_VERTICAL_FACTOR } from "../../theme/sizings.theme";
import { LastEditedTypography } from "../../components/typography/last-edited-typography.component";

interface INotificationDetailPageContentProps {
  entry: Notification;
}

export const NotificationDetailPageContent: React.FC<INotificationDetailPageContentProps> = (props) => {
  const { entry } = props;
  const { removeFavorite, createFavorite } = useFavorite();
  const { breakpoint } = useContext(LayoutContext);

  const pageContentRef = useRef<ReactInstance>(null);

  const favoriteInputs = useMemo(() => {
    const onRemoveFavorite = (fav: Favorite) => removeFavorite(fav);
    const onCreateFavorite = () => {
      const input = {
        entryId: entry.id.toString(),
        description: entry.title,
        teaser: entry.teaser ?? undefined,
        type: FavoriteType.NOTIFICATION,
        source: FavoriteSource.STRAPI,
      };

      createFavorite(input);
    };

    return {
      entryId: entry.id.toString(),
      type: FavoriteType.NOTIFICATION,
      source: FavoriteSource.STRAPI,
      onRemoveFavorite,
      onCreateFavorite,
    };
  }, [createFavorite, entry, removeFavorite]);

  const date = useMemo(() => {
    return entry.updatedAt ?? entry.publishedAt;
  }, [entry]);

  return (
    <Box ref={pageContentRef}>
      <Grid
        container
        sx={{
          pt: PAGE_PAPER_PADDING_VERTICAL_FACTOR[breakpoint],
          pb: PAGE_PAPER_PADDING_VERTICAL_FACTOR[breakpoint],
          pl: PAGE_PAPER_PADDING_HORIZONTAL_FACTOR[breakpoint],
          pr: PAGE_PAPER_PADDING_HORIZONTAL_FACTOR[breakpoint],
        }}
      >
        <Grid item xs={12}>
          <ContentTitle
            loading={false}
            title={entry.title}
            pageContentRef={pageContentRef}
            favoriteInputs={favoriteInputs}
          />
        </Grid>
        {entry.teaser && (
          <Grid item xs={12} sx={{ mb: 6 }}>
            <Typography fontWeight="bold">{entry.teaser}</Typography>
          </Grid>
        )}
        {entry.text && (
          <Grid item xs={12} sx={{ pt: 4 }}>
            <EditorContent editorContentHtmlString={entry.text} />
          </Grid>
        )}
        <Grid item>
          <LastEditedTypography date={date} />
        </Grid>
      </Grid>
    </Box>
  );
};
