import { Button, DialogContent } from "@mui/material";
import { FC, useCallback, useContext } from "react";
import { useTranslation } from "react-i18next";
import { CategoryFilterIcon } from "../../../../assets/icons/icons.component";
import { CustomDialogTitle } from "../../../../components/dialog/custom-dialog-title.component";
import { ThinkTankCategoryFilter } from "../../../../components/filter/think-tank-category-filter.component";
import {
  IFullThinkTankCategoryFilterInfo,
  useSelectedThinkTankCategories,
} from "../../../../components/filter/hooks/use-selected-think-tank-categories.hook";
import { DashboardWidgetsContext } from "../dashboard-widgets.context";
import { IWidgetMenuItemBaseProps } from "../dictionaries/widget-menu.dictionary";
import { CustomDialogActions } from "../../../../components/dialog/custom-dialog-actions.component";

interface IWidgetThinkTankCategoryFilterProp extends IWidgetMenuItemBaseProps {}

export const WidgetThinkTankCategoryFilter: FC<IWidgetThinkTankCategoryFilterProp> = (props) => {
  const { onClose, widget } = props;

  const { saveWidget, setWidgetCategories } = useContext(DashboardWidgetsContext);
  const { t } = useTranslation();

  const { selectedCategories, setSelectedCategories, categories } = useSelectedThinkTankCategories({
    initialValues: widget.categoryFilter,
  });

  const saveCategories = useCallback(
    async (categories: IFullThinkTankCategoryFilterInfo[]) => {
      if (!widget) return;
      const categoryFilter = categories.map((category) => {
        return { id: category.category.id, isLocked: category.isLocked };
      });
      setWidgetCategories(
        widget.id,
        categoryFilter.map((category) => category.id),
      );
      saveWidget({ ...widget, categoryFilter });
    },
    [saveWidget, widget, setWidgetCategories],
  );

  const handleApply = useCallback(async () => {
    saveCategories(selectedCategories);
    onClose();
  }, [saveCategories, selectedCategories, onClose]);

  const handleReset = useCallback(async () => {
    // fixed categories cannot be removed from filter
    const fixedCategories = selectedCategories.filter((selectedCategory) => selectedCategory.isLocked);
    saveCategories(fixedCategories);
    onClose();
  }, [saveCategories, onClose, selectedCategories]);

  return (
    <>
      <CustomDialogTitle
        title={t("widgets.menu_items.category_filter")}
        Icon={CategoryFilterIcon}
        iconProps={{ sx: { color: "primaryDark.main" } }}
      />
      <DialogContent>
        <ThinkTankCategoryFilter
          selectedCategories={selectedCategories}
          setSelectedCategories={setSelectedCategories}
          categories={categories}
        />
      </DialogContent>
      <CustomDialogActions>
        <Button variant="outlined" onClick={handleReset}>
          {t("general.reset_filter")}
        </Button>
        <Button variant="contained" onClick={() => handleApply()}>
          {t("general.apply")}
        </Button>
      </CustomDialogActions>
    </>
  );
};
