import { FC } from "react";
import { WidgetType } from "../../../../api/muu-api/graphql/generated";
import { CategoryFilterIcon, ChangeColorIcon } from "../../../../assets/icons/icons.component";
import { WidgetColorSelection } from "../menu-components/color-selection/widget-color-selection.component";
import { WidgetCategoryFilter } from "../menu-components/widget-category-filter.component";
import { WidgetThinkTankCategoryFilter } from "../menu-components/widget-think-tank-category-filter.component";
import { WidgetRestaurantFilter } from "../menu-components/widget-restaurant-filter.component";
import { IExtendedWidget } from "../types/widget.type";

enum WidgetMenuItem {
  Color = "Color",
  CategoryFilter = "CategoryFilter",
  ThinkTankCategoryFilter = "ThinkTankCategoryFilter",
  RestaurantsFilter = "RestaurantsFilter",
}

export interface IWidgetMenuItemBaseProps {
  widget: IExtendedWidget;
  onClose: VoidFunction;
}

export interface IWidgetMenuItemDictionaryEntry {
  id: number;
  icon: JSX.Element;
  translationKey: string;
  Component: FC<IWidgetMenuItemBaseProps>;
}

const colorWidgetMenuEntry: IWidgetMenuItemDictionaryEntry = {
  id: 1,
  icon: <ChangeColorIcon />,
  translationKey: "widgets.menu_items.color",
  Component: WidgetColorSelection,
};

const categoryWidgetMenuEntry: IWidgetMenuItemDictionaryEntry = {
  id: 2,
  icon: <CategoryFilterIcon />,
  translationKey: "widgets.menu_items.category_filter",
  Component: WidgetCategoryFilter,
};

const thinkTankCategoryWidgetMenuEntry: IWidgetMenuItemDictionaryEntry = {
  id: 2,
  icon: <CategoryFilterIcon />,
  translationKey: "widgets.menu_items.category_filter",
  Component: WidgetThinkTankCategoryFilter,
};

const restaurantsFilterWidgetMenuEntry: IWidgetMenuItemDictionaryEntry = {
  id: 2,
  icon: <CategoryFilterIcon />,
  translationKey: "widgets.menu_items.restaurant_filter",
  Component: WidgetRestaurantFilter,
};

export const WidgetMenuItemDictionary = {
  [WidgetMenuItem.Color]: colorWidgetMenuEntry,
  [WidgetMenuItem.CategoryFilter]: categoryWidgetMenuEntry,
  [WidgetMenuItem.ThinkTankCategoryFilter]: thinkTankCategoryWidgetMenuEntry,
  [WidgetMenuItem.RestaurantsFilter]: restaurantsFilterWidgetMenuEntry,
};

export const WidgetTypeMenuDictionary = {
  [WidgetType.BUS_BAHN_BIM]: [WidgetMenuItem.Color],
  [WidgetType.INFOCENTER]: [WidgetMenuItem.Color, WidgetMenuItem.CategoryFilter],
  [WidgetType.CAROUSEL]: [WidgetMenuItem.Color, WidgetMenuItem.CategoryFilter],
  [WidgetType.NOTIFICATIONS]: [WidgetMenuItem.Color, WidgetMenuItem.CategoryFilter],
  [WidgetType.EVENTS]: [WidgetMenuItem.Color, WidgetMenuItem.CategoryFilter],
  [WidgetType.FAVORITES]: [WidgetMenuItem.Color],
  [WidgetType.THINK_TANK]: [WidgetMenuItem.Color, WidgetMenuItem.ThinkTankCategoryFilter],
  [WidgetType.LUNCH]: [WidgetMenuItem.Color, WidgetMenuItem.RestaurantsFilter],
  [WidgetType.WORK_SPACES]: [WidgetMenuItem.Color],
};
