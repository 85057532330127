import { InfoRounded } from "@mui/icons-material";
import { Box, ClickAwayListener, Divider, Paper, Stack, Tooltip, Typography } from "@mui/material";
import React, { useContext, useMemo, useState } from "react";
import {
  FavoriteSource,
  FavoriteType,
  LegalRegisterTermDefinition,
  useLegalDocumentsQuery,
} from "../../api/muu-api/graphql/generated";
import { LegalDocument } from "./legal-document.component";
import { LegalRegisterAccordion } from "./legal-register-accordion.component";
import { UserContext } from "../../app/context/user.context";
import {
  LEGAL_REGISTER_PADDING_BOTTOM_FACTOR,
  LEGAL_REGISTER_PADDING_HORIZONTAL_FACTOR,
  LEGAL_REGISTER_PADDING_TOP_FACTOR,
} from "../../theme/sizings.theme";
import { LayoutContext } from "../../components/layout/layout.context";
import { NoContentFound } from "../../components/error/no-content-found.component";
import { LegalRegisterSkeleton } from "./legal-register.skeleton";
import { useFavorites } from "../../hooks/use-favorites.hook";
import { EditorContent } from "../../components/editor/editor-content.component";

interface ILegalRegisterPageProps {}

export const LegalRegisterPage: React.FC<ILegalRegisterPageProps> = (props) => {
  const { language } = useContext(UserContext);
  const { data: legalRegisterResult, loading, error } = useLegalDocumentsQuery({ variables: { language } });

  const { favoritesDict } = useFavorites({ type: FavoriteType.LEGAL_REGISTER }, !legalRegisterResult);

  const { breakpoint } = useContext(LayoutContext);

  const [openAtIndex, setOpenAtIndex] = useState<number>(-1);

  const { termDefinitionHeader, termDefinition, legalDocumentGroups } = useMemo(() => {
    return (legalRegisterResult?.legalRegister || { legalDocumentGroups: [] }) as LegalRegisterTermDefinition;
  }, [legalRegisterResult]);

  return (
    <Paper
      elevation={4}
      sx={{
        borderRadius: 4,
        p: 0,
        flex: 1,
        display: "flex",
        overflow: "hidden",
        flexDirection: "column",
        position: "relative",
        width: "100%",
      }}
    >
      {loading && <LegalRegisterSkeleton />}
      {!loading && error && legalDocumentGroups.length <= 0 && (
        <Box>
          <NoContentFound variant="legalRegisterOverviewError" />
        </Box>
      )}
      <Box
        sx={{
          pt: LEGAL_REGISTER_PADDING_TOP_FACTOR[breakpoint],
        }}
      >
        <Box
          sx={{
            ml: LEGAL_REGISTER_PADDING_HORIZONTAL_FACTOR[breakpoint],
            mr: LEGAL_REGISTER_PADDING_HORIZONTAL_FACTOR[breakpoint],
            mb: LEGAL_REGISTER_PADDING_BOTTOM_FACTOR[breakpoint],
          }}
        >
          <Typography variant="h5" sx={{ pb: 4 }}>
            {termDefinitionHeader}
          </Typography>
          <Typography variant="body1">
            <EditorContent editorContentHtmlString={termDefinition ?? ""} />
          </Typography>
        </Box>
        <Box sx={{ width: "100%" }}>
          {legalDocumentGroups
            // Don't display groups with no legal documents
            .filter((group) => group.legalDocuments.length > 0)
            .map((legalDocumentGroup, index) => (
              <LegalRegisterAccordion
                key={`legal-document-group-${legalDocumentGroup.id}`}
                title={legalDocumentGroup.title}
                icon={
                  <ClickAwayListener onClickAway={() => setOpenAtIndex(-1)}>
                    <Tooltip
                      open={openAtIndex === index}
                      onClick={(event) => {
                        event.stopPropagation();
                        setOpenAtIndex(openAtIndex === index ? -1 : index);
                      }}
                      title={<Typography variant="caption">{legalDocumentGroup.description}</Typography>}
                      arrow
                      placement="top-start"
                    >
                      <InfoRounded aria-label="info" sx={{ color: "accent1.main" }} />
                    </Tooltip>
                  </ClickAwayListener>
                }
                isFirstInAccordionList={index === 0}
              >
                <Stack spacing={4} divider={<Divider orientation="horizontal" />}>
                  {legalDocumentGroup.legalDocuments.map((legalDocument, index) => (
                    <LegalDocument
                      key={`legal-document-${legalDocument.id}`}
                      document={legalDocument}
                      favorite={
                        favoritesDict?.[FavoriteType.LEGAL_REGISTER]?.[FavoriteSource.STRAPI]?.[legalDocument.id]
                      }
                    />
                  ))}
                </Stack>
              </LegalRegisterAccordion>
            ))}
        </Box>
        {!loading && legalDocumentGroups.length <= 0 && !error && (
          <Box
            sx={{
              mb: LEGAL_REGISTER_PADDING_BOTTOM_FACTOR[breakpoint],
            }}
          >
            <NoContentFound variant="legalRegisterOverview" />
          </Box>
        )}
      </Box>
    </Paper>
  );
};
