import { useApolloClient } from "@apollo/client";
import { Button, Checkbox, FormControlLabel, Grid, Paper, Switch, Typography } from "@mui/material";
import React, { useCallback, useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  BellDataSettingsInput,
  BellDataSettings as PrismaBellDataSettings,
  SettingsDocument,
  UpdateBellDataSettingsMutation,
  useCategoriesQuery,
  useUpdateBellDataSettingsMutation,
} from "../../api/muu-api/graphql/generated";
import { SnackbarSeverity, useSnackbar } from "../snackbar/snackbar.context";
import { getBellDataSettingsInitialValues } from "./helper/get-bell-data-settings-init-values";
import { IBellDataSettings } from "./types/notification-settings-input.type";
import { UserContext } from "../../app/context/user.context";
import { NONE_CATEGORY } from "../../app/helpers/const.helper";

interface IBellDataSettingsProps {
  initialValues: PrismaBellDataSettings | null;
}

export const BellDataSettings: React.FC<IBellDataSettingsProps> = (props) => {
  const { initialValues } = props;
  const [settings, setSettings] = useState<IBellDataSettings>(getBellDataSettingsInitialValues(initialValues));
  const { t } = useTranslation();
  const { language } = useContext(UserContext);
  const { data: categoriesResult } = useCategoriesQuery({
    variables: {
      language,
    },
  });
  const categories = useMemo(
    () => categoriesResult?.categories?.filter((category) => category.searchFilter !== NONE_CATEGORY) ?? [],
    [categoriesResult],
  );
  const { showSnackbar } = useSnackbar();
  const client = useApolloClient();

  const onUpdateCompleted = useCallback(
    (result: UpdateBellDataSettingsMutation) => {
      client.writeQuery({
        query: SettingsDocument,
        data: { settings: result.updateBellDataSettings },
      });
      showSnackbar(t("general.save_success"), SnackbarSeverity.Success);
    },
    [client, t, showSnackbar],
  );

  const onError = useCallback(() => {
    showSnackbar(t("general.save_error"), SnackbarSeverity.Error);
  }, [showSnackbar, t]);

  const [updateBellDataSettings, { loading }] = useUpdateBellDataSettingsMutation({
    onCompleted: onUpdateCompleted,
    onError,
  });

  const handleSaveBellDataSettings = useCallback(() => {
    const bellDataSettingsInput: BellDataSettingsInput = {
      categoryIds: settings.categoryIds,
      receiveNotificationsWithReadReceipt: true,
      receiveNotificationsWithoutReadReceipt: settings.notificationsWithoutReadReceipt,
    };

    updateBellDataSettings({ variables: { bellDataSettingsInput } });
  }, [settings, updateBellDataSettings]);

  const isCategorySelected = useCallback((categoryId: string) => settings.categoryIds.includes(categoryId), [settings]);

  const handleCheckboxChanged = useCallback(
    (categoryId: string, checked: boolean) => {
      if (checked) {
        setSettings({ ...settings, categoryIds: [...settings.categoryIds, categoryId] });
      } else {
        setSettings({ ...settings, categoryIds: settings.categoryIds.filter((id) => id !== categoryId) });
      }
    },
    [settings],
  );

  const handleNotificationsWithoutReadReceiptSwitched = useCallback(
    (checked: boolean) => {
      const categoryIds = checked ? categories.map((category) => category.id) : [];
      setSettings({
        notificationsWithoutReadReceipt: checked,
        categoryIds,
      });
    },
    [categories],
  );

  return (
    <Paper sx={{ borderRadius: 4, p: 0, overflow: "hidden" }}>
      <Grid container direction="column" sx={{ p: 6 }}>
        <Grid item sx={{ pb: 6 }}>
          <Typography variant="h6" color="primaryDark.main" sx={{ color: "text.primary" }}>
            {t("notification_settings.notifications")}
          </Typography>
        </Grid>
        <Grid item sx={{ pb: 4 }}>
          <FormControlLabel
            control={
              <Switch
                checked={settings.notificationsWithoutReadReceipt}
                onChange={(_, checked) => handleNotificationsWithoutReadReceiptSwitched(checked)}
                disabled={loading}
              />
            }
            label={
              <Typography variant="body2"> {t("notification_settings.notifications_without_read_receipt")}</Typography>
            }
          />
        </Grid>
        {categories.map((category) => (
          <Grid key={`bell-data-settings-${category.id}`} item sx={{ height: 40, pl: 11 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isCategorySelected(category.id)}
                  disabled={loading || !settings.notificationsWithoutReadReceipt}
                  onChange={(_, checked) => handleCheckboxChanged(category.id, checked)}
                />
              }
              label={<Typography variant="body2"> {category.title}</Typography>}
            />
          </Grid>
        ))}
        <Grid item sx={{ pt: 4 }}>
          <Typography variant="body2"> {t("notification_settings.notifications_with_read_receipt")}</Typography>
        </Grid>
        <Grid item justifyContent={"flex-end"} container sx={{ pt: 8 }}>
          <Button variant="contained" disabled={loading} onClick={handleSaveBellDataSettings}>
            {t("general.save_changes")}
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
};
