import { Grid } from "@mui/material";
import React, { useContext, useMemo } from "react";
import {
  useCarouselArticlesForLanguageQuery,
  useInfocenterImportantArticleForLanguageQuery,
} from "../../api/muu-api/graphql/generated";
import { CarouselWidgetContent } from "../../components/widgets/carousel/carousel.widget-content";
import { ImportantNews } from "./important-news/important-news.component";
import { DashboardWidgetsContext, POLLING_INTERVAL } from "./widget-section/dashboard-widgets.context";
import { EditWidgetsButtonBar } from "./widget-section/edit-widgets-button-bar.component";
import { WidgetSection } from "./widget-section/widget-section.component";
import { UserContext } from "../../app/context/user.context";
import { OVERVIEW_SPACING_FACTOR } from "../../theme/sizings.theme";
import { LayoutContext } from "../../components/layout/layout.context";

interface IDashboardContentProps {}

export const DashboardContent: React.FC<IDashboardContentProps> = (props) => {
  const { language } = useContext(UserContext);
  const { breakpoint } = useContext(LayoutContext);
  const { data: importantArticleResult } = useInfocenterImportantArticleForLanguageQuery({
    variables: { language },
    pollInterval: POLLING_INTERVAL,
  });
  const {
    data: carouselData,
    loading: carouselLoading,
    error: carouselError,
  } = useCarouselArticlesForLanguageQuery({
    skip: !language,
    variables: { language, limit: 5 },
    pollInterval: POLLING_INTERVAL,
  });
  const importantArticle = useMemo(
    () => importantArticleResult?.infocenterImportantArticleForLanguage,
    [importantArticleResult],
  );
  const { isInEditMode } = useContext(DashboardWidgetsContext);

  return (
    <Grid id="dashboard-content" container item xs={12} spacing={OVERVIEW_SPACING_FACTOR[breakpoint]} direction="row">
      {isInEditMode && (
        <Grid item xs={12} id="dashboard-action-buttons">
          <EditWidgetsButtonBar />
        </Grid>
      )}
      {importantArticle && (
        <Grid item xs={12}>
          <ImportantNews article={importantArticle} />
        </Grid>
      )}
      {!carouselError && (!carouselData || carouselData?.carouselArticlesForLanguage.length > 0) && (
        <Grid item xs={12}>
          <CarouselWidgetContent
            carouselContent={carouselData?.carouselArticlesForLanguage ?? []}
            loading={carouselLoading}
          />
        </Grid>
      )}
      <Grid id="moveable-widget-section" item xs={12}>
        <WidgetSection />
      </Grid>
    </Grid>
  );
};
