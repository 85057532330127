import { useEffect, useMemo, useState } from "react";
import { Restaurant, useRestaurantsQuery } from "../../../api/muu-api/graphql/generated";

export const useSelectedRestaurants = (args: {
  initialIds?: number[];
  initialRestaurants?: Restaurant[];
  preloadedRestaurants?: Restaurant[];
}) => {
  const { preloadedRestaurants, initialIds, initialRestaurants: initialRestaurantsFromArgs } = args;
  const { data: restaurantsResult } = useRestaurantsQuery({ skip: !!preloadedRestaurants });

  const restaurants: Restaurant[] = useMemo(
    () =>
      preloadedRestaurants
        ? preloadedRestaurants
        : restaurantsResult?.restaurants.map((restaurant) => {
            return { ...restaurant, meals: [], specials: [] };
          }) ?? [],
    [restaurantsResult, preloadedRestaurants],
  );

  const initialRestaurants = useMemo(() => {
    const initialRestaurantsFromIds = restaurants.filter((restaurant) => (initialIds ?? []).includes(restaurant.id));

    if (initialRestaurantsFromIds.length > 0) {
      return initialRestaurantsFromIds;
    }
    return initialRestaurantsFromArgs;
  }, [restaurants, initialIds, initialRestaurantsFromArgs]);

  const [selectedRestaurants, setSelectedRestaurants] = useState<Restaurant[]>(initialRestaurants || []);

  useEffect(() => {
    setSelectedRestaurants(initialRestaurants || []);
  }, [initialRestaurants]);

  return { selectedRestaurants, setSelectedRestaurants, restaurants };
};
