import { Typography } from "@mui/material";
import React, { FC } from "react";
import { TopicTeaserContent } from "../../../api/muu-api/graphql/generated";

interface ITopicTeaserProps {
  content: TopicTeaserContent;
}

export const TopicTeaser: FC<ITopicTeaserProps> = (props) => {
  const { content } = props;
  return <Typography variant="h6">{content.teaser}</Typography>;
};
