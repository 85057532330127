import { Box, Grid, Link, SxProps, Typography, TypographyVariant, useTheme } from "@mui/material";
import { CSSProperties, FC, useContext, useMemo } from "react";
import { ThemeContext } from "../layout/theme.context";

interface ICustomChipProps {
  icon?: JSX.Element;
  label: string;
  variant?: TypographyVariant;
  chipVariant?: "header" | "default";
  sx?: SxProps;
  onClick?: VoidFunction;
}

export const CustomChip: FC<ICustomChipProps> = (props) => {
  const { icon, label, variant, sx: sxFromProps, onClick, chipVariant = "default" } = props;
  const theme = useTheme();
  const { isContrastThemeActive } = useContext(ThemeContext);

  const sx = useMemo(() => {
    const baseSx: SxProps = {
      ...sxFromProps,
      borderRadius: 6,
      pt: 2,
      pb: 2,
      pr: 4,
      pl: 4,
      cursor: onClick ? "pointer" : (sxFromProps as CSSProperties)?.cursor,
      color: "text.primary",
      transition: ".2s",
    };

    switch (chipVariant) {
      case "header":
        return {
          ...baseSx,
          bgcolor: "background.paper",
          boxShadow: theme.boxShadow.light,
          "&:hover": {
            bgcolor: "primary.25",
            color: "primaryDark.main",
            textDecoration: isContrastThemeActive ? "underline" : "none",
          },
        };
      case "default":
        return {
          ...baseSx,
          bgcolor: "grey.100",
          "&:hover": {
            textDecoration: isContrastThemeActive ? "underline" : "none",
          },
        };
    }
  }, [chipVariant, onClick, sxFromProps, theme]);

  return (
    <Link component="button" onClick={onClick} sx={sx}>
      <Grid container alignItems="center" spacing={2} flexWrap="nowrap">
        {icon && (
          <Grid item>
            <Box sx={{ width: theme.icon.medium, height: theme.icon.medium }}>{icon}</Box>
          </Grid>
        )}
        <Grid item>
          <Typography variant={variant} sx={{ textWrap: "nowrap" }}>
            {label}
          </Typography>
        </Grid>
      </Grid>
    </Link>
  );
};
