import { IExtendedWidget } from "../types/widget.type";

export const removeEmptyRows = (widgetsToClean: IExtendedWidget[]): IExtendedWidget[] => {
  const widgets: IExtendedWidget[] = Object.assign([], widgetsToClean);
  if (widgets.length === 0) {
    return [];
  }

  const min = Math.min(...widgets.map((w) => w.row));
  while (Math.min(...widgets.map((w) => w.row)) > 0) {
    widgets.forEach((w) => {
      w.row--;
    });
  }

  const max = Math.max(...widgets.map((w) => w.row));
  for (let i = min; i < max; i++) {
    if (widgets.some((w) => w.row === i)) {
      continue;
    }

    // if there are no more rows greater than i, break or infinite loop is created
    if (widgets.filter((w) => w.row > i).length === 0) {
      break;
    }

    widgets
      .filter((w) => w.row > i)
      .forEach((w) => {
        w.row -= 1;
      });

    i--;
  }

  return widgets;
};
