import { Button, Grid, Paper } from "@mui/material";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  BellDataCountDocument,
  Language,
  NotificationForUserDocument,
  NotificationState,
  SearchNotificationDocument,
  useNotificationForUserLazyQuery,
  useNotificationReadMutation,
} from "../../api/muu-api/graphql/generated";
import { ChevronLeftIcon } from "../../assets/icons/icons.component";
import { NavMenuContext } from "../../components/layout/nav/context/nav-menu.context";
import { getAbsoluteRoutingPath } from "../../utils/get-absolute-routing-path.util";
import { NotificationDetailPageContent } from "./notification-detail.page-content";
import { NotificationDetailSkeleton } from "./notification-detail.skeleton";
import { NotificationConfirmationDialog } from "./dialog/notification-confirmation.dialog";
import { useApolloClient } from "@apollo/client";
import { NoContentFound } from "../../components/error/no-content-found.component";
import { LayoutContext } from "../../components/layout/layout.context";

interface INotificationDetailPageWrapperProps {
  id: string;
  language: Language;
}

export const NotificationDetailPageWrapper: React.FC<INotificationDetailPageWrapperProps> = (props) => {
  const { id, language } = props;
  const navigate = useNavigate();
  const client = useApolloClient();
  const { pageOfInterestDictionary } = useContext(NavMenuContext);
  const { t } = useTranslation();
  const [isOpenConfirmationDialog, setIsOpenConfirmationDialog] = useState<boolean>(false);
  const { breakpointInfo } = useContext(LayoutContext);

  const [notificationForUserQuery, { data, loading }] = useNotificationForUserLazyQuery();

  useEffect(() => {
    if (!id) {
      return;
    }
    notificationForUserQuery({
      fetchPolicy: "no-cache",
      variables: { notificationId: id, language },
      onCompleted: () => {
        client.refetchQueries({ include: [BellDataCountDocument] });
      },
    });
  }, [id, notificationForUserQuery, language]);

  const [notificationRead] = useNotificationReadMutation({
    variables: { notificationId: id },
    onCompleted: () => {
      client.refetchQueries({
        include: [BellDataCountDocument, NotificationForUserDocument, SearchNotificationDocument],
      });
    },
  });

  const notification = useMemo(() => data?.notificationForUser || undefined, [data]);

  useEffect(() => {
    if (notification?.isReadReceipt || notification?.readAt || !notification?.id || !notification?.publishedAt) {
      return;
    }
    notificationRead();
  }, [notificationRead, notification]);

  const navigateToOverview = useCallback(() => {
    navigate(getAbsoluteRoutingPath(pageOfInterestDictionary.NOTIFICATIONS.path));
  }, [navigate, pageOfInterestDictionary]);

  const onOpenConfirmationDialog = useCallback(() => {
    setIsOpenConfirmationDialog(true);
  }, [setIsOpenConfirmationDialog]);

  const onCloseConfirmationDialog = useCallback(() => {
    setIsOpenConfirmationDialog(false);
  }, [setIsOpenConfirmationDialog]);

  const onConfirmNotification = useCallback(() => {
    if (!notification?.id) return;
    notificationRead();
    onCloseConfirmationDialog();
  }, [notification, notificationRead, onCloseConfirmationDialog]);

  const displayConfirm = useMemo(() => {
    if (!notification) return false;
    const result = notification?.isReadReceipt === true && notification?.publishedAt > 0; // publishedAt is in millis

    return result;
  }, [notification]);

  const isDisabledConfirmation = useMemo(() => {
    return notification?.readAt !== null;
  }, [notification]);

  return (
    <Grid container direction="column" spacing={6}>
      <Grid
        item
        container
        direction={breakpointInfo.isMobile ? "column" : "row"}
        justifyContent="space-between"
        spacing={3}
      >
        <Grid item>
          <Button
            variant="outlined"
            startIcon={<ChevronLeftIcon size="small" />}
            onClick={navigateToOverview}
            fullWidth={breakpointInfo.isMobile}
          >
            {t("general.back_to_overview")}
          </Button>
        </Grid>
        {displayConfirm && (
          <Grid item>
            <Button
              variant="outlined"
              onClick={onOpenConfirmationDialog}
              disabled={isDisabledConfirmation}
              fullWidth={breakpointInfo.isMobile}
            >
              {isDisabledConfirmation
                ? t(`notifications.status.${NotificationState.ACCEPTED}`)
                : t("notifications.read_notification")}
            </Button>
          </Grid>
        )}
      </Grid>
      <NotificationConfirmationDialog
        isOpen={isOpenConfirmationDialog}
        handleClose={onCloseConfirmationDialog}
        handleConfirmation={onConfirmNotification}
      />
      {!loading && !notification && <NoContentFound variant="notificationError" />}
      <Grid item xs container>
        <Paper
          sx={{
            borderRadius: 4,
            flex: 1,
            width: "100%",
          }}
        >
          {loading && <NotificationDetailSkeleton />}
          {!loading && notification && <NotificationDetailPageContent entry={notification} />}
        </Paper>
      </Grid>
    </Grid>
  );
};
