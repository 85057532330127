import { IconButton } from "@mui/material";
import { FC, useState } from "react";
import { PlusIcon } from "../../../../assets/icons/icons.component";
import { MenuItemDialog } from "../../../../components/widgets/menu-item.dialog";
import { IWidgetActionItemBaseProps } from "../dictionaries/widget-action.dictionary";
import { useWidgetColorToThemeColor } from "../hooks/use-widget-color-to-theme-color.hook";
import { WidgetAddLinkFavorite } from "./widget-add-link-favorite.component";
import { useTranslation } from "react-i18next";

interface IWidgetAddLinkFavoriteActionProp extends IWidgetActionItemBaseProps {}

export const WidgetAddLinkFavoriteAction: FC<IWidgetAddLinkFavoriteActionProp> = (props) => {
  const { t } = useTranslation();
  const { widget, color } = props;
  const [isOpen, setIsOpen] = useState(false);

  const widgetColorPaletteOption = useWidgetColorToThemeColor(widget.color, false);

  return (
    <>
      <MenuItemDialog
        widget={widget}
        open={!!isOpen}
        onClose={() => setIsOpen(false)}
        Component={WidgetAddLinkFavorite}
      />
      <IconButton
        aria-label="Add Favorite"
        sx={{ display: "flex", alignItems: "center", "&:hover": { backgroundColor: "none" } }}
        onClick={() => setIsOpen(true)}
        title={t("dashboard.tooltip.widget.favorites.add")}
      >
        <PlusIcon sx={{ color: color ?? `${widgetColorPaletteOption}.contrastText` }} />
      </IconButton>
    </>
  );
};
