import { config } from "../../utils/config.util";

export const parseRelativeLink = (link: string) => {
  // Map relative links for uploads with internal domain

  // REACT_APP_RELATIVE_LINK_URL represents the domain of the frontend (not strapi!), since there is a redirect for the /uploads folder
  // in local development however, this url represents the strapi domain, since we do not have a redirect

  if (link.startsWith("#")) {
    return {
      link,
      isExternal: false,
      isImage: false,
      isAnchor: true,
      relativeLink: link,
    };
  }
  if (link.startsWith("/uploads")) {
    return {
      link: `${config.REACT_APP_RELATIVE_LINK_URL}${link}`,
      isExternal: true,
      isImage: true,
      isAnchor: false,
      relativeLink: link,
    };
  }
  if (link.startsWith("/")) {
    return {
      link,
      isExternal: false,
      isImage: false,
      isAnchor: false,
      relativeLink: link,
    };
  }
  if (link.startsWith(`${config.REACT_APP_RELATIVE_LINK_URL}/uploads`)) {
    return { link, isExternal: true, isImage: true, isAnchor: false };
  }
  if (link.startsWith(config.REACT_APP_RELATIVE_LINK_URL)) {
    return { link, isExternal: false, isImage: false, isAnchor: false };
  }

  if (link.startsWith(`${window.location.protocol}//${window.location.host}`)) {
    return {
      link,
      isExternal: false,
      isImage: false,
      isAnchor: false,
      relativeLink: link.split(`${window.location.protocol}//${window.location.host}`)[1],
    };
  }
  if (link.startsWith(window.location.host)) {
    return {
      link: `${window.location.protocol}//${link}`,
      isExternal: false,
      isImage: false,
      isAnchor: false,
      relativeLink: link.split(window.location.host)[1],
    };
  }

  if (
    !link.startsWith("http://") &&
    !link.startsWith("https://") &&
    !link.startsWith("mailto:") &&
    !link.startsWith("tel:") &&
    !link.startsWith("/")
  ) {
    // If the URL does not start with http:// or https://,
    // assume it's a relative URL and prepend the appropriate protocol.
    return { link: `https://${link}`, isExternal: true };
  }
  return { link, isExternal: true };
};
