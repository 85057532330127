import { ApolloError } from "@apollo/client";
import { Grid, Skeleton, Typography, useTheme } from "@mui/material";
import React, { FunctionComponent, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Language, useUpdateUserMutation, useUserDetailsQuery } from "../../../api/muu-api/graphql/generated";
import { UserContext } from "../../../app/context/user.context";
import { WorldIcon } from "../../../assets/icons/icons.component";
import { AVAILABLE_LANGUAGES } from "../../../translations/i18n";
import { CustomIconButton } from "../../icon/custom-icon-button.component";
import { LoadingText } from "../../loading/loading-text.component";
import { ISelectionPopoverOption, SelectionPopover } from "../../popover/selection-popover";
import { SnackbarSeverity, useSnackbar } from "../../snackbar/snackbar.context";
import { useSearchParams } from "react-router-dom";

export const LANGUAGE_QUERY_PARAM = "language";

export const LanguageSelect: FunctionComponent = () => {
  const theme = useTheme();
  const { t, i18n } = useTranslation();
  const [open, setOpen] = useState<HTMLButtonElement | null>(null);
  const { language, setLanguage } = useContext(UserContext);
  const [searchParams, setSearchParams] = useSearchParams();

  const { showSnackbar } = useSnackbar();

  const { data: userDetails, loading } = useUserDetailsQuery();

  useEffect(() => {
    if (userDetails?.userDetails.language) {
      setLanguage(userDetails.userDetails.language);
      i18n.changeLanguage(userDetails.userDetails.language);
    }
  }, [userDetails, setLanguage]);

  const [updateUser] = useUpdateUserMutation({
    onError: (_error: ApolloError) => {
      showSnackbar(t("language_select.update_error"), SnackbarSeverity.Error);
    },
  });

  const onClose = useCallback(() => {
    setOpen(null);
  }, []);

  const onIconClicked = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => setOpen(event.currentTarget),
    [],
  );

  const options: ISelectionPopoverOption[] = useMemo(() => {
    return AVAILABLE_LANGUAGES.map((language) => ({ key: language, label: t(`language_select.${language}`) }));
  }, [t]);

  const removeLanguageFromUrl = useCallback(() => {
    const languageValue = searchParams.get(LANGUAGE_QUERY_PARAM);

    if (languageValue) {
      searchParams.delete(LANGUAGE_QUERY_PARAM);
      setSearchParams(searchParams);
    }
  }, [searchParams]);

  const onOptionSelected = useCallback(
    (option: ISelectionPopoverOption) => {
      i18n.changeLanguage(option.key);
      setLanguage(option.key as Language);
      removeLanguageFromUrl();
      updateUser({
        variables: {
          language: option.key as Language,
        },
      });
      onClose();
    },
    [onClose, updateUser, setLanguage, removeLanguageFromUrl],
  );

  if (loading) {
    return (
      <Grid container direction="row" alignItems="center">
        <Grid item>
          <Skeleton variant="circular" width={20} />
        </Grid>
        <Grid item sx={{ display: { xs: "none", xl: "block" } }}>
          <LoadingText variant="body1" lines={2} />
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container direction="row" alignItems="center" sx={{ pr: 2 }}>
      <Grid item>
        <CustomIconButton
          role="button"
          aria-label="Show language selection"
          aria-pressed={!!open}
          onClick={onIconClicked}
          title={t("dashboard.tooltip.language_selection")}
          sx={{
            color: theme.palette.grey[500],
            ...(open && {
              bgcolor: "action.selected",
            }),
          }}
        >
          <WorldIcon />
        </CustomIconButton>
      </Grid>
      <Grid item sx={{ display: { xs: "none", xl: "block" } }}>
        <Typography color={theme.palette.grey[500]} fontSize={16}>
          {t(`language_select.short.${language}`)}
        </Typography>
      </Grid>
      <SelectionPopover
        options={options}
        open={!!open}
        anchorEl={open}
        onClose={onClose}
        onOptionSelected={onOptionSelected}
        selectedKey={language}
      />
    </Grid>
  );
};
