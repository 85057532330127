import React from "react";
import { Box, Divider, Grid, Skeleton, useTheme } from "@mui/material";

interface IBellDataLoadingItemProps {}

export const BellDataLoadingItem: React.FC<IBellDataLoadingItemProps> = (props) => {
  const theme = useTheme();
  return (
    <Box>
      <Grid container sx={{ p: 5 }}>
        <Grid item container flexDirection="column" xs>
          <Grid item>
            <Skeleton variant="text" sx={{ fontSize: theme.typography.subtitle2 }} width={180} />
          </Grid>
          <Grid item sx={{ width: "100%" }}>
            <Skeleton variant="text" sx={{ fontSize: theme.typography.body2 }} width={240} />
          </Grid>
          <Grid item>
            <Skeleton variant="text" sx={{ fontSize: theme.typography.body2 }} width={80} />
          </Grid>
        </Grid>
        <Grid item>
          <Skeleton variant="circular" width={theme.icon.medium} height={theme.icon.medium} />
        </Grid>
      </Grid>
      <Divider />
    </Box>
  );
};
