import { useContext, useMemo } from "react";
import { LayoutContext } from "../../layout.context";
import { ISelectionPopoverOption } from "../../../popover/selection-popover";
import { ProfileOptions } from "../../profile/profile.component";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { EDIT_DASHBOARD_PATH } from "../../../../pages/dashboard/widget-section/types/widget.type";
import { StaticPageDictionary } from "../../../../router/routes.const";

export const useProfileSelectionPopoverOptions = () => {
  const { editDashboardEnabled } = useContext(LayoutContext);

  const { t, i18n } = useTranslation();
  const { pathname, search } = useLocation();

  const options: ISelectionPopoverOption[] = useMemo(() => {
    const newOptions: ISelectionPopoverOption[] = [
      { key: ProfileOptions.PROFILE_CARD, label: t(`profile.${ProfileOptions.PROFILE_CARD}`) },
      { key: ProfileOptions.SETTINGS, label: t(`profile.${ProfileOptions.SETTINGS}`) },
    ];

    if (!editDashboardEnabled) {
      return newOptions;
    }

    if (!search.includes(EDIT_DASHBOARD_PATH) || !pathname.includes(StaticPageDictionary.DASHBOARD.path)) {
      newOptions.push({ key: ProfileOptions.EDIT_DASHBOARD, label: t(`profile.${ProfileOptions.EDIT_DASHBOARD}`) });
    }

    return newOptions;
  }, [t, pathname, search, editDashboardEnabled, i18n.language]);

  return { options };
};
