import { Box, SxProps, Tab, Tabs, Theme, Typography } from "@mui/material";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { QuickLink, useAvailableQuickLinksQuery } from "../../../api/muu-api/graphql/generated";
import { AttachIcon, DeeplinkIcon } from "../../../assets/icons/icons.component";
import { ICreateCustomLink } from "../types/create-link.type";
import { AddFavoriteCustomLinkTab } from "./add-favorite-custom-link-tab.component";
import { AddFavoriteQuickLinkTab } from "./add-favorite-quick-link-tab.component";

enum AddFavoriteLinkTab {
  CUSTOM_LINK = 0,
  QUICK_LINK = 1,
}

const TabHeight = 48;
const tabStyle: SxProps<Theme> = {
  width: "50%",
  "&.Mui-selected": { color: "primaryDark.850" },
  height: TabHeight,
  minHeight: TabHeight,
};

interface IAddFavoriteLinkProps {
  customLinks: ICreateCustomLink[];
  setCustomLinks: (customLinks: ICreateCustomLink[]) => void;
  quickLinks: QuickLink[];
  setQuickLinks: (quickLinks: QuickLink[]) => void;
}

export const AddFavoriteLink: React.FC<IAddFavoriteLinkProps> = (props) => {
  const { customLinks, setCustomLinks, quickLinks, setQuickLinks } = props;
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState(AddFavoriteLinkTab.CUSTOM_LINK);

  const { data, loading } = useAvailableQuickLinksQuery({
    fetchPolicy: "no-cache",
  });

  const availableQuickLinks = useMemo(() => data?.availableQuickLinks ?? [], [data]);

  const hasQuickLinksAdded = useMemo(() => quickLinks.length > 0, [quickLinks]);
  const hasCustomLinksAdded = useMemo(() => customLinks.length > 0, [customLinks]);

  const quickLinkLabel = useMemo(() => {
    const text = `${t("favorites.quick_link")}${hasQuickLinksAdded ? "*" : ""}`;
    return (
      <Typography variant="body1" sx={{ textTransform: "none" }}>
        {text}
      </Typography>
    );
  }, [t, hasQuickLinksAdded]);

  const customLinkLabel = useMemo(() => {
    const text = `${t("favorites.custom_link")}${hasCustomLinksAdded ? "*" : ""}`;
    return (
      <Typography variant="body1" sx={{ textTransform: "none" }}>
        {text}
      </Typography>
    );
  }, [t, hasCustomLinksAdded]);

  const handleChange = useCallback((_: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  }, []);

  return (
    <>
      <Box>
        <Tabs value={activeTab} onChange={handleChange} sx={{ height: TabHeight, minHeight: TabHeight }}>
          <Tab label={customLinkLabel} icon={<AttachIcon />} iconPosition="start" sx={tabStyle} />
          <Tab label={quickLinkLabel} icon={<DeeplinkIcon />} iconPosition="start" sx={tabStyle} />
        </Tabs>
      </Box>
      <Box sx={{ pt: 4 }}>
        {activeTab === AddFavoriteLinkTab.CUSTOM_LINK && (
          <AddFavoriteCustomLinkTab customLinks={customLinks} setCustomLinks={setCustomLinks} />
        )}
        {activeTab === AddFavoriteLinkTab.QUICK_LINK && (
          <AddFavoriteQuickLinkTab
            quickLinks={quickLinks}
            setQuickLinks={setQuickLinks}
            availableQuickLinks={availableQuickLinks}
            areAvailableQuickLinksLoading={loading}
          />
        )}
      </Box>
    </>
  );
};
