import { Grid } from "@mui/material";
import React, { useCallback, useContext, useMemo } from "react";
import { TopicPageContent } from "./topic-page-content.component";
import { TopicPageSubMenuItems } from "./topic-page-sub-menu-items.component";
import { IMenuItemWithPortalRoute } from "../../components/layout/nav/context/nav-menu.context";
import { TopicPageContext } from "./context/topic-page.context";
import { LoadingCard } from "../../components/loading/loading-card.component";
import { NoContentFound } from "../../components/error/no-content-found.component";

interface ITopicPageLayoutProps {
  subMenuItems: IMenuItemWithPortalRoute[];
}

export const TopicPageLayout: React.FC<ITopicPageLayoutProps> = (props) => {
  const { topicPage, topicPageLoading, topicPageQueryError } = useContext(TopicPageContext);
  const { subMenuItems } = props;
  const flat = useCallback(
    (accumulator: IMenuItemWithPortalRoute[], item: IMenuItemWithPortalRoute): IMenuItemWithPortalRoute[] => {
      if (item.menuItems.length > 0) {
        return item.menuItems.reduce(flat, [...accumulator, item]);
      }
      accumulator.push(item);
      return accumulator;
    },
    [],
  );

  const allMenuItems = useMemo(() => subMenuItems.reduce(flat, []), [flat, subMenuItems]);

  return (
    <Grid id="topic-page-layout" container direction="column" rowSpacing={6} sx={{ width: "100%" }}>
      {topicPageLoading && (
        <Grid item xs={12}>
          <LoadingCard height={100} />
        </Grid>
      )}
      {topicPageQueryError && (
        <Grid item xs={12}>
          <NoContentFound variant="topicPageDetailError" />
        </Grid>
      )}
      {topicPage?.content && topicPage.content.length > 0 && (
        <Grid id="topic-page-content-container" item xs sx={{ width: "100%" }}>
          <TopicPageContent />
        </Grid>
      )}
      {topicPage?.showAllSubmenuItems && subMenuItems.length > 0 && (
        <Grid item xs sx={{ width: "100%" }}>
          <TopicPageSubMenuItems subMenuItems={allMenuItems} />
        </Grid>
      )}
    </Grid>
  );
};
