import React, { useMemo } from "react";
import { TopicPage } from "../topic/topic.page";
import { useLocation } from "react-router-dom";

interface IStaticTopicPagePageProps {}

export const StaticTopicPagePage: React.FC<IStaticTopicPagePageProps> = (props) => {
  const location = useLocation();

  const id = useMemo(() => {
    return location.pathname.substring(location.pathname.lastIndexOf("/") + 1);
  }, [location]);

  if (!id) {
    return null;
  }

  return <TopicPage id={id} subMenuItems={[]} localize={true} />;
};
