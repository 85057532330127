import { ExpandMore } from "@mui/icons-material";
import { Button, Grid, Popover, useTheme } from "@mui/material";
import React, { useCallback, useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { FilterButton } from "../button/filter-button.component";
import { FILTER_POPOVER_PADDING } from "../../../theme/sizings.theme";
import { LayoutContext } from "../../layout/layout.context";

export const FILTER_BUTTON_POPOVER_TOP_SPACING_FACTOR = -4;

interface IFilterButtonPopoverProps {
  filterTitle: string;
  applyFilter: () => void;
  resetFilter: () => void;
  closeFilter: () => void;
  title: string;
  startIcon?: React.ReactNode;
  maxWidth?: number | string;
  filled?: boolean;
  children: React.ReactNode;
}

export const FilterButtonPopover: React.FC<IFilterButtonPopoverProps> = (props) => {
  const { filterTitle, applyFilter, resetFilter, closeFilter, title, startIcon, filled, maxWidth, children } = props;
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const { t } = useTranslation();
  const theme = useTheme();
  const { breakpoint, breakpointInfo } = useContext(LayoutContext);

  const onApplyFilter = useCallback(() => {
    applyFilter();
    setAnchorEl(null);
  }, [applyFilter]);
  const handleClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
    closeFilter();
  }, [closeFilter]);

  const handleReset = useCallback(() => {
    setAnchorEl(null);
    resetFilter();
  }, [resetFilter]);

  const open = useMemo(() => {
    return Boolean(anchorEl);
  }, [anchorEl]);

  const id = useMemo(() => {
    return open ? `${title}-popover` : undefined;
  }, [open, title]);

  const popoverStyle = useMemo(() => {
    if (maxWidth) {
      return { maxWidth: maxWidth, width: `calc(100% - ${FILTER_POPOVER_PADDING[breakpoint]}px)` };
    }

    if (breakpointInfo.isMobile) {
      return { width: `calc(100% - ${FILTER_POPOVER_PADDING[breakpoint]}px)` };
    }

    return {};
  }, [breakpoint, breakpointInfo, maxWidth]);

  return (
    <Grid item>
      <FilterButton
        title={title}
        aria-describedby={id}
        onClick={handleClick}
        endIcon={<ExpandMore />}
        filled={filled}
        startIcon={startIcon}
      >
        {filterTitle}
      </FilterButton>
      <Popover
        id={id}
        open={open}
        onClose={handleClose}
        anchorEl={anchorEl}
        anchorReference="anchorEl"
        disableScrollLock
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: parseFloat(theme.spacing(FILTER_BUTTON_POPOVER_TOP_SPACING_FACTOR)),
          horizontal: 0,
        }}
        PaperProps={{
          style: popoverStyle,
        }}
      >
        <Grid container sx={{ p: 4, width: "100%" }}>
          <Grid item xs={12}>
            {children}
          </Grid>
          <Grid item xs={12} sx={{ pt: 8 }}>
            <Grid
              container
              spacing={breakpointInfo.isMobile ? 3 : 4}
              justifyContent="flex-end"
              direction={breakpointInfo.isMobile ? "column-reverse" : "row"}
            >
              <Grid item>
                <Button
                  variant="outlined"
                  title={t("general.reset_filter")}
                  onClick={handleReset}
                  fullWidth={breakpointInfo.isMobile}
                >
                  {t("general.reset_filter")}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  title={t("general.apply")}
                  onClick={onApplyFilter}
                  fullWidth={breakpointInfo.isMobile}
                >
                  {t("general.apply")}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Popover>
    </Grid>
  );
};
