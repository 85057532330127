import { Box, Divider, Grid, Typography } from "@mui/material";
import React, { ReactInstance, useCallback, useContext, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Favorite, FavoriteSource, FavoriteType, ThinkTankType } from "../../api/muu-api/graphql/generated";
import { GiveIcon, MailIcon, SearchIcon, UserOutlinedIcon } from "../../assets/icons/icons.component";
import { ContentSubtitle } from "../../components/content/content.subtitle";
import { ContentTitle } from "../../components/content/content.title";
import { EditorContent } from "../../components/editor/editor-content.component";
import { PhoneOrMailTypography } from "../../components/typography/phone-or-mail-typography.component";
import { useFavorite } from "../../hooks/use-favorite.hook";
import { StaticPageDictionary } from "../../router/routes.const";
import { getAbsoluteRoutingPath } from "../../utils/get-absolute-routing-path.util";
import { ThinkTankContext } from "./context/think-tank.context";
import { LastEditedTypography } from "../../components/typography/last-edited-typography.component";
import { PAGE_PAPER_PADDING_HORIZONTAL_FACTOR, PAGE_PAPER_PADDING_VERTICAL_FACTOR } from "../../theme/sizings.theme";
import { LayoutContext } from "../../components/layout/layout.context";
import { ThinkTankAttachments } from "./think-tank-attachments.component";
import { CategoryChips } from "../../components/categories/category-chips.component";

interface IThinkTankDetailPageContentProps {}

export const ThinkTankDetailPageContent: React.FC<IThinkTankDetailPageContentProps> = (props) => {
  const { post, isLoading, postLinksToProfile } = useContext(ThinkTankContext);
  const { t } = useTranslation();
  const pageContentRef = useRef<ReactInstance>(null);
  const { removeFavorite, createFavorite } = useFavorite();
  const navigate = useNavigate();
  const { breakpoint } = useContext(LayoutContext);

  const typeInfo = useMemo(() => {
    const icon =
      post?.type === ThinkTankType.OFFER ? (
        <GiveIcon sx={{ color: "grey.500", display: "flex" }} />
      ) : (
        <SearchIcon sx={{ color: "grey.500", display: "flex" }} />
      );

    return (
      <Grid item>
        <Grid container sx={{ flexWrap: "nowrap" }} spacing={2}>
          <Grid xs="auto" item container direction="column" justifyContent="center">
            {icon}
          </Grid>
          <Grid item xs>
            <Typography variant="overline" sx={{ color: "grey.500" }}>
              {t(`think_tank.type.${post?.type}`)}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    );
  }, [post, t]);

  const favoriteInputs = useMemo(() => {
    if (!post) return;
    const onRemoveFavorite = (fav: Favorite) => removeFavorite(fav);
    const onCreateFavorite = () => {
      const input = {
        entryId: post.id.toString(),
        description: post.title,
        teaser: post.description,
        type: FavoriteType.THINK_TANK,
        source: FavoriteSource.STRAPI,
      };

      createFavorite(input);
    };

    return {
      entryId: post.id.toString(),
      type: FavoriteType.THINK_TANK,
      source: FavoriteSource.STRAPI,
      onRemoveFavorite,
      onCreateFavorite,
    };
  }, [createFavorite, post, removeFavorite]);

  const onNameClick = useCallback(() => {
    if (!post || !postLinksToProfile) return;

    navigate({
      pathname: getAbsoluteRoutingPath(StaticPageDictionary.PERSON_DETAIL.path),
      search: `id=${post.creatorMedUniId}`,
    });
  }, [post, navigate, postLinksToProfile]);

  if (!post) return <></>;

  return (
    <Box ref={pageContentRef}>
      <Grid
        container
        sx={{
          pt: PAGE_PAPER_PADDING_VERTICAL_FACTOR[breakpoint],
          pb: PAGE_PAPER_PADDING_VERTICAL_FACTOR[breakpoint],
          pl: PAGE_PAPER_PADDING_HORIZONTAL_FACTOR[breakpoint],
          pr: PAGE_PAPER_PADDING_HORIZONTAL_FACTOR[breakpoint],
        }}
        direction="column"
      >
        <ContentTitle
          loading={isLoading}
          title={post.title}
          pageContentRef={pageContentRef}
          favoriteInputs={favoriteInputs}
        />
        <ContentSubtitle subtitle={typeInfo} />
        <Grid item sx={{ pt: 4, width: "100%" }}>
          <EditorContent editorContentHtmlString={post.description} purify />
        </Grid>
        {post.attachments.length > 0 && (
          <Grid item sx={{ pt: 4, width: "100%" }}>
            <ThinkTankAttachments attachments={post.attachments} />
          </Grid>
        )}
        <Grid item container direction="row" justifyContent="flex-end">
          <Grid item>
            <LastEditedTypography date={post.updatedAt} />
          </Grid>
        </Grid>
        <Grid item sx={{ pt: 4 }}>
          <Divider sx={{ mt: 6, mb: 6 }} />
        </Grid>
        {/* Do not use PersonDetailRow because of wordwrap behavior */}
        <Grid item container rowSpacing={1} sx={{ pt: 4 }}>
          <Grid item xs={12}>
            <Grid container sx={{ flexWrap: "nowrap", wordBreak: "break-word" }}>
              <Grid item sx={{ mr: 2 }}>
                <UserOutlinedIcon sx={{ color: "primary.500" }} />
              </Grid>
              <Grid item xs>
                <Typography
                  onClick={onNameClick}
                  sx={postLinksToProfile ? { "&:hover": { cursor: "pointer", textDecoration: "underline" } } : {}}
                >{`${post.creatorFirstName} ${post.creatorLastName}`}</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container sx={{ flexWrap: "nowrap", wordBreak: "break-word" }}>
              <Grid item sx={{ mr: 2 }}>
                <MailIcon sx={{ color: "primary.500" }} />
              </Grid>
              <Grid item xs>
                <PhoneOrMailTypography type="mail" value={post.creatorEmail} />
              </Grid>
            </Grid>
          </Grid>
          {post.thinkTankCategory && (
            <Grid item xs={12}>
              <CategoryChips mainCategory={post.thinkTankCategory} loading={isLoading} type="thinkTank" />
            </Grid>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};
