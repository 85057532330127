import { IconButton, IconButtonProps, SxProps, Theme, useTheme } from "@mui/material";
import React, { FunctionComponent, useMemo } from "react";
import { IconSize } from "../../theme/types/theme";

type CustomIconButtonVariant = "default" | "contained";

interface ICustomIconButtonProps extends Omit<IconButtonProps, "size"> {
  size?: IconSize;
  variant?: CustomIconButtonVariant;
}

export const CustomIconButton: FunctionComponent<ICustomIconButtonProps> = (props) => {
  const { size, children, sx, variant = "default", ...rest } = props;
  const theme = useTheme();

  const customSx = useMemo(() => {
    let _customSx: SxProps<Theme> = {};

    switch (variant) {
      case "contained": {
        _customSx = {
          ..._customSx,
          transition: ".2s",
          "&:hover": {
            bgcolor: "background.paper",
            color: "primaryDark.main",
          },
          bgcolor: "background.paper",
          boxShadow: theme.boxShadow.light,
        };
        break;
      }
    }

    if (size) {
      _customSx = { ..._customSx, height: theme.icon[size], width: theme.icon[size] };
    }

    return { ..._customSx, ...sx };
  }, [theme, size, sx, variant]);

  return (
    <IconButton sx={customSx} {...rest}>
      {children}
    </IconButton>
  );
};
