import React, { ComponentProps, PropsWithChildren } from "react";

export const nestComponentArray = (components: React.FC[]): React.FC<PropsWithChildren> => {
  return components.reduce(
    (AccumulatedComponents: React.FC<PropsWithChildren>, CurrentComponent: React.FC<PropsWithChildren>) => {
      return ({ children }: ComponentProps<React.FC<PropsWithChildren>>): JSX.Element => {
        return (
          <AccumulatedComponents>
            <CurrentComponent>{children}</CurrentComponent>
          </AccumulatedComponents>
        );
      };
    },
    ({ children }: PropsWithChildren) => <>{children}</>,
  );
};
