import React, { useCallback, useContext } from "react";
import { BellData, BellDataType } from "../../../api/muu-api/graphql/generated";
import { Divider, Grid, Link, Typography } from "@mui/material";
import { formatMillisToLocaleDate } from "../../../utils/date.util";
import { useTranslation } from "react-i18next";
import { NotificationUnacceptedIcon, NotificationUnreadIcon } from "../../../assets/icons/icons.component";
import { useNavigate } from "react-router-dom";
import { getAbsoluteRoutingPath } from "../../../utils/get-absolute-routing-path.util";
import { NavMenuContext } from "../nav/context/nav-menu.context";
import { encodeIdBase64 } from "../../../utils/base64.util";
import { DETAIL_PATH } from "../../../router/routes.const";

interface IBellDataItemProps {
  bellData: BellData;
  onClose: VoidFunction;
}

export const BellDataItem: React.FC<IBellDataItemProps> = (props) => {
  const { bellData, onClose } = props;
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const { pageOfInterestDictionary } = useContext(NavMenuContext);

  const onClick = useCallback(() => {
    switch (bellData.type) {
      case BellDataType.NOTIFICATION:
        navigate(
          `${getAbsoluteRoutingPath(pageOfInterestDictionary.NOTIFICATIONS.path)}/${DETAIL_PATH}/?id=${encodeIdBase64(
            bellData.entryId,
            { type: null },
          )}`,
        );
        break;
    }
    onClose();
  }, [bellData, navigate, pageOfInterestDictionary, onClose]);

  return (
    <Link
      id={`belldata-item-${bellData.entryId}`}
      onClick={onClick}
      component="button"
      variant="body2"
      sx={{ width: "100%", textAlign: "left" }}
      style={{ textDecoration: "none", color: "inherit", font: "inherit" }}
    >
      <Grid container sx={{ p: 5 }}>
        <Grid item container flexDirection="column" xs>
          <Grid item>
            <Typography variant="subtitle2">{bellData.title}</Typography>
          </Grid>
          <Grid item sx={{ width: "100%" }}>
            <Typography
              variant="body2"
              sx={{
                overflow: "hidden",
                scrollBehavior: "none",
                display: "-webkit-box",
                WebkitLineClamp: 1,
                WebkitBoxOrient: "vertical",
                color: "grey.500",
              }}
            >
              {bellData.teaser}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2" sx={{ color: "grey.500" }}>
              {formatMillisToLocaleDate(bellData.createdAt, i18n.language)}
            </Typography>
          </Grid>
        </Grid>
        <Grid item>
          {bellData.content.isReadReceipt && <NotificationUnacceptedIcon color="error" />}
          {!bellData.content.isReadReceipt && <NotificationUnreadIcon />}
        </Grid>
      </Grid>
      <Divider />
    </Link>
  );
};
