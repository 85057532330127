import { Badge, useTheme } from "@mui/material";
import React, { useState } from "react";
import { NotificationsIcon } from "../../../assets/icons/icons.component";
import { CustomIconButton } from "../../icon/custom-icon-button.component";
import { BellDataDrawer } from "./bell-data-drawer.component";
import { useBellDataCount } from "./hook/use-bell-data-count.hook";
import { useTranslation } from "react-i18next";

export const BellDataIndicator: React.FC = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
  const totalUnseenCount = useBellDataCount();

  return (
    <>
      <BellDataDrawer open={drawerOpen} onClose={() => setDrawerOpen(false)} onOpen={() => setDrawerOpen(true)} />
      <CustomIconButton
        aria-label="Notifications"
        onClick={() => setDrawerOpen(true)}
        title={t("dashboard.tooltip.bell")}
      >
        <Badge badgeContent={totalUnseenCount ?? 0} color="error">
          <NotificationsIcon sx={{ color: theme.palette.grey[500] }} />
        </Badge>
      </CustomIconButton>
    </>
  );
};
