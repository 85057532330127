import { Grid, Paper, Typography } from "@mui/material";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { NoContentFound } from "../../components/error/no-content-found.component";
import { ThinkTankContext, ThinkTankMode } from "./context/think-tank.context";
import { ThinkTankDetailHeader } from "./think-tank-detail-header.component";
import { ThinkTankDetailPageContent } from "./think-tank-detail.page-content";
import { ThinkTankDetailSkeleton } from "./think-tank-detail.skeleton";
import { ThinkTankEditPageContent } from "./think-tank-edit.page-content";

interface IThinkTankPageContentProps {}

export const ThinkTankPageContent: React.FC<IThinkTankPageContentProps> = (props) => {
  const { mode, isLoading, post } = useContext(ThinkTankContext);
  const { t } = useTranslation();

  return (
    <Grid container direction="column" rowSpacing={6}>
      <Grid item>
        <Typography variant="h4" color="primaryDark.main">
          {t("think_tank.page_title")}
        </Typography>
      </Grid>
      <ThinkTankDetailHeader />
      {!isLoading && !post && (
        <Grid item>
          <NoContentFound variant="thinkTank" />
        </Grid>
      )}

      <Grid item xs container>
        <Paper
          sx={{
            borderRadius: 4,
            flex: 1,
            width: "100%",
          }}
        >
          {isLoading && <ThinkTankDetailSkeleton />}
          {!isLoading && mode === ThinkTankMode.DETAIL && <ThinkTankDetailPageContent />}
          {!isLoading && mode === ThinkTankMode.EDIT && <ThinkTankEditPageContent />}
        </Paper>
      </Grid>
    </Grid>
  );
};
