import { Table, TableCell, TableRow } from "@mui/material";
import { HTMLReactParserOptions, domToReact } from "html-react-parser";
import { ChildNode, Element } from "domhandler";
import { HtmlElement } from "../types/editor-content.type";
import {
  getTableCellDefinitionFlags,
  getTableV1Style,
  getTableV2Style,
  getTableVariant,
} from "../helpers/editor-content-table.helper";
import { EDITOR_CONTENT_LARGE_SPACING } from "../../../theme/sizings.theme";

export const DEFAULT_TABLE_VARIANT = "3";

export const getTable = (children: ChildNode[], options: HTMLReactParserOptions) => {
  return (
    <Table
      sx={{
        borderCollapse: "collapse",
        width: "100%",
        mb: `${EDITOR_CONTENT_LARGE_SPACING.DESKTOP}px`,
        mt: `${EDITOR_CONTENT_LARGE_SPACING.DESKTOP}px`,
      }}
    >
      {domToReact(children, options)}
    </Table>
  );
};

export const mapToTable = (name: string, node: Element, children: ChildNode[], options: HTMLReactParserOptions) => {
  const variant = getTableVariant(node);

  switch (variant) {
    case "1":
      return mapToTableV1(name, node, children, options);
    case "2":
      return mapToTableV2(name, node, children, options);
    case "4":
      return mapToTableV4(name, node, children, options);
    case "3":
    default:
      return mapToTableV3(name, node, children, options);
  }
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const mapToTableV1 = (name: string, node: Element, children: ChildNode[], options: HTMLReactParserOptions) => {
  if (name === HtmlElement.Table) {
    return getTable(children, options);
  }
  if (name === HtmlElement.Tr) {
    return <TableRow>{domToReact(children, options)}</TableRow>;
  }
  if (name === HtmlElement.Td) {
    const styles = getTableV1Style(node);
    return (
      <TableCell
        sx={{
          pt: 3,
          pb: 3,
          pr: 4,
          pl: 4,
          ...styles,
        }}
      >
        {domToReact(children, options)}
      </TableCell>
    );
  }
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const mapToTableV2 = (name: string, node: Element, children: ChildNode[], options: HTMLReactParserOptions) => {
  if (name === HtmlElement.Table) {
    return getTable(children, options);
  }
  if (name === HtmlElement.Tr) {
    return <TableRow>{domToReact(children, options)}</TableRow>;
  }
  if (name === HtmlElement.Td) {
    const styles = getTableV2Style(node);
    return (
      <TableCell sx={{ pt: 3, pb: 3, pr: 4, pl: 4, border: "none", ...styles }}>
        {domToReact(children, options)}
      </TableCell>
    );
  }
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const mapToTableV3 = (name: string, node: Element, children: ChildNode[], options: HTMLReactParserOptions) => {
  if (name === HtmlElement.Table) {
    return getTable(children, options);
  }
  if (name === HtmlElement.Tr) {
    return <TableRow>{domToReact(children, options)}</TableRow>;
  }
  if (name === HtmlElement.Td) {
    const { isFirstCell } = getTableCellDefinitionFlags(node);
    const pl = isFirstCell ? 0 : 4;
    return <TableCell sx={{ pt: 3, pb: 3, pr: 4, pl, border: "none" }}>{domToReact(children, options)}</TableCell>;
  }
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const mapToTableV4 = (name: string, node: Element, children: ChildNode[], options: HTMLReactParserOptions) => {
  if (name === HtmlElement.Table) {
    return getTable(children, options);
  }
  if (name === HtmlElement.Tr) {
    return (
      <TableRow sx={{ ":nth-child(odd)": { backgroundColor: "primaryDark.25" } }}>
        {domToReact(children, options)}
      </TableRow>
    );
  }
  if (name === HtmlElement.Td) {
    return (
      <TableCell
        sx={{
          pt: 3,
          pb: 3,
          pr: 4,
          pl: 4,
          border: "none",
          borderLeftStyle: "solid",
          borderLeftColor: "primary.contrastText",
          borderLeftWidth: 1,
        }}
      >
        {domToReact(children, options)}
      </TableCell>
    );
  }
};
