import { Alert, AlertProps, Slide, Snackbar } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import React, { createContext, FunctionComponent, ReactElement, useCallback, useContext, useState } from "react";

interface ISnackbarProviderProps {
  children?: React.ReactNode;
}
interface ISnackbarContext {
  showSnackbar: (message: string, severity?: SnackbarSeverity, shouldAutoHide?: boolean) => void;
}

const SnackbarContext = createContext<ISnackbarContext>({
  showSnackbar: () => null,
});

interface ISnackbarMessage {
  message: string;
  severity: AlertProps["severity"];
  shouldAutoHide?: boolean;
}

export enum SnackbarSeverity {
  Success = "success",
  Info = "info",
  Warning = "warning",
  Error = "error",
}

function SlideTransition(props: TransitionProps) {
  return (
    <Slide {...props} direction="down">
      {props.children as ReactElement}
    </Slide>
  );
}

export const SnackbarProvider: FunctionComponent<ISnackbarProviderProps> = (props) => {
  const queueRef = React.useRef<ISnackbarMessage[]>([]);
  const [snackbarMessage, setSnackbarMessage] = useState<ISnackbarMessage | null>(null);
  const [shouldShowSnackbar, setShouldShowSnackbar] = useState<boolean>(false);

  const processQueue = useCallback(() => {
    const nextSnackbarMessage = queueRef.current.shift();
    if (nextSnackbarMessage) {
      setSnackbarMessage(nextSnackbarMessage);
      setShouldShowSnackbar(true);
    }
  }, []);

  const onShowSnackbar = useCallback(
    (message: string, severity: SnackbarSeverity = SnackbarSeverity.Info, shouldAutoHide: boolean = true) => {
      queueRef.current.push({
        message,
        severity,
        shouldAutoHide,
      });

      if (shouldShowSnackbar) {
        setShouldShowSnackbar(false);
      } else {
        processQueue();
      }
    },
    [shouldShowSnackbar, processQueue],
  );

  const onClose = useCallback(() => {
    setShouldShowSnackbar(false);
  }, [setShouldShowSnackbar]);

  const handleExited = useCallback(() => {
    processQueue();
  }, [processQueue]);

  return (
    <SnackbarContext.Provider value={{ showSnackbar: onShowSnackbar }}>
      {/* prevent previously opened snackbar from occuping space  */}
      {shouldShowSnackbar && (
        <Snackbar
          open={shouldShowSnackbar}
          autoHideDuration={!!snackbarMessage?.shouldAutoHide ? 3000 : null}
          onClose={onClose}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          TransitionComponent={SlideTransition}
          TransitionProps={{ onExited: handleExited }}
        >
          <Alert onClose={onClose} severity={snackbarMessage?.severity} variant="filled">
            {snackbarMessage?.message}
          </Alert>
        </Snackbar>
      )}
      {props.children}
    </SnackbarContext.Provider>
  );
};

export const useSnackbar = (): ISnackbarContext => useContext(SnackbarContext);
