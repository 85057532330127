import { Grid, SxProps, useTheme } from "@mui/material";
import React from "react";

interface IPersonDetailRowProps {
  left: JSX.Element;
  right: JSX.Element;
  sx?: SxProps;
}

export const PersonDetailRow: React.FC<IPersonDetailRowProps> = (props) => {
  const { left, right, sx } = props;
  const theme = useTheme();

  return (
    <Grid container sx={{ ...sx }}>
      <Grid container sx={{ flexWrap: "nowrap", wordBreak: "break-word" }}>
        <Grid item xs="auto" sx={{ mr: 2, height: theme.icon["xsmall"], width: theme.icon["xsmall"] }}>
          {left}
        </Grid>
        <Grid item xs sx={{ mt: 0.5 }}>
          {right}
        </Grid>
      </Grid>
    </Grid>
  );
};
