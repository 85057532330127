import { Dialog } from "@mui/material";
import { FC, useContext } from "react";
import { IWidgetMenuItemBaseProps } from "../../pages/dashboard/widget-section/dictionaries/widget-menu.dictionary";
import { IExtendedWidget } from "../../pages/dashboard/widget-section/types/widget.type";
import { DIALOG_MARGIN_FACTOR, WIDGET_DIALOG_WIDTH } from "../../theme/sizings.theme";
import { LayoutContext } from "../layout/layout.context";

export interface IMenuItemDialogProps {
  widget: IExtendedWidget;
  open: boolean;
  onClose: VoidFunction;
  Component: FC<IWidgetMenuItemBaseProps>;
  widthOverride?: string;
  disableEnforceFocus?: boolean;
}

export const MenuItemDialog: FC<IMenuItemDialogProps> = (props) => {
  const { open, onClose, widget, Component, widthOverride, disableEnforceFocus = false } = props;
  const { breakpoint } = useContext(LayoutContext);

  return (
    <Dialog
      id="widget-menu-item-dialog"
      onClose={onClose}
      open={open}
      PaperProps={{
        sx: {
          width: widthOverride ?? WIDGET_DIALOG_WIDTH[breakpoint],
          minWidth: "auto",
          p: 0,
          m: DIALOG_MARGIN_FACTOR[breakpoint],
        },
      }}
      disableEnforceFocus={disableEnforceFocus}
    >
      <Component onClose={onClose} widget={widget} />
    </Dialog>
  );
};
