import { Box, Divider, Stack, Typography } from "@mui/material";
import { RestaurantMenu, WidgetColor, WidgetType } from "../../../api/muu-api/graphql/generated";
import { ChevronLeftIcon, ChevronRightIcon } from "../../../assets/icons/icons.component";
import { i18n } from "../../../translations/i18n";
import { formatJSDatetoLocaleDateHuge } from "../../../utils/date.util";
import { CustomIconButton } from "../../icon/custom-icon-button.component";
import { WidgetNoEntry } from "../widget-no-entry.component";
import { DateShiftOptions } from "./lunch-menus.widget-content";
import { RestaurantEntry } from "./restaurant-entry.component";
import { DefaultWidgetSkeleton } from "../widget-default-skeleton.component";

export interface ILunchMenusColumnProps {
  date: Date;
  restaurants: RestaurantMenu[];
  isFirstColumn?: boolean;
  isLastColumn?: boolean;
  onSwitchDate: (dateShift: DateShiftOptions) => void;
  isLoading?: boolean;
  currentColor: WidgetColor;
}

export const LunchMenusColumn: React.FC<ILunchMenusColumnProps> = (props) => {
  const { date, restaurants, isFirstColumn, isLastColumn, onSwitchDate, isLoading, currentColor } = props;

  return (
    <Box
      id="lunch-menu-columns"
      sx={{
        display: "flex",
        flexDirection: "column",
        maxHeight: "100%",
      }}
    >
      <Box sx={{ display: "flex", borderBottom: "1px solid", borderColor: "grey.100" }}>
        {isFirstColumn && (
          <CustomIconButton aria-label="Show previous day(s)" onClick={() => onSwitchDate(-1)}>
            <ChevronLeftIcon sx={{ color: "text.primary" }} />
          </CustomIconButton>
        )}
        <Typography
          variant="body2"
          sx={{
            m: "auto",
            pt: 2,
            pb: 2,
            display: "flex",
            justifyContent: "center",
            fontWeight: 600,
            textAlign: "center",
          }}
        >
          {formatJSDatetoLocaleDateHuge(date, i18n.language)}
        </Typography>
        {isLastColumn && (
          <CustomIconButton aria-label="Show next day(s)" onClick={() => onSwitchDate(1)}>
            <ChevronRightIcon sx={{ color: "text.primary" }} />
          </CustomIconButton>
        )}
      </Box>
      <Box
        id="lunch-box"
        sx={{
          scrollBehavior: "auto",
          overflowY: "auto",
          display: "-webkit-box",
          WebkitBoxOrient: "vertical",
          "&:hover": {
            overflowY: "auto",
          },
        }}
      >
        {isLoading && <DefaultWidgetSkeleton rows={5} />}
        {!isLoading && (
          <Stack
            spacing={2}
            sx={{ mb: 2 }}
            divider={
              <Divider
                orientation="horizontal"
                flexItem
                sx={{
                  borderColor: "primaryDark.main",
                }}
              />
            }
          >
            {restaurants.length === 0 && <WidgetNoEntry widgetType={WidgetType.LUNCH} />}
            {restaurants.map((restaurant) => (
              <RestaurantEntry
                key={`restaurant-${restaurant.id}`}
                restaurant={restaurant}
                currentColor={currentColor}
              />
            ))}
          </Stack>
        )}
      </Box>
    </Box>
  );
};
