import { Button, Grid, Typography, useTheme } from "@mui/material";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { NotificationState } from "../../api/muu-api/graphql/generated";
import { NotificationStateWithAll } from "./context/notification-state-filter.context";
import { getNotificationStatusIcon } from "./helpers/notification-icon.helper";

interface INotificationStatusFilterProps {
  onClick: (state: NotificationStateWithAll) => void;
  isSelected: (state: NotificationStateWithAll) => boolean;
}

export const NotificationStatusFilter: React.FC<INotificationStatusFilterProps> = (props) => {
  const { onClick, isSelected } = props;
  const { t } = useTranslation();
  const theme = useTheme();

  const getStatusLabel = useCallback(
    (state: NotificationStateWithAll) => {
      const statusIcon = getNotificationStatusIcon(state);
      const backgroundColor = isSelected(state) ? "primaryDark.50" : undefined;
      const textColor = isSelected(state) ? "primary.500" : "primaryDark.850";
      return (
        <Grid item xs={12} sx={{ p: 1 }}>
          <Button
            startIcon={statusIcon}
            sx={{ height: 32, backgroundColor, width: "100%", justifyContent: "flex-start", p: theme.spacing(1, 3) }}
            onClick={() => onClick(state)}
          >
            <Typography sx={{ color: textColor }}>{t(`notifications.status.${state}`)}</Typography>
          </Button>
        </Grid>
      );
    },
    [t, onClick, isSelected, theme],
  );

  return (
    <Grid container direction="column">
      {getStatusLabel("ALL")}
      {getStatusLabel(NotificationState.UNACCEPTED)}
      {getStatusLabel(NotificationState.ACCEPTED)}
      {getStatusLabel(NotificationState.UNREAD)}
      {getStatusLabel(NotificationState.READ)}
    </Grid>
  );
};
