import React, { useMemo } from "react";
import { TopicPage } from "../topic/topic.page";
import { useSearchParams } from "react-router-dom";
import { IMenuItemWithPortalRoute } from "../../components/layout/nav/context/nav-menu.context";
import { checkIfValueIsTypeOfEnum } from "../../utils/enum.util";
import { Language } from "../../api/muu-api/graphql/generated";
import { LANGUAGE_QUERY_PARAM } from "../../components/layout/language-select/language-select.component";

interface IDynamicTopicPageProps {
  id: string;
  subMenuItems: IMenuItemWithPortalRoute[];
}

export const DynamicTopicPage: React.FC<IDynamicTopicPageProps> = (props) => {
  const { id, subMenuItems } = props;
  const [searchParams] = useSearchParams();

  const language = useMemo(() => {
    const languageString = searchParams.get(LANGUAGE_QUERY_PARAM);

    if (languageString === null) {
      return undefined;
    }

    if (!checkIfValueIsTypeOfEnum(Language, languageString)) {
      return undefined;
    }
    return languageString as Language;
  }, [searchParams]);

  return <TopicPage id={id} subMenuItems={subMenuItems} localize={true} language={language} />;
};
