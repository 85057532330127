import React, { createContext, FC, PropsWithChildren, useContext, useEffect, useMemo } from "react";
import {
  Language,
  TopicPage,
  useTopicPageForLanguageLazyQuery,
  useTopicPageLazyQuery,
} from "../../../api/muu-api/graphql/generated";
import { UserContext } from "../../../app/context/user.context";
import { ApolloError } from "@apollo/client";

interface ITopicPageContext {
  topicPageLoading: boolean;
  topicPage?: TopicPage;
  topicPageQueryError?: ApolloError;
}

export const TopicPageContext = createContext<ITopicPageContext>({} as ITopicPageContext);

interface ITopicPageProviderProps extends PropsWithChildren {
  id: string;
  localize: boolean;
  language?: Language;
}

export const TopicPageContextProvider: FC<ITopicPageProviderProps> = (props) => {
  const { language: languageFromUserContext } = useContext(UserContext);
  const { id, localize, language: languageFromProps } = props;
  const [topicPageQuery, { loading: topicPageQueryLoading, data: topicPageQueryData, error: topicPageQueryError }] =
    useTopicPageLazyQuery();
  const [
    topicPageForLanguageQuery,
    {
      loading: topicPageForLanguageQueryLoading,
      data: topicPageForLanguageQueryData,
      error: topicPageForLanguageQueryError,
    },
  ] = useTopicPageForLanguageLazyQuery();

  useEffect(() => {
    if (!id) {
      return;
    }

    if (!localize) {
      topicPageQuery({ variables: { id } });
    } else {
      topicPageForLanguageQuery({ variables: { id, language: languageFromProps ?? languageFromUserContext } });
    }
  }, [topicPageQuery, topicPageForLanguageQuery, id, localize, languageFromProps, languageFromUserContext]);

  const topicPage = useMemo(
    () => topicPageQueryData?.topicPage || topicPageForLanguageQueryData?.topicPageForLanguage || undefined,
    [topicPageForLanguageQueryData, topicPageQueryData],
  );

  return (
    <TopicPageContext.Provider
      value={{
        topicPageLoading: topicPageQueryLoading || topicPageForLanguageQueryLoading,
        topicPage,
        topicPageQueryError: topicPageQueryError || topicPageForLanguageQueryError,
      }}
    >
      {props.children}
    </TopicPageContext.Provider>
  );
};
