import { Media } from "../../../api/muu-api/graphql/generated";
import { IThinkTankPostFormFile } from "../../../components/think-tank/think-tank-post-form.component";

export const useThinkTankAttachment = (attachment: Media | IThinkTankPostFormFile) => {
  return {
    mimeType: (attachment as Media).mimeType || (attachment as IThinkTankPostFormFile)?.file?.type || "",
    name: (attachment as Media).mediaTitle || (attachment as IThinkTankPostFormFile)?.file?.name || "",
    url: (attachment as Media).mediaUrl || (attachment as IThinkTankPostFormFile)?.content || "",
    alt: (attachment as Media).mediaAlt || "think-tank-attachment",
    shouldOpenInNewTab: (attachment as Media).mediaUrl ? true : false,
  };
};
