import { Divider, Grid, Skeleton, Typography, useTheme } from "@mui/material";
import React, { useContext, useMemo } from "react";
import { SearchOrganizationalUnit } from "../../api/muu-api/graphql/generated";
import { FilterOrganizationIcon } from "../../assets/icons/icons.component";
import { TopicPageDictionary } from "../../router/routes.const";
import { SEARCH_RESULT_ICON_HORIZONTAL_MARGIN_FACTOR, SEARCH_RESULT_SKELETON_WIDTH } from "../../theme/sizings.theme";
import { LayoutContext } from "../layout/layout.context";
import { useTracking } from "../../app/context/tracking/tracking.context";
import { CustomLink } from "../link/custom-link.component";

interface IOrganizationalUnitSearchResultProps {
  searchOrganizationalUnit?: SearchOrganizationalUnit;
  loading: boolean;
  isLastItem: boolean;
}

export const OrganizationalUnitSearchResult: React.FC<IOrganizationalUnitSearchResultProps> = (props) => {
  const { searchOrganizationalUnit, loading } = props;
  const theme = useTheme();
  const { breakpoint } = useContext(LayoutContext);
  const { trackEvent } = useTracking();

  const href = useMemo(() => {
    if (!searchOrganizationalUnit) {
      return;
    }
    trackEvent({
      event: "search_result_view",
      action: "redirect",
      category: "page_visit",
    });

    const topicPageDictionaryEntry = TopicPageDictionary[searchOrganizationalUnit.id];
    if (!topicPageDictionaryEntry) {
      return `/page/${searchOrganizationalUnit.id}`;
    }
    return `/${topicPageDictionaryEntry.route}?language=${topicPageDictionaryEntry.language}`;
  }, [searchOrganizationalUnit, trackEvent]);

  return (
    <Grid container direction="column">
      <CustomLink href={href} style={{ textDecoration: "none", color: "inherit", font: "inherit" }}>
        <Grid container direction="row" alignItems="center" spacing={4} sx={{ p: 4 }}>
          <Grid
            item
            sx={{
              color: "accent1.main",
              ml: SEARCH_RESULT_ICON_HORIZONTAL_MARGIN_FACTOR[breakpoint],
              mr: SEARCH_RESULT_ICON_HORIZONTAL_MARGIN_FACTOR[breakpoint],
            }}
          >
            <FilterOrganizationIcon color="inherit" fontSize="medium" />
          </Grid>
          <Grid item xs container direction="column">
            {!!searchOrganizationalUnit && searchOrganizationalUnit.title && (
              <Grid item>
                <Typography variant="subtitle2" sx={{ textAlign: "start", wordBreak: "break-word" }}>
                  {searchOrganizationalUnit.title}
                </Typography>
              </Grid>
            )}
            {!searchOrganizationalUnit && loading && (
              <Grid item>
                <Skeleton
                  variant="text"
                  width={SEARCH_RESULT_SKELETON_WIDTH[breakpoint]}
                  sx={{ fontSize: theme.typography.body1 }}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      </CustomLink>
      <Grid item>
        <Divider sx={{ backgroundColor: "grey.100" }} />
      </Grid>
    </Grid>
  );
};
