import { Grid } from "@mui/material";
import { FunctionComponent, MutableRefObject, ReactInstance, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useReactToPrint } from "react-to-print";
import { Favorite, FavoriteSource, FavoriteType } from "../../api/muu-api/graphql/generated";
import { DeeplinkIcon, PrintIcon, StarIcon } from "../../assets/icons/icons.component";
import { CustomIconButton } from "../../components/icon/custom-icon-button.component";
import { FavoriteAction } from "../favorite/favorite-action.component";
import { SnackbarSeverity, useSnackbar } from "../snackbar/snackbar.context";
import { useFavorites } from "../../hooks/use-favorites.hook";

export interface IFavoriteInputs {
  entryId: string;
  type: FavoriteType;
  source: FavoriteSource;
  onRemoveFavorite: (favorite: Favorite) => void;
  onCreateFavorite: VoidFunction;
}

interface IPageActionIconButtonsProps {
  disabled?: boolean;
  printRef: MutableRefObject<ReactInstance | null>;
  favoriteInputs?: IFavoriteInputs;
  alwaysShowFavoriteAction?: boolean;
}

export const PageActionIconButtons: FunctionComponent<IPageActionIconButtonsProps> = (props) => {
  const { disabled = false, printRef, alwaysShowFavoriteAction = true, favoriteInputs } = props;
  const { showSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const [isPrinting, setIsPrinting] = useState<boolean>(false);

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    onAfterPrint: () => setIsPrinting(false),
  });

  useEffect(() => {
    if (isPrinting) {
      handlePrint();
    }
  }, [isPrinting, handlePrint]);

  const onDeeplinkClicked = useCallback(() => {
    navigator.clipboard.writeText(window.location.href);
    showSnackbar(t("general.copy_link_success"), SnackbarSeverity.Success);
  }, [showSnackbar, t]);

  const { favoritesDict } = useFavorites(
    {
      source: favoriteInputs?.source,
      type: favoriteInputs?.type,
      entryId: favoriteInputs?.entryId,
    },
    disabled,
  );

  return (
    <Grid container spacing={2} sx={{ opacity: isPrinting ? 0 : 1, transition: ".2s" }}>
      <Grid item>
        <CustomIconButton
          aria-label="Copy Link"
          variant="contained"
          disabled={disabled || isPrinting}
          onClick={onDeeplinkClicked}
        >
          <DeeplinkIcon />
        </CustomIconButton>
      </Grid>
      <Grid item>
        <CustomIconButton
          aria-label="Print Page"
          variant="contained"
          disabled={disabled || isPrinting}
          onClick={() => setIsPrinting(true)}
        >
          <PrintIcon />
        </CustomIconButton>
      </Grid>
      {alwaysShowFavoriteAction && !favoriteInputs && (
        <Grid item>
          <CustomIconButton aria-label="Add Favorite" variant="contained" disabled={disabled || isPrinting}>
            <StarIcon />
          </CustomIconButton>
        </Grid>
      )}
      {favoriteInputs && (
        <Grid item>
          <FavoriteAction
            onRemoveFavorite={favoriteInputs.onRemoveFavorite}
            onCreateFavorite={favoriteInputs.onCreateFavorite}
            displayStyle="button"
            disabled={disabled || isPrinting}
            favorite={favoritesDict?.[favoriteInputs.type]?.[favoriteInputs.source]?.[favoriteInputs.entryId]}
          />
        </Grid>
      )}
    </Grid>
  );
};
