import { Box, Grid, useTheme } from "@mui/material";
import React, { Fragment, useContext } from "react";
import { Typography } from "@mui/material";
import { ThemeContext } from "../theme.context";
import { Breadcrumb } from "./breadcrumb.component";
import { PageHeaderContext } from "./page-header.context";

interface IPageHeaderBreadcrumbsDesktopProps {}

const Dot = (props: { color: string }) => {
  const theme = useTheme();
  const { color } = props;
  return (
    <Box
      sx={{
        borderRadius: "100%",
        width: theme.spacing(1),
        height: theme.spacing(1),
        bgcolor: color,
      }}
    />
  );
};

export const PageHeaderBreadcrumbsDesktop: React.FC<IPageHeaderBreadcrumbsDesktopProps> = (props) => {
  const { shouldRenderChips, heading, breadcrumbsToRender, getMenuItemSx, onClickMenuItem } =
    useContext(PageHeaderContext);
  const { isContrastThemeActive } = useContext(ThemeContext);

  return (
    <Grid container item xs={12} sx={{ mb: shouldRenderChips ? 6 : 0 }} spacing={4} alignItems="center">
      {breadcrumbsToRender.map((breadcrumb, index) => (
        <Fragment key={breadcrumb.url}>
          {index > 0 && (
            <Grid item>
              <Dot color="text.primary" />
            </Grid>
          )}
          <Grid item>
            <Breadcrumb
              breadcrumb={breadcrumb}
              onClick={() => onClickMenuItem(breadcrumb)}
              getMenuItemSx={getMenuItemSx}
            />
          </Grid>
        </Fragment>
      ))}
      {breadcrumbsToRender.length > 0 && heading && (
        <Grid item>
          <Dot color={isContrastThemeActive ? "grey.600" : "grey.300"} />
        </Grid>
      )}
      {heading && (
        <Grid item>
          <Box title={heading}>
            <Typography
              variant="caption"
              color={isContrastThemeActive ? "grey.600" : "grey.300"}
              sx={{ cursor: "default" }}
            >
              {heading}
            </Typography>
          </Box>
        </Grid>
      )}
    </Grid>
  );
};
