import { FormControl, InputLabel, MenuItem, Select, SelectProps, SxProps, Theme } from "@mui/material";
import { FC } from "react";

export interface ISelectOption {
  id: string;
  value: string;
  disabled?: boolean;
}

interface ICustomSelectProps extends SelectProps {
  options: ISelectOption[];
  label: string;
  labelId: string;
  menuItemSx?: SxProps<Theme>;
}

export const CustomSelect: FC<ICustomSelectProps> = (props) => {
  const { options, label, labelId, menuItemSx, ...other } = props;
  return (
    <FormControl fullWidth>
      <InputLabel id={labelId} sx={{ backgroundColor: "background.paper" }}>
        {label}
      </InputLabel>
      <Select labelId={labelId} label={label} {...other}>
        {options.map((option) => (
          <MenuItem
            sx={{ ...menuItemSx }}
            key={`select_option_${option.id}_${option.value}`}
            value={option.id}
            disabled={option.disabled}
          >
            {option.value}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
