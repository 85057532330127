import React, { Fragment } from "react";
import { IExtendedWidget } from "../../../pages/dashboard/widget-section/types/widget.type";
import { Box, Divider, Typography } from "@mui/material";
import { WorkSpacesWebexRoom } from "./work-spaces-webex-room.component";
import { useDmsPagesQuery } from "../../../api/muu-api/graphql/generated";
import { CustomLink } from "../../link/custom-link.component";
import { WorkSpacesSkeleton } from "./work-spaces-skeleton.component";
import { POLLING_INTERVAL } from "../../../pages/dashboard/widget-section/dashboard-widgets.context";

interface IWorkSpacesWidgetContentProps {
  widget: IExtendedWidget;
}

export const WorkSpacesWidgetContent: React.FC<IWorkSpacesWidgetContentProps> = (props) => {
  const { data, loading } = useDmsPagesQuery({
    pollInterval: POLLING_INTERVAL,
  });

  const dmsPages = data?.dmsPages || [];

  return (
    <Box id="workspaces-widget-content" sx={{ width: "100%", overflowY: "auto", overscrollBehavior: "contain" }}>
      <WorkSpacesWebexRoom />
      <Divider />
      <Box id="work-spaces">
        {loading && <WorkSpacesSkeleton />}
        {!loading &&
          dmsPages.map((dmsPage, index) => (
            <Fragment key={`work-spaces-dms-page-${dmsPage.id}-${index}`}>
              <CustomLink href={dmsPage.url} underline="none">
                <Box sx={{ p: 4 }}>
                  <Typography
                    variant="subtitle2"
                    sx={{
                      overflow: "hidden",
                      display: "-webkit-box",
                      WebkitLineClamp: 1,
                      WebkitBoxOrient: "vertical",
                      wordBreak: "break-word",
                      color: "text.primary",
                    }}
                  >
                    {dmsPage.title}
                  </Typography>
                  {dmsPage.description && (
                    <Typography
                      variant="body2"
                      sx={{
                        overflow: "hidden",
                        display: "-webkit-box",
                        WebkitLineClamp: 3,
                        WebkitBoxOrient: "vertical",
                        wordBreak: "break-word",
                        color: "text.primary",
                      }}
                    >
                      {dmsPage.description}
                    </Typography>
                  )}
                </Box>
              </CustomLink>
              <Divider />
            </Fragment>
          ))}
      </Box>
    </Box>
  );
};
