import { Theme, useTheme } from "@mui/material";
import React, { createContext, useCallback, useEffect, useState } from "react";
import { Breakpoint, EDIT_DASHBOARD_MIN_WIDTH } from "../../theme/sizings.theme";

export interface IBreakpointInfo {
  isMobile: boolean;
  isDesktop: boolean;
  isDesktopXL: boolean;
}

interface ILayoutContextProviderProps {
  children?: React.ReactNode;
}

interface ILayoutContext {
  sidebarOpen: boolean;
  setSidebarOpen: (open: boolean) => void;
  editDashboardEnabled: boolean;
  breakpoint: Breakpoint;
  breakpointInfo: IBreakpointInfo;
}

const getCurrentBreakpointAndBreakpointsInfo = (theme: Theme) => {
  if (window.innerWidth <= theme.breakpoints.values.md) {
    return {
      breakpoint: Breakpoint.MOBILE,
      breakpointInfo: {
        isMobile: true,
        isDesktop: false,
        isDesktopXL: false,
      },
    };
  }
  if (window.innerWidth <= theme.breakpoints.values.xl) {
    return {
      breakpoint: Breakpoint.DESKTOP,
      breakpointInfo: {
        isMobile: false,
        isDesktop: true,
        isDesktopXL: false,
      },
    };
  }
  return {
    breakpoint: Breakpoint.DESKTOP_XL,
    breakpointInfo: {
      isMobile: false,
      isDesktop: false,
      isDesktopXL: true,
    },
  };
};

const SIDEBAR_COLLAPSE_POINT = 1150;

export const LayoutContext = createContext<ILayoutContext>({} as ILayoutContext);

export const LayoutContextProvider: React.FC<ILayoutContextProviderProps> = (props) => {
  const { children } = props;
  const theme = useTheme();
  const [sidebarOpen, setSidebarOpen] = useState<boolean>(window.innerWidth > theme.breakpoints.values.xl);
  const [breakpoint, setBreakpoint] = useState<Breakpoint>(getCurrentBreakpointAndBreakpointsInfo(theme).breakpoint);
  const [editDashboardEnabled, setEditDashboardEnabled] = useState<boolean>(
    window.innerWidth >= EDIT_DASHBOARD_MIN_WIDTH[breakpoint],
  );
  const [breakpointInfo, setBreakpointInfo] = useState<IBreakpointInfo>(
    getCurrentBreakpointAndBreakpointsInfo(theme).breakpointInfo,
  );

  const handleResize = useCallback(
    (_event: Event) => {
      const { breakpoint: newBreakpoint, breakpointInfo: newBreakpointInfo } =
        getCurrentBreakpointAndBreakpointsInfo(theme);
      if (window.innerWidth > SIDEBAR_COLLAPSE_POINT) {
        setSidebarOpen(true);
      } else {
        setSidebarOpen(false);
      }

      if (window.innerWidth >= EDIT_DASHBOARD_MIN_WIDTH[breakpoint]) {
        setEditDashboardEnabled(true);
      } else {
        setEditDashboardEnabled(false);
      }

      if (breakpoint !== newBreakpoint) {
        setBreakpoint(newBreakpoint);
        setBreakpointInfo(newBreakpointInfo);
      }
    },
    [theme, breakpoint],
  );

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, [handleResize]);

  return (
    <LayoutContext.Provider
      value={{
        sidebarOpen,
        setSidebarOpen,
        editDashboardEnabled,
        breakpoint,
        breakpointInfo,
      }}
    >
      {children}
    </LayoutContext.Provider>
  );
};
