import { Palette } from "@mui/material";
import { WidgetType } from "../../../../api/muu-api/graphql/generated";
import {
  FavoritesIcon,
  FilterEventsIcon,
  InfoCenterIcon,
  LunchIcon,
  MuuIcon,
  NotificationIcon,
  ThinkTankIcon,
  TramIcon,
  WorkSpacesIcon,
} from "../../../../assets/icons/icons.component";
import { IExtendedWidget } from "../types/widget.type";

export const getWidgetIcon = (widget: IExtendedWidget, title: string, themeColor: keyof Palette) => {
  switch (widget.type) {
    case WidgetType.BUS_BAHN_BIM: {
      return <TramIcon sx={{ color: `${themeColor}.contrastText`, display: "flex" }} title={title} />;
    }
    case WidgetType.INFOCENTER: {
      return <InfoCenterIcon sx={{ color: `${themeColor}.contrastText`, display: "flex" }} title={title} />;
    }
    case WidgetType.EVENTS: {
      return <FilterEventsIcon sx={{ color: `${themeColor}.contrastText`, display: "flex" }} title={title} />;
    }
    case WidgetType.FAVORITES: {
      return <FavoritesIcon sx={{ color: `${themeColor}.contrastText`, display: "flex" }} title={title} />;
    }
    case WidgetType.NOTIFICATIONS: {
      return <NotificationIcon sx={{ color: `${themeColor}.contrastText`, display: "flex" }} title={title} />;
    }
    case WidgetType.THINK_TANK: {
      return <ThinkTankIcon sx={{ color: `${themeColor}.contrastText`, display: "flex" }} title={title} />;
    }
    case WidgetType.LUNCH: {
      return <LunchIcon sx={{ color: `${themeColor}.contrastText`, display: "flex" }} title={title} />;
    }
    case WidgetType.WORK_SPACES: {
      return <WorkSpacesIcon sx={{ color: `${themeColor}.contrastText`, display: "flex" }} title={title} />;
    }

    default:
      return <MuuIcon sx={{ color: `${themeColor}.contrastText`, display: "flex" }} title={title} />;
  }
};
