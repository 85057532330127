import { CardMedia, CardMediaProps, useTheme } from "@mui/material";
import React from "react";
import { Media } from "../../api/muu-api/graphql/generated";
import { isValidIcon } from "../../app/helpers/strapi-media.helper";
import { IconSize } from "../../theme/types/theme";

interface ICustomMediaIconProps extends CardMediaProps {
  strapiMedia: Media;
  size?: IconSize;
}

export const CustomMediaIcon: React.FC<ICustomMediaIconProps> = (props) => {
  const { strapiMedia, size = "medium", sx } = props;
  const theme = useTheme();

  if (!isValidIcon(strapiMedia)) {
    return null;
  }

  return (
    <CardMedia
      component="img"
      height={theme.icon[size]}
      width={theme.icon[size]}
      image={strapiMedia?.mediaUrl ?? ""}
      alt={strapiMedia?.mediaAlt ?? ""}
      sx={{
        objectFit: "cover",
        objectPosition: "center",
        ...sx,
      }}
    />
  );
};
