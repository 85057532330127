import { Box, Card, CardActionArea, CardContent, Grid, Typography } from "@mui/material";
import React, { useCallback, useContext, useMemo } from "react";
import { DETAIL_PATH } from "../../router/routes.const";
import { encodeIdBase64 } from "../../utils/base64.util";
import { formatJSDateToLocaleShort } from "../../utils/date.util";
import { NotificationStatusBox } from "./notification-status.component";
import { INotificationCardData } from "./types/notification.types";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { LayoutContext } from "../layout/layout.context";
import { useTracking } from "../../app/context/tracking/tracking.context";
import { DEFAULT_CARD_HEIGHT } from "../../theme/sizings.theme";
import { EditorContent } from "../editor/editor-content.component";

interface INotificationCardProps {
  entry: INotificationCardData;
}

export const NotificationCard: React.FC<INotificationCardProps> = (props) => {
  const { entry } = props;
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const { breakpoint, breakpointInfo } = useContext(LayoutContext);
  const { trackEvent } = useTracking();

  const navigationPath = useMemo(() => `${DETAIL_PATH}?id=${encodeIdBase64(entry.id, { type: null })}`, [entry]);

  const navigateToDetails = useCallback(() => {
    trackEvent({
      event: "detail_view",
      action: "redirect",
      category: "page_visit",
    });
    navigate(navigationPath);
  }, [navigate, navigationPath, trackEvent]);

  return (
    <Card
      variant="outlined"
      sx={{
        cursor: "pointer",
        width: "100%",
        backgroundColor: "background.paper",
        borderRadius: 4,
        display: "flex",
        height: breakpointInfo.isMobile ? undefined : DEFAULT_CARD_HEIGHT[breakpoint],
      }}
    >
      <CardActionArea onClick={navigateToDetails} sx={{ width: "100%", height: "100%" }}>
        <CardContent
          sx={{
            height: "calc(100% - 32px)",
            overflow: "hidden",
            width: "calc(100% - 32px)",
          }}
        >
          <Grid container direction={"row"}>
            <Grid item xs={12} sx={{ display: "flex" }}>
              <Typography variant="overline" sx={{ color: "grey.500", flexGrow: 1 }}>
                {formatJSDateToLocaleShort(entry.publishedAt, i18n.language)}
              </Typography>
              <Box sx={{ height: 24 }}>
                <NotificationStatusBox status={entry.notificationState} />
              </Box>
            </Grid>
            <Grid item xs={12} sx={{ pb: 2 }}>
              <Typography variant="h6" sx={{ overflowWrap: "break-word" }}>
                <EditorContent editorContentHtmlString={entry.title ?? ""} />
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="body2"
                sx={{
                  overflow: "hidden",
                  scrollBehavior: "none",
                  display: "-webkit-box",
                  WebkitLineClamp: 2,
                  WebkitBoxOrient: "vertical",
                  color: "grey.500",
                }}
              >
                <EditorContent editorContentHtmlString={entry.teaser ?? ""} />
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};
