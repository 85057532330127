import { useTheme } from "@mui/material";
import React, { useMemo } from "react";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { ISubTopicPageData, TopicPageSubMenuItem } from "./topic-page-sub-menu-item.component";
import { IMenuItemWithPortalRoute } from "../../components/layout/nav/context/nav-menu.context";

interface ITopicPageSubMenuItemsProps {
  subMenuItems: IMenuItemWithPortalRoute[];
}

export const TopicPageSubMenuItems: React.FC<ITopicPageSubMenuItemsProps> = (props) => {
  const { subMenuItems } = props;
  const theme = useTheme();

  const topicSubPageData: ISubTopicPageData[] = useMemo(() => {
    return subMenuItems
      .filter((menuItem) => menuItem.page && menuItem.page.__typename === "SimpleTopicPage")
      .map((menuItem) => {
        return {
          pageTitle: menuItem.page?.heading ?? "",
          pageId: menuItem.page?.id ?? "-1",
          itemName: menuItem.title,
        };
      })
      .sort((subPage1, subPage2) => subPage1.itemName.localeCompare(subPage2.itemName));
  }, [subMenuItems]);

  return (
    <ResponsiveMasonry columnsCountBreakPoints={{ 0: 1, [theme.breakpoints.values.sm]: 2 }}>
      <Masonry gutter={theme.spacing(6)}>
        {topicSubPageData.map((data, index) => {
          return <TopicPageSubMenuItem subPageData={data} key={`${data.pageId}-${data.itemName}-${index}`} />;
        })}
      </Masonry>
    </ResponsiveMasonry>
  );
};
