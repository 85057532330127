import { InfocenterType } from "../../../api/muu-api/graphql/generated";
import { DETAIL_PATH, PageOfInterestDictionaryType } from "../../../router/routes.const";
import { encodeIdBase64 } from "../../../utils/base64.util";
import { getAbsoluteRoutingPath } from "../../../utils/get-absolute-routing-path.util";

export const getInfocenterDetailUrl = (
  id: string,
  type: InfocenterType,
  pageOfInterestDictionary: PageOfInterestDictionaryType,
  localize: boolean = false,
) => {
  return `${getAbsoluteRoutingPath(pageOfInterestDictionary.INFOCENTER.path)}/${DETAIL_PATH}/?id=${encodeIdBase64(id, {
    type,
    localize,
  })}`;
};
