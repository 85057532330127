import { Autocomplete, ClickAwayListener, InputAdornment, Slide, TextField, useTheme } from "@mui/material";
import React, { KeyboardEvent, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useSuggestLazyQuery } from "../../../api/muu-api/graphql/generated";
import { SearchIcon } from "../../../assets/icons/icons.component";
import { useDebounce } from "../../../hooks/use-debounce.hook";
import { StaticPageDictionary } from "../../../router/routes.const";
import { APP_BAR, SEARCH_BAR_HEIGHT } from "../../../theme/sizings.theme";
import { getAbsoluteRoutingPath } from "../../../utils/get-absolute-routing-path.util";
import { CustomIconButton } from "../../icon/custom-icon-button.component";
import { LayoutContext } from "../layout.context";
import { SearchContext } from "./context/search.context";
import { SearchFilter } from "./search-filter.component";

export interface ISearchBarProps {}

export const Searchbar: React.FC<ISearchBarProps> = (props) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { breakpointInfo, breakpoint } = useContext(LayoutContext);
  const [searchParams] = useSearchParams();
  const searchFromUrl = useMemo(() => searchParams.get("search"), [searchParams]);
  const [search, setSearch] = useState<string>(searchFromUrl || "");
  const [openSearchbar, setOpenSearchbar] = useState<boolean>(false);
  const { selectedFilter } = useContext(SearchContext);
  const [autoCompleteOptions, setAutoCompleteOptions] = useState<string[]>([]);
  const [suggestQuery, { data }] = useSuggestLazyQuery({ fetchPolicy: "no-cache" });
  const debouncedSearch = useDebounce(search, 300);

  useEffect(() => {
    if (debouncedSearch.length < 3) {
      setAutoCompleteOptions([]);
      return;
    }
    suggestQuery({
      fetchPolicy: "no-cache",
      variables: {
        search: debouncedSearch,
        limit: 10,
        filter: { indices: [selectedFilter.indexName] },
      },
    });
  }, [debouncedSearch, suggestQuery, selectedFilter.indexName]);

  useEffect(() => {
    if (!data) return;
    setAutoCompleteOptions(data.suggest.results);
  }, [data]);

  const onSubmit = useCallback(
    (value?: string) => {
      let searchValue = value ?? search;
      if (searchValue) {
        setSearch(searchValue);
      }
      setAutoCompleteOptions([]);
      navigate({
        pathname: getAbsoluteRoutingPath(StaticPageDictionary.SEARCH.path),
        search: `search=${searchValue}`,
      });
    },
    [navigate, search],
  );

  const onEnter = useCallback(
    (event: KeyboardEvent<HTMLInputElement>) => {
      if (event.key === "Enter") {
        onSubmit(undefined);
      }
    },
    [onSubmit],
  );

  return breakpointInfo.isMobile ? (
    <ClickAwayListener
      aria-label="searchbar"
      onClickAway={(event: MouseEvent | TouchEvent) => {
        const target = (event.target as Node) || null;
        if (!target) return;
        // Prevent clickaway trigger on filterselection
        if (target.nodeName !== "BODY") {
          setOpenSearchbar(false);
        }
      }}
    >
      <div id="searchbar-box">
        {!openSearchbar && (
          <CustomIconButton onClick={() => setOpenSearchbar(!openSearchbar)}>
            <SearchIcon fontSize="small" sx={{ color: "grey.500" }} />
          </CustomIconButton>
        )}
        <Slide direction="down" in={openSearchbar} mountOnEnter unmountOnExit={false}>
          <Autocomplete
            value={search}
            onChange={(event, value) => {
              if (!value) {
                setSearch("");
                return;
              }
              onSubmit(value);
            }}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            color="grey"
            id="searchbar-autocomplete"
            options={autoCompleteOptions}
            filterOptions={(x) => x}
            freeSolo
            clearText={t("dashboard.tooltip.search.clear")}
            onKeyDown={onEnter}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                focused
                // label={t("searchbar.label")}
                variant="standard"
                onChange={(event) => setSearch(event.target.value)}
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <InputAdornment position="start" sx={{ height: "100%", pr: 6, mr: 0 }}>
                      <SearchFilter />
                    </InputAdornment>
                  ),
                  endAdornment: <>{params.InputProps.endAdornment}</>,
                  disableUnderline: true,
                  sx: {
                    fontSize: theme.typography.body1,
                    fontWeight: 400,
                    width: "100%",
                    zIndex: 99,
                    display: "flex",
                    height: APP_BAR.MOBILE,
                    backgroundColor: "white",
                    color: "grey.500",
                    "&.MuiOutlinedInput-root": {
                      p: 0,
                    },
                    "&.MuiInput-root .MuiInput-input": {
                      "&::placeholder": {
                        color: "grey.500",
                        opacity: 1,
                      },
                    },
                  },
                }}
                placeholder={t("searchbar.placeholder")}
              />
            )}
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
            }}
          />
        </Slide>
      </div>
    </ClickAwayListener>
  ) : (
    <Autocomplete
      value={search}
      onChange={(event, value) => {
        if (!value) {
          setSearch("");
          return;
        }
        onSubmit(value);
      }}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      color="grey"
      id="searchbar-autocomplete"
      options={autoCompleteOptions}
      filterOptions={(x) => x}
      freeSolo
      clearText={t("dashboard.tooltip.search.clear")}
      onKeyDown={onEnter}
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth
          focused
          // label={t("searchbar.label")}
          variant="standard"
          onChange={(event) => setSearch(event.target.value)}
          sx={{
            ".MuiFormLabel-root": { display: "hidden" },
            ".MuiInputAdornment-root": {
              marginTop: "0px !important",
            },
          }}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position="start" sx={{ height: "100%", pr: 6, mr: 0 }}>
                <SearchFilter />
              </InputAdornment>
            ),
            endAdornment: (
              <>
                <InputAdornment position="end" sx={{ pr: 2 }} title={t("dashboard.tooltip.search.glass")}>
                  <SearchIcon
                    fontSize="small"
                    sx={{ color: theme.palette.grey[500], cursor: "pointer" }}
                    onClick={() => onSubmit()}
                  />
                </InputAdornment>
                {params.InputProps.endAdornment}
              </>
            ),
            disableUnderline: true,
            sx: {
              fontSize: 16,
              fontWeight: 400,
              borderColor: "grey.searchBorder",
              borderRadius: 4,
              borderWidth: "1px",
              borderStyle: "solid",
              height: SEARCH_BAR_HEIGHT[breakpoint],
              color: "grey.500",
              "&.MuiOutlinedInput-root": {
                p: 0,
              },
              "&.MuiInput-root .MuiInput-input": {
                "&::placeholder": {
                  color: "grey.500",
                  opacity: 1,
                },
              },
              "& .MuiAutocomplete-endAdornment": {
                top: 0,
                right: 8,
                "& .MuiAutocomplete-clearIndicator": {
                  top: 12,
                  backgroundColor: "transparent",
                },
              },
            },
          }}
          placeholder={t("searchbar.placeholder")}
        />
      )}
    />
  );
};
