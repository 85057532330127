import React from "react";
import { DashboardWidgetsContextProvider } from "./widget-section/dashboard-widgets.context";
import { DashboardContent } from "./dashboard-content.component";

interface IDashboardPageProps {}

export const DashboardPage: React.FC<IDashboardPageProps> = (props) => {
  return (
    <DashboardWidgetsContextProvider>
      <DashboardContent />
    </DashboardWidgetsContextProvider>
  );
};
