export enum LOCAL_STORAGE_KEY {
  I18N_LNG = "MUU_I18N_LNG",
}

export enum SESSION_STORAGE_KEY {
  MUU_TOKEN = "MUU_TOKEN",
  INITIAL_ROUTE = "INITIAL_ROUTE",
  INITIAL_SEARCH_PARAMS = "INITIAL_SEARCH_PARAMS",
  INITIAL_FRAGMENT = "INITIAL_FRAGMENT",
}
