import { ThemeProvider } from "@mui/material";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { Navigate, NavigateFunction, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { Layout } from "../components/layout/layout.component";
import { NavMenuContext } from "../components/layout/nav/context/nav-menu.context";
import { ThemeContext } from "../components/layout/theme.context";
import { PageLoaderOverlay } from "../components/loading/page-loader-overlay.component";
import { ScrollToTop } from "../components/scroll/scroll-to-top.component";
import { SESSION_STORAGE_KEY } from "../models/storage-keys";
import "./app.css";
import { StaticPageDictionary } from "../router/routes.const";
import { PolicyErrorPage } from "../pages/error/403.page";
import { UnauthorizedErrorPage } from "../pages/error/401.page";

interface IAppProps {}

export const App: React.FC<IAppProps> = () => {
  const [dynamicRoutesRendered, setDynamicRoutesRendered] = useState<boolean>(false);
  const { routeComponents, menuItemsFlat } = useContext(NavMenuContext);
  const { theme } = useContext(ThemeContext);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (dynamicRoutesRendered) {
      handleInitialRoute(navigate);
    } else if (menuItemsFlat.length > 0) {
      setDynamicRoutesRendered(true);
    }
  }, [dynamicRoutesRendered, menuItemsFlat, navigate]);

  const isErrorPage = useMemo(() => location.pathname === "/401" || location.pathname === "/403", [location.pathname]);

  return (
    <ThemeProvider theme={theme}>
      <ScrollToTop />
      {isErrorPage && (
        <Routes>
          <Route path="/401" element={<UnauthorizedErrorPage />} />
          <Route path="/403" element={<PolicyErrorPage />} />
        </Routes>
      )}
      {!dynamicRoutesRendered && !isErrorPage && <PageLoaderOverlay />}
      {dynamicRoutesRendered && !isErrorPage && (
        <Routes>
          <Route path="/" element={<Layout />}>
            {routeComponents}
            <Route path="/" element={<Navigate to={StaticPageDictionary.DASHBOARD.path} />} />
            <Route path="*" element={<Navigate to={StaticPageDictionary.DASHBOARD.path} />} />
          </Route>
        </Routes>
      )}
    </ThemeProvider>
  );
};
const handleInitialRoute = (navigate: NavigateFunction) => {
  const initialRoute = sessionStorage.getItem(SESSION_STORAGE_KEY.INITIAL_ROUTE);
  const initialSearchParams = sessionStorage.getItem(SESSION_STORAGE_KEY.INITIAL_SEARCH_PARAMS);
  const initialFragment = sessionStorage.getItem(SESSION_STORAGE_KEY.INITIAL_FRAGMENT);

  if (initialRoute) {
    navigate(
      `${initialRoute}${initialSearchParams ? initialSearchParams : ""}${initialFragment ? initialFragment : ""}`,
    );
  }

  sessionStorage.removeItem(SESSION_STORAGE_KEY.INITIAL_ROUTE);
  sessionStorage.removeItem(SESSION_STORAGE_KEY.INITIAL_SEARCH_PARAMS);
  sessionStorage.removeItem(SESSION_STORAGE_KEY.INITIAL_FRAGMENT);
};
