import { MenuList } from "@mui/material";
import { useContext } from "react";
import { LayoutContext } from "../../layout.context";
import { NavMenuBox } from "./nav-menu-box.component";
import { NavMenuItem } from "../nav-menu-item/nav-menu-item.component";
import { NavMenuTitle } from "./nav-menu-title.component";
import { IMenuWithPortalRoutes } from "../context/nav-menu.context";

interface NavMenuProps {
  menu: IMenuWithPortalRoutes;
}

export const NavMenu: React.FC<NavMenuProps> = (props) => {
  const { menu } = props;
  const { sidebarOpen } = useContext(LayoutContext);

  return (
    <NavMenuBox label={menu.title} id={`menu-box-${menu.title}`}>
      {sidebarOpen && (
        <NavMenuTitle
          title={menu.title}
          id={`menu-box-title-${menu.title}`}
          label={`menu-box-title-${menu.title}-label`}
        />
      )}
      <MenuList disablePadding aria-label={`Menu ${menu.title}`}>
        {menu.menuItems.map((item) => (
          <NavMenuItem key={item.id} item={item} />
        ))}
      </MenuList>
    </NavMenuBox>
  );
};
