import React, { useContext, useState } from "react";
import { THINK_TANK_IMAGE_PREVIEW_SIZE } from "../../theme/sizings.theme";
import { LayoutContext } from "../../components/layout/layout.context";
import { Media } from "../../api/muu-api/graphql/generated";
import { Box, useTheme } from "@mui/material";
import { ThinkTankImagePreviewDialog } from "./think-tank-image-preview.dialog";
import { IThinkTankPostFormFile } from "../../components/think-tank/think-tank-post-form.component";
import { useThinkTankAttachment } from "./hooks/use-think-tank-attachment.hook";
import { CustomIconButton } from "../../components/icon/custom-icon-button.component";
import { TrashIcon } from "../../assets/icons/icons.component";

interface IThinkTankImageAttachmentProps {
  attachment: Media | IThinkTankPostFormFile;
  editMode?: boolean;
  onDelete?: () => void;
}

export const ThinkTankImageAttachment: React.FC<IThinkTankImageAttachmentProps> = (props) => {
  const { attachment, editMode, onDelete } = props;
  const { breakpoint } = useContext(LayoutContext);
  const theme = useTheme();
  const [imageDialogOpen, setImageDialogOpen] = useState<boolean>(false);

  const { alt, url } = useThinkTankAttachment(attachment);

  return (
    <Box sx={{ position: "relative" }}>
      <ThinkTankImagePreviewDialog attachment={attachment} open={imageDialogOpen} setOpen={setImageDialogOpen} />
      {editMode && (
        <CustomIconButton
          sx={{ position: "absolute", top: theme.spacing(2), right: theme.spacing(2), zIndex: 1 }}
          size="xlarge"
          variant="contained"
          onClick={(event) => {
            event.stopPropagation();
            onDelete && onDelete();
          }}
        >
          <TrashIcon />
        </CustomIconButton>
      )}
      <img
        onClick={() => setImageDialogOpen(true)}
        src={url}
        alt={alt}
        width={THINK_TANK_IMAGE_PREVIEW_SIZE[breakpoint]}
        height={THINK_TANK_IMAGE_PREVIEW_SIZE[breakpoint]}
        style={{ objectFit: "cover", borderRadius: theme.spacing(2), cursor: "pointer" }}
      />
    </Box>
  );
};
