import { ListItemText, Typography } from "@mui/material";
import React, { useContext } from "react";
import { LayoutContext } from "../../layout.context";
import { useNavMenuItemColor } from "../hooks/use-nav-menu-item-color.hook";

interface INavMenuItemTextProps {
  children?: React.ReactNode;
  isOpen: boolean;
  isSelected: boolean;
  isHovering: boolean;
  disablePadding?: boolean;
}

export const NavMenuItemText: React.FC<INavMenuItemTextProps> = (props) => {
  const { children, isOpen, isSelected, isHovering, disablePadding = false } = props;
  const { sidebarOpen } = useContext(LayoutContext);

  const color = useNavMenuItemColor(isHovering, isSelected, isOpen);

  return (
    <ListItemText
      sx={{
        pl: disablePadding || !sidebarOpen ? 0 : 4,
        pr: 1,
        flexGrow: 1,
        color: color,
        m: "auto",
      }}
      primary={
        <Typography
          variant={sidebarOpen ? "body2" : "caption"}
          style={{ fontWeight: isOpen || isSelected ? 700 : 400 }}
          noWrap
        >
          {children}
        </Typography>
      }
    />
  );
};
