import { ApolloClient } from "@apollo/client";
import { FavoritesDocument } from "../../../api/muu-api/graphql/generated";
import { MutateFavoritesFunc } from "../types/favorite.type";

export const writeToFavoritesCache = (client: ApolloClient<object>, mutateFavoritesFunc: MutateFavoritesFunc) => {
  const result = client.readQuery({ query: FavoritesDocument });
  if (!result) return;
  const favorites = result.favorites;
  const mutatedFavorites = mutateFavoritesFunc(Object.assign([], favorites ?? []));
  client.writeQuery({ query: FavoritesDocument, data: { favorites: mutatedFavorites } });
};
