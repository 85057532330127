import { Box, Button, ClickAwayListener, IconButton, Typography } from "@mui/material";
import { DatePicker, MobileDatePicker } from "@mui/x-date-pickers";
import { DateTime } from "luxon";
import React, { useCallback, useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ChevronLeftIcon, ChevronRightIcon } from "../../assets/icons/icons.component";
import { formatDateTimeToLocaleMonthYear } from "../../utils/date.util";
import { LayoutContext } from "../layout/layout.context";

interface IMonthSwitchFilterProps {
  date: DateTime;
  setDate: (date: DateTime) => void;
}

export const MonthSwitchFilter: React.FC<IMonthSwitchFilterProps> = (props) => {
  const { date, setDate } = props;
  const { i18n } = useTranslation();

  const anchorEl = React.useRef<HTMLButtonElement | null>(null);
  const [open, setOpen] = React.useState<boolean>(false);

  const { breakpointInfo } = useContext(LayoutContext);

  const handleClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    event.preventDefault();
    setOpen((currentOpen) => {
      return !currentOpen;
    });
  }, []);

  const id = useMemo(() => {
    return open ? "date-popper" : undefined;
  }, [open]);

  const onSwitchNextMonth = useCallback(() => {
    const nextDate = date.plus({ months: 1 });
    setDate(nextDate);
  }, [date, setDate]);

  const onSwitchPrevMonth = useCallback(() => {
    const prevDate = date.minus({ months: 1 });
    setDate(prevDate);
  }, [date, setDate]);

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
        <IconButton aria-label="Go to previous month" onClick={onSwitchPrevMonth} sx={{ color: "text.primary" }}>
          <ChevronLeftIcon />
        </IconButton>
        <Button aria-describedby={id} onClick={handleClick} ref={anchorEl}>
          <Typography variant="body2" fontWeight={600} sx={{ pl: 2, pr: 2, color: "text.primary" }}>
            {formatDateTimeToLocaleMonthYear(date, i18n.language)}
          </Typography>
        </Button>

        {breakpointInfo.isMobile && (
          <MobileDatePicker
            views={["month"]}
            value={date}
            sx={{ display: "none" }}
            open={open}
            onChange={(value) => {
              if (value) {
                setDate(value);
                setOpen(false);
              }
            }}
            onClose={() => setOpen(false)}
          />
        )}
        {!breakpointInfo.isMobile && (
          <DatePicker
            views={["month"]}
            value={date}
            onChange={(value) => {
              if (value) {
                setDate(value);
                setOpen(false);
              }
            }}
            onClose={() => setOpen(false)}
            slotProps={{
              popper: {
                open: open,
                anchorEl: anchorEl.current,
                placement: "top",
                sx: {
                  pb: 2,
                },
              },
              textField: {
                sx: {
                  display: "none",
                },
              },
            }}
          />
        )}

        <IconButton aria-label="Go to next month" onClick={onSwitchNextMonth} sx={{ color: "text.primary" }}>
          <ChevronRightIcon />
        </IconButton>
      </Box>
    </ClickAwayListener>
  );
};
