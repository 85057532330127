import React, { useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAliasQuery } from "../../api/muu-api/graphql/generated";
import { StaticPageDictionary, TopicPageDictionary } from "../../router/routes.const";
import { parseRelativeLink } from "../../app/helpers/relative-link.helper";

export const AliasPage: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const alias = useMemo(() => {
    return location.pathname.substring(location.pathname.lastIndexOf("/") + 1);
  }, [location]);

  useAliasQuery({
    skip: !alias,
    variables: {
      alias: alias,
    },
    onCompleted: (data) => {
      if (data.alias.url) {
        const { link, isExternal } = parseRelativeLink(data.alias.url);
        if (isExternal) {
          window.location.href = link;
          return;
        }
        navigate(link);
        return;
      }
      if (data.alias.topicPageId) {
        const topicPageDictionaryEntry = TopicPageDictionary[data.alias.topicPageId];
        if (!topicPageDictionaryEntry) {
          navigate(`/page/${data.alias.topicPageId}`);
          return;
        }
        navigate(`/${topicPageDictionaryEntry.route}?language=${topicPageDictionaryEntry.language}`);
      }
    },
    onError: (error) => {
      // TODO: Redirect to 404 page when implemented
      // navigate("/404");
      navigate(`${StaticPageDictionary.DASHBOARD.path}`);
    },
  });

  return null;
};
