import { Box } from "@mui/material";
import React from "react";
import { LoadingText } from "../../components/loading/loading-text.component";

export const LegalRegisterSkeleton: React.FC = () => {
  return (
    <Box>
      <Box sx={{ p: 6, pt: 8 }}>
        <LoadingText variant="h5" lines={1} />
        <LoadingText variant="body1" lines={5} />
      </Box>
      <Box sx={{ p: 6, pt: 2 }}>
        <LoadingText variant="body1" lines={5} />
      </Box>
    </Box>
  );
};
