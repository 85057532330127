import { Accordion, AccordionDetails, AccordionProps, AccordionSummary, Grid, Typography } from "@mui/material";
import { FC, useContext, useState } from "react";
import { ChevronDownIcon } from "../../assets/icons/icons.component";
import { LayoutContext } from "../../components/layout/layout.context";
import { LEGAL_REGISTER_PADDING_HORIZONTAL_FACTOR } from "../../theme/sizings.theme";

interface ICustomAccordionProps extends AccordionProps {
  icon?: JSX.Element;
  title: string;
  isFirstInAccordionList?: boolean;
}

export const LegalRegisterAccordion: FC<ICustomAccordionProps> = (props) => {
  const { children, title, icon, isFirstInAccordionList = false, ...rest } = props;

  const { breakpointInfo, breakpoint } = useContext(LayoutContext);

  const [open, setOpen] = useState<boolean>(false);

  return (
    <Accordion
      {...rest}
      disableGutters={true}
      expanded={open}
      sx={{
        mt: !isFirstInAccordionList && !open ? "-2px!important" : "unset",
        borderTopStyle: "solid",
        borderTopColor: open ? "accent1.main" : "grey.100",
        boxShadow: "none",
        transition: ".3s",
        borderTopWidth: 1,
        ":first-of-type": {
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
        },
      }}
    >
      <AccordionSummary
        expandIcon={<ChevronDownIcon />}
        onClick={() => setOpen(!open)}
        sx={{
          pl: LEGAL_REGISTER_PADDING_HORIZONTAL_FACTOR[breakpoint],
          pr: LEGAL_REGISTER_PADDING_HORIZONTAL_FACTOR[breakpoint],
          pt: 1,
          pb: 1,
          borderBottomStyle: open ? "solid" : "none",
          borderBottomWidth: open ? 1 : 0,
          borderBottomColor: "grey.100",
          transition: ".3s",
        }}
      >
        <Grid container alignItems="center">
          {icon && (
            <Grid item sx={{ pr: 2, display: "flex" }}>
              {icon}
            </Grid>
          )}
          <Grid item xs>
            <Typography
              variant={breakpointInfo.isMobile ? "body1" : "h6"}
              sx={{ color: "text.primary", fontWeight: 700, wordBreak: "break-word" }}
            >
              {title}
            </Typography>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          borderRadius: 0,
          mt: 4,
          mb: 4,
          p: 0,
        }}
      >
        {children}
      </AccordionDetails>
    </Accordion>
  );
};
