import { Box, CardMedia, Grid, SxProps, Theme, useTheme } from "@mui/material";
import React, { useContext, useMemo, useState } from "react";
import { ExternalPageSkeleton } from "./external-page.skeleton";
import { APP_BAR_OFFSET, PAGE_PAPER_PADDING_HORIZONTAL_FACTOR } from "../../theme/sizings.theme";
import { LayoutContext } from "../../components/layout/layout.context";

interface IExternalPageProps {
  id: string;
  url: string;
}

export const ExternalPage: React.FC<IExternalPageProps> = (props) => {
  const { id, url } = props;
  const [isLoading, setIsLoading] = useState(true);
  const theme = useTheme();
  const { breakpoint } = useContext(LayoutContext);

  const sx: SxProps<Theme> = useMemo(() => {
    return isLoading ? { height: 0, width: 0 } : { height: "100%", width: "100%" };
  }, [isLoading]);

  const height = useMemo(() => {
    const headingHeight = theme.typography.h4.fontSize;
    return `calc(100vh - ${APP_BAR_OFFSET[breakpoint]}px - ${headingHeight}px - ${PAGE_PAPER_PADDING_HORIZONTAL_FACTOR[breakpoint]}px * 4)`;
  }, [theme, breakpoint]);

  return (
    <Grid id="external-page-layout" container direction="column" sx={{ height, width: "100%" }}>
      <Box sx={{ height: "100%", width: "100%" }}>
        {isLoading && <ExternalPageSkeleton />}
        <CardMedia
          title="External"
          key={`ext-${id}`}
          onLoad={() => setIsLoading(false)}
          sx={{ ...sx, "&.MuiCardMedia-media": { border: "none" } }}
          component="iframe"
          image={url}
        />
      </Box>
    </Grid>
  );
};
