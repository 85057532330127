import { Box, SxProps, Tab, Tabs, Theme, Typography } from "@mui/material";
import React, { useCallback, useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { GiveIcon, SearchIcon } from "../../../assets/icons/icons.component";
import { IExtendedWidget } from "../../../pages/dashboard/widget-section/types/widget.type";
import { ThemeContext } from "../../layout/theme.context";
import { ThinkTankContentContext } from "../../think-tank/context/think-tank-content.context";
import { ThinkTankOffers } from "./think-tank-offers.component";
import { ThinkTankRequests } from "./think-tank-requests.component";

enum ThinkTankTab {
  OFFER = 0,
  REQUEST = 1,
}

const TabHeight = 48;
const tabStyle: SxProps<Theme> = {
  width: "50%",
  "&.Mui-selected": { color: "primaryDark.850" },
  height: TabHeight,
  minHeight: TabHeight,
  ".MuiTab-iconWrapper": {
    marginRight: 2,
  },
};

interface IThinkTankContentProps {
  widget: IExtendedWidget;
}

export const ThinkTankContent: React.FC<IThinkTankContentProps> = (props) => {
  const { widget } = props;
  const [activeTab, setActiveTab] = useState(ThinkTankTab.OFFER);
  const { t } = useTranslation();
  const { boxRef } = useContext(ThinkTankContentContext);
  const { isContrastThemeActive } = useContext(ThemeContext);

  const dividerColor = useMemo(() => {
    return isContrastThemeActive ? "widgetHeader.main" : "primary.550";
  }, [isContrastThemeActive]);

  const handleChange = useCallback((_: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  }, []);

  const getLabel = useCallback(
    (translationKey: string) => {
      return (
        <Typography variant="subtitle2" fontWeight={600} sx={{ textTransform: "none" }}>
          {t(translationKey)}
        </Typography>
      );
    },
    [t],
  );

  return (
    <Box ref={boxRef} sx={{ width: "100%", overflowY: "auto", overflow: "auto" }}>
      <Box>
        <Tabs
          variant="fullWidth"
          value={activeTab}
          onChange={handleChange}
          sx={{ height: TabHeight, minHeight: TabHeight, ".MuiTabs-indicator": { backgroundColor: dividerColor } }}
        >
          <Tab
            tabIndex={0}
            label={getLabel("think_tank.offer")}
            icon={<GiveIcon />}
            iconPosition="start"
            sx={tabStyle}
          />
          <Tab label={getLabel("think_tank.request")} icon={<SearchIcon />} iconPosition="start" sx={tabStyle} />
        </Tabs>
      </Box>
      <Box>
        {activeTab === ThinkTankTab.REQUEST && <ThinkTankRequests widget={widget} />}
        {activeTab === ThinkTankTab.OFFER && <ThinkTankOffers widget={widget} />}
      </Box>
    </Box>
  );
};
