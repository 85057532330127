import { Grid, IconButton } from "@mui/material";
import React, { useContext } from "react";
import { CarouselDotIcon } from "../../../assets/icons/icons.component";
import { LayoutContext } from "../../layout/layout.context";
import { SLIDE_INDICATOR_MAX_WIDTH } from "../../../theme/sizings.theme";

interface ICarouselSlideIndicatorProps {
  slides: number[];
  setSlide: (index: number) => void;
  activeSlide: number;
}

export const CarouselSlideIndicator: React.FC<ICarouselSlideIndicatorProps> = (props) => {
  const { slides, setSlide, activeSlide } = props;
  const { breakpoint } = useContext(LayoutContext);

  return (
    <Grid
      container
      id="carousel-selector"
      justifyContent="flex-end"
      sx={{ maxWidth: SLIDE_INDICATOR_MAX_WIDTH[breakpoint] }}
    >
      {slides.map((_slide, index) => {
        return (
          <Grid key={`slide-${index}`} item>
            <IconButton
              onClick={() => {
                setSlide(index);
              }}
              aria-label={activeSlide ? "Active Slide" : "Select Slide"}
              key={index}
              disabled={index === activeSlide}
              sx={{
                p: 1,
                opacity: 0.48,
                color: "white",
                "&:disabled": {
                  color: "white",
                  opacity: 1,
                },
              }}
            >
              <CarouselDotIcon size="xxsmall" />
            </IconButton>
          </Grid>
        );
      })}
    </Grid>
  );
};
