import { Divider, Grid, Skeleton } from "@mui/material";
import React from "react";
import { LoadingText } from "../../loading/loading-text.component";

export const AddFavoriteQuickLinkSkeleton: React.FC = (props) => {
  return (
    <>
      <Grid container alignItems="center" spacing={4} justifyContent="flex-start" sx={{ pt: 2, pb: 2 }}>
        <Grid item xs="auto">
          <Skeleton variant="rectangular" width={24} height={24} />
        </Grid>
        <Grid item xs>
          <LoadingText variant="body2" lines={2} />
        </Grid>
        <Grid item xs="auto">
          <Skeleton variant="circular" width={24} height={24} />
        </Grid>
      </Grid>
      <Divider />
    </>
  );
};
