import { Typography } from "@mui/material";
import React from "react";

interface INavMenuTitleProps {
  title: string;
  id?: string;
  label?: string;
}

export const NavMenuTitle: React.FC<INavMenuTitleProps> = (props) => {
  const { title, id } = props;

  return (
    <Typography
      id={id}
      variant="body2"
      sx={{
        fontWeight: "bold",
        textTransform: "uppercase",
        pt: 6,
        pb: 2,
        pl: 4,
        pr: 4,
      }}
    >
      {title}
    </Typography>
  );
};
