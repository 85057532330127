import { NotificationState } from "../../../api/muu-api/graphql/generated";
import {
  InfoFilledIcon,
  NotificationAcceptedIcon,
  NotificationReadIcon,
  NotificationUnacceptedIcon,
  NotificationUnreadIcon,
} from "../../../assets/icons/icons.component";
import { IconSize } from "../../../theme/types/theme";
import { NotificationStateWithAll } from "../context/notification-state-filter.context";
import { notificationStatusToColor } from "./notification-status-to-color.helper";

export const getNotificationStatusIcon = (
  state: NotificationStateWithAll,
  iconSize?: IconSize | undefined,
  themeColor?: string,
): JSX.Element => {
  const color = notificationStatusToColor(state);
  switch (state) {
    case NotificationState.ACCEPTED:
      return <NotificationAcceptedIcon sx={{ color: themeColor ?? color }} size={iconSize} />;
    case NotificationState.UNACCEPTED:
      return <NotificationUnacceptedIcon sx={{ color: themeColor ?? color }} size={iconSize} />;
    case NotificationState.READ:
      return <NotificationReadIcon sx={{ color: themeColor ?? "grey.800" }} size={iconSize} />;
    case NotificationState.UNREAD:
      return <NotificationUnreadIcon sx={{ color: themeColor ?? "grey.800" }} size={iconSize} />;
    case "ALL":
      return <InfoFilledIcon sx={{ color: themeColor ?? color }} size={iconSize} />;
  }
};
