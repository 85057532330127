import { Link, Typography, TypographyPropsVariantOverrides, useTheme } from "@mui/material";
import { Variant } from "@mui/material/styles/createTypography";
import { OverridableStringUnion } from "@mui/types";
import React, { useContext } from "react";
import { ThemeContext } from "../layout/theme.context";

type SendTypography = "mail" | "phone";

interface IPhoneOrMailTypographyProps {
  value: string;
  label?: string;
  type: SendTypography;
  variant?: OverridableStringUnion<Variant | "inherit", TypographyPropsVariantOverrides> | undefined;
}

export const PhoneOrMailTypography: React.FC<IPhoneOrMailTypographyProps> = (props) => {
  const { value, variant, label, type } = props;
  const { isContrastThemeActive } = useContext(ThemeContext);
  const theme = useTheme();

  return (
    <Link
      href={type === "mail" ? `mailto:${value}` : `tel:${value}`}
      underline={isContrastThemeActive ? "always" : "hover"}
      sx={{
        ...(isContrastThemeActive && {
          color: theme.palette.grey[600],
          textDecorationColor: theme.palette.grey[600],
        }),
      }}
    >
      <Typography variant={variant}>{label ?? value}</Typography>
    </Link>
  );
};
