export enum Breakpoint {
  MOBILE = "MOBILE",
  DESKTOP = "DESKTOP",
  DESKTOP_XL = "DESKTOP_XL",
}

export type Sizing = {
  [key in Breakpoint]: number;
};

//if mobile is not set desktop spacing will be used
const createSizing = (args: { desktop: number; mobile?: number; desktopXL?: number }): Sizing => ({
  [Breakpoint.DESKTOP]: args.desktop,
  [Breakpoint.MOBILE]: args.mobile !== undefined ? args.mobile : args.desktop,
  [Breakpoint.DESKTOP_XL]: args.desktopXL !== undefined ? args.desktopXL : args.desktop,
});

export const SPACING: Sizing = createSizing({ desktop: 4 });
export const CONTENT_MAX_WIDTH: Sizing = createSizing({ desktop: 1440 });
export const EDIT_DASHBOARD_MIN_WIDTH: Sizing = createSizing({ desktop: 768 }); // width of iPad Mini in portrait mode
export const DIALOG_MARGIN_FACTOR: Sizing = createSizing({ desktop: 0, mobile: 2 });
export const DIALOG_ICON_MARGIN_FACTOR: Sizing = createSizing({ desktop: 6, mobile: 3 });
export const NAV_WIDTH: Sizing = createSizing({ desktop: 280, mobile: 260 });
export const SMALL_NAV_WIDTH: Sizing = createSizing({ desktop: 88 });
export const APP_BAR: Sizing = createSizing({ desktop: 92, mobile: 60 });
export const APP_BAR_OFFSET: Sizing = createSizing({ desktop: 92 + 40, mobile: 60 + 24 });
export const SEARCH_BAR_HEIGHT: Sizing = createSizing({ desktop: 56 });
export const SEARCH_MENU_MIN_WIDTH: Sizing = createSizing({ desktop: 220 });
export const FILTER_POPOVER_MAX_WIDTH: Sizing = createSizing({ desktop: 496 });
export const FILTER_POPOVER_PADDING: Sizing = createSizing({ desktop: 32 });
export const WIDGET_CONTENT_ROW_HEIGHT: Sizing = createSizing({ desktop: 22 });
export const INFOCENTER_CARD_HEIGHT: Sizing = createSizing({ desktop: 356 });
export const OVERVIEW_LOADING_CARD_HEIGHT: Sizing = createSizing({ desktop: 200 });
export const INFOCENTER_DETAIL_HEADER: Sizing = createSizing({ desktop: 356, mobile: 256 });
export const INFOCENTER_DETAIL_HEADER_TEXT: Sizing = createSizing({ desktop: 128 });
export const CAROUSEL_INDICATOR_TOP_POSITION: Sizing = createSizing({ desktop: 358 });
export const DEFAULT_CARD_HEIGHT: Sizing = createSizing({ desktop: 188 });
export const DEFAULT_CARD_IMAGE_HEIGHT: Sizing = createSizing({ desktop: 188, mobile: 160 });
export const INFOCENTER_IMPORTANT_ARTICLE_HEIGHT: Sizing = createSizing({ desktop: 84, mobile: 126 });
export const PAGE_PAPER_PADDING_HORIZONTAL_FACTOR: Sizing = createSizing({ desktop: 20, mobile: 4 });
export const PAGE_PAPER_PADDING_VERTICAL_FACTOR: Sizing = createSizing({ desktop: 15, mobile: 6 });
export const LEGAL_REGISTER_PADDING_TOP_FACTOR: Sizing = createSizing({ desktop: 6, mobile: 6 });
export const LEGAL_REGISTER_PADDING_BOTTOM_FACTOR: Sizing = createSizing({ desktop: 8, mobile: 6 });
export const LEGAL_REGISTER_PADDING_HORIZONTAL_FACTOR: Sizing = createSizing({ desktop: 6, mobile: 4 });
export const INFOCENTER_HEADER_PADDING_TOP_FACTOR: Sizing = createSizing({ desktop: 7, mobile: 6 });
export const INFOCENTER_HEADER_PADDING_BOTTOM_FACTOR: Sizing = createSizing({ desktop: 7, mobile: 6 });
export const INFOCENTER_HEADER_PADDING_HORIZONTAL_FACTOR: Sizing = createSizing({ desktop: 20, mobile: 4 });
export const INFOCENTER_HEADER_SUBTITLE_PADDING_BOTTOM_FACTOR: Sizing = createSizing({ desktop: 2, mobile: 0 });
export const INFOCENTER_CONTENT_PADDING_TOP_FACTOR: Sizing = createSizing({ desktop: 15, mobile: 0 });
export const TOPIC_PAGE_TITLE_MARGIN_FACTOR: Sizing = createSizing({ desktop: 10, mobile: 4 });
export const TOPIC_PAGE_DESCRIPTION_MAX_HEIGHT: Sizing = createSizing({ desktop: window.screen.height * 0.33 });
export const EDITOR_CONTENT_LARGE_SPACING: Sizing = createSizing({ desktop: 10 });
export const PAGE_PADDING_HORIZONTAL_FACTOR: Sizing = createSizing({ desktop: 10, mobile: 4 });
export const OVERVIEW_SPACING_FACTOR: Sizing = createSizing({ desktop: 6, mobile: 4 });
export const PAGE_PADDING_VERTICAL_FACTOR: Sizing = createSizing({ desktop: 10, mobile: 4 });
export const SEARCH_RESULT_SKELETON_WIDTH: Sizing = createSizing({ desktop: 230 });
export const WIDGET_DIALOG_WIDTH: Sizing = createSizing({ desktop: 500 });
export const CUSTOM_DIALOG_TITLE_HEIGHT: Sizing = createSizing({ desktop: 76 });
export const WIDGET_ROW_IMG_SIZE: Sizing = createSizing({ desktop: 44 });
export const WIDGET_INACTIVE_DRAGGING_BORDER: Sizing = createSizing({ desktop: 2 });
export const WIDGET_ACTIVE_DRAGGING_BORDER: Sizing = createSizing({ desktop: 6 });
export const INFINITE_SCROLL_PAGE_OFFSET: Sizing = createSizing({ desktop: 20 });
export const LOGO_LARGE_WIDTH: Sizing = createSizing({ desktop: 160 });
export const LOGO_SMALL_WIDTH: Sizing = createSizing({ desktop: 50 });
export const PERSON_SMALL_PROFILE_IMG_SIZE: Sizing = createSizing({ desktop: 88 });
export const PERSON_PROFILE_IMG_SIZE: Sizing = createSizing({ desktop: 200, mobile: 100 });
export const PERSON_PROFILE_CARD_WIDTH: Sizing = createSizing({ desktop: 718 });
export const PERSON_PROFILE_MAX_ROW_SIZE: Sizing = createSizing({ desktop: 277 });
export const PAGE_LOADER_LOGO: Sizing = createSizing({ desktop: window.screen.height * 0.2 });
export const WIDGET_HEIGHT: Sizing = createSizing({ desktop: 415 });
export const WIDGET_HEADER_HEIGHT: Sizing = createSizing({ desktop: 60 });
export const BELL_DATA_DRAWER_WIDTH: Sizing = createSizing({ desktop: 420, mobile: 420 });
export const THINK_TANK_DIALOG_WIDTH: Sizing = createSizing({ desktop: 600 });
export const THINK_TANK_IMAGE_PREVIEW_SIZE: Sizing = createSizing({ desktop: 100 });
export const INFOCENTER_CAROUSEL_WIDTH = createSizing({ desktop: 344 });
export const INFOCENTER_CAROUSEL_HEIGHT = createSizing({ desktop: 210 });
export const INFOCENTER_CAROUSEL_BUTTON_WIDTH = createSizing({ desktop: 106 });
export const INFOCENTER_CAROUSEL_BUTTON_HEIGHT = createSizing({ desktop: 36 });
export const WIDGET_TOTAL_ROW_HEIGHT = createSizing({ desktop: 60 });
export const DIVIDER_PADDING = createSizing({ desktop: 6.5 });
export const WIDGET_CONTENT_BOTTOM_PADDING = createSizing({ desktop: 3.75 });
export const WIDGET_COLOR_SELECTION_WIDTH = createSizing({ desktop: 260, mobile: 210 });
export const WIDGET_COLOR_SELECTION_ITEM_HEIGHT = createSizing({ desktop: 60 });
export const WIDGET_COLOR_SELECTION_SPACING_FACTOR = createSizing({ desktop: 8, mobile: 4 });
export const QUICKLINK_SELECTION_SPACING_FACTOR = createSizing({ desktop: 4, mobile: 2 });
export const CONTENT_TITLE_MARGIN_BOTTOM_FACTOR = createSizing({ desktop: 0, mobile: 4 });
export const SEARCH_RESULT_AVATAR_SIZE = createSizing({ desktop: 80, mobile: 60 });
export const SEARCH_RESULT_ICON_HORIZONTAL_MARGIN_FACTOR = createSizing({ desktop: 4, mobile: 0 });
export const DEFAULT_CARD_MIN_WIDTH = createSizing({ desktop: 480, mobile: 0 });
export const EVENT_WIDGET_MIN_ROW_HEIGHT: Sizing = createSizing({ desktop: 50 });
export const NOTIFICATION_STATE_FILTER_MIN_HEIGHT: Sizing = createSizing({ desktop: 36 });
export const LUNCH_WIDGET_PRICE_MIN_WIDTH: Sizing = createSizing({ desktop: 45 });
export const PAGE_LOGO_MARGIN_BOTTOM: Sizing = createSizing({ desktop: 16 });
export const SLIDE_INDICATOR_MAX_WIDTH: Sizing = createSizing({ desktop: 100 });
