import React, { createContext, FC, PropsWithChildren } from "react";
import { useBreadcrumbs } from "../../../../hooks/use-breadcrumbs.hook";
import { IMenuItemWithPortalRoute } from "./nav-menu.context";
import { useCurrentMenuItem } from "../../../../hooks/use-current-menu-item.hook";

interface IBreadcrumbsContext {
  breadcrumbs: IMenuItemWithPortalRoute[];
  currentMenuItem?: IMenuItemWithPortalRoute;
}

export const BreadcrumbsContext = createContext<IBreadcrumbsContext>({} as IBreadcrumbsContext);

interface IBreadcrumbsProviderProps extends PropsWithChildren {}

export const BreadcrumbsContextProvider: FC<IBreadcrumbsProviderProps> = (props) => {
  const currentMenuItem = useCurrentMenuItem();
  const breadcrumbs = useBreadcrumbs(currentMenuItem);

  return (
    <BreadcrumbsContext.Provider value={{ breadcrumbs, currentMenuItem }}>{props.children}</BreadcrumbsContext.Provider>
  );
};
