import { Grid, Typography } from "@mui/material";
import React from "react";
import { CustomLink } from "../../components/link/custom-link.component";

interface IEventLinkProps {
  startAdornment: React.ReactNode;
  link: string;
  title: string;
}

export const EventLink: React.FC<IEventLinkProps> = (props) => {
  const { startAdornment, link, title } = props;

  return (
    <Grid item container xs="auto" alignItems="center">
      <Grid item sx={{ display: "flex" }}>
        {startAdornment}
      </Grid>
      <Grid item xs sx={{ pl: 1 }}>
        <CustomLink href={link} target="_blank">
          <Typography variant="body1">{title}</Typography>
        </CustomLink>
      </Grid>
    </Grid>
  );
};
