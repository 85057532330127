import { InfocenterEntryAll, InfocenterType, SearchInfocenter } from "../api/muu-api/graphql/generated";
import Research from "../assets/images/research.jpg";

const DEFAULT_RESEARCH_MEDIA = {
  __typename: "Media",
  id: "default",
  mediaAlt: "Forschung",
  mediaTitle: "Forschung",
  mediaUrl: Research,
  mimeType: "",
};

const RESEARCH_CATEGORY = "Forschungsförderung";

const isInfocenterArticleDefaultImage = (infocenterArticle: InfocenterEntryAll | SearchInfocenter) => {
  if (infocenterArticle.type === InfocenterType.RESEARCH) return true;
  const category =
    (infocenterArticle as InfocenterEntryAll).mainCategory?.searchFilter ?? infocenterArticle.mainCategory;
  if (
    infocenterArticle.type === InfocenterType.STRAPI &&
    category === RESEARCH_CATEGORY &&
    !infocenterArticle.media?.mediaUrl
  )
    return true;
  return false;
};

export const getInfocenterMedia = (infocenterArticle: InfocenterEntryAll | SearchInfocenter) => {
  if (!isInfocenterArticleDefaultImage(infocenterArticle)) return infocenterArticle.media;
  return DEFAULT_RESEARCH_MEDIA;
};
