import { Grid, Skeleton, TypographyVariant, useTheme } from "@mui/material";
import React, { FC, useMemo } from "react";

interface ILoadingTextProps {
  variant: TypographyVariant;
  lines: number;
}

export const LoadingText: FC<ILoadingTextProps> = (props) => {
  const { variant, lines = 10 } = props;
  const theme = useTheme();

  const lineComponents = useMemo(() => {
    const _lineComponents = [];
    for (let i = 0; i < lines - 1; i++) {
      _lineComponents.push(
        <Skeleton
          variant="text"
          sx={{ fontSize: theme.typography[variant], width: `${Math.random() * (100 - 80) + 80}%` }}
        />,
      );
    }
    return _lineComponents;
  }, [theme, lines, variant]);

  return (
    <Grid item xs={12} container spacing={1} flexDirection="column">
      {lineComponents.map((lineComponent, index) => (
        <Grid item key={`line-${index}`}>
          {lineComponent}
        </Grid>
      ))}
    </Grid>
  );
};
