import { useTheme } from "@mui/material";
import React, { useContext } from "react";
import { UserContext } from "../../../app/context/user.context";
import { Language } from "../../../api/muu-api/graphql/generated";

interface IPublicTransportWidgetContentProps {}

export const PublicTransportWidgetContent: React.FC<IPublicTransportWidgetContentProps> = () => {
  const theme = useTheme();
  const { language } = useContext(UserContext);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing(2),
        height: "auto",
        flex: 1,
        boxShadow: "none",
      }}
    >
      <iframe
        style={{ height: "100%", border: "none" }}
        src={language === Language.DE ? "publicTransport.html" : "publicTransportEn.html"}
      ></iframe>
    </div>
  );
};
