import { Box, Button, Typography, useTheme } from "@mui/material";
import React, { FunctionComponent, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { WidgetColor } from "../../../../../api/muu-api/graphql/generated";
import { CheckIcon } from "../../../../../assets/icons/icons.component";
import { hexToRgb } from "../../../../../utils/color.util";
import { useWidgetColorToThemeColor } from "../../hooks/use-widget-color-to-theme-color.hook";

interface IWidgetColorSelectionDefaultColor {
  height: number;
  width: number;
  onChange: () => void;
  isSelected: boolean;
}

export const WidgetColorSelectionDefaultColor: FunctionComponent<IWidgetColorSelectionDefaultColor> = (props) => {
  const { t } = useTranslation();
  const { height, width, onChange, isSelected } = props;
  const theme = useTheme();

  const colorKey = useWidgetColorToThemeColor(WidgetColor.DEFAULT);
  const colorAsRgb = useMemo(() => hexToRgb(theme.palette[colorKey].contrastText), [theme, colorKey]);

  return (
    <Box
      component={Button}
      onClick={onChange}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        bgcolor: `${colorKey}.main`,
        color: `${colorKey}.contrastText`,
        borderWidth: 1.5,
        borderStyle: "solid",
        borderColor: `${colorKey}.dark`,
        height,
        width,
        borderRadius: 3,
        cursor: "pointer",
        position: "relative",
        transition: "0.3s",
        boxShadow: isSelected
          ? `4px 4px 8px 0px rgba(${colorAsRgb?.r},${colorAsRgb?.g},${colorAsRgb?.b}, 0.32)`
          : undefined,
      }}
    >
      {isSelected && (
        <CheckIcon
          size="small"
          sx={{
            color: `${colorKey}.contrastText`,
            position: "absolute",
            left: width / 5,
          }}
        />
      )}
      <Typography variant="body1">{t("widget_color.default")}</Typography>
    </Box>
  );
};
