import { Category } from "../../api/muu-api/graphql/generated";
import { ICategoryFilterInfo } from "../../pages/dashboard/widget-section/types/widget.type";

export const getCategoriesForSearchQueries = (
  widgetCategories: ICategoryFilterInfo[],
  allCategories: Category[],
): string[] => {
  const selectedUnlockedCategories = allCategories.filter((category) =>
    widgetCategories
      .filter((info) => !info.isLocked)
      .map((info) => info.id)
      .includes(category.id.toString()),
  );

  const selectedLockedCategories = allCategories.filter((category) =>
    widgetCategories
      .filter((info) => info.isLocked)
      .map((info) => info.id)
      .includes(category.id.toString()),
  );

  // if there are no selected unlocked categories (categories that the user chose for himself) -> all categories should be used
  if (selectedUnlockedCategories.length === 0) {
    return [];
  }

  // if there are selected unlocked categories -> both arrays must be returned
  return [
    ...selectedUnlockedCategories.map((category) => category.searchFilter),
    ...selectedLockedCategories.map((category) => category.searchFilter),
  ];
};
