import { Box, SxProps } from "@mui/material";
import { FC, useContext } from "react";
import { ChevronRightIcon } from "../../../../assets/icons/icons.component";
import { LayoutContext } from "../../layout.context";
import { NavMenuItemIcon } from "./nav-menu-item-icon.component";

interface INavMenuItemSubmenuIndicatorProps {
  isOpen: boolean;
  isSelected: boolean;
  isHovering: boolean;
  sx: SxProps;
}

export const NavMenuItemSubmenuIndicator: FC<INavMenuItemSubmenuIndicatorProps> = (props) => {
  const { isOpen, isSelected, isHovering, sx } = props;
  const { sidebarOpen } = useContext(LayoutContext);

  return (
    <Box
      sx={{
        position: "absolute",
        right: 0,
        top: 0,
        bottom: 0,
        minWidth: 0,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        ...sx,
      }}
    >
      <NavMenuItemIcon
        IconComponent={ChevronRightIcon}
        isOpen={isOpen}
        isSelected={isSelected}
        isHovering={isHovering}
        size={sidebarOpen ? "medium" : "xsmall"}
        sx={{
          transform: !isOpen ? "none" : "rotate(90deg)",
          transition: ".2s",
        }}
      />
    </Box>
  );
};
