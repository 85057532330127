import {
  createContext,
  FunctionComponent,
  MutableRefObject,
  PropsWithChildren,
  ReactInstance,
  useContext,
  useMemo,
  useRef,
} from "react";
import { useSearchParams } from "react-router-dom";
import { IFavoriteInputs } from "../../../components/button/page-action-icon-buttons.component";
import { useFavorite } from "../../../hooks/use-favorite.hook";
import { decodeIdBase64 } from "../../../utils/base64.util";
import {
  EventEntry,
  EventType,
  Favorite,
  FavoriteType,
  useEventsDetailsQuery,
} from "../../../api/muu-api/graphql/generated";
import { eventSourceToFavoriteSource } from "../../../components/widgets/events/helper/event-mapping.helper";
import { ApolloError } from "@apollo/client";
import { checkIfValueIsTypeOfEnum } from "../../../utils/enum.util";
import { UserContext } from "../../../app/context/user.context";

interface IEventsDetailContext {
  event?: EventEntry;
  eventError?: ApolloError;
  eventLoading: boolean;
  pageContentRef: MutableRefObject<ReactInstance | null>;
  favoriteInputs: IFavoriteInputs | undefined;
}

export const EventsDetailContext = createContext<IEventsDetailContext>({} as IEventsDetailContext);

interface IEventsDetailProviderProps extends PropsWithChildren {}

export const EventsDetailProvider: FunctionComponent<IEventsDetailProviderProps> = (props) => {
  const [searchParams] = useSearchParams();
  const pageContentRef = useRef<ReactInstance>(null);
  const { removeFavorite, createFavorite } = useFavorite();
  const { language } = useContext(UserContext);

  const { id, type } = useMemo(() => {
    const decoded = decodeIdBase64<EventType>(searchParams.get("id") || "");

    return {
      id: decoded.id || null,
      type: checkIfValueIsTypeOfEnum(EventType, `${decoded.type}`) ? decoded.type : null,
    };
  }, [searchParams]);

  const {
    data,
    loading: eventLoading,
    error: eventError,
  } = useEventsDetailsQuery({
    skip: !id || !type,
    fetchPolicy: "no-cache",
    variables: { id: id!, type: type!, language },
  });
  const event = useMemo(() => data?.eventEntry || undefined, [data]);

  const favoriteInputs = useMemo(() => {
    if (!event) return undefined;
    const onRemoveFavorite = (fav: Favorite) => removeFavorite(fav);
    const onCreateFavorite = () => {
      const input = {
        entryId: event.id,
        description: event.title,
        teaser: event.teaser ?? undefined,
        type: FavoriteType.EVENT,
        source: eventSourceToFavoriteSource(event.eventType),
      };

      createFavorite(input);
    };

    return {
      entryId: event.id,
      type: FavoriteType.EVENT,
      source: eventSourceToFavoriteSource(event.eventType),
      onRemoveFavorite,
      onCreateFavorite,
    };
  }, [createFavorite, event, removeFavorite]);

  return (
    <EventsDetailContext.Provider
      value={{
        event,
        eventLoading,
        eventError,
        pageContentRef,
        favoriteInputs,
      }}
    >
      {props.children}
    </EventsDetailContext.Provider>
  );
};
