import { createContext, FC, PropsWithChildren, useState } from "react";
import { NotificationState } from "../../../api/muu-api/graphql/generated";

export type NotificationStateWithAll = NotificationState | "ALL";

interface INotificationStateFilterContext {
  notificationState: NotificationStateWithAll;
  setNotificationState: (state: NotificationStateWithAll) => void;
}

export const NotificationStateFilterContext = createContext<INotificationStateFilterContext>(
  {} as INotificationStateFilterContext,
);

interface INotificationStateFilterProviderProps extends PropsWithChildren {}

export const NotificationStateFilterContextProvider: FC<INotificationStateFilterProviderProps> = (props) => {
  const [notificationState, setNotificationState] = useState<NotificationStateWithAll>("ALL");

  return (
    <NotificationStateFilterContext.Provider value={{ notificationState, setNotificationState }}>
      {props.children}
    </NotificationStateFilterContext.Provider>
  );
};
