import { Grid, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { camelToSnakeCase } from "../../utils/case.util";

export type NoContentVariant =
  | "infocenter"
  | "infocenterError"
  | "infocenterOverview"
  | "infocenterOverviewError"
  | "notificationOverview"
  | "notificationOverviewError"
  | "event"
  | "eventError"
  | "eventOverview"
  | "eventOverviewError"
  | "person"
  | "personError"
  | "document"
  | "topic"
  | "topicPageDetailError"
  | "notification"
  | "notificationError"
  | "thinkTank"
  | "legalRegisterOverview"
  | "legalRegisterOverviewError"
  | "settingsError";

interface INoContentFoundProps {
  variant: NoContentVariant;
}

export const NoContentFound: React.FC<INoContentFoundProps> = (props) => {
  const { variant } = props;
  const { t } = useTranslation();
  return (
    <Grid container sx={{ flex: 1 }} justifyContent="center" alignItems="center">
      <Grid item sx={{ mt: 20 }}>
        <Typography variant="h6" sx={{ color: "grey.500" }}>
          {t(`no_content.${camelToSnakeCase(variant)}`)}
        </Typography>
      </Grid>
    </Grid>
  );
};
