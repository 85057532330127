import { Box, Divider, Grid, Paper, Skeleton, Typography, useTheme } from "@mui/material";
import parse from "html-react-parser";
import { useCallback, useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { InfocenterEntry, InfocenterResearchEntry, InfocenterType } from "../../api/muu-api/graphql/generated";
import { EditorContent } from "../../components/editor/editor-content.component";
import { LayoutContext } from "../../components/layout/layout.context";
import { CustomLink } from "../../components/link/custom-link.component";
import { LoadingText } from "../../components/loading/loading-text.component";
import {
  DIVIDER_PADDING,
  INFOCENTER_CONTENT_PADDING_TOP_FACTOR,
  PAGE_PAPER_PADDING_HORIZONTAL_FACTOR,
  PAGE_PAPER_PADDING_VERTICAL_FACTOR,
} from "../../theme/sizings.theme";
import { InfocenterDetailContext } from "./context/infocenter-detail.context";
import { CategoryChips } from "../../components/categories/category-chips.component";
import { InfocenterDetailHeader } from "./infocenter-detail-header.component";
import { InfocenterDetailResearchFields } from "./infocenter-detail-research-fields.component";
import { LastEditedTypography } from "../../components/typography/last-edited-typography.component";

interface IInfocenterDetailContentProps {}

export const InfocenterDetailContent: React.FC<IInfocenterDetailContentProps> = (props) => {
  const { infocenterEntry, infocenterEntryLoading, pageContentRef } = useContext(InfocenterDetailContext);
  const theme = useTheme();
  const { t } = useTranslation();
  const { breakpoint } = useContext(LayoutContext);

  const { publishedAt, relatedLinks, short, showResearchFields } = useMemo(() => {
    switch (infocenterEntry?.type) {
      case InfocenterType.TYPO3_NEWS:
      case InfocenterType.STRAPI:
        return {
          short: undefined,
          publishedAt:
            (infocenterEntry as InfocenterEntry).updatedAt ?? (infocenterEntry as InfocenterEntry).publishedAt,
          relatedLinks: (infocenterEntry as InfocenterEntry).relatedLinks || [],
          showResearchFields: false,
        };
      case InfocenterType.RESEARCH:
        return {
          short: (infocenterEntry as InfocenterResearchEntry).short,
          publishedAt: (infocenterEntry as InfocenterResearchEntry).startDate,
          relatedLinks: [],
          showResearchFields: true,
        };
    }
    return {
      short: undefined,
      publishedAt: undefined,
      relatedLinks: [],
      showResearchFields: false,
    };
  }, [infocenterEntry]);

  const cleanRelatedLink = useCallback((link: string) => {
    // links often include the person name or a title which breaks the link, so remove it
    // example www.my-page.at/topic my name
    const cleanedLink = link.split(" ")[0];
    const parsed = cleanedLink.replaceAll('"', "");

    return parsed;
  }, []);

  return (
    <Paper sx={{ borderRadius: 4, p: 0, flex: 1, display: "flex", overflow: "hidden", flexDirection: "column" }}>
      <Box ref={pageContentRef}>
        <InfocenterDetailHeader />
        <Grid
          container
          sx={{
            pt: INFOCENTER_CONTENT_PADDING_TOP_FACTOR[breakpoint],
            pb: PAGE_PAPER_PADDING_VERTICAL_FACTOR[breakpoint],
            pl: PAGE_PAPER_PADDING_HORIZONTAL_FACTOR[breakpoint],
            pr: PAGE_PAPER_PADDING_HORIZONTAL_FACTOR[breakpoint],
          }}
        >
          {!!short && (
            <Grid item xs={12} sx={{ mb: PAGE_PAPER_PADDING_VERTICAL_FACTOR[breakpoint] }}>
              <Typography fontWeight="bold">{parse(short)}</Typography>
            </Grid>
          )}
          {!!infocenterEntry?.description && (
            <Grid item xs={12}>
              <EditorContent editorContentHtmlString={infocenterEntry.description} />
            </Grid>
          )}
          {infocenterEntryLoading && !infocenterEntry?.description && <LoadingText variant="body1" lines={10} />}
          <Grid item container justifyContent="flex-end" xs={12}>
            {showResearchFields && (
              <Grid item xs={12} sx={{ mt: PAGE_PAPER_PADDING_VERTICAL_FACTOR[breakpoint] }}>
                <InfocenterDetailResearchFields />
              </Grid>
            )}
            {!!publishedAt && <LastEditedTypography date={publishedAt} />}
            {infocenterEntryLoading && (
              <Skeleton variant="text" width={200} sx={{ fontSize: theme.typography.body1 }} />
            )}
          </Grid>
          <Grid item xs={12}>
            <CategoryChips
              additionalCategories={infocenterEntry?.additionalCategories}
              mainCategory={infocenterEntry?.mainCategory}
              loading={infocenterEntryLoading}
              type="infocenter"
            />
          </Grid>
          {relatedLinks.length > 0 && (
            <>
              <Grid item xs={12} sx={{ mb: DIVIDER_PADDING[breakpoint], mt: DIVIDER_PADDING[breakpoint] }}>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6" sx={{ color: "primaryDark.main", mb: 2 }}>
                  {t("infocenter.related_links")}
                </Typography>
                <Grid item xs={12} container>
                  {relatedLinks!.map((relatedLink, index) => (
                    <Grid item xs={12} key={`relatedLink-${relatedLink}`}>
                      <CustomLink
                        key={`${index}-${relatedLink.url}`}
                        href={cleanRelatedLink(relatedLink.url)}
                        target="_blank"
                      >
                        {relatedLink.title}
                      </CustomLink>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
      </Box>
    </Paper>
  );
};
