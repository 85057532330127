import { Typography } from "@mui/material";
import React, { useCallback, useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { Category, Media, SearchInfocenter } from "../../../api/muu-api/graphql/generated";
import { parseRelativeLink } from "../../../app/helpers/relative-link.helper";
import { formatShortDate } from "../../../utils/date.util";
import { CustomMediaIcon } from "../../icon/custom-media-icon.component";
import { NavMenuContext } from "../../layout/nav/context/nav-menu.context";
import { getInfocenterDetailUrl } from "../helper/infocenter.helper";
import { useTracking } from "../../../app/context/tracking/tracking.context";
import { DefaultCard } from "../../card/default-card.component";
import { getInfocenterMedia } from "../../../utils/media.util";

interface IInfocenterCardProps {
  infocenter: SearchInfocenter;
  categories: Category[];
}

export const InfocenterCard: React.FC<IInfocenterCardProps> = (props) => {
  const { infocenter, categories } = props;
  const navigate = useNavigate();
  const { pageOfInterestDictionary } = useContext(NavMenuContext);
  const { trackEvent } = useTracking();

  const categoryIcon = useMemo(() => {
    return (
      categories.find((category) => category.searchFilter === infocenter.mainCategory)?.icon ?? categories[0]?.icon
    );
  }, [categories, infocenter]);

  const navigateToDetails = useCallback(() => {
    trackEvent({
      event: "detail_view",
      action: "redirect",
      category: "page_visit",
    });
    navigate(getInfocenterDetailUrl(infocenter.id, infocenter.type, pageOfInterestDictionary, true));
  }, [infocenter, pageOfInterestDictionary]);

  const buttonProps = useMemo(() => {
    return infocenter.link
      ? { target: "_blank", href: infocenter.link ? parseRelativeLink(infocenter.link).link : "" }
      : { onClick: navigateToDetails };
  }, [infocenter.link, navigateToDetails]);

  const media = useMemo(() => {
    return getInfocenterMedia(infocenter);
  }, [infocenter]);

  return (
    <DefaultCard
      title={infocenter.title}
      dateInfo={
        <Typography variant="caption" fontWeight="700">
          {formatShortDate(infocenter.createdAt)}
        </Typography>
      }
      buttonProps={buttonProps}
      description={infocenter.teaser}
      media={media as Media | undefined}
      iconBar={categoryIcon && <CustomMediaIcon strapiMedia={categoryIcon} size="medium" />}
    />
  );
};
