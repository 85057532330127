import { createTheme, ThemeOptions } from "@mui/material/styles";
import { deepmerge } from "@mui/utils";
import { BaseThemeOptions } from "./base.theme-options";
import { getMuiSwitchStyleOverride } from "./style-overrides/mui-switch.style-override";

export interface IThemeColorDefinition {
  [key: string | number]: string;
}

const primaryDark: IThemeColorDefinition = {
  // Sketch Palette "Dunkelgrün"
  main: "#007934",
  25: "#F2F8F5",
  50: "#E5F1EA",
  100: "#CCE4D6",
  200: "#B2D6C2",
  300: "#66AF85",
  400: "#33945D",
  500: "#007934",
  600: "#00612A",
  700: "#00491F",
  800: "#003015",
  850: "#262626",
  900: "#00180A",
  contrastText: "#ffffff",
};

const grey: IThemeColorDefinition = {
  900: "#191919",
  800: "#333333",
  850: "#262626",
  700: "#4C4C4C",
  600: "#666666",
  500: "#888888",
  400: "#999999",
  300: "#B2B2B2",
  200: "#CCCCCC",
  100: "#E5E5E5",
  50: "#F2F2F2",
  25: "#F9F9F9",
  disabledBackground: "#919eab3d",
  searchBorder: "#F2F2F2",
};

const primary: IThemeColorDefinition = {
  main: "#007934",
  dark: "#007B55",
  contrastText: "#ffffff",
  25: "#EAF7EA",
  50: "#EAF7EA",
  500: "#007934",
  550: "#34B233",
};

const text: IThemeColorDefinition = {
  primary: "#212B36",
};

const LightThemeOptions: ThemeOptions = {
  palette: {
    primary,
    secondary: {
      main: "#D87F56",
      500: "#FD793C",
    },
    background: {
      paper: "#ffffff",
    },
    error: {
      main: "#FD793C",
      contrastText: "#ffffff",
    },
    grey,
    text,
    accent1: {
      main: "#048F8F",
      contrastText: "#ffffff",
      25: "#048f8f1A",
    },
    accent2: {
      main: "#FD3C8F",
      contrastText: "#ffffff",
    },
    accent3: {
      main: "#FF9E00",
      contrastText: "#ffffff",
    },
    accent4: {
      main: "#790074",
      contrastText: "#ffffff",
    },
    accent5: {
      main: "#9D0808",
      contrastText: "#ffffff",
    },
    widgetHeader: {
      main: "#F9F9F9",
      dark: "#E5E5E5",
      contrastText: primaryDark.main,
    },
    importantNews: {
      main: "#048F8F",
    },
    menuBar: {
      main: "#EAF7EA",
      contrastText: primaryDark.main,
    },
    accent6: {
      // Sketch "Widget bearbeiten blau"
      main: "#06A7FF",
      contrastText: "#FFFFFF",
    },
    overlay: {
      main: "#00000077",
      contrastText: "#ffffff",
    },
    transparentOverlay: {
      main: "#ffffffcc",
      contrastText: "#212B36",
    },
    primaryDark,
  },
  typography: {
    h1: {
      color: primaryDark.main,
    },
    h2: {
      color: primaryDark.main,
    },
    h3: {
      color: primaryDark.main,
    },
    h4: {
      color: primaryDark.main,
    },
    editorH1: {
      color: text.primary,
    },
    editorH2: {
      color: text.primary,
    },
    editorH3: {
      color: text.primary,
    },
    editorH4: {
      color: text.primary,
    },
    editorH5: {
      color: text.primary,
    },
    editorH6: {
      color: text.primary,
    },
  },
  boxShadow: {
    light: "0px 1px 2px 0px rgba(145, 158, 171, 0.24)",
  },
  components: {
    MuiLink: {
      defaultProps: {
        underline: "hover",
      },
      styleOverrides: {
        root: {
          cursor: "pointer",
          color: grey[400],
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          ":hover": {
            backgroundColor: "#EAF7EA",
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          ":hover": {
            backgroundColor: primaryDark[25],
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        contained: {
          backgroundColor: primaryDark.main,
        },
        outlined: {
          borderColor: primaryDark.main,
          color: primaryDark.main,
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: "#048F8F",
        },
        arrow: {
          color: "#048F8F",
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          "&.Mui-checked": { color: primary[500] },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          "&.Mui-checked": { color: primary[500] },
        },
      },
    },
    MuiSwitch: {
      styleOverrides: getMuiSwitchStyleOverride(primary, grey),
    },
  },
};

export const LightTheme = createTheme(deepmerge(LightThemeOptions, BaseThemeOptions));
