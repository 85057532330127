import React from "react";
import { Grid, Typography, Button, Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { getAbsoluteRoutingPath } from "../../utils/get-absolute-routing-path.util";
import { StaticPageDictionary } from "../../router/routes.const";

interface ErrorPageProps {
  errorCode: string;
}

export const ErrorPage: React.FC<ErrorPageProps> = (props) => {
  const { errorCode } = props;
  const { t } = useTranslation();

  return (
    <Box sx={{ height: "100vh", width: "100vw", display: "flex", alignContent: "center", justifyContent: "center" }}>
      <Grid
        container
        alignContent={"center"}
        justifyItems="center"
        sx={{ display: "block", maxWidth: 488, textAlign: "center" }}
      >
        <Grid item xs={12}>
          <Typography
            color="primary.main"
            sx={{
              fontSize: 122,
              lineHeight: "143px",
              fontWeight: 700,
              userSelect: "none",
            }}
          >
            {errorCode}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            color="primary.main"
            variant="h4"
            sx={{
              userSelect: "none",
            }}
          >
            {t(`error.${errorCode}.title`)}
          </Typography>
        </Grid>
        <Grid item xs={12} marginTop={10}>
          <Typography
            variant="body1"
            sx={{
              userSelect: "none",
            }}
          >
            {t(`error.${errorCode}.description`)}
          </Typography>
        </Grid>
        {errorCode !== "401" && (
          <Grid item xs={12} marginTop={10}>
            <Button
              variant="contained"
              onClick={() => {
                window.location.replace(getAbsoluteRoutingPath(StaticPageDictionary.DASHBOARD.path));
              }}
            >
              {t("error.back_to_dashboard")}
            </Button>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};
