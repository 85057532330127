import { SvgIconProps, SvgIcon, useTheme } from "@mui/material";
import React, { FunctionComponent } from "react";
import { IconSize } from "../../theme/types/theme";

export interface ICustomSvgIconProps extends SvgIconProps {
  size?: IconSize;
  component: FunctionComponent<React.SVGProps<SVGSVGElement>>;
  title?: string;
}

export const CustomSvgIcon: FunctionComponent<ICustomSvgIconProps> = (props) => {
  const { size = "medium", component, sx, ...rest } = props;
  const theme = useTheme();

  return <SvgIcon sx={{ height: theme.icon[size], width: theme.icon[size], ...sx }} {...rest} component={component} />;
};
