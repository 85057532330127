import { Box, Divider, Grid, Typography, useTheme } from "@mui/material";
import React, { Fragment, useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  LegalDocument as ApiLegalDocument,
  Favorite,
  FavoriteSource,
  FavoriteType,
} from "../../api/muu-api/graphql/generated";
import { PdfIcon } from "../../assets/icons/icons.component";
import { FavoriteAction } from "../../components/favorite/favorite-action.component";
import { LayoutContext } from "../../components/layout/layout.context";
import { CustomLink } from "../../components/link/custom-link.component";
import { useFavorite } from "../../hooks/use-favorite.hook";
import { formatMillisToShortDate } from "../../utils/date.util";
import { LegalDocumentDetail } from "./legal-document-detail.component";
import { LEGAL_REGISTER_PADDING_HORIZONTAL_FACTOR } from "../../theme/sizings.theme";

interface ILegalDocumentProps {
  document: ApiLegalDocument;
  favorite: Favorite;
}

export const LegalDocument: React.FC<ILegalDocumentProps> = (props) => {
  const { favorite, document } = props;

  const { createFavorite, removeFavorite } = useFavorite();
  const { t } = useTranslation();
  const { breakpointInfo, breakpoint } = useContext(LayoutContext);
  const theme = useTheme();

  const details = useMemo(
    () =>
      [
        { description: t("legal_register.legal_basis"), value: document.legalBasis },
        {
          description: t("legal_register.publication_date"),
          value: document.publicationAt ? formatMillisToShortDate(document.publicationAt) : null,
        },
        { description: t("legal_register.responsibility"), value: document.responsibility },
        { description: t("legal_register.comment"), value: document.comment },
        {
          description: t("legal_register.modification_date"),
          value: document.modificationAt ? formatMillisToShortDate(document.modificationAt) : null,
        },
        // Remove empty values
      ].filter((detail) => detail.value !== null),
    [document, t],
  );

  const favoriteAction = useMemo(() => {
    return (
      <FavoriteAction
        favorite={favorite}
        onCreateFavorite={() =>
          createFavorite({
            entryId: document.id,
            description: document.title,
            source: FavoriteSource.STRAPI,
            type: FavoriteType.LEGAL_REGISTER,
          })
        }
        onRemoveFavorite={removeFavorite}
        displayStyle="icon"
      />
    );
  }, [createFavorite, document, removeFavorite, favorite]);

  const linkComponent = useMemo(() => {
    const typoComponent = <Typography sx={{ fontWeight: 600 }}>{document.title}</Typography>;
    if (!document.media) {
      return typoComponent;
    }
    return (
      <CustomLink href={document.media.mediaUrl} underline="none" variant="h6" color="inherit" target="_blank">
        {typoComponent}
      </CustomLink>
    );
  }, [document]);

  return (
    <Grid
      container
      rowGap={2}
      alignItems="center"
      sx={{
        pl: LEGAL_REGISTER_PADDING_HORIZONTAL_FACTOR[breakpoint],
        pr: LEGAL_REGISTER_PADDING_HORIZONTAL_FACTOR[breakpoint],
      }}
    >
      <Grid item xs>
        <Typography variant="body2" sx={{ color: "grey.700" }}>
          {document.shortCode}
        </Typography>
      </Grid>
      {breakpointInfo.isMobile && (
        <>
          <Grid item sx={{ height: theme.icon.medium }}>
            <PdfIcon aria-label="pdf" size="medium" sx={{ color: "accent1.main" }} />
          </Grid>
          <Grid item>
            <Box sx={{ ml: "auto" }}>{favoriteAction}</Box>
          </Grid>
        </>
      )}
      <Grid item xs={12}>
        <Grid container spacing={2} direction={"column"} sm>
          <Grid item>
            <Box sx={{ display: "flex", columnGap: 2 }}>
              {!breakpointInfo.isMobile && <PdfIcon size="medium" sx={{ color: "accent1.main" }} />}
              {linkComponent}
              {!breakpointInfo.isMobile && <Box sx={{ ml: "auto" }}>{favoriteAction}</Box>}
            </Box>
          </Grid>
          <Grid item>
            <Box sx={{ display: "flex", flexWrap: "wrap", rowGap: 0, columnGap: 4 }}>
              {details.map((detail, index) => (
                <Fragment key={`detail-${document.id}-${index}`}>
                  <LegalDocumentDetail description={detail.description} value={detail.value as string} />
                  {index !== details.length - 1 && breakpointInfo.isDesktopXL && <Divider orientation="vertical" />}
                </Fragment>
              ))}
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
