import {
  Box,
  Card,
  CardActionArea,
  CardActionAreaProps,
  CardContent,
  CardMedia,
  Grid,
  Typography,
} from "@mui/material";
import React, { useContext, useMemo } from "react";
import { Media } from "../../api/muu-api/graphql/generated";
import { ISvgIconBaseProps } from "../../assets/icons/icons.component";
import { DEFAULT_CARD_HEIGHT, DEFAULT_CARD_IMAGE_HEIGHT } from "../../theme/sizings.theme";
import { EditorContent } from "../editor/editor-content.component";
import { LayoutContext } from "../layout/layout.context";

interface IDefaultCardProps {
  title?: string | null;
  description?: string | null;
  media?: Media;
  dateInfo: React.ReactNode;
  iconBar?: React.ReactNode;
  defaultIcon?: React.ReactElement<ISvgIconBaseProps>;
  displayMedia?: boolean;
  buttonProps: CardActionAreaProps;
}

export const DefaultCard: React.FC<IDefaultCardProps> = (props) => {
  const { title, description, media, dateInfo, buttonProps, iconBar, defaultIcon, displayMedia = true } = props;
  const { breakpoint, breakpointInfo } = useContext(LayoutContext);

  const defaultMedia = useMemo(() => {
    return (
      <Box
        sx={{
          width: breakpointInfo.isMobile ? "100%" : DEFAULT_CARD_IMAGE_HEIGHT[breakpoint],
          height: DEFAULT_CARD_IMAGE_HEIGHT[breakpoint],
          backgroundColor: defaultIcon ? "accent1.25" : "primaryDark.main",
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
        }}
      >
        {defaultIcon}
      </Box>
    );
  }, [breakpointInfo, breakpoint, defaultIcon]);

  return (
    <Card
      variant="outlined"
      sx={{
        cursor: "pointer",
        width: "100%",
        backgroundColor: "background.paper",
        borderRadius: 4,
        display: "flex",
        height: breakpointInfo.isMobile ? undefined : DEFAULT_CARD_HEIGHT[breakpoint],
      }}
    >
      <CardActionArea {...buttonProps}>
        <CardContent
          sx={{
            height: "100%",
            overflow: "hidden",
            width: "100%",
            p: 0,
          }}
        >
          <Grid container direction={breakpointInfo.isMobile ? "column" : "row"} flexWrap="nowrap">
            {displayMedia && (
              <Grid
                item
                sx={{
                  width: breakpointInfo.isMobile ? "100%" : DEFAULT_CARD_IMAGE_HEIGHT[breakpoint],
                  height: DEFAULT_CARD_IMAGE_HEIGHT[breakpoint],
                }}
              >
                {media && (
                  <CardMedia
                    component="img"
                    image={media.mediaUrl ?? ""}
                    alt={media.mediaAlt ?? ""}
                    sx={{
                      backgroundColor: "primaryDark.main",
                      objectFit: "cover",
                      objectPosition: "center",
                      height: DEFAULT_CARD_IMAGE_HEIGHT[breakpoint],
                      width: breakpointInfo.isMobile ? "100%" : DEFAULT_CARD_IMAGE_HEIGHT[breakpoint],
                    }}
                  />
                )}
                {!media && <>{defaultMedia}</>}
              </Grid>
            )}
            <Grid
              item
              xs
              container
              spacing={2}
              direction="row"
              sx={{
                p: 4,
                height: "fit-content",
              }}
            >
              <Grid item xs={12}>
                <Grid container alignItems="center">
                  <Grid item xs>
                    {dateInfo}
                  </Grid>
                  <Grid item>{iconBar}</Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant="h6"
                  sx={{
                    overflow: "hidden",
                    display: "-webkit-box",
                    WebkitLineClamp: 3,
                    WebkitBoxOrient: "vertical",
                    wordBreak: "break-word",
                  }}
                >
                  <EditorContent editorContentHtmlString={title ?? ""} />
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant="body2"
                  sx={{
                    overflow: "hidden",
                    scrollBehavior: "none",
                    display: "-webkit-box",
                    WebkitLineClamp: 2,
                    WebkitBoxOrient: "vertical",
                    color: "grey.500",
                  }}
                >
                  <EditorContent editorContentHtmlString={description ?? ""} />
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};
