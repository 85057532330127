import { Button, DialogContent } from "@mui/material";
import { FC, useCallback, useContext } from "react";
import { useTranslation } from "react-i18next";
import { Restaurant } from "../../../../api/muu-api/graphql/generated";
import { CategoryFilterIcon } from "../../../../assets/icons/icons.component";
import { CustomDialogTitle } from "../../../../components/dialog/custom-dialog-title.component";
import { useSelectedRestaurants } from "../../../../components/filter/hooks/use-selected-restaurants.hook";
import { RestaurantFilter } from "../../../../components/filter/restaurant-filter.component";
import { DashboardWidgetsContext } from "../dashboard-widgets.context";
import { IWidgetMenuItemBaseProps } from "../dictionaries/widget-menu.dictionary";
import { CustomDialogActions } from "../../../../components/dialog/custom-dialog-actions.component";

interface IWidgetRestaurantFilterProp extends IWidgetMenuItemBaseProps {}

export const WidgetRestaurantFilter: FC<IWidgetRestaurantFilterProp> = (props) => {
  const { onClose, widget } = props;

  const { saveWidget, setWidgetRestaurants } = useContext(DashboardWidgetsContext);
  const { t } = useTranslation();

  const { selectedRestaurants, setSelectedRestaurants, restaurants } = useSelectedRestaurants({
    initialIds: widget.restaurantIds,
  });

  const saveRestaurants = useCallback(
    async (restaurants: Restaurant[]) => {
      if (!widget) return;
      const restaurantIds = restaurants.map((restaurant) => restaurant.id);
      setWidgetRestaurants(widget.id, restaurantIds);
      saveWidget({ ...widget, restaurantIds });
    },
    [saveWidget, widget, setWidgetRestaurants],
  );

  const handleApply = useCallback(async () => {
    saveRestaurants(selectedRestaurants);
    onClose();
  }, [saveRestaurants, selectedRestaurants, onClose]);

  const handleReset = useCallback(async () => {
    saveRestaurants([]);
    onClose();
  }, [saveRestaurants, onClose]);

  return (
    <>
      <CustomDialogTitle
        title={t("widgets.menu_items.restaurant_filter")}
        Icon={CategoryFilterIcon}
        iconProps={{ sx: { color: "primaryDark.main" } }}
      />
      <DialogContent>
        <RestaurantFilter
          selectedRestaurants={selectedRestaurants}
          setSelectedRestaurants={setSelectedRestaurants}
          restaurants={restaurants}
        />
      </DialogContent>
      <CustomDialogActions>
        <Button variant="outlined" onClick={handleReset}>
          {t("general.reset_filter")}
        </Button>
        <Button variant="contained" onClick={() => handleApply()}>
          {t("general.apply")}
        </Button>
      </CustomDialogActions>
    </>
  );
};
