import { useContext, useEffect, useMemo, useState } from "react";
import { ThinkTankCategory, useThinkTankCategoriesQuery } from "../../../api/muu-api/graphql/generated";
import { ICategoryFilterInfo } from "../../../pages/dashboard/widget-section/types/widget.type";
import { UserContext } from "../../../app/context/user.context";

export interface IFullThinkTankCategoryFilterInfo {
  category: ThinkTankCategory;
  isLocked: boolean;
}

export const useSelectedThinkTankCategories = (args: {
  initialValues?: ICategoryFilterInfo[];
  initialCategories?: IFullThinkTankCategoryFilterInfo[];
  preloadedCategories?: ThinkTankCategory[];
}) => {
  const { language } = useContext(UserContext);
  const { preloadedCategories, initialValues, initialCategories: initialCategoriesFromArgs } = args;
  const { data: categoriesResult } = useThinkTankCategoriesQuery({
    skip: !!preloadedCategories,
    variables: {
      language,
      active: true,
    },
  });

  const categories: ThinkTankCategory[] = useMemo(
    () => (preloadedCategories ? preloadedCategories : categoriesResult?.thinkTankCategories ?? []),
    [categoriesResult, preloadedCategories],
  );

  const initialCategories = useMemo(() => {
    const initialCategoriesFromIds: IFullThinkTankCategoryFilterInfo[] = [];
    (initialValues ?? []).forEach((info: ICategoryFilterInfo) => {
      const category = categories.find((category) => category.id === info.id);
      if (!category) return;
      initialCategoriesFromIds.push({ category, isLocked: info.isLocked });
    });

    if (initialCategoriesFromIds.length > 0) {
      return initialCategoriesFromIds;
    }
    return initialCategoriesFromArgs;
  }, [categories, initialValues, initialCategoriesFromArgs]);

  const [selectedCategories, setSelectedCategories] = useState<IFullThinkTankCategoryFilterInfo[]>(
    initialCategories || [],
  );

  useEffect(() => {
    setSelectedCategories(initialCategories || []);
  }, [initialCategories]);

  return { selectedCategories, setSelectedCategories, categories };
};
