import React, { FC, useCallback } from "react";
import { IMenuItemWithPortalRoute } from "../context/nav-menu.context";
import { Menu } from "@mui/material";
import { NavNestedMenuItem } from "./nav-nested-menu-item.component";

interface INavNavNestedMenu {
  item: IMenuItemWithPortalRoute;
  anchorEl: HTMLElement | null;
  onClose: VoidFunction;
  onCloseAll: VoidFunction;
}

export const NavNestedMenu: FC<INavNavNestedMenu> = (props) => {
  const { item, anchorEl, onClose, onCloseAll } = props;

  const customOnCloseAll = useCallback(() => {
    onClose();
    onCloseAll();
  }, [onClose, onCloseAll]);

  return (
    <Menu
      open={!!anchorEl}
      anchorEl={anchorEl}
      onClose={onClose}
      PaperProps={{ sx: { borderRadius: 3.5, pl: 2, pr: 2 } }}
      anchorOrigin={{ horizontal: "right", vertical: "top" }}
      transformOrigin={{ vertical: "top", horizontal: "left" }}
    >
      {item.menuItems.map((menuItem) => (
        <NavNestedMenuItem key={menuItem.id} item={menuItem} onCloseAll={customOnCloseAll} />
      ))}
    </Menu>
  );
};
