import { useContext, useMemo } from "react";
import { IMenuItemWithPortalRoute, NavMenuContext } from "../components/layout/nav/context/nav-menu.context";
import { useLocation } from "react-router-dom";
import { sortDESC } from "../utils/sort.util";

export const useCurrentMenuItem = (): IMenuItemWithPortalRoute | undefined => {
  const location = useLocation();
  const { menuItemsFlat } = useContext(NavMenuContext);

  const currentMenuItem = useMemo(() => {
    const routePaths = location.pathname.split("/").filter((path) => !!path);
    const currentPath = routePaths.join("/");

    const matchedMenuItems = menuItemsFlat.filter((item) => currentPath.includes(item.route));
    const currentMenuItem = matchedMenuItems.sort((a, b) => sortDESC(a.route.length, b.route.length))[0];

    return currentMenuItem;
  }, [menuItemsFlat, location]);

  return currentMenuItem;
};
