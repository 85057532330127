import { Divider, Grid, Typography } from "@mui/material";
import React, { useContext, useMemo } from "react";
import { QuickLink } from "../../../api/muu-api/graphql/generated";
import { DeeplinkIcon, FavoriteStarIcon, StarIcon } from "../../../assets/icons/icons.component";
import { QUICKLINK_SELECTION_SPACING_FACTOR } from "../../../theme/sizings.theme";
import { LayoutContext } from "../../layout/layout.context";

interface ICreateFavoriteQuickLinkRowProps {
  quickLink: QuickLink;
  onClick: VoidFunction;
  isFavorite: boolean;
}

export const CreateFavoriteQuickLinkRow: React.FC<ICreateFavoriteQuickLinkRowProps> = (props) => {
  const { quickLink, onClick, isFavorite } = props;

  const { breakpoint } = useContext(LayoutContext);

  const icon = useMemo(() => {
    return isFavorite ? <FavoriteStarIcon sx={{ color: "accent3.main" }} /> : <StarIcon />;
  }, [isFavorite]);

  return (
    <>
      <Grid
        container
        alignItems="center"
        spacing={QUICKLINK_SELECTION_SPACING_FACTOR[breakpoint]}
        sx={{ pt: 2, pb: 2, "&:hover": { cursor: "pointer" } }}
        onClick={onClick}
      >
        <Grid item>
          <DeeplinkIcon />
        </Grid>
        <Grid item xs>
          <Typography
            variant="body1"
            sx={{
              overflow: "hidden",
              display: "-webkit-box",
              WebkitLineClamp: 1,
              WebkitBoxOrient: "vertical",
              wordBreak: "break-word",
            }}
          >
            {quickLink.title}
          </Typography>
        </Grid>
        <Grid item>{icon}</Grid>
      </Grid>
      <Divider />
    </>
  );
};
