import { WidgetType } from "../../../../api/muu-api/graphql/generated";
import { ThinkTankWidgetContent } from "../../../../components/widgets/think-tank/think-tank.widget-content";
import { EventsWidgetContent } from "../../../../components/widgets/events/events.widget-content";
import { FavoritesWidgetContent } from "../../../../components/widgets/favorite/favorites.widget-content";
import { InfocenterWidgetContent } from "../../../../components/widgets/infocenter/infocenter.widget-content";
import { LunchMenusWidgetContent } from "../../../../components/widgets/lunch-menus/lunch-menus.widget-content";
import { NotificationsWidgetContent } from "../../../../components/widgets/notification/notifications.widget-content";
import { PublicTransportWidgetContent } from "../../../../components/widgets/public-transport/public-transport.widget-content";
import { IExtendedWidget } from "../types/widget.type";
import { WorkSpacesWidgetContent } from "../../../../components/widgets/work-spaces/work-spaces.widget-content";

export const getWidgetContentByType = (widget: IExtendedWidget, isSmall: boolean) => {
  switch (widget.type) {
    case WidgetType.INFOCENTER:
      return <InfocenterWidgetContent widget={widget} />;
    case WidgetType.BUS_BAHN_BIM:
      return <PublicTransportWidgetContent />;
    case WidgetType.EVENTS:
      return <EventsWidgetContent widget={widget} />;
    case WidgetType.FAVORITES:
      return <FavoritesWidgetContent />;
    case WidgetType.NOTIFICATIONS:
      return <NotificationsWidgetContent widget={widget} />;
    case WidgetType.THINK_TANK:
      return <ThinkTankWidgetContent widget={widget} />;
    case WidgetType.LUNCH:
      return <LunchMenusWidgetContent widget={widget} isSmall={isSmall} />;
    case WidgetType.WORK_SPACES:
      return <WorkSpacesWidgetContent widget={widget} />;
  }
};
