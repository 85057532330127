import React, { useContext } from "react";
import LargeLogo from "./svg/logo-large.svg";
import SmallLogo from "./svg/logo-small.svg";
import { LOGO_LARGE_WIDTH, LOGO_SMALL_WIDTH } from "../theme/sizings.theme";
import { LayoutContext } from "../components/layout/layout.context";

export type LogoSize = "small" | "large";

export interface ILogoProps {
  size?: LogoSize;
  width?: number;
  height?: number;
  viewBox?: string;
}
export const Logo: React.FC<ILogoProps> = (props) => {
  const { breakpoint } = useContext(LayoutContext);

  switch (props.size) {
    case "small":
      return <img {...props} src={SmallLogo} width={LOGO_SMALL_WIDTH[breakpoint]} alt="Logo" />;
    case "large":
    default:
      return <img {...props} src={LargeLogo} width={LOGO_LARGE_WIDTH[breakpoint]} alt="Logo" />;
  }
};
