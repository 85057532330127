import { SxProps } from "@mui/material";
import { FC, MouseEvent } from "react";
import { CustomMenuItem } from "../../../menu-item/custom-menu-item.component";

interface INavMenuItemButtonProps {
  children: (isHovering: boolean) => React.ReactNode;
  onClick?: (event: MouseEvent<HTMLElement>) => void;
  href?: string;
  component?: string;
  isMenu: boolean;
  sx?: SxProps;
  isSelected: boolean;
  id?: string;
  label?: string;
  renderSubMenu?: boolean;
}

export const NavMenuItemButton: FC<INavMenuItemButtonProps> = (props) => {
  const { onClick, href, component, children, isMenu, sx, isSelected, label, renderSubMenu } = props;

  return (
    <CustomMenuItem
      selected={isSelected}
      onClick={onClick}
      sx={{
        width: "100%",
        flexGrow: 1,
        borderRadius: 2,
        position: "relative",
        pt: "5px",
        pb: "5px",
        ...sx,
      }}
      component={component ? "a" : undefined}
      href={href}
      target={component ? "_blank" : "self"}
      tabIndex={0}
      aria-label={label}
      aria-haspopup={isMenu}
      aria-expanded={renderSubMenu}
      title={label}
    >
      {children}
    </CustomMenuItem>
  );
};
