import { Box } from "@mui/material";
import React, { useCallback, useContext } from "react";
import { ChevronLeftIcon, ChevronRightIcon } from "../../../assets/icons/icons.component";
import { APP_BAR, SMALL_NAV_WIDTH, NAV_WIDTH } from "../../../theme/sizings.theme";
import { CustomIconButton } from "../../icon/custom-icon-button.component";
import { LayoutContext } from "../layout.context";
import { useTranslation } from "react-i18next";

const NAV_HANDLE_OFFSET = 4;

export const NavHandle: React.FC = () => {
  const { t } = useTranslation();
  const { setSidebarOpen: setIsOpen, sidebarOpen: isOpen } = useContext(LayoutContext);

  const handleDrawer = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen, setIsOpen]);

  return (
    <Box
      aria-label="Nav Handle"
      role="contentinfo"
      sx={{
        justifyContent: "flex-end",
        zIndex: 1201,
        overflow: "visible",
        position: "fixed",
        width: `calc(${
          isOpen ? NAV_WIDTH.DESKTOP + NAV_HANDLE_OFFSET : SMALL_NAV_WIDTH.DESKTOP + NAV_HANDLE_OFFSET
        }px)`,
        display: "flex",
        top: 0,
        left: 0,
      }}
    >
      <CustomIconButton
        size="xlarge"
        sx={{
          bgcolor: "background.default",
          top: `calc(${APP_BAR.DESKTOP / 2 - 16}px)`,
          border: "1px solid rgba(145, 158, 171, 0.12)",
          boxShadow: "0px 13px 26px -4px rgba(145, 158, 171, 0.12)",
          "&:hover": {
            bgcolor: "background.default",
          },
          color: "grey.850",
        }}
        onClick={handleDrawer}
        edge="end"
        role="button"
        aria-label="Nav Handle"
        title={t("dashboard.tooltip.sidebar")}
      >
        {isOpen ? <ChevronLeftIcon size="small" /> : <ChevronRightIcon size="small" />}
      </CustomIconButton>
    </Box>
  );
};
