import { Typography } from "@mui/material";
import React, { useCallback } from "react";
import { Favorite, SearchEvent } from "../../../api/muu-api/graphql/generated";
import { FilterEventsIcon } from "../../../assets/icons/icons.component";
import { encodeIdBase64 } from "../../../utils/base64.util";
import { formatShortDate, formatShortTime } from "../../../utils/date.util";
import { DefaultCard } from "../../../components/card/default-card.component";
import { DETAIL_PATH } from "../../../router/routes.const";
import { EventsActionIconBar } from "../events-action-icon-bar.component";
import { i18n } from "../../../translations/i18n";
import { useNavigate } from "react-router-dom";
import { useTracking } from "../../../app/context/tracking/tracking.context";
import { useTranslation } from "react-i18next";

interface IEventCardProps {
  eventEntry: SearchEvent;
  favorite: Favorite | undefined;
}

export const EventCard: React.FC<IEventCardProps> = (props) => {
  const { eventEntry, favorite } = props;
  const navigate = useNavigate();
  const { trackEvent } = useTracking();
  const { t } = useTranslation();

  const dateInfo = (
    <>
      <Typography variant="caption" fontWeight="700">
        {formatShortDate(eventEntry.startTime)}
      </Typography>
      <Typography variant="caption" sx={{ color: "grey.500" }}>
        {" -  "}
        {formatShortTime(eventEntry.startTime, i18n.language) + t("general.time")}
      </Typography>
    </>
  );
  const navigationPath = `${DETAIL_PATH}/?id=${encodeIdBase64(eventEntry.id, { type: eventEntry.eventType })}`;

  const navigateToDetails = useCallback(() => {
    trackEvent({
      event: "detail_view",
      action: "redirect",
      category: "page_visit",
    });
    navigate(navigationPath);
  }, [navigate, navigationPath, trackEvent]);

  return (
    <DefaultCard
      title={eventEntry.title}
      description={eventEntry.teaser}
      media={eventEntry.media ?? undefined}
      dateInfo={dateInfo}
      buttonProps={{ onClick: navigateToDetails }}
      iconBar={<EventsActionIconBar event={eventEntry} favorite={favorite} />}
      defaultIcon={<FilterEventsIcon size="xxlarge" sx={{ color: "accent1.main" }} />}
    />
  );
};
