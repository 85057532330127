import { useContext, useEffect, useMemo } from "react";
import { Category, SearchEventQuery, useSearchEventQuery } from "../../../api/muu-api/graphql/generated";
import { UpdateQueryType, useInfiniteScrolling } from "../../../hooks/use-infinite-scrolling.hook";
import { UserContext } from "../../../app/context/user.context";

export const useEventsWithFetchMore = (args: {
  from: string | null;
  to: string | null;
  appliedCategories: Category[];
}) => {
  const { from, to, appliedCategories } = args;

  const { language } = useContext(UserContext);

  const updateQuery: UpdateQueryType<SearchEventQuery> = (previousQueryResult, { fetchMoreResult }) => {
    if (!fetchMoreResult || fetchMoreResult.searchEvent.results.length === 0) return previousQueryResult;
    return {
      ...previousQueryResult,
      searchEvent: {
        ...previousQueryResult.searchEvent,
        results: previousQueryResult.searchEvent.results.concat(fetchMoreResult.searchEvent.results),
      },
    };
  };

  const {
    data,
    loading: isInitialQueryLoading,
    fetchMore,
    error,
  } = useSearchEventQuery({
    variables: {
      limit: 20,
      offset: 0,
      categories: appliedCategories.map((category) => category.searchFilter),
      dateRange: {
        from,
        to,
      },
      language,
    },
  });

  const { isLoadingMore, setIsLoadingMore, setFetchMoreOffset } = useInfiniteScrolling({
    fetchMore,
    updateQuery,
    isInitialQueryLoading,
    itemCount: data?.searchEvent.count,
  });

  useEffect(() => {
    setFetchMoreOffset(20);
  }, [from, to, appliedCategories, language]);

  const searchResults = useMemo(() => {
    setIsLoadingMore(false);
    return data?.searchEvent.results || [];
  }, [data]);

  return { isLoadingMore, error, isInitialQueryLoading, setIsLoadingMore, searchResults };
};
