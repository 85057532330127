import { FunctionComponent, useCallback, useContext } from "react";
import { BurgerIcon } from "../../../assets/icons/icons.component";
import { CustomIconButton } from "../../icon/custom-icon-button.component";
import { LayoutContext } from "../layout.context";

export const MobileNavHandle: FunctionComponent = () => {
  const { setSidebarOpen: setIsOpen, sidebarOpen: isOpen } = useContext(LayoutContext);

  const handleDrawer = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen, setIsOpen]);

  return (
    <CustomIconButton size="xlarge" sx={{ color: "grey.500" }} onClick={handleDrawer}>
      <BurgerIcon />
    </CustomIconButton>
  );
};
