import { useContext, useEffect, useMemo, useState } from "react";
import { Category, useCategoriesQuery } from "../../../api/muu-api/graphql/generated";
import { ICategoryFilterInfo } from "../../../pages/dashboard/widget-section/types/widget.type";
import { UserContext } from "../../../app/context/user.context";

export interface IFullCategoryFilterInfo {
  category: Category;
  isLocked: boolean;
}

export const useSelectedCategories = (args: {
  initialValues?: ICategoryFilterInfo[];
  initialCategories?: IFullCategoryFilterInfo[];
  preloadedCategories?: Category[];
}) => {
  const { language } = useContext(UserContext);
  const { preloadedCategories, initialValues, initialCategories: initialCategoriesFromArgs } = args;
  const { data: categoriesResult } = useCategoriesQuery({
    skip: !!preloadedCategories,
    variables: {
      language,
    },
  });

  const categories: Category[] = useMemo(
    () => (preloadedCategories ? preloadedCategories : categoriesResult?.categories ?? []),
    [categoriesResult, preloadedCategories],
  );

  const initialCategories = useMemo(() => {
    const initialCategoriesFromIds: IFullCategoryFilterInfo[] = [];
    (initialValues ?? []).forEach((info: ICategoryFilterInfo) => {
      const category = categories.find((category) => category.id === info.id);
      if (!category) return;
      initialCategoriesFromIds.push({ category, isLocked: info.isLocked });
    });

    if (initialCategoriesFromIds.length > 0) {
      return initialCategoriesFromIds;
    }
    return initialCategoriesFromArgs;
  }, [categories, initialValues, initialCategoriesFromArgs]);

  const [selectedCategories, setSelectedCategories] = useState<IFullCategoryFilterInfo[]>(initialCategories || []);

  useEffect(() => {
    setSelectedCategories(initialCategories || []);
  }, [initialCategories]);

  return { selectedCategories, setSelectedCategories, categories };
};
