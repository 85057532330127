import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import CustomEditor from "ckeditor5-custom-build/build/ckeditor";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { SnackbarSeverity, useSnackbar } from "../snackbar/snackbar.context";
import { EditorConfig } from "@ckeditor/ckeditor5-core";

interface EditorProps {
  data: string | null | undefined;
  setData: (data: string) => void;
}

// https://ckeditor.com/docs/ckeditor5/latest/installation/integrations/react.html
export const Editor: React.FC<EditorProps> = (props) => {
  const { data, setData } = props;
  const { t } = useTranslation();
  const { showSnackbar } = useSnackbar();

  const config: EditorConfig = useMemo(() => {
    return {
      toolbar: {
        items: [
          "undo",
          "redo",
          "-",
          "heading",
          "|",
          "bold",
          "italic",
          "underline",
          "superscript",
          "subscript",
          "link",
          "|",
          "bulletedList",
          "numberedList",
          "-",
          "removeFormat",
          "indent",
          "outdent",
          "blockquote",
          "alignment",
          "horizontalLine",
          "fontColor",
          "|",
          "strapiMediaLib",
          "insertTable",
        ],
        shouldNotGroupWhenFull: true,
      },
      heading: {
        options: [
          { model: "paragraph", title: "Paragraph", class: "ck-heading_paragraph" },
          { model: "heading1", view: "h1", title: "Heading 1", class: "ck-heading_heading1" },
          { model: "heading2", view: "h2", title: "Heading 2", class: "ck-heading_heading2" },
          { model: "heading3", view: "h3", title: "Heading 3", class: "ck-heading_heading3" },
          { model: "heading4", view: "h4", title: "Heading 4", class: "ck-heading_heading4" },
          { model: "heading5", view: "h5", title: "Heading 5", class: "ck-heading_heading5" },
          { model: "heading6", view: "h6", title: "Heading 6", class: "ck-heading_heading6" },
        ],
      },
      indentBlock: {
        offset: 24,
        unit: "px",
      },
      table: {
        contentToolbar: ["tableColumn", "tableRow", "mergeTableCells", "|", "toggleTableCaption"],
      },
      fontColor: {
        columns: 5,
        documentColors: 0,
        colors: [
          { label: t("ck_editor.colors.dark_green"), color: "#007934" },
          { label: t("ck_editor.colors.light_green"), color: "#34B233" },
          { label: t("ck_editor.colors.orange"), color: "#FD793C" },
          { label: t("ck_editor.colors.petrol"), color: "#048F8F" },
          { label: t("ck_editor.colors.sunflower_yellow"), color: "#FF9E00" },
        ],
      },
    };
  }, [t]);

  return (
    <CKEditor
      editor={CustomEditor}
      config={config}
      data={data}
      onError={() => {
        showSnackbar(t("ck_editor.init_error"), SnackbarSeverity.Error);
      }}
      onChange={(_, editor: ClassicEditor) => {
        setData(editor.getData());
      }}
    />
  );
};
