import { Element } from "domhandler";
import { cssStringToCssProps } from "../helpers/editor-content.helper";
import { IBreakpointInfo } from "../../layout/layout.context";
import { config } from "../../../utils/config.util";

export const mapToImg = (node: Element, breakpointInfo: IBreakpointInfo) => {
  // With some settings it is possible to include strapi images with full url therefore we check if the src starts with /uploads before adapting
  let imgUrl = node.attribs.src;
  if (imgUrl.startsWith("/uploads")) {
    imgUrl = `${config.REACT_APP_RELATIVE_LINK_URL}${node.attribs.src}`;
  }
  const cssProps = cssStringToCssProps(node.attribs.style);

  let width: string | number = node.attribs.width;
  const style = { ...cssProps, width: cssProps?.width || width || "undefined" };

  if (breakpointInfo.isMobile && parseFloat(node.attribs.width) > window.innerWidth) {
    style.width = "100%";
    width = "100%";
  }

  return (
    <img
      style={style}
      className={node.attribs.class}
      src={imgUrl}
      alt={node.attribs.alt}
      sizes={node.attribs.sizes}
      width={width}
    />
  );
};
