import { Grid } from "@mui/material";
import React, { useContext, useMemo } from "react";
import { SearchInfocenter, useCategoriesQuery } from "../../api/muu-api/graphql/generated";
import { InfocenterCard } from "./card/infocenter-card.component";
import { InfocenterProminentCard } from "./card/infocenter-prominent-card.component";
import { LoadingCard } from "../loading/loading-card.component";
import { LayoutContext } from "../layout/layout.context";
import {
  DEFAULT_CARD_MIN_WIDTH,
  INFOCENTER_CARD_HEIGHT,
  OVERVIEW_LOADING_CARD_HEIGHT,
  OVERVIEW_SPACING_FACTOR,
} from "../../theme/sizings.theme";
import { NoContentFound } from "../error/no-content-found.component";
import { ApolloError } from "@apollo/client";
import { UserContext } from "../../app/context/user.context";
import { useColumnLayout } from "../../hooks/use-column-layout.hook";

interface IInfocenterOverviewProps {
  results: SearchInfocenter[];
  loading: boolean;
  error?: ApolloError;
}

export const InfocenterOverview: React.FC<IInfocenterOverviewProps> = (props) => {
  const { results, loading, error } = props;
  const { breakpoint, breakpointInfo } = useContext(LayoutContext);
  const { language } = useContext(UserContext);

  const { columns } = useColumnLayout();

  const { data: categories, loading: loadingCategories } = useCategoriesQuery({
    variables: {
      language,
    },
  });

  const isLoading = useMemo(() => {
    return loading || loadingCategories;
  }, [loading, loadingCategories]);

  const { prominentCard, defaultCards } = useMemo(() => {
    const prominentCard = results.find((infocenter) => infocenter.media);

    return { prominentCard, defaultCards: results.filter((infocenter) => infocenter.id !== prominentCard?.id) };
  }, [results]);

  return (
    <Grid item container id="infocenter-overview">
      {isLoading && (
        <Grid container spacing={OVERVIEW_SPACING_FACTOR[breakpoint]}>
          <Grid item sx={{ width: "100%" }}>
            <LoadingCard height={INFOCENTER_CARD_HEIGHT[breakpoint]} />
          </Grid>
          {[...Array(8)].map((_, index) => (
            <Grid item xs={12} md={6} key={`infocenter-loading-${index}`}>
              <LoadingCard height={OVERVIEW_LOADING_CARD_HEIGHT[breakpoint]} />
            </Grid>
          ))}
        </Grid>
      )}
      {!isLoading && !prominentCard && defaultCards.length <= 0 && !error && (
        <Grid item xs={12}>
          <NoContentFound variant="infocenterOverview" />
        </Grid>
      )}
      {error && <NoContentFound variant="infocenterOverviewError" />}
      {!isLoading && categories && (
        <Grid container spacing={OVERVIEW_SPACING_FACTOR[breakpoint]} id="infocenter-overview-cards" columns={columns}>
          {prominentCard && (
            <Grid item xs={12} key={prominentCard.id}>
              {breakpointInfo.isMobile && (
                <InfocenterCard infocenter={prominentCard} categories={categories.categories} />
              )}
              {!breakpointInfo.isMobile && <InfocenterProminentCard infocenter={prominentCard} />}
            </Grid>
          )}
          {defaultCards &&
            defaultCards.map((card) => {
              return (
                <Grid item xs={6} key={card.id} minWidth={DEFAULT_CARD_MIN_WIDTH[breakpoint]}>
                  <InfocenterCard infocenter={card} categories={categories.categories} />
                </Grid>
              );
            })}
        </Grid>
      )}
    </Grid>
  );
};
