import { Box, useTheme } from "@mui/material";
import { ForwardedRef, forwardRef, useCallback, useContext } from "react";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import { LayoutContext } from "../../../components/layout/layout.context";
import { Widget } from "../../../components/widgets/widget.component";
import { OVERVIEW_SPACING_FACTOR, WIDGET_HEIGHT, WIDGET_TOTAL_ROW_HEIGHT } from "../../../theme/sizings.theme";
import { DashboardWidgetsContext } from "./dashboard-widgets.context";
import { getTransformValueForWidget } from "./helpers/get-transform-value-for-widget.helper";
import { IExtendedWidget } from "./types/widget.type";

interface IWidgetRowWithDraggableProps {
  widgets: IExtendedWidget[];
  index: number;
}

export const WidgetRowWithDraggable = forwardRef((props: IWidgetRowWithDraggableProps, ref: ForwardedRef<any>) => {
  const { widgets, index: rowIndex } = props;
  const { t } = useTranslation();

  const { breakpoint } = useContext(LayoutContext);
  const { currentlyDraggingWidget, widgetOptions, isInEditMode } = useContext(DashboardWidgetsContext);
  const theme = useTheme();

  const getTransformForWidget = useCallback(
    (
      isDraggingWidget: boolean,
      isDropAnimating: boolean,
      isDraggingOverRow: boolean,
      isDraggedWidgetInCurrentRow: boolean,
      currentWidgetIndex: number,
      providedTransform?: string,
    ) => {
      return getTransformValueForWidget(
        isDraggingWidget,
        isDropAnimating,
        isDraggingOverRow,
        isDraggedWidgetInCurrentRow,
        currentWidgetIndex,
        parseFloat(theme.spacing(6)),
        widgetOptions,
        currentlyDraggingWidget?.initialX,
        providedTransform,
      );
    },
    [widgetOptions, theme, currentlyDraggingWidget],
  );
  return (
    <Box ref={ref} sx={{ width: "100%" }}>
      <Droppable
        droppableId={`${rowIndex}`}
        direction="horizontal"
        isDropDisabled={widgets.length === 3 && !widgets.find((widget) => widget.id === currentlyDraggingWidget?.id)}
      >
        {(providedDroppable, droppableSnapshot) => (
          <Box
            component="div"
            {...providedDroppable.droppableProps}
            ref={providedDroppable.innerRef}
            sx={{
              ml: -OVERVIEW_SPACING_FACTOR[breakpoint],
              display: "flex",
              flexDirection: {
                xs: "column",
                lg: "row",
              },
              height: {
                lg: WIDGET_HEIGHT[breakpoint] + parseFloat(theme.spacing(6)),
              },
            }}
          >
            {widgets.map((widget, index) => (
              <Draggable
                draggableId={widget.id}
                index={index}
                key={`widget-${widget.id}`}
                isDragDisabled={!isInEditMode}
              >
                {(provided, snapshot) => (
                  <Widget
                    widget={widget}
                    title={`${t(`widgets.${widget.type}.title`)}`}
                    {...provided.draggableProps}
                    style={{
                      ...provided.draggableProps.style,
                      width: undefined, // overwrite width in "sx"
                      transform: getTransformForWidget(
                        snapshot.isDragging,
                        snapshot.isDropAnimating,
                        droppableSnapshot.isDraggingOver,
                        !!widgets.find((widget) => currentlyDraggingWidget?.id === widget.id),
                        index,
                        provided.draggableProps.style?.transform,
                      ),
                    }}
                    isSmall={widgets.length > 2}
                    {...provided.dragHandleProps}
                    ref={provided.innerRef}
                    sx={{
                      mt: OVERVIEW_SPACING_FACTOR[breakpoint],
                      ml: OVERVIEW_SPACING_FACTOR[breakpoint],
                      width: {
                        lg:
                          (snapshot.isDragging || droppableSnapshot.isDraggingOver) && widgetOptions
                            ? widgetOptions.width
                            : `calc(${100 / widgets.length}% - ${theme.spacing(6)})`,
                      },
                    }}
                    isFixedWidget={false}
                  />
                )}
              </Draggable>
            ))}
            {/* providedDroppable.placeholder has to be in the dom; otherwise react-beautiful-dnd shows a warning in console
              display: "none" because the placeholder breaks the UI :)
          */}
            <Box sx={{ display: "none" }}>{providedDroppable.placeholder}</Box>
            {widgetOptions && droppableSnapshot.isDraggingOver && (
              <Box
                className="placeholder"
                sx={{
                  position: "absolute",
                  top: widgetOptions.placeHolderY + parseFloat(theme.spacing(6)),
                  left: widgetOptions.placeHolderX,
                  height: widgetOptions.height,
                  width: widgetOptions.width,
                  borderRadius: 4,
                  backgroundColor: "grey.100",
                }}
              />
            )}
          </Box>
        )}
      </Droppable>
    </Box>
  );
});
