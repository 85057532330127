import { FC, useContext } from "react";
import { NotificationStateFilterContext } from "../../../../components/notification/context/notification-state-filter.context";
import { DefaultNotificationStateFilter } from "../../../../components/notification/default-notification-state-filter.component";
import { IWidgetActionItemBaseProps } from "../dictionaries/widget-action.dictionary";

interface IWidgetNotificationStateFilterActionProps extends IWidgetActionItemBaseProps {}

export const WidgetNotificationStateFilterAction: FC<IWidgetNotificationStateFilterActionProps> = (props) => {
  const { color, isSmall } = props;
  const { notificationState, setNotificationState } = useContext(NotificationStateFilterContext);

  return (
    <DefaultNotificationStateFilter
      notificationState={notificationState}
      setNotificationState={setNotificationState}
      variant="outlined"
      iconSize="medium"
      color={color}
      fontWeight={400}
      isSmall={isSmall}
      iconButton
    />
  );
};
