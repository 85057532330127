import React from "react";
import { Media } from "../../api/muu-api/graphql/generated";
import { Dialog, useTheme } from "@mui/material";
import { CustomIconButton } from "../../components/icon/custom-icon-button.component";
import { CloseIcon } from "../../assets/icons/icons.component";
import { IThinkTankPostFormFile } from "../../components/think-tank/think-tank-post-form.component";
import { useThinkTankAttachment } from "./hooks/use-think-tank-attachment.hook";

interface IThinkTankImagePreviewDialogProps {
  attachment: Media | IThinkTankPostFormFile;
  open: boolean;
  setOpen: (open: boolean) => void;
}

export const ThinkTankImagePreviewDialog: React.FC<IThinkTankImagePreviewDialogProps> = (props) => {
  const { attachment, open, setOpen } = props;
  const theme = useTheme();

  const { alt, url } = useThinkTankAttachment(attachment);

  return (
    <Dialog open={open} onClose={() => setOpen(false)} PaperProps={{ sx: { position: "relative", m: 2 } }}>
      <CustomIconButton
        sx={{ position: "absolute", top: theme.spacing(2), right: theme.spacing(2), zIndex: 1 }}
        onClick={() => setOpen(false)}
        size="xlarge"
        variant="contained"
      >
        <CloseIcon />
      </CustomIconButton>
      <img
        src={url}
        alt={alt}
        style={{
          width: "100%",
          height: "auto",
          objectFit: "contain",
          maxWidth: "100%",
          maxHeight: "100%",
        }}
      />
    </Dialog>
  );
};
