import { Grid } from "@mui/material";
import { FC, useCallback, useMemo } from "react";
import { CategoryFilterItem } from "./category-filter-item.component";
import { IFullCategoryFilterInfo } from "./hooks/use-selected-categories.hook";
import { Category } from "../../api/muu-api/graphql/generated";
import { NONE_CATEGORY } from "../../app/helpers/const.helper";

interface ICategoryFilterProps {
  categories: Category[];
  selectedCategories: IFullCategoryFilterInfo[];
  setSelectedCategories: (newCategories: IFullCategoryFilterInfo[]) => void;
}

export const CategoryFilter: FC<ICategoryFilterProps> = (props) => {
  const { selectedCategories, setSelectedCategories, categories } = props;

  const categoryFilterOptions: IFullCategoryFilterInfo[] = useMemo(() => {
    return categories.map((category) => {
      const selectedCategory = selectedCategories.find(
        (selectedCategory) => selectedCategory.category.id === category.id,
      );
      return {
        category,
        isLocked: selectedCategory?.isLocked ?? false,
      };
    });
  }, [categories, selectedCategories]);

  const onClick = useCallback(
    (categoryFilterInfo: IFullCategoryFilterInfo) => {
      const selectedCategoryInfo = selectedCategories.find(
        (categoryInfo) => categoryInfo.category.id === categoryFilterInfo.category.id,
      );
      if (selectedCategoryInfo && selectedCategoryInfo.isLocked) return;

      if (selectedCategoryInfo) {
        setSelectedCategories([
          ...selectedCategories.filter(
            (selectedCategory) => selectedCategory.category.id !== categoryFilterInfo.category.id,
          ),
        ]);
      } else {
        setSelectedCategories([...selectedCategories, categoryFilterInfo]);
      }
    },
    [selectedCategories, setSelectedCategories],
  );

  return (
    <Grid container direction="row" spacing={2}>
      {categoryFilterOptions
        .filter((entry) => entry.category.searchFilter !== NONE_CATEGORY)
        .map((categoryFilterInfo) => (
          <Grid item xs={12} sm={6} key={categoryFilterInfo.category.id}>
            <CategoryFilterItem
              categoryFilterInfo={categoryFilterInfo}
              isSelected={selectedCategories
                .map((selectedCategory) => selectedCategory.category.id)
                .includes(categoryFilterInfo.category.id)}
              onClick={() => onClick(categoryFilterInfo)}
            />
          </Grid>
        ))}
    </Grid>
  );
};
