import { Box, Stack } from "@mui/material";
import { FC, useCallback, useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import {
  SearchNotification,
  useCategoriesQuery,
  useSearchNotificationQuery,
  WidgetType,
} from "../../../api/muu-api/graphql/generated";
import { getCategoriesForSearchQueries } from "../../../app/helpers/category.helper";
import { IExtendedWidget } from "../../../pages/dashboard/widget-section/types/widget.type";
import { getAbsoluteRoutingPath } from "../../../utils/get-absolute-routing-path.util";
import { NavMenuContext } from "../../layout/nav/context/nav-menu.context";
import { NotificationStateFilterContext } from "../../notification/context/notification-state-filter.context";
import { NotificationWidgetRow } from "./notification-widget-row.component";
import { WidgetNoEntry } from "../widget-no-entry.component";
import { UserContext } from "../../../app/context/user.context";
import { DefaultWidgetSkeleton } from "../widget-default-skeleton.component";
import { WidgetContentShowAll } from "../widget-content-show-all.component";
import { POLLING_INTERVAL } from "../../../pages/dashboard/widget-section/dashboard-widgets.context";

const MAX_NOTIFICATIONS_COUNT = 5;

interface INotificationsWidgetContentProps {
  widget: IExtendedWidget;
}

export const NotificationsWidgetContent: FC<INotificationsWidgetContentProps> = (props) => {
  const { widget } = props;
  const { language } = useContext(UserContext);
  const { data: categoriesResult } = useCategoriesQuery({
    variables: {
      language,
    },
  });
  const { pageOfInterestDictionary } = useContext(NavMenuContext);
  const { notificationState } = useContext(NotificationStateFilterContext);
  const navigate = useNavigate();
  const categories = useMemo(() => categoriesResult?.categories || [], [categoriesResult]);
  const widgetCategories: string[] = useMemo(
    () => getCategoriesForSearchQueries(widget.categoryFilter, categories ?? []),
    [categories, widget],
  );

  // TODO: Clarify with Fabian
  const { data: notificationResult, loading: notificationsLoading } = useSearchNotificationQuery({
    fetchPolicy: "no-cache",
    variables: {
      categories: widgetCategories,
      limit: MAX_NOTIFICATIONS_COUNT,
      offset: 0,
      notificationState,
      language,
    },
    skip: false,
    pollInterval: POLLING_INTERVAL,
  });

  const notifications: SearchNotification[] = useMemo(
    () => notificationResult?.searchNotification.results ?? [],
    [notificationResult],
  );

  const onShowAllClick = useCallback(() => {
    if (!pageOfInterestDictionary.NOTIFICATIONS) {
      return;
    }
    navigate({
      pathname: getAbsoluteRoutingPath(pageOfInterestDictionary.NOTIFICATIONS.path),
    });
  }, [navigate, pageOfInterestDictionary]);

  return (
    <Box sx={{ width: "100%", flex: 1, display: "flex" }}>
      <Stack direction="column" display="flex" flex={1} flexDirection="column">
        {notificationsLoading && <DefaultWidgetSkeleton rows={5} />}
        {!notificationsLoading && notifications.length === 0 && <WidgetNoEntry widgetType={WidgetType.NOTIFICATIONS} />}
        {notifications.map((notification) => (
          <Box key={`notification-${notification.id}`}>
            <NotificationWidgetRow notification={notification} />
          </Box>
        ))}
        {notifications.length >= MAX_NOTIFICATIONS_COUNT && <WidgetContentShowAll onShowAllClick={onShowAllClick} />}
      </Stack>
    </Box>
  );
};
