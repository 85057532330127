import React, { useMemo } from "react";
import { PersonDetailRow } from "./person-detail-row.component";
import { OrganizationIcon } from "../../assets/icons/icons.component";
import { SxProps, Typography } from "@mui/material";
import parse from "html-react-parser";
import { useOrganizationTopicPageQuery } from "../../api/muu-api/graphql/generated";
import { TopicPageDictionary } from "../../router/routes.const";
import { useTranslation } from "react-i18next";
import { CustomLink } from "../link/custom-link.component";

export interface IPersonDetailOrganizationRowProps {
  id?: string;
  name: string;
  organizationFunction?: string;
  iconColor: string;
  sx?: SxProps;
  iconSize?: "xsmall" | "small" | "medium";
}

export const PersonDetailOrganizationRow: React.FC<IPersonDetailOrganizationRowProps> = (props) => {
  const { id, name, organizationFunction, iconColor, sx, iconSize = "xsmall" } = props;

  const { t } = useTranslation();

  const { data } = useOrganizationTopicPageQuery({
    skip: !id,
    variables: { organizationId: id! },
  });

  const href = useMemo(() => {
    if (!data?.organizationTopicPageId) return;
    const topicPagePath = TopicPageDictionary[data.organizationTopicPageId]?.route;
    if (!topicPagePath) {
      return `/page/${data.organizationTopicPageId}`;
    }
    return `/${topicPagePath}`;
  }, [data]);

  return (
    <PersonDetailRow
      sx={{ ...sx }}
      left={<OrganizationIcon style={{ color: iconColor }} fontSize="medium" size={iconSize} />}
      right={
        <>
          {href && (
            <CustomLink aria-label="Organization Page" href={href}>
              <Typography sx={{ textAlign: "left" }}>{parse(name)}</Typography>
            </CustomLink>
          )}
          {!href && <Typography sx={{ textAlign: "left" }}>{parse(name)}</Typography>}
          {organizationFunction && (
            <Typography sx={{ textAlign: "left" }}>
              {`${t("person_detail.organization_function")}: ${parse(organizationFunction)}`}
            </Typography>
          )}
        </>
      }
    />
  );
};
