import { Box, Collapse, MenuList, useTheme } from "@mui/material";
import React, { Fragment, MouseEvent, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router";
import { getAbsoluteRoutingPathNameAndSearchParams } from "../../../../utils/get-absolute-routing-path.util";
import { LayoutContext } from "../../layout.context";
import { BreadcrumbsContext } from "../context/breadcrumbs.context";
import { IMenuItemWithPortalRoute, NavMenuContext } from "../context/nav-menu.context";
import {
  checkIfMenuItemNavigates,
  checkIfMenuItemNavigatesWithCategory,
} from "../helper/check-if-menu-item-navigates.helper";
import { NavNestedMenu } from "../nav-menu/nav-nested-menu.component";
import { NavMenuItemButton } from "./nav-menu-item-button.component";
import { NavMenuItemContent } from "./nav-menu-item-content.component";
import { NavMenuItemSubmenuIndicator } from "./nav-menu-item-submenu-indicator.component";
import { useTracking } from "../../../../app/context/tracking/tracking.context";
import { Breakpoint } from "../../../../theme/sizings.theme";
import { useLocation } from "react-router-dom";

interface NavMenuItemProps {
  item: IMenuItemWithPortalRoute;
}

export const NavMenuItem: React.FC<NavMenuItemProps> = (props) => {
  const { item } = props;
  const { sidebarOpen, setSidebarOpen, breakpoint } = useContext(LayoutContext);
  const { breadcrumbs, currentMenuItem } = useContext(BreadcrumbsContext);
  const [isOpen, setIsOpen] = useState<boolean>(breadcrumbs.includes(item) && item.level < 4);
  const navigate = useNavigate();
  const location = useLocation();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const { pageOfInterestDictionary } = useContext(NavMenuContext);

  const { trackEvent } = useTracking();

  const hasSubMenu = useMemo(() => item.menuItems.length > 0 && item.level < 4, [item]);
  const renderSubMenu = useMemo(() => hasSubMenu && sidebarOpen, [hasSubMenu, sidebarOpen]);

  const theme = useTheme();

  const pl = useMemo(() => {
    if (item.level === 1) {
      return `${theme.icon.medium + parseFloat(theme.spacing(item.level))}px`;
    }
    return item.level;
  }, [item, theme]);

  useEffect(() => {
    if (!sidebarOpen) {
      setIsOpen(sidebarOpen);
    }
  }, [sidebarOpen]);

  useEffect(() => {
    if (!sidebarOpen) {
      return;
    }
    setIsOpen(breadcrumbs.includes(item));
  }, [breadcrumbs, item, sidebarOpen]);

  const handleMenuItemClick = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      if (checkIfMenuItemNavigates(item)) {
        trackEvent({
          event: `${item.url}_visit`,
          category: "page_visit",
          action: "menu_click",
        });
        // Close mobile navigation if new route was selected (only on redirect/navigate)
        if (breakpoint === Breakpoint.MOBILE && currentMenuItem?.route !== item.route) setSidebarOpen(false);
        navigate(getAbsoluteRoutingPathNameAndSearchParams(item.route));
      } else {
        if (checkIfMenuItemNavigatesWithCategory(item, pageOfInterestDictionary)) {
          trackEvent({
            event: `${item.url}_visit`,
            category: "page_visit",
            action: "menu_click",
          });
          navigate(getAbsoluteRoutingPathNameAndSearchParams(item.url));
        }
      }

      if (sidebarOpen && hasSubMenu) {
        trackEvent({
          event: `${item.url}_visit`,
          category: "close_submenu",
          action: "menu_click",
        });
        setIsOpen(!isOpen);
      }
      if (!sidebarOpen && anchorEl && hasSubMenu) {
        setAnchorEl(null);
      }
      if (!sidebarOpen && !anchorEl && hasSubMenu) {
        trackEvent({
          event: `${item.url}_visit`,
          category: "open_submenu",
          action: "menu_click",
        });
        setAnchorEl(event.currentTarget);
      }
    },
    [
      item,
      navigate,
      isOpen,
      anchorEl,
      hasSubMenu,
      sidebarOpen,
      trackEvent,
      currentMenuItem,
      breakpoint,
      setSidebarOpen,
      pageOfInterestDictionary,
    ],
  );

  const isSelected = useMemo(
    () => currentMenuItem?.route === item.route || `${currentMenuItem?.route}${location.search}` === item.url,
    [currentMenuItem, item, location],
  );

  return (
    <Fragment>
      <NavMenuItemButton
        href={item.isExternalLink ? item.url : undefined}
        component={item.isExternalLink ? "a" : undefined}
        onClick={item.isExternalLink ? undefined : handleMenuItemClick}
        isMenu={hasSubMenu}
        isSelected={isSelected}
        label={item.title}
        renderSubMenu={renderSubMenu}
      >
        {(isHovering: boolean) => (
          <>
            <NavMenuItemContent
              item={item}
              isOpen={isOpen}
              isSelected={isSelected}
              isHovering={isHovering}
              hasSubMenu={hasSubMenu}
            />
            {sidebarOpen && hasSubMenu && (
              <NavMenuItemSubmenuIndicator
                sx={{ right: 0 }}
                isOpen={isOpen}
                isSelected={isSelected}
                isHovering={isHovering}
              />
            )}
            <Box sx={{ position: "relative", top: 0, left: 0 }}></Box>
          </>
        )}
      </NavMenuItemButton>
      <Box
        sx={{
          color: "text.primary",
          p: 0,
          display: "block",
          pb: "3px",
          pl,
        }}
      >
        {renderSubMenu && (
          <Collapse in={isOpen} unmountOnExit>
            <MenuList
              disablePadding
              id={`menu-item-${item.route}-${item.level}`}
              aria-labelledby={`item-${item.route}-${item.level}`}
            >
              {item.menuItems.map((subMenuItem) => (
                <NavMenuItem key={subMenuItem.id} item={subMenuItem} />
              ))}
            </MenuList>
          </Collapse>
        )}
      </Box>

      {!sidebarOpen && (
        <NavNestedMenu
          anchorEl={anchorEl}
          item={item}
          onCloseAll={() => setAnchorEl(null)}
          onClose={() => setAnchorEl(null)}
        />
      )}
    </Fragment>
  );
};
