import { Button, Grid } from "@mui/material";
import React, { useCallback, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { EventsDetailPageContent } from "./events-detail.page-content";
import { getAbsoluteRoutingPath } from "../../utils/get-absolute-routing-path.util";
import { NavMenuContext } from "../../components/layout/nav/context/nav-menu.context";
import { ChevronLeftIcon } from "../../assets/icons/icons.component";
import { useTranslation } from "react-i18next";
import { EventsDetailProvider } from "./context/events-detail.context";
import { LayoutContext } from "../../components/layout/layout.context";

interface IEventsDetailPageProps {}

export const EventsDetailPage: React.FC<IEventsDetailPageProps> = () => {
  const navigate = useNavigate();
  const { pageOfInterestDictionary } = useContext(NavMenuContext);
  const { t } = useTranslation();
  const { breakpointInfo } = useContext(LayoutContext);

  const navigateToOverview = useCallback(() => {
    navigate(getAbsoluteRoutingPath(pageOfInterestDictionary.EVENTS.path));
  }, [navigate, pageOfInterestDictionary]);

  return (
    <Grid container direction="column" spacing={6}>
      <Grid item>
        <Button
          variant="outlined"
          startIcon={<ChevronLeftIcon size="small" />}
          onClick={navigateToOverview}
          fullWidth={breakpointInfo.isMobile}
        >
          {t("general.back_to_overview")}
        </Button>
      </Grid>
      <Grid item xs container>
        <EventsDetailProvider>
          <EventsDetailPageContent />
        </EventsDetailProvider>
      </Grid>
    </Grid>
  );
};
