import { Skeleton } from "@mui/material";
import React from "react";

interface ILoadingCardProps {
  width?: number;
  height: number;
}

export const LoadingCard: React.FC<ILoadingCardProps> = (props) => {
  const { width, height } = props;

  return <Skeleton variant="rectangular" width={width ?? "100%"} height={height} sx={{ borderRadius: 4 }} />;
};
