import { FavoriteSource, InfocenterType } from "../../../api/muu-api/graphql/generated";

export const infocenterSourceToFavoriteSource = (type: InfocenterType) => {
  switch (type) {
    case InfocenterType.RESEARCH:
      return FavoriteSource.RESEARCH;
    case InfocenterType.STRAPI:
      return FavoriteSource.STRAPI;
    case InfocenterType.TYPO3_NEWS:
      return FavoriteSource.TYPO3;
  }
};

export const favoriteSourceToInfocenterSource = (source: FavoriteSource) => {
  switch (source) {
    case FavoriteSource.RESEARCH:
      return InfocenterType.RESEARCH;
    case FavoriteSource.STRAPI:
      return InfocenterType.STRAPI;
    case FavoriteSource.TYPO3:
      return InfocenterType.TYPO3_NEWS;
    default:
      return InfocenterType.STRAPI;
  }
};
