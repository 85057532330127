import React, { MouseEvent, forwardRef, useCallback, useContext, useMemo, useState } from "react";
import { IMenuItemWithPortalRoute } from "../context/nav-menu.context";
import { Typography } from "@mui/material";
import { NavMenuItemSubmenuIndicator } from "../nav-menu-item/nav-menu-item-submenu-indicator.component";
import { NavMenuItemButton } from "../nav-menu-item/nav-menu-item-button.component";
import { useNavigate } from "react-router-dom";
import { getAbsoluteRoutingPath } from "../../../../utils/get-absolute-routing-path.util";
import { NavNestedMenu } from "./nav-nested-menu.component";
import { BreadcrumbsContext } from "../context/breadcrumbs.context";
import { checkIfMenuItemNavigates } from "../helper/check-if-menu-item-navigates.helper";

interface INavNestedMenuItemProps {
  item: IMenuItemWithPortalRoute;

  onCloseAll: VoidFunction;
}

export const NavNestedMenuItem: React.FC<INavNestedMenuItemProps> = forwardRef((props, ref) => {
  const { item, onCloseAll } = props;
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const { currentMenuItem } = useContext(BreadcrumbsContext);
  const hasSubMenu = useMemo(() => item.menuItems.length > 0 && item.level < 4, [item]);

  const onClick = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      if (checkIfMenuItemNavigates(item)) {
        navigate({
          pathname: getAbsoluteRoutingPath(item.route),
        });
      }

      if (hasSubMenu) {
        setAnchorEl(anchorEl ? null : event.currentTarget);
      } else {
        onCloseAll();
      }
    },
    [anchorEl, hasSubMenu, navigate, item, onCloseAll],
  );

  const isSelected = useMemo(() => currentMenuItem?.route === item.route, [currentMenuItem, item]);

  return (
    <>
      <NavMenuItemButton onClick={onClick} isMenu={false} isSelected={isSelected}>
        {(isHovering: boolean) => (
          <>
            <Typography sx={{ mr: hasSubMenu ? 2 : 0 }}>{item.title}</Typography>
            {hasSubMenu && (
              <NavMenuItemSubmenuIndicator
                isOpen={false}
                isSelected={isSelected}
                isHovering={isHovering}
                sx={{ right: 0 }}
              />
            )}
          </>
        )}
      </NavMenuItemButton>
      {hasSubMenu && (
        <NavNestedMenu anchorEl={anchorEl} item={item} onClose={() => setAnchorEl(null)} onCloseAll={onCloseAll} />
      )}
    </>
  );
});
