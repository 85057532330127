import { Divider, Grid, Typography } from "@mui/material";
import parse from "html-react-parser";
import React, { useContext, useMemo } from "react";
import { Link } from "react-router-dom";
import { Favorite, SearchEvent } from "../../../api/muu-api/graphql/generated";
import { FilterEventsIcon } from "../../../assets/icons/icons.component";
import { EventsActionIconBar } from "../../../pages/events/events-action-icon-bar.component";
import { i18n } from "../../../translations/i18n";
import { format24ShortTime, formatMonthDate } from "../../../utils/date.util";
import { NavMenuContext } from "../../layout/nav/context/nav-menu.context";
import { WidgetRowMedia } from "../row/widget-row-media.component";
import { getEventDetailUrl } from "./helper/event.helper";
import { EVENT_WIDGET_MIN_ROW_HEIGHT } from "../../../theme/sizings.theme";
import { LayoutContext } from "../../layout/layout.context";

interface IEventsPostRowProps {
  event: SearchEvent;
  favorite: Favorite | undefined;
}

export const EventsPostRow: React.FC<IEventsPostRowProps> = (props) => {
  const { event, favorite } = props;
  const { pageOfInterestDictionary } = useContext(NavMenuContext);
  const { breakpoint } = useContext(LayoutContext);

  const eventDetailsPath = useMemo(
    () => getEventDetailUrl(event.id, event.eventType, pageOfInterestDictionary),
    [event, pageOfInterestDictionary],
  );

  return (
    <>
      <Link
        id={`event-row-link-${event.id}`}
        to={eventDetailsPath}
        style={{ textDecoration: "none", color: "inherit", font: "inherit" }}
      >
        <Grid
          container
          alignItems="center"
          columnSpacing={4}
          justifyContent="flex-start"
          sx={{ p: 2, pl: 4, pr: 4, display: "flex" }}
        >
          <Grid item xs="auto">
            <Typography variant="body2" fontWeight={600}>
              {formatMonthDate(event.startTime)}
            </Typography>
            <Typography variant="body2" sx={{ color: "grey.500" }}>
              {format24ShortTime(event.startTime, i18n.language)}
            </Typography>
          </Grid>
          <Grid item xs="auto" sx={{ mb: -2, minHeight: EVENT_WIDGET_MIN_ROW_HEIGHT[breakpoint] }}>
            <WidgetRowMedia media={event.media} defaultIcon={<FilterEventsIcon sx={{ color: "accent1.main" }} />} />
          </Grid>
          <Grid item xs>
            <Typography variant="body2" sx={{ fontWeight: "bold" }}>
              {parse(event.title ?? "")}
            </Typography>
            <Typography
              variant="body2"
              sx={{
                color: "grey.500",
                overflow: "hidden",
                display: "-webkit-box",
                WebkitLineClamp: 1,
                WebkitBoxOrient: "vertical",
              }}
            >
              {parse(event.teaser ?? "")}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            md="auto"
            sx={{
              justifyContent: "flex-end",
              flexGrow: 1,
              display: "flex",
            }}
          >
            <Grid item>
              <EventsActionIconBar event={event} favorite={favorite} />
            </Grid>
          </Grid>
        </Grid>
      </Link>
      <Divider />
    </>
  );
};
