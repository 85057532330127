import { useContext, useMemo } from "react";
import { IMenuItemWithPortalRoute, NavMenuContext } from "../components/layout/nav/context/nav-menu.context";

export const useBreadcrumbs = (currentMenuItem?: IMenuItemWithPortalRoute) => {
  const { menuItemsFlat } = useContext(NavMenuContext);

  return useMemo(() => {
    if (!currentMenuItem) {
      return [];
    }

    const routes = currentMenuItem.route.split("/");

    const breadcrumbs: IMenuItemWithPortalRoute[] = [];
    let incrementalRoute = "";
    routes.forEach((route, index) => {
      incrementalRoute = `${incrementalRoute}${index !== 0 ? "/" : ""}${route}`;
      const menuItem = menuItemsFlat.find((menuItem) => menuItem.route === incrementalRoute);
      if (menuItem && index < routes.length - 1) {
        breadcrumbs.push(menuItem);
      }
    });

    return breadcrumbs;
  }, [menuItemsFlat, currentMenuItem]);
};
